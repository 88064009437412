import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom'
import { Table } from 'reactstrap'
import { ShipmentState, AppState, Dispatcher, RootState, IOrderDetail } from "AppTypes";
import { shipmentActions } from "../../shipment/registration/state_management/actions";
import { appActions } from "../../../app/state_management/actions";
import { Modal } from "../../../components/Modal";
import moment from "moment";
import { success, error } from '@services/toast-service'
import { VALIDATE_FLOAT_TEMPERATURE } from "@const/constants";

type Props = {
    dispatch: Dispatcher;
    shipmentState: ShipmentState;
    appState: AppState;
};

const ShipmentOrderDetail: React.FC<Props> = ({ shipmentState, appState, dispatch }) => {
    const history = useHistory();
    const { orderByLocation, weatherInfoUpdating } = shipmentState;
    const { dashboardShipmentInfoExpaned } = appState;
    const { id, code, blockName, liftName, details, plannedDate, plannedVolume, shipmentStatusReport, weatherInfo, avgTemprature, minTemprature, maxTemprature } = orderByLocation;
    const { plannedCompletedTime, totalTransferedAmount, completedShipmentCount, completedTransferedAmount, unRegisteredAmount } = shipmentStatusReport;
    const [showWeatherModel, setShowWeatherModel] = useState(false);
    const locationId = localStorage.getItem('currentLocationId') || appState.currentLocationId || ''
    useEffect(() => {

        if (locationId) {
            dispatch(shipmentActions.fetchOrderByLocation(locationId));
        }
    }, [locationId]);

    const handleExpandShipmentDetail = () => {
        dispatch(appActions.setDashboardShipmentInfoExpaned(!dashboardShipmentInfoExpaned));
    }
    const handleSaveWeatherInfo = async () => {
        try {
            if (weatherInfoUpdating !== '' && weatherInfoUpdating.length > 10) {
                error("天候は10文字以下で入力してください。");
                return;
            }
            if ((minTemprature && !VALIDATE_FLOAT_TEMPERATURE.test(minTemprature.toString())) || (!minTemprature && minTemprature !== 0)) {
                error("最低気温は-99.99 ～ 99.9の数値で入力してください。");
                return;
            }
            if ((maxTemprature && !VALIDATE_FLOAT_TEMPERATURE.test(maxTemprature.toString())) || (!maxTemprature && maxTemprature !== 0)) {
                error("最高気温は-99.99 ～ 99.9の数値で入力してください。");
                return;
            }
            if ((avgTemprature && !VALIDATE_FLOAT_TEMPERATURE.test(avgTemprature.toString())) || (!avgTemprature && avgTemprature !== 0)) {
                error("平均気温は-99.99 ～ 99.9の数値で入力してください。");
                return;
            }
            const response = await dispatch(shipmentActions.updateLocationWeatherInfo({ weatherInfo : weatherInfoUpdating, minTemprature, maxTemprature, avgTemprature, id: locationId }));
            if (response.payload && response.payload.success) {

                success("保存が完了しました。");
                setShowWeatherModel(false);

            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }
    }



    const handleOpenWeatherInfoUpdateModal = () => {
        setShowWeatherModel(true)
    }
    const handleCancelWeatherUpdateModel = () => {
        setShowWeatherModel(false)
        dispatch(shipmentActions.setWeatherInfoData({ key: 'weatherInfo', value: weatherInfo }));
    }

    const handleChangeWeatherInfoData = (key: string, value: string | number) => {
        dispatch(shipmentActions.setWeatherInfoData({ key, value }));
    }

    const handleChangeWeatherInfoUpdating = (value: string) => {
        dispatch(shipmentActions.setWeatherInfoUpdating(value));
    }
    return (
        <>
            <div className="bg-warning text-center cursor-pointer" style={{ fontWeight: "bold", cursor: "pointer" }} onClick={handleExpandShipmentDetail}>打設情報</div>
            <div className="row">

                <div className={dashboardShipmentInfoExpaned ? "col-6" : "col-12"}>
                    <Table bordered size='sm'>
                        <tbody>
                            <tr><td className="text-center bg-light">打設日</td></tr>
                            <tr><td className="text-center">{plannedDate ? moment(plannedDate).format("YYYY年MM月DD日"): ""}</td></tr>
                            <tr><td className="text-center bg-light">打設場所</td></tr>
                            <tr><td className="text-center">{blockName} {liftName}</td></tr>
                            <tr><td className="text-center bg-light">計画打設量</td></tr>
                            <tr><td className="text-center">{plannedVolume.toFixed(2)} m3</td></tr>
                        </tbody>
                    </Table>

                    <div className="div-scroll-y">
                        <Table bordered className="text-center" size='sm'>
                            <thead>
                                <tr>
                                    <th className="bg-light">工場</th>
                                    <th className="bg-light">出荷量</th>
                                    <th className="bg-light">配合</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    details && details?.length > 0 ? details.map((od: IOrderDetail) =>
                                    (<tr key={od.id}>
                                        <th>{od.factoryName}</th>
                                        <th>{od.plannedTransferAmount.toFixed(2)}</th>
                                        <th>{od.mixtureName}</th>
                                    </tr>))
                                        :
                                        (<tr>
                                            <td colSpan={3}>データなし</td>
                                        </tr>)
                                }
                            </tbody>
                        </Table>

                    </div>
                </div>
                <div className="col-6" hidden={!dashboardShipmentInfoExpaned}>
                    <Table bordered size='sm'>
                        <tbody>
                            <tr><td className="text-center bg-light">終了予測</td></tr>
                            <tr><td className="text-center">{plannedCompletedTime ? moment(plannedCompletedTime).format("YYYY年MM月DD日 HH:MM") : "--"}</td></tr>
                            <tr><td className="text-center bg-light">出荷累計</td></tr>
                            <tr><td className="text-center">{totalTransferedAmount.toFixed(2)} m3</td></tr>
                            <tr><td className="text-center bg-light">荷卸終了</td></tr>
                            <tr><td className="text-center">{completedTransferedAmount}m3　　{completedShipmentCount}台</td></tr>
                            <tr><td className="text-center bg-light">進捗率</td></tr>
                            <tr><td className="text-center">
                                {
                                    plannedVolume > 0 && ((completedTransferedAmount / plannedVolume) * 100).toFixed(0)
                                }
                                {
                                    plannedVolume <= 0 && '0'
                                }%

                            </td></tr>
                            <tr><td className="text-center bg-light">残数量</td></tr>
                            <tr><td className="text-center">{unRegisteredAmount} m3</td></tr>
                            <tr><td className="text-center bg-light">天候</td></tr>
                            <tr>
                                <td className="text-center">

                                    <span>{weatherInfo}</span>

                                    <input type="button" style={{ float: "right" }} value="詳細" onClick={handleOpenWeatherInfoUpdateModal} />
                                </td>

                            </tr>
                        </tbody>
                    </Table>
                </div>

            </div>
            <Modal isActive={showWeatherModel} onClose={() => handleCancelWeatherUpdateModel()} title="天候登録" width="30%">
                <div className="modal-body">
                    <h6>天候を入力してください</h6>
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>
                                    <label htmlFor="txtWeatherInfo">天候</label>
                                </td>
                                <td>
                                    <input type="text" className="form-input" value={weatherInfoUpdating} onChange={(e) => { handleChangeWeatherInfoUpdating(e.target.value) }} />

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="txtWeatherInfo">最低気温</label>
                                </td>
                                <td>
                                    <input type="number" step="0.01" className="form-input" value={minTemprature} onChange={(e) => { handleChangeWeatherInfoData('minTemprature', e.target.value) }} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label htmlFor="txtWeatherInfo">最高気温</label>
                                </td>
                                <td>
                                    <input type="number" step="0.01" className="form-input" value={maxTemprature} onChange={(e) => { handleChangeWeatherInfoData('maxTemprature', e.target.value) }} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label htmlFor="txtWeatherInfo">平均気温</label>
                                </td>
                                <td>
                                    <input type="number" step="0.01" className="form-input" value={avgTemprature} onChange={(e) => { handleChangeWeatherInfoData('avgTemprature', e.target.value) }} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="modal-footer">
                    {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                    <input type="button" onClick={handleSaveWeatherInfo} value="登録" />　
                    )}
                    <input type="button" onClick={handleCancelWeatherUpdateModel} value="キャンセル" />
                </div>
            </Modal>

        </>
    )
}

export default connect((state: RootState) => ({
    shipmentState: state.shipment,
    appState: state.app
}))(ShipmentOrderDetail);