import axios from '@utils/axios-client'
import { appendParams } from '@utils/func'
import { IWarningTimeSetting } from 'AppTypes';
class LocationAPI {
    prefix = '/api/v1/Location'

     
    async fetchAll() {
        const url = `${this.prefix}/GetAll`;
        const response = await axios.get(url)
        return response.data;
    }

    async fetchLocationByConstructionModel(constructionModelId: string) {
        const url = `${this.prefix}/GetByConstructionModel?id=${constructionModelId}`;
        const response = await axios.get(url)
        return response.data;
    }

    async updateTimeLimit(params: any) {
        const url = `${this.prefix}/UpdateTimeLimit`
        return await axios.put(url, params)
    }

    async updatePump(params: any) {
        const url = `${this.prefix}/UpdatePump`
        return await axios.put(url, params)
    }

    async updateWeatherInfo(params: any) {
        const url = `${this.prefix}/UpdateWeatherInfo`
        return await axios.put(url, params)
    }

    async updateStatus(params: any) {
        const url = `${this.prefix}/UpdateStatus`
        return await axios.put(url, params)
    }

   
    delete(id: string) {
        const url = `${this.prefix}/Delete?id=${id}`
        return axios.delete(url)
    }

    async insert(params: FormData) {
        const url = `${this.prefix}/Create`
        return await axios.post(url, params)
    }

    async update(params: any) {
        const url = `${this.prefix}/Update`
        return await axios.put(url, params)
    }

    async GetWithMesh(params: any) {
        const url = appendParams(`${this.prefix}/GetWithMesh`, { ...params })
        // const url = `${this.prefix}/GetWithMesh`
        const response = await axios.get(url)
        return response.data;
    }

    async exportCastingRecordReport(locationId: string) {
        const url = `${this.prefix}/ReportCastingRecord?locationId=${locationId}`;
        const response = await axios.get(url, { responseType: 'blob' })
        return response.data
    }

    async exportCastingManagementReport(locationId: string) {
        const url = `${this.prefix}/ReportCastingManagement?locationId=${locationId}`;
        const response = await axios.get(url, { responseType: 'blob' })
        return response.data
    }

    async exportCastingTimeReport(locationId: string) {
        const url = `${this.prefix}/ReportCastingTime?locationId=${locationId}`;
        const response = await axios.get(url, { responseType: 'blob' })
        return response.data
    }

    async exportCastingDetailReport(locationId: string) {
        const url = `${this.prefix}/ReportCastingDetail?locationId=${locationId}`;
        const response = await axios.get(url, { responseType: 'blob' })
        return response.data
    }

    async GetMeshHistory(LocationId: string) {
        const url = `${this.prefix}/GetMeshHistory?LocationId=${LocationId}`
        const response = await axios.get(url)
        return response.data;
    }

    async getWarningTimeSettings(locationId: string) {
        const url = `${this.prefix}/GetWarningTimeSetting?locationId=${locationId}`;
        return axios.get<{ data: IWarningTimeSetting }>(url)
    }

    async changeMode(params: any) {
        const url = `${this.prefix}/ChangeMode`
        return await axios.put(url, params)
    }

    async fetchGetById(locationId: string) {
        const url = `${this.prefix}/GetById?id=${locationId}`;
        const response = await axios.get(url)
        return response.data;
    }

}

export const locationAPI = new LocationAPI()