import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { TCompressionExperimentManagement } from "./types";
import { ICompressionExperiment, IShippingInformationBrief } from "AppTypes";
import moment from "moment";

export const initialState: TCompressionExperimentManagement.TState = {
    compressionExperimentList: [],
    shippingInformationList: [],
    plannedDate: '',
    insert: {
        id: "",
        shippingInformationId: "",
        experimentDate: "",
        experimentAmount1: null,
        experimentAmount2: null,
        experimentAmount3: null,
        experimentAmountPerUnit1: null,
        experimentAmountPerUnit2: null,
        experimentAmountPerUnit3: null,
        compressionValue1: null,
        compressionValue2: null,
        compressionValue3: null,
        note: ""
    },
    update: {
        id: "",
        shippingInformationId: "",
        experimentDate: "",
        experimentAmount1: null,
        experimentAmount2: null,
        experimentAmount3: null,
        experimentAmountPerUnit1: null,
        experimentAmountPerUnit2: null,
        experimentAmountPerUnit3: null,
        compressionValue1: null,
        compressionValue2: null,
        compressionValue3: null,
        note: ""
    },
    selectedShipmentId: "",
    deleteId: "",
    isLoading: false
};

export const fetchList = createAsyncThunk(
    "compressionExperiment/fetchList",
    async (shippingInformationId: string, { rejectWithValue }) => {
        try {
            const response = await api.compressionExperimentAPI.fetchList(shippingInformationId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchShippingInformationList = createAsyncThunk(
    "compressionExperiment/fetchShippingInformationList",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.shippingInformationAPI.fetchListBrief(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const insert = createAsyncThunk(
    "compressionExperiment/insert",
    async (params: ICompressionExperiment, { rejectWithValue }) => {
        try {
            const response = await api.compressionExperimentAPI.insert(params);
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }

    }
);


export const update = createAsyncThunk(
    "compressionExperiment/update",
    async (params: ICompressionExperiment, { rejectWithValue }) => {
        try {
            const response = await api.compressionExperimentAPI.update(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const deleteOne = createAsyncThunk(
    "compressionExperiment/deleteOne",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.compressionExperimentAPI.delete(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);



const compressionExperimentSlice = createSlice({
    name: "compressionExperiment",
    initialState,
    reducers: {
        setSelectedShipmentId(state, action) {
            state.selectedShipmentId = action.payload;
        },
        setUpdateData(state, action) {
            const { key, value } = action.payload;
            const item = state.update;
            if (item) {
                item[key] = value;
            }
        },
        setUpdatingId(state, action) {
            if (action.payload) {
                const updatingItem = state.compressionExperimentList.find(x => x.id === action.payload)
                if (updatingItem) {
                    state.update = updatingItem;

                }
            } else {
                state.update = initialState.update;
            }

        },
        setDeleteId(state, action) {

            state.deleteId = action.payload;
        },
        resetInsert(state) {

            state.insert = initialState.insert;
        },
        setInsertData(state, action) {
            const { key, value } = action.payload;
            const item = state.insert;
            if (item) {
                item[key] = value;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchList.pending,
            (state) => {
                state.isLoading = true;
            }

        );

        builder.addCase(
            fetchShippingInformationList.fulfilled,
            (
                state,
                action: PayloadAction<IShippingInformationBrief[]>
            ) => {
                state.shippingInformationList = action.payload;
                state.isLoading = false;
            }
        );

        builder.addCase(
            fetchShippingInformationList.pending,
            (state) => {
                state.isLoading = true;
            }

        );
        builder.addCase(
            fetchList.fulfilled,
            (
                state,
                action: PayloadAction <ICompressionExperiment[]>
            ) => {

                state.compressionExperimentList = action.payload;
                if (state.compressionExperimentList.length > 1) {
                    state.plannedDate = moment(state.compressionExperimentList[0].experimentDate).subtract(7, 'd').format("YYYY/MM/DD");
                }
               
                state.isLoading = false;
            }
        );
    },
});

export const {
    setSelectedShipmentId,
    setUpdateData,
    setUpdatingId,
    setDeleteId,
    resetInsert,
    setInsertData
} = compressionExperimentSlice.actions;

export default compressionExperimentSlice.reducer;
