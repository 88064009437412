import axios from '@utils/axios-client'
import { IMixerTruck } from "AppTypes"

class MixerTruckAPI {
    prefix = '/api/v1/MixerTruck'

    fetchList() {
        const url = `${this.prefix}/GetAll`;
        return axios.get<{ data: IMixerTruck[] }>(url)
    }

}

export const mixerTruckAPI = new MixerTruckAPI()