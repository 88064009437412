export const JP_TRANSLATION = {
  loginForm: {
    userID: 'ユーザID',
    password: 'パスワード',
    loginBtn: 'ログイン',
    passwordField: {
      required: 'パスワードを入力してください'
    },
    userIDField: {
      required: 'ユーザIDを入力してください。',
      format: '半角英数字を入力してください。'
    },
    loginFailed: 'ユーザIDまたはパスワードに誤りがあります。'
  },
  validation: {
    default: {
      email: '有効なメールアドレスを入力してください',
      required: 'この項目は必須です',
      titleRequired: 'タイトルを入力してください',
      contentRequired: '内容を入力してください',
      invalidEmailOrPassword: '無効なメールアドレスまたはパスワード',
      invalidUserNameOrPassword: 'I無効なユーザー名またはパスワード',
      emailDoesntExist: 'メールは存在しません',
      userOrEmailDoesntExist: 'ユーザーまたは電子メールが存在しません',
      typePostCode: '数字のみである必要があります。',
      lengthPostCode: '正確に7文字である必要があります。',
      phoneNumber: '電話番号の形式が無効です。',
      fileUpload: '動画をアップロードしてください'
    },
    videoCategoryForm: {
      titleField: {
        required: 'カテゴリ名を入力してください',
        max: 'Too Long!',
        duplicate: 'Exist'
      }
    },
    videoForm: {
      titleField: {
        required: 'タイトルを入力してください',
        max: 'Too Long!'
      },
      videoCategoryField: {
        required: 'カテゴリを入力してください'
      },
      displayOrder: {
        max: 'Too Long!'
      },
      description: {
        max: 'Too Long!'
      },
      file: {
        FILE_SIZE: 'Uploaded file is too big.',
        FILE_FORMAT: 'Uploaded file has unsupported format.',
        required: 'file is required'
      }
    },
    menuTypeForm: {
      name: 'メニューを入力してください。',
      category: 'カテゴリを選択してください。',
      price: '0円以上のお金を入力してください。'
    }
  },
  common: {
    pleaseEnter: '入力してください',
    pleaseSelect: '選択してください',
    save: '保存',
    detail: '詳細',
    edit: '編集',
    delete: '削除',
    disable: '無効化',
    insert: '挿入',
    cancel: 'キャンセル',
    ok: 'OK',
    All: '全て',
    OneMonth: '1ヶ月',
    SixMonth: '6ヶ月',
    TwelveMonth: '12ヶ月',
    san: 'さん',
    okToRemove: '削除します。よろしいですか。'
  },
  messages: {
    saveDataSuccessfully: '保存が完了しました。',
    saveDataFailed: 'データの保存に失敗しました',
    deleteDataSuccessfully: '削除しました',
    createBillingSuccess: '支払いが正常に作成されました。',
    generalErrorMsg: '予期せぬエラーが発生した場合。この場合、レスポンスボディは返却しません。',
    pageNotFoundMsg: 'ページが見つかりません',
    invalidUserNameOrPasswordMsg: '無効なユーザー名またはパスワード',
    notHaveMoney: 'アカウントの金額を確認してください。',
    unauthorized: 'このリソースにアクセスする権限がありません。 ログインしていないか、IPアドレスがシステムで許可されていません。 詳細については、管理者にお問い合わせください。',
    imageRequire: '画像を追加ください。'
  },
  actions: {
    goBack: '戻る',
    goForward: '次の一歩',
    reloadPage: 'ページをリロード',
    submit: 'Submit'
  },
  states: {
    noData: 'データなし'
  },
}
