export const EN_TRANSLATION = {
  loginForm: {
    userID: 'ユーザID',
    password: 'パスワード',
    loginBtn: 'ログイン',
    passwordField: {
      required: 'パスワードを入力してください'
    },
    userIDField: {
      required: 'ユーザIDを入力してください。'
    },
    loginFailed: 'ユーザIDまたはパスワードに誤りがあります。'
  },
  validation: {
    default: {
      email: 'Invalid email',
      required: 'This field is required',
      invalidEmailOrPassword: 'Invalid email address or password',
      invalidUserNameOrPassword: 'Invalid username or password',
      emailDoesntExist: 'Email does not exists',
      userOrEmailDoesntExist: 'User or email does not exists',
      typePostCode: 'Must be only digits.',
      lengthPostCode: 'Must be exactly 7 characters.',
      phoneNumber: 'Invalid phone number format.',
      fileUpload: 'Please upload video'
    },
    menuTypeForm: {
      name: 'Please enter your name menu',
      category: 'Please choose category',
      price: 'Please enter money of 0 yen or more.'
    }
  },
}
