import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "./Setting.css";

const Setting = () => {
    const history = useHistory();
    return (
        <div>
            <div className="settingContainer">
                <div className="settingPageTitle">
                    <p>各種設定</p>
                </div>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>1.</td>
                            <td>ログインユーザー登録</td>
                        </tr>
                        <tr>
                            <td>2.</td>
                            <td>
                                <a
                                    aria-hidden="true"
                                    onClick={() => history.push("/setting/factory")}
                                >
                                    生コン工場登録
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>3.</td>
                            <td>

                                <a
                                    aria-hidden="true"
                                    onClick={() => history.push("/setting/mixture")}
                                >
                                    配合登録
                                </a>                            </td>
                        </tr>
                        <tr>
                            <td>4.</td>
                            <td>

                                <a
                                    aria-hidden="true"
                                    onClick={() => history.push("/setting/blocklift")}
                                >
                                    打設場所登録
                                </a>                            </td>
                        </tr>
                        <tr>
                            <td>5.</td>
                            <td>
                                <a
                                    aria-hidden="true"
                                    onClick={() => history.push("/setting/filecategory")}
                                >
                                    文書管理分類登録
                                </a>       

                            </td>
                        </tr>
                        <tr>
                            <td>6.</td>
                            <td>
                                <a
                                    aria-hidden="true"
                                    onClick={() => history.push("/setting/warningtime")}
                                >
                                    警告時間設定
                                </a>         
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default connect()(Setting);
