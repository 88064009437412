import axios from '@utils/axios-client'
import { ICompressionExperiment } from 'AppTypes';

class CompressionExperimentAPI {
    prefix = '/api/v1/CompressionExperiment'



    async fetchList(shippingInformationId: string) {
        const url = `${this.prefix}/Get?shippingInformationId=${shippingInformationId}`;
        const response = await axios.get<{ data: ICompressionExperiment[] }>(url)
        return response.data;
    }

    async insert(params: ICompressionExperiment) {
        const url = `${this.prefix}/Create`
        return await axios.post(url, params)
    }

    async insertByShipment(shipmentId: string) {
        const url = `${this.prefix}/CreateByShipment`
        return await axios.post(url, { shipmentId });
    }

    async update(params: ICompressionExperiment) {
        const url = `${this.prefix}/Update`
        return await axios.put(url, params)
    }

    delete(id: string) {
        const url = `${this.prefix}/Delete?id=${id}`
        return axios.delete(url)
    }

    deleteByShipment(shipmentId: string) {
        const url = `${this.prefix}/DeleteByShipment?shipmentId=${shipmentId}`
        return axios.delete(url)
    }
}

export const compressionExperimentAPI = new CompressionExperimentAPI()