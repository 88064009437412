import { TFileCategoryManagement } from '@features/setting/filecategory/state_management/types'
import axios from '@utils/axios-client'
import { appendParams } from '@utils/func'

class FileCategoryAPI {
    prefix = '/api/v1/FileCategory'



    fetchAll(isActive: boolean | null) {
        const url = appendParams(`${this.prefix}/GetAll`, { isActive })
        return axios.get<{ data: TFileCategoryManagement.FileCategory[] }>(url)
    }

    async insert(params: TFileCategoryManagement.FileCategory) {
        const url = `${this.prefix}/Create`
        return await axios.post(url, params)
    }

    async update(params: TFileCategoryManagement.FileCategory) {
        const url = `${this.prefix}/Update`
        return await axios.put(url, params)
    }

    delete(id: string) {
        const url = `${this.prefix}/Delete?id=${id}`
        return axios.delete(url)
    }
}

export const fileCategoryAPI = new FileCategoryAPI()