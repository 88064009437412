import { TShippingInformationManagement } from '@features/shippinginformation/state_management/types'
import axios from '@utils/axios-client'
import { appendParams } from "@utils/func"
import {
    IProcessChartData, IShippingInformationBrief, ISlumpInfo
} from 'AppTypes'

class ShippingInformationAPI {
    prefix = '/api/v1/ShippingInformation'

    async fetchList(param: any) {
        const url = appendParams(`${this.prefix}/GetAll`, { ...param });
        const response = await axios.get<{ data: TShippingInformationManagement.ShippingInformation[] }>(url)
        return response.data;
    }

    async fetchListForEditor(param: any) {
        const url = appendParams(`${this.prefix}/GetAllForEditor`, { ...param });
        const response = await axios.get<{ data: TShippingInformationManagement.ShippingInformation[] }>(url)
        return response.data;
    }

    async exportShipmentReport() {
        const url = `${this.prefix}/Export`;
        const response = await axios.get(url, { responseType: 'blob' })
        return response.data;
    }

    async fetchListBrief(params: any) {
        const url = appendParams(`${this.prefix}/GetAllBrief`, { ...params });
        const response = await axios.get<{ data: IShippingInformationBrief[] }>(url)
        return response.data;
    }

    async updateRange(params: any) {
        const url = `${this.prefix}/UpdateRange`
        return await axios.put(url, params)
    }

    delete(id: string) {
        const url = `${this.prefix}/Delete?id=${id}`
        return axios.delete(url)
    }

    async deleteRange(ids: string[]) {
        const url = `${this.prefix}/DeleteRange`
        return await axios.post(url, { ids })
    }

    async fetchProcessChartData(params: any) {
        const url = appendParams(`${this.prefix}/GetChartData`, { ...params });
        const response = await axios.get<{ data: IProcessChartData }>(url)
        return response.data;
    }


    async fetchSlumpInfo(id: string) {
        const url = `${this.prefix}/GetSlumpInfo?id=${id}`;
        const response = await axios.get<{ data: ISlumpInfo }>(url)
        return response.data;
    }

    async updateSlumpInfo(params: ISlumpInfo) {
        const url = `${this.prefix}/UpdateSlumpInfo`
        return await axios.patch(url, params)
    }

}

export const shippingInformationAPI = new ShippingInformationAPI()