import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { EN_TRANSLATION } from './en/translation'
import { JP_TRANSLATION } from './jp/translation'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: EN_TRANSLATION
      },
      jp: {
        translation: JP_TRANSLATION
      }
    },
    keySeparator: '.'
  })

i18n.changeLanguage('jp')

export default i18n
