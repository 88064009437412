import { connect } from "react-redux";
import { VersionState, Dispatcher, RootState } from "AppTypes";


type Props = {
    dispatch: Dispatcher;
    versionState: VersionState;
};


const VersionPage: React.FC<Props> = ({ versionState, dispatch }) => {
    return (
        <>

            <div className="settingItemPageTitle">
                <p>バージョン</p>
            </div>
            <div className="text-center">This is version page</div>
        </>
    );
};

export default connect((state: RootState) => ({
    versionState: state.version
}))(VersionPage);
