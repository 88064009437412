import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { QUERY_PAGING, regularNumber } from "@share/const";
//import { TFactoryManagement } from "./state_management/types";
import { connect } from "react-redux";
import { MeshSettingState, Dispatcher, RootState, IMeshSetting } from "AppTypes";
import { meshSettingActions } from "./state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Modal } from "../../../components/Modal";
import { success, error } from '@services/toast-service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleUp, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'
import { isNumericInput } from '@utils/format'
type Props = {
    dispatch: Dispatcher;
    meshSettingState: MeshSettingState;
};


const MeshSetting: React.FC<Props> = ({ meshSettingState, dispatch }) => {


    const { isLoading, meshSettingList } = meshSettingState;

    const history = useHistory();

    useEffect(() => {
        dispatch(meshSettingActions.fetch());
    }, []);



    const handleSave = async () => {
        if (meshSettingList.find(x => x.transferTime < 0)) {
            error("透過率は0～100の数値で入力してください");
            return;
        }
        try {
            const response = await dispatch(meshSettingActions.updateRange(meshSettingList));
            if (response.payload && response.payload.success) {
                success("保存が完了しました。");
            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }

    const handleIncreaseTransferTime = (id: string) => {

        const meshSetting = meshSettingList.find(x => x.id === id);

        let value = 0;
        if (meshSetting) {
            if (meshSetting.transferTime >= 100) {
                value = 100;
            } else if (meshSetting.transferTime < 0) {
                value = 0;
            } else {
                value = meshSetting.transferTime + 1;
            }
            dispatch(meshSettingActions.setMeshTransferTime({ id, value }));
        }
      

    }


    const handleChangeTransferTime = (e: any, id: string, value: string) => {

        var intVal = parseInt(value ? value :  "0");
        if (intVal < 0 || intVal > 100) {
            error('透過率は0～100の数値を入力してください');
            return;
        }

        const meshSetting = meshSettingList.find(x => x.id === id);
        if (meshSetting) {
           
            dispatch(meshSettingActions.setMeshTransferTime({ id, value: intVal }));
        }

    }


    const handleDecreaseTransferTime = (id: string) => {

        const meshSetting = meshSettingList.find(x => x.id === id);

        let value = 0;
        if (meshSetting) {
            if (meshSetting.transferTime > 100) {
                value = 100;
            } else if (meshSetting.transferTime <= 0) {
                value = 0;
            } else {
                value = meshSetting.transferTime - 1;
            }
            dispatch(meshSettingActions.setMeshTransferTime({ id, value }));
        }
    }

    return (
        <>
            <LoadingSpinner isShowing={isLoading} />
            <div className="settingItemPageTitle">
                <p>メッシュカラー設定</p>
            </div>
            <div className="setting-container table-40">
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>

                            <th scope="col">項目</th>
                            <th scope="col">色</th>
                            <th scope="col">透過率（％）</th>
                        {/*    <th style={{ maxWidth: "10%" }} />*/}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            meshSettingList.length ? meshSettingList.map((m: IMeshSetting) => (
                                <tr key={m.id}>
                                    <td>{m.name}</td>
                                    <td>{m.colorName}</td>
                                    <td>
                                        <input
                                            type="number"
                                            className="form-control wd-100"
                                            value={m.transferTime}
                                            onChange={e => regularNumber.test(e.target.value) && handleChangeTransferTime(e, m.id, e.target.value)} />
                                        {/*{m.transferTime}*/}


                                    </td>
                                {/*    <td className="settingControlContainer">*/}
                                {/*        <p><FontAwesomeIcon size="lg" icon={faChevronCircleUp} onClick={() => handleIncreaseTransferTime(m.id)} /></p>*/}

                                {/*        <p><FontAwesomeIcon size="lg" icon={faChevronCircleDown} onClick={() => handleDecreaseTransferTime(m.id)} /></p>*/}
                                {/*    </td>*/}
                                </tr>
                            )) : (

                                <tr>
                                    <td colSpan={3}>
                                        データなし
                                    </td>

                                </tr>)
                        }
                    </tbody>
                </table>
            </div>
            <div className="goHomeButtonContainer mb-5">
                <input type="button" value="登録" onClick={() => { handleSave() }} className="goHomeButton" />
                <input type="button" value="戻る" onClick={() => { history.goBack() }} className="goHomeButton ml-5" />
            </div>
        </>
    );
};

export default connect((state: RootState) => ({
    meshSettingState: state.meshSetting
}))(MeshSetting);
