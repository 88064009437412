import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { TConstructionManagement } from "./types";

export const initialState: TConstructionManagement.TState = {
  statusCallGetListMesh: false,
  modeShow: 1,
  isLoading: false,
  // floorCanEdit: 1,
  isShowTableMesh: false,
  isShowFullScreen: false,
};

export const fetchUpdateMesh = createAsyncThunk(
  "constructionManagement/fetchUpdateMesh",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await api.meshAPI.siteUpdate(params);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchResetBeforePumping = createAsyncThunk(
  "constructionManagement/resetBeforePumping",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await api.meshAPI.resetBeforePumping(params);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUpdateMeshList = createAsyncThunk(
  "constructionManagement/fetchUpdateMeshList",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await api.meshAPI.siteUpdateList(params);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchLocationUpdateStatus = createAsyncThunk(
  "constructionManagement/fetchLocationUpdateStatus",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await api.meshAPI.locationUpdateStatus(params);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const revertByPump = createAsyncThunk(
    "constructionManagement/revertByPump",
    async (pumpId: string, { rejectWithValue }) => {
        try {
            const response = await api.meshAPI.revertByPump(pumpId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);



const constructionSlice = createSlice({
  name: "construction",
  initialState,
  reducers: {
    setStatusCallGetListMesh(state, action) {
      state.statusCallGetListMesh = action.payload;
    },
    setModeShow(state, action) {
      state.modeShow = action.payload;
    },
    // setFloorCanEdit(state, action) {
    //   state.floorCanEdit = action.payload
    // },
    setShowTableMesh(state, action) {
      state.isShowTableMesh = action.payload
    },
    setShowFullScreen(state, action) {
      state.isShowFullScreen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUpdateMesh.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUpdateMesh.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchUpdateMesh.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchResetBeforePumping.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchResetBeforePumping.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchResetBeforePumping.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchUpdateMeshList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUpdateMeshList.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchUpdateMeshList.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchLocationUpdateStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLocationUpdateStatus.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchLocationUpdateStatus.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  setStatusCallGetListMesh,
  setModeShow,
  setShowTableMesh,
  setShowFullScreen,
} =
  constructionSlice.actions;

export default constructionSlice.reducer;
