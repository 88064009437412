import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { IMeshSetting } from "AppTypes";
import { TMeshSettingManagement } from "./types";

export const initialState: TMeshSettingManagement.TState = {
    meshSettingList: [],
    isLoading: false
};

export const fetch = createAsyncThunk(
    "meshSetting/fetch",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.meshSettingAPI.fetch();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);




export const updateRange = createAsyncThunk(
    "meshSetting/updateRange",
    async (params: IMeshSetting[], { rejectWithValue }) => {
        try {
            const response = await api.meshSettingAPI.updateRange(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const meshSetting = createSlice({
    name: "meshSetting",
    initialState,
    reducers: {
        setMeshTransferTime(state, action) {
            const { id, value } = action.payload;
            const mesh = state.meshSettingList.find(x => x.id === id);
            if (mesh) {
                mesh.transferTime = value;
            }
        },
        setMeshTransferTimeAll(state, action) {
            state.meshSettingList = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetch.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(
            fetch.fulfilled,
            (
                state,
                action: PayloadAction<{ data: IMeshSetting[] }>
            ) => {
                state.meshSettingList = action.payload.data;
                state.isLoading = false;
            }
        );
    },
});

export const {
    setMeshTransferTime,
    setMeshTransferTimeAll,
} = meshSetting.actions;

export default meshSetting.reducer;
