import axios from '@utils/axios-client'
import {
    UserAuthenticateRequest
} from 'AppTypes'
import { TAuthManagement } from '../../features/auth/state_management/type';

export async function authenticate(payload: UserAuthenticateRequest) {
    const response = await axios.post('/api/v1/User/Authenticate', payload)
    return response;

}

export async function fetchRedirectConfig() {
    const response = await axios.get<{ data: TAuthManagement.TState["loginRedirectConfig"] }>('/api/v1/User/GetLoginRedirect')
    return response;

}
