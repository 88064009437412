import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { IDisplayItemSetting } from "AppTypes";
import { TDisplayItemSettingManagement } from "./types";
import { settingCastingDefault, settingShippingDefault } from "@const/constants";
import { WritableDraft } from "immer/dist/internal";

export const initialState: TDisplayItemSettingManagement.TState = {
    displayItemSettingList: [],
    isLoading: false
};
export const fetchList = createAsyncThunk(
    "displayItemSetting/fetchList",
    async (type: number | null, { rejectWithValue }) => {
        try {
            const response = await api.displayItemSettingAPI.fetchAll(type);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const updateRange = createAsyncThunk(
    "fatoryManagement/update",
    async (data: IDisplayItemSetting[], { rejectWithValue }) => {
        try {
            const response = await api.displayItemSettingAPI.updateRange(data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const displayItemSettingSlice = createSlice({
    name: "displayItemSetting",
    initialState,
    reducers: {
        setItemData(state, action) {
            const { key, value, id } = action.payload;
            const selectedItem = state.displayItemSettingList.find(x => x.id === id);
            if (selectedItem) {
                selectedItem[key] = value;
            }
        },
        increaseShipmentDisplayOrder(state, action) {
            const item = state.displayItemSettingList.find(x => x.id === action.payload);
            const shipmentDisplaySettings = state.displayItemSettingList?.filter(x => x.type === "Shipping") ?? [];

            if (item) {
                const { displayOrder } = item;
                const nextItem = shipmentDisplaySettings.find(x => x.displayOrder === displayOrder + 1);
                if (nextItem) {
                    let order = item.displayOrder;
                    item.displayOrder = nextItem.displayOrder;
                    nextItem.displayOrder = order;
                }
            }
        },
        setFirstShipmentDisplayOrder(state, action) {
            const shipmentDisplaySettings: any[] = [];
            for (const itemDisplay of state.displayItemSettingList) {
                if (itemDisplay.type === action.payload.type) {
                    shipmentDisplaySettings.push(itemDisplay)
                }
            }
            if (shipmentDisplaySettings?.length > 0) {
                const itemIndex = shipmentDisplaySettings.findIndex((item: any) => item.id === action.payload.id);
                if (itemIndex > -1) {
                    shipmentDisplaySettings.forEach((item: any) => {
                        if (item.displayOrder < shipmentDisplaySettings[itemIndex].displayOrder && item.id !== action.payload.id) {
                            item.displayOrder += 1;
                        }
                    });
                    shipmentDisplaySettings.forEach((item: any) => {
                        if (item.id === action.payload.id) {
                            item.displayOrder = 1;
                        }
                    });
                }
            }
        },
        decreaseShipmentDisplayOrder(state, action) {
            const item = state.displayItemSettingList.find(x => x.id === action.payload);
            const shipmentDisplaySettings = state.displayItemSettingList?.filter(x => x.type === "Shipping") ?? [];

            if (item) {
                const { displayOrder } = item;
                const nextItem = shipmentDisplaySettings.find(x => x.displayOrder === displayOrder - 1);
                if (nextItem) {
                    let order = item.displayOrder;
                    item.displayOrder = nextItem.displayOrder;
                    nextItem.displayOrder = order;
                }
            }
        },
        setLastShipmentDisplayOrder(state, action) {
            const shipmentDisplaySettings: any[] = [];
            for (const itemDisplay of state.displayItemSettingList) {
                if (itemDisplay.type === action.payload.type) {
                    shipmentDisplaySettings.push(itemDisplay)
                }
            }
            if (shipmentDisplaySettings?.length > 0) {
                const itemIndex = shipmentDisplaySettings.findIndex((item: any) => item.id === action.payload.id);
                if (itemIndex > -1) {
                    shipmentDisplaySettings.forEach((item: any) => {
                        if (item.displayOrder > shipmentDisplaySettings[itemIndex].displayOrder && item.id !== action.payload.id) {
                            item.displayOrder -= 1;
                        }
                    });
                    shipmentDisplaySettings.forEach((item: any) => {
                        if (item.id === action.payload.id) {
                            item.displayOrder = shipmentDisplaySettings.length;
                        }
                    });
                }
            }
        },
        increaseCastingDisplayOrder(state, action) {
            const item = state.displayItemSettingList.find(x => x.id === action.payload);
            const shipmentDisplaySettings = state.displayItemSettingList?.filter(x => x.type === "Casting") ?? [];
            if (item) {
                const { displayOrder } = item;
                const nextItem = shipmentDisplaySettings.find(x => x.displayOrder === displayOrder + 1);
                if (nextItem) {
                    let order = item.displayOrder;
                    item.displayOrder = nextItem.displayOrder;
                    nextItem.displayOrder = order;
                }
            }
        },
        decreaseCastingDisplayOrder(state, action) {
            const item = state.displayItemSettingList.find(x => x.id === action.payload);
            const shipmentDisplaySettings = state.displayItemSettingList?.filter(x => x.type === "Casting") ?? [];
            if (item) {
                const { displayOrder } = item;
                const nextItem = shipmentDisplaySettings.find(x => x.displayOrder === displayOrder - 1);
                if (nextItem) {
                    let order = item.displayOrder;
                    item.displayOrder = nextItem.displayOrder;
                    nextItem.displayOrder = order;
                }
            }
        },
        setDefaultSetting(state, action) {
            if (action.payload.type === "Casting") {
                settingCastingDefault.forEach((itemDefault, indexDefault) => {
                    const indexDisplayFind = state.displayItemSettingList?.find(item => item.code === itemDefault && item.type === action.payload.type);
                    if (indexDisplayFind) {
                        indexDisplayFind.displayOrder = indexDefault + 1;
                        indexDisplayFind.isDisplayed = true;
                    }
                })
            } else {
                settingShippingDefault.forEach((itemDefault, indexDefault) => {
                    const indexDisplayFind = state.displayItemSettingList?.find(item => item.code === itemDefault && item.type === action.payload.type);
                    if (indexDisplayFind) {
                        indexDisplayFind.displayOrder = indexDefault + 1;
                        indexDisplayFind.isDisplayed = true;
                    }
                })
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(
            fetchList.fulfilled,
            (
                state,
                action: PayloadAction<IDisplayItemSetting[]>
            ) => {
                state.displayItemSettingList = action.payload;
                state.isLoading = false;
            }
        );
    },
});

export const {
    setItemData,
    increaseShipmentDisplayOrder,
    decreaseShipmentDisplayOrder,
    increaseCastingDisplayOrder,
    setFirstShipmentDisplayOrder,
    decreaseCastingDisplayOrder,
    setLastShipmentDisplayOrder,
    setDefaultSetting,
} = displayItemSettingSlice.actions;

export default displayItemSettingSlice.reducer;
