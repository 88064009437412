import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import '../404/404.css'

type Props = {}

const Unauthorized: React.FC<Props> = () => {
  const { t } = useTranslation()

  return (
    <div id="unexpected-error" style={{ flexDirection: 'column' }}>
      <section className="unexpected-error-block text-center">
        <div className="no-gutters align-items-center">
          <div className="col-12 unexpected-error-content p-4">
            <h1>401</h1>
            <p>{t('messages.unauthorized')}</p>
          </div>
        </div>
      </section>
      <div aria-hidden="true" className="plaintext-button text-center">
        <Link role="button" className="btn btn-lg btn-info text-white" to="/login">{t('auth.login')}</Link>
      </div>
    </div>
  )
}

export default Unauthorized
