import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { LOGINTYPE } from "@share/const"
import {
    AuthState,
    Dispatcher,
    RootState,
} from 'AppTypes'
import { useQuery } from '@utils/func'
import { authActions } from './state_management/actions'
import './LoginSelector.css'

type Props = {
    dispatch: Dispatcher,
    authState: AuthState,
}

const LoginSelector: React.FC<Props> = ({
    authState,
    dispatch
}) => {
    const query = useQuery();

    useEffect(() => {
        dispatch(authActions.fetchRedirectConfig());
    }, [])

    const { loginRedirectConfig } = authState;
    const handleSelectLogin = (type: string) => {
        dispatch(authActions.setLoginType(type));
    }

    return (
        <div className="loginSelector-background">
            <div className={`${process.env.REACT_APP_MODE_RUN !== 'viewer' ? 'col-12 row' : 'col-12'}`}>
                <div className={`${process.env.REACT_APP_MODE_RUN !== 'viewer' ? 'col-md-6 col-sm-12' : ''}`}>
                    <div className="position-relative item-selector">
                        <div className="title-selector fs-2">
                            <Link to={`/login${query.get('redirectTo') ? `?redirectTo=${query.get('redirectTo')}` : ''}`} onClick={() => handleSelectLogin(LOGINTYPE.OFFICE)}>事務所　管理画面</Link>
                        </div>
                        <div className="image-selector image-selector-office"></div>
                    </div>
                </div>
                {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                    <div className="col-md-6 col-sm-12 position-relative">
                        <div className="position-relative item-selector">
                            <div className="title-selector fs-2">
                                <Link to={`/login${query.get('redirectTo') ? `?redirectTo=${query.get('redirectTo')}` : ''}`} onClick={() => handleSelectLogin(LOGINTYPE.WORKINGSITE)}>現場　管理画面</Link>
                            </div>
                            <div className="image-selector image-construction"></div>
                        </div>
                    </div>
                )}
            </div>
            {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                <div className="col-12 row">
                    <div className="col-md-6 col-sm-12 position-relative">
                        <div className="position-relative item-selector">
                            <div className="title-selector fs-2">
                                <a href={loginRedirectConfig.unshippedListUrl}>生コン工場　管理画面</a>
                            </div>
                            <div className="image-selector image-concrete-factory"></div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 position-relative">
                    <div className="position-relative item-selector pump-container">
                            <div className="title-selector w-100">
                                <div className="fs-2 mb-4">
                                    <a href={loginRedirectConfig.tagManagementUrl}>生コン車　管理画面</a>
                                    <img className="fs-2 image-card-ic" src="/assets/card-ic.png" alt="" />
                                </div>
                                <div className="fs-4 d-flex flex-column align-items-start w-100 justify-content-start">
                                    <div className="ps-1 w-70">※ICタグを利用しない場合（手動登録）</div>
                                    <div className="w-55"><a href={loginRedirectConfig.endListUrl}>現場到着・荷卸管理画面</a></div>
                                    <div className="select-child pl-5">
                                        <div><a href={loginRedirectConfig.undeliveredListUrl}>現場到着</a>のみ</div>
                                        <div><a href={loginRedirectConfig.unloadingListUrl}>荷卸</a>のみ</div>
                                    </div>
                                </div>
                            </div>
                            <div className="image-selector image-concrete-car"></div>
                        </div>
                        <div className="title-selector"></div>
                    </div>
                </div>
            )}
            <div className="footer">
                SKY SYSTEM
            </div>
        </div>
    )
}

export default connect((state: RootState) => ({
    authState: state.auth,
    routerState: state.router
}))(LoginSelector)
