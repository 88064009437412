import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { Table } from 'reactstrap'
import { ShippingInformationState, Dispatcher, RootState } from "AppTypes";
import { connect } from "react-redux";
import { shippingInformationActions } from "./state_management/actions";
import { shipmentActions } from "../shipment/registration/state_management/actions";
import LoadingSpinner from "../../components/LoadingSpinner";
import { TShippingInformationManagement } from "./state_management/types";
import moment from "moment";
import { Modal } from "../../components/Modal";
import { success, error } from '@services/toast-service'
import "./ShippingInformationEditor.css"

type Props = {
    dispatch: Dispatcher;
    shippingInformationState: ShippingInformationState;
};

const ShippingInformationEditor: React.FC<Props> = ({ shippingInformationState, dispatch }) => {
    const { shippingInformationList, shippingInformationListBackup, pumpList } = shippingInformationState;
   
    const { isLoading } = shippingInformationState;
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
    const history = useHistory();
    const locationId = localStorage.getItem('currentLocationId') || ''

    useEffect(() => {
        dispatch(shippingInformationActions.fetchMixerTruckList());
        if (locationId) {
            dispatch(shippingInformationActions.fetchListForEditor({ locationId }));
            dispatch(shippingInformationActions.fetchPumpList({ locationId }));
        } else {
            history.push("/locationselector")
        }

    }, []);


    const statuses = [{ key: "NotStartYet", value: "まだ" },
    { key: "Moving", value: "まだ" },
    { key: "Arrived", value: "現着" },
    { key: "Unloading", value: "荷卸中" },
    { key: "Completed", value: "終了" }]

    const getStatus = (key: string) => {
        const status = statuses.find(x => x.key === key);
        if (status) {
            return status.value;
        }
        return "まだ";
    }

    const handleDeleteCancelButtonClick = async () => {
        setShowDeleteConfirmModal(false);
    }

    const handleChangeCellValue = (id: string, key: string, value: string | number | boolean) => {
        dispatch(shippingInformationActions.setCellValue({ id, key, value: key === 'transferAmount' && typeof(value) === 'number' ? value.toFixed(2) : value }));
    }

    const handleSaveEdittedData = async () => {
        try {
            const postedData = shippingInformationList.map((x: TShippingInformationManagement.ShippingInformation) => ({
                id: x.id,
                truckCode: x.truckCode,
                pumpId: x.pumpId,
                transferAmount: x.transferAmount || 0,
                transferTime: x.transferTime || null,
                arrivalTime: x.arrivalTime || null,
                pumpStartTime: x.pumpStartTime || null,
                pumpFinishTime: x.pumpFinishTime || null,
                isActive: x.isActive,
                isExperimented: x.isExperimented,
                isCompressiveExperimented: x.isCompressiveExperimented,
                slumpAmount: x.slumpAmount || null,
                airAmount: x.airAmount || null,
                conTemperature: x.conTemperature || null,
                outsideTemperature: x.outsideTemperature || null,
                unitWaterVolume: x.unitWaterVolume || null,
                unitWaterVolumneRI: x.unitWaterVolumneRI || null,
                unitVolumeMassRI: x.unitVolumeMassRI || null,
                cloAmount: x.cloAmount || null,
                compressiveStrength: x.compressiveStrength ?? null
            }));
            const response = await dispatch(shippingInformationActions.updateRange({ shippingInfos: postedData }));
            if (response.payload && response.payload.success) {
                await dispatch(shippingInformationActions.fetchListForEditor({ locationId }));
                await dispatch(shipmentActions.fetchOrderByLocation(locationId));
                await dispatch(shipmentActions.fetchProcessChartData({ locationId }));
                success("保存が完了しました。");

            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }
    }

    const bulkDeleteClick = () => {
        setShowDeleteConfirmModal(true)
    }

    const handleConfirmDeleteButtonClick = async () => {
        const deletedIds = shippingInformationList.filter(x => x.checked).map(x => x.id);
        if (deletedIds.length) {
            const response = await dispatch(shippingInformationActions.deleteRange(deletedIds));
            if (response.payload && response.payload.success) {
                dispatch(shippingInformationActions.fetchListForEditor({ locationId }));
                success("削除しました。");
            } else {
                error(response.payload.errors[0])
            }
        }
        setShowDeleteConfirmModal(false);
        return;

    }

    const resetData = () => {
        dispatch(shippingInformationActions.resetData())
    }

    const handleCheckRowItemToDelete = (id: string, checked: boolean) => {
        dispatch(shippingInformationActions.setDeleteCheckedRow({ id, checked }))
    }

    const handleChangeRowIsExperimented = (id: string, checked: boolean) => {

        dispatch(shippingInformationActions.setCellValue({ id, key: "isExperimented", value: checked }));
    }

    const handleChangeRowIsCompressiveExperimented = (id: string, checked: boolean) => {
        if (checked) {
            //add compression experiments
            dispatch(shippingInformationActions.insertCompressionExperiments(id));

        } 
        dispatch(shippingInformationActions.setCellValue({ id, key: "isCompressiveExperimented", value: checked }));
    }

    const shouldDisableCompressiveCheckbox = (id: string) => {
        const item = shippingInformationListBackup.find(x => x.id === id && x.isCompressiveExperimented);
        if (item) return true;

        return false;
    }
    const renderList = shippingInformationList?.length ? (
        shippingInformationList.map((si: TShippingInformationManagement.ShippingInformation) => (
            <tr key={si.id}>
                <td>

                    <input type="checkbox" className="form-input" checked={si.isCancelled} onChange={({ currentTarget }) => handleCheckRowItemToDelete(si.id, currentTarget.checked)} />

                </td>

                <td>
                    <input type="text" required className="form-input narrow" value={si.truckCode ?? ""} onChange={(e) => { handleChangeCellValue(si.id, 'truckCode', e.target.value) }} />
                </td>
                <td>
                    <input type="checkbox" className="form-input w-70" checked={si.isExperimented} onChange={({ currentTarget }) => handleChangeRowIsExperimented(si.id, currentTarget.checked)} />
                </td>
                <td>
                    {
                        <select className="form-select-office" onBlur={e => handleChangeCellValue(si.id, 'pumpId', e.currentTarget.value)}>
                            <option value="" />
                            {pumpList.map(p => (
                                <option key={p.id} value={p.id} selected={p.id === si.pumpId }>{p.code}</option>
                            ))}
                        </select>

                    }

                </td>
                <td style={{ width: "20%" }}>
                    <p className="wd-50">{si.factoryName}</p>
                </td>
                <td>
                    <input type="number" step="0.01" required className="form-input narrow" min="0" value={si.transferAmount} onChange={(e) => { handleChangeCellValue(si.id, 'transferAmount', e.target.value) }} />
                </td>
                <td>
                    <input type="datetime-local" required className="form-input wd-185" value={si.transferTime ? moment(si.transferTime).format("YYYY-MM-DD HH:mm") : ""} onChange={(e) => { handleChangeCellValue(si.id, 'transferTime', e.target.value) }} />
                </td>
                <td>
                    <input type="datetime-local" required className="form-input wd-185" value={si.arrivalTime ? moment(si.arrivalTime).format("YYYY-MM-DD HH:mm") : ""} onChange={(e) => { handleChangeCellValue(si.id, 'arrivalTime', e.target.value) }} />
                </td>
                <td>
                    <input type="datetime-local" required className="form-input wd-185" value={si.pumpStartTime ? moment(si.pumpStartTime).format("YYYY-MM-DD HH:mm") : ""} onChange={(e) => { handleChangeCellValue(si.id, 'pumpStartTime', e.target.value) }} />
                </td>
                <td>
                    <input type="datetime-local" required className="form-input wd-185" value={si.pumpFinishTime ? moment(si.pumpFinishTime).format("YYYY-MM-DD HH:mm") : ""} onChange={(e) => { handleChangeCellValue(si.id, 'pumpFinishTime', e.target.value) }} />
                </td>
                <td>
                    <input type="checkbox" className="form-input wd-50" checked={si.isActive} onChange={({ currentTarget }) => handleChangeCellValue(si.id, "isActive", currentTarget.checked)} />
                </td>
                <td>
                    <input type="number" required className="form-input narrow" min="0" value={si.slumpAmount ?? 0} onChange={(e) => { handleChangeCellValue(si.id, 'slumpAmount', e.target.value) }} />
                </td>
                <td>
                    <input type="number" required className="form-input narrow" min="0" value={si.airAmount ?? 0} onChange={(e) => { handleChangeCellValue(si.id, 'airAmount', e.target.value) }} />
                </td>
                <td>
                    <input type="number" required className="form-input narrow" min="0" value={si.conTemperature ?? 0} onChange={(e) => { handleChangeCellValue(si.id, 'conTemperature', e.target.value) }} />
                </td>
                <td>
                    <input type="number" required className="form-input narrow" min="0" value={si.outsideTemperature ?? 0} onChange={(e) => { handleChangeCellValue(si.id, 'outsideTemperature', e.target.value) }} />
                </td>
                <td>
                    <input type="number" required className="form-input narrow" min="0" value={si.unitWaterVolume ?? 0} onChange={(e) => { handleChangeCellValue(si.id, 'unitWaterVolume', e.target.value) }} />
                </td>
                {/* <td>
                    <input type="number" required className="form-input narrow" value={si.unitWaterVolumneRI?.toFixed(2) ?? 0} onChange={(e) => { handleChangeCellValue(si.id, 'unitWaterVolumneRI', e.target.value) }} />
                </td>
                <td>
                    <input type="number" required className="form-input narrow" value={si.unitVolumeMassRI?.toFixed(2) ?? 0} onChange={(e) => { handleChangeCellValue(si.id, 'unitVolumeMassRI', e.target.value) }} />
                </td> */}
                <td>
                    <input type="number" required className="form-input narrow" min="0" value={si.cloAmount ?? 0} onChange={(e) => { handleChangeCellValue(si.id, 'cloAmount', e.target.value) }} />
                </td>
                <td>
                    <input type="checkbox" className="form-input w-70" disabled={shouldDisableCompressiveCheckbox(si.id)} checked={si.isCompressiveExperimented} onChange={({ currentTarget }) => handleChangeRowIsCompressiveExperimented(si.id, currentTarget.checked)} />
                </td>
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan={19}>データなし</td>
        </tr>
    );


    return (
        <>
            <LoadingSpinner isShowing={isLoading} />
            <div className="settingItemPageTitle">
                <p>運搬情報編集</p>
            </div>
            <div className="content editor-container">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>削除</th>
                            <th scope="col">車番</th>
                            <th scope="col">試験<wbr/>対象</th>
                            <th scope="col">ポンプ車</th>
                            <th scope="col">工場</th>
                            <th scope="col">積載量</th>
                            <th scope="col">出荷時刻</th>
                            <th scope="col">現場到着</th>
                            <th scope="col">荷卸開始</th>
                            <th scope="col">荷卸終了</th>
                            <th scope="col">キャンセル</th>
                            <th scope="col">スランプ</th>
                            <th scope="col">空気量</th>
                            <th scope="col">Con温度</th>
                            <th scope="col">外気温</th>
                            <th scope="col">単位水量</th>
                            {/* <th scope="col">単位水量RI</th>
                            <th scope="col">単位容積質量RI</th> */}
                            <th scope="col">塩化物量</th>
                            <th scope="col">圧縮<wbr />強度</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderList}
                    </tbody>
                </table>
            </div>
            <div className="goHomeButtonContainer mb-3">
                <input type="button" value="削除" onClick={() => { bulkDeleteClick() }} className="goHomeButton" />
                <input type="button" value="修正前の状態に戻す" onClick={resetData} className="goHomeButton ml-5" />
                <input type="button" value="保存" onClick={handleSaveEdittedData} className="goHomeButton ml-5" />
            </div>

            <div className="goHomeButtonContainer">
                <input type="button" value="戻る" onClick={() => { history.goBack() }} className="goHomeButton" />
            </div>

            <Modal isActive={showDeleteConfirmModal} onClose={() => handleDeleteCancelButtonClick()} width="30%">
                <div className="modal-body">
                    <p>選択している項目を削除します。よろしいですか？</p>
                </div>
                <div className="modal-footer">
                    <input type="button" className="mr-5" onClick={handleConfirmDeleteButtonClick} value="削除" />
                    <input type="button" className="ml-1" onClick={() => { handleDeleteCancelButtonClick() }} value="キャンセル" />
                </div>
            </Modal>
        </>
    )
}

export default connect((state: RootState) => ({
    shippingInformationState: state.shippingInformation,
}))(ShippingInformationEditor);
