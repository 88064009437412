import React, { useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Formik, Field, ErrorMessage } from 'formik'
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup'
import { connect } from "react-redux";
import Picker from 'rc-picker'
import jaJP from 'rc-picker/es/locale/ja_JP'
import moment, { Moment } from 'moment'
import momentGenerateConfig from 'rc-picker/es/generate/moment'
import 'rc-picker/assets/index.css'
import { OverlappingState, Dispatcher, RootState, AppState, ShippingInformationState } from "AppTypes";
import { shippingInformationActions } from "@features/shippinginformation/state_management/actions";
import { overlappingActions } from "./state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { success, error } from "@services/toast-service";
import "./OverlappingList.css"

type Props = {
    dispatch: Dispatcher;
    overlappingState: OverlappingState;
    shippingInformationState: ShippingInformationState;
    appState: AppState
};



const OverlappingList: React.FC<Props> = ({ overlappingState, dispatch, appState, shippingInformationState }) => {
    const { data } = overlappingState.overlappingList;
    const history = useHistory();
    const locationId = localStorage.getItem('currentLocationId') || appState.currentLocationId || ''
    useEffect(() => {

        if (!locationId) {
            history.push("/locationselector");
        } else {
            dispatch(overlappingActions.fetchList({
                LocationId: locationId
            }));
            dispatch(shippingInformationActions.fetchPumpList({ LocationId: locationId }));
        }
    }, []);

    const updateAction = async (values: any) => {
        try {
            var postedData = values.map((x: any) => {
                return { pumpId: x.pumpId || null, pumpStartTime: x.pumpStartTime ? x.pumpStartTime : null, pumpFinishTime: x.pumpFinishTime ? x.pumpFinishTime : null, id: x.id };
            });
            const response = await dispatch(overlappingActions.updateList({ listMesh: postedData }));
            if (response.payload && response.payload.success) {
                success("保存が完了しました");
            } else {
                error(response.payload.errors[0]);
            }
        } catch (error: any) {
            error(error);
        }
    };

    const validationOverlapping = Yup.array()
        .of(
            Yup.object().shape({
                pumpId: Yup.string().when("pumpFinishTime", {
                    is: (pumpFinishTime: any) => pumpFinishTime,
                    then: Yup.string().required("ポンプ車を選択してください"),
                    otherwise: Yup.string().nullable()
                }),
                pumpStartTime: Yup.string(),
                pumpFinishTime: Yup.string()

                    .when("pumpStartTime", {
                        is: (pumpStartTime: any) => pumpStartTime,
                        then: Yup.string().required("打設終了日時を入力してください"),
                        otherwise: Yup.string().nullable()
                    })


            })
        );

    return (
        <>
            <LoadingSpinner isShowing={overlappingState.isLoading} />
            <div className="settingItemPageTitle">
                <p>打重ね情報編集</p>
            </div>

            <Formik
                enableReinitialize
                initialValues={data}
                validationSchema={validationOverlapping}
                onSubmit={(values, actions) => {
                    updateAction(values)
                    actions.setSubmitting(false)
                }}
            >{
                    ({ values, submitForm, setFieldValue, errors, resetForm }) => (
                        <div className="content setting-container">

                            <div className="editor-container">

                            <table className="table table-bordered">
                                <thead>
                                    <tr className="text-center">
                                        <th scope="col">メッシュ番号</th>
                                        <th scope="col">ポンプ車</th>
                                        <th scope="col">打設開始</th>
                                        <th scope="col">打設終了</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {values?.map((item: any, index: number) => (
                                        <tr key={item.id}>
                                            <td className="text-center">{item.code}</td>
                                            <td className="text-center">

                                                <Field as="select" className="form-input wd-185" name={`${index}.pumpId`}
                                                    onChange={(e: any) => setFieldValue(`${index}.pumpId`, e.target.value)}>
                                                    <option value=""></option>
                                                    {shippingInformationState.pumpList?.map(itemPump => (
                                                        <option key={index + itemPump.id} value={itemPump.id} selected={item.pumpId === itemPump.id}>{itemPump.name}</option>
                                                    ))}
                                                </Field>
                                                {errors[index]?.pumpId ? (
                                                    <div className="text-danger text-14">{errors[index]?.pumpId}</div>
                                                ) : null}
                                            </td>
                                            <td className="text-center">

                                                <Field type="datetime-local" className="form-input wd-185" value={item.pumpStartTime ? moment(item.pumpStartTime).format("YYYY-MM-DD HH:mm") : ""} max={item.pumpFinishTime} name={`${index}.pumpStartTime`}
                                                    onChange={(e: any) => setFieldValue(`${index}.pumpStartTime`, e.target.value)} />

                                                {errors[index]?.pumpStartTime ? (
                                                    <div className="text-danger text-14">{errors[index]?.pumpStartTime}</div>
                                                ) : null}
                                            </td>
                                            <td className="text-center">
                                                <Field type="datetime-local" className="form-input wd-185" min={item.pumpStartTime} value={item.pumpFinishTime ? moment(item.pumpFinishTime).format("YYYY-MM-DD HH:mm") : ""} name={`${index}.pumpFinishTime`}
                                                    onChange={(e: any) => setFieldValue(`${index}.pumpFinishTime`, e.target.value)} />

                                                {errors[index]?.pumpFinishTime ? (
                                                    <div className="text-danger text-14">{errors[index]?.pumpFinishTime}</div>
                                                ) : null}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            </div>

                            <div className="d-flex justify-content-center mb-3">
                                {/*<input type="button" value="戻る" onClick={() => history.goBack()} className="goHomeButton" />*/}
                                <input type="reset" onClick={() => resetForm()} value="修正前の状態に戻す" className="goHomeButton ml-5" />
                                <input type="submit" value="保存" onClick={submitForm} className="goHomeButton ml-5" />
                            </div>

                            <div className="goHomeButtonContainer">
                                <input type="button" value="戻る" onClick={() => history.goBack()} className="goHomeButton" />
                            </div>
                        </div>
                    )}
            </Formik>
        </>
    );
};

export default connect((state: RootState) => ({
    overlappingState: state.overlapping,
    shippingInformationState: state.shippingInformation,
    appState: state.app
}))(OverlappingList);
