import moment from 'moment'

export const formatTime = (dateISO: string) => moment(dateISO).format('YYYY/MM/DD HH:mm:ss')

export const isSameMonth = (date: string) => {
  const now = new Date()
  return moment(now.toISOString()).isSame(date, 'month')
}

export const formatPhoneNumber = (str: string) => {
  // Filter only numbers from the input
  const cleaned = str.replace(/\D/g, '')

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return `${match[1]}.${match[2]}.${match[3]}`
  }

  return ''
}

export const isNumericInput = (event: any) => {
  const key = event.keyCode
  // Allow number line number pad
  return ((key >= 48 && key <= 57) || (key >= 96 && key <= 105))
}

const isModifierKey = (event: any) => {
  const key = event.keyCode
  return (event.shiftKey === true || key === 35 || key === 36) // Allow Shift, Home, End
      || (key === 8 || key === 9 || key === 13 || key === 46) // Allow Backspace, Tab, Enter, Delete
      || (key > 36 && key < 41) // Allow left, up, right, down
      || (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true)
        && (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
}

export const enforceFormat = (event: any) => {
  // Input must be of a valid number format or a modifier key, and not longer than ten digits
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault()
  }
}

/**
 * @src https://stackoverflow.com/questions/30058927/format-a-phone-number-as-a-user-types-using-pure-javascript
 * @param event
 * @returns
 */
export const formatToPhone = (event: any) => {
  if (isModifierKey(event)) { return }

  const input = event.target.value.replace(/\D/g, '').substring(0, 10) // First ten digits of input only
  const areaCode = input.substring(0, 3)
  const middle = input.substring(3, 6)
  const last = input.substring(6, 10)

  if (input.length > 6) { event.target.value = `${areaCode}.${middle}.${last}` }
}

export const numberWithCommas = (x: any) => (x || '').toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')

export const convertMinuteToHour = (minute: number) => {

    if (minute === 0) {
        return "00:00";
    }
    var hours = Math.floor(minute / 60);
    var minutes = minute % 60;

    let hourString = `${hours}`;
    if (hours < 10) {
        hourString = `0${hours}`;
    }

    let minuteString = `${minutes}`;
    if (minutes < 10) {
        minuteString = `0${minutes}`;
    }

    return `${hourString}:${minuteString}`
}

export const regularNumber = /^[0-9]+$/
