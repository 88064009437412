import React, { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux";
import { DisplayItemSettingState, Dispatcher, RootState, IDisplayItemSetting } from "AppTypes";
import { displayItemSettingActions } from "./state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { success, error } from '@services/toast-service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleUp, faAngleUp, faAngleDoubleDown, faAngleDown } from '@fortawesome/free-solid-svg-icons'

type Props = {
    dispatch: Dispatcher;
    displayItemSettingState: DisplayItemSettingState;
};


const DisplayItemSetting: React.FC<Props> = ({ displayItemSettingState, dispatch }) => {

    const history = useHistory();
    const { displayItemSettingList } = displayItemSettingState;
    const [indexShippingItem, setIndexShippingItem] = useState(-1);
    const [itemShippingSelect, setItemShippingSelect] = useState('');

    const [indexOverlappingItem, setIndexOverlappingItem] = useState(-1);
    const [itemOverlappingSelect, setItemOverlappingSelect] = useState('');

    const compare = (a: IDisplayItemSetting, b: IDisplayItemSetting) => {
        if (a.displayOrder < b.displayOrder) {
            return -1;
        }
        if (a.displayOrder > b.displayOrder) {
            return 1;
        }
        return 0;
    }

    const shipmentDisplaySettings = displayItemSettingList?.filter(x => x.type === "Shipping").sort(compare) ?? [];
    const castingDisplaySettings = displayItemSettingList?.filter(x => x.type === "Casting").sort(compare) ?? [];

    useEffect(() => {
        dispatch(displayItemSettingActions.fetchList(null));
    }, []);

    const handleChangeItemData = async (id: string, checkbox: HTMLInputElement) => {
        dispatch(displayItemSettingActions.setItemData({ id, key: 'isDisplayed', value: checkbox.checked }));
    }

    const handleIncreaseShipmentOrder = (id: string) => {
        dispatch(displayItemSettingActions.increaseShipmentDisplayOrder(id));
    }
    const handleDecreaseShipmentOrder = (id: string) => {
        dispatch(displayItemSettingActions.decreaseShipmentDisplayOrder(id));
    }

    const handleIncreaseCastingOrder = (id: string) => {
        dispatch(displayItemSettingActions.increaseCastingDisplayOrder(id));
    }
    const handleDecreaseCastingOrder = (id: string) => {
        dispatch(displayItemSettingActions.decreaseCastingDisplayOrder(id));
    }

    const handleSave = async () => {
        if (displayItemSettingList?.find(x => x.displayOrder <= 0 || !x.name)) {
            error("保存ができませんでした。");
            return;
        }
        try {
            const response = await dispatch(displayItemSettingActions.updateRange(displayItemSettingList));
            if (response.payload && response.payload.success) {
                success("保存が完了しました。");
            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }
    }

    const handleClickShipping = (id: string, moveEnd: boolean) => {
        const indexItem = shipmentDisplaySettings.findIndex(item => item.id === id);
        if (moveEnd) {
            const shippingScroll = document.getElementById(`shipping-${id}`);
            if (shippingScroll) shippingScroll.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            if (shipmentDisplaySettings[indexItem - 1]?.id) {
                const shippingScroll = document.getElementById(`shipping-${shipmentDisplaySettings[indexItem - 1].id}`);
                if (shippingScroll) shippingScroll.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }

    const handleClickCasting = (id: string, moveEnd: boolean) => {
        const indexItem = castingDisplaySettings.findIndex(item => item.id === id);
        if (moveEnd) {
            const castingScroll = document.getElementById(`casting-${id}`);
            if (castingScroll) castingScroll.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            if (castingDisplaySettings[indexItem - 1]?.id) {
                const castingScroll = document.getElementById(`casting-${castingDisplaySettings[indexItem - 1].id}`);
                if (castingScroll) castingScroll.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }

    return (
        <>
            <LoadingSpinner isShowing={false} />
            <div className="settingItemPageTitle">
                <p>表示項目設定</p>
            </div>
            <div className="row col-12">
                <div className="col-5 p-0">
                    <h5 className="pl-30 text-center text-bold">運搬情報表示項目</h5>
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-sm btn-outline-primary button-set-default" onClick={() => {
                            dispatch(displayItemSettingActions.setDefaultSetting({type: 'Shipping'}))
                        }}>初期状態に戻す</button>
                    </div>
                    <div className="setting-container scrollableContainer table-wrapper-scroll-y">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">表示</th>
                                    <th scope="col">項目</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    shipmentDisplaySettings?.length ? (
                                        shipmentDisplaySettings.map((setting: IDisplayItemSetting, index) => (
                                            <tr key={setting.id} className={setting.id === itemShippingSelect ? 'bg-info': ''} id={`shipping-${setting.id}`}>
                                                <td>
                                                    <input type="checkbox" className="form-input" checked={setting.isDisplayed} onChange={({ currentTarget }) => handleChangeItemData(setting.id, currentTarget)} />

                                                </td>
                                                <td onClick={() => {
                                                    if (setting.id === itemShippingSelect) {
                                                        setIndexShippingItem(-1);
                                                        setItemShippingSelect('')
                                                    } else {
                                                        setIndexShippingItem(index);
                                                        setItemShippingSelect(setting.id);
                                                    }
                                                }}>
                                                    {setting.name}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={3}>データなし</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-1">
                    <div className="d-flex flex-column justify-content-center h-100">
                        <div className="d-flex flex-column h-50 justify-content-evenly">
                            <div>
                                <button 
                                    className="btn btn-outline-dark" 
                                    aria-label="Mute" 
                                    onClick={() => dispatch(displayItemSettingActions.setFirstShipmentDisplayOrder({id: itemShippingSelect, type: 'Shipping'}))}
                                    disabled={itemShippingSelect === shipmentDisplaySettings[0]?.id || indexShippingItem === -1}
                                >
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={faAngleDoubleUp}
                                    />
                                </button>
                            </div>
                            <div>
                                <button
                                    className="btn btn-outline-dark"
                                    aria-label="Mute"
                                    onClick={async () => {
                                        await handleDecreaseShipmentOrder(itemShippingSelect);
                                    }}
                                    disabled={itemShippingSelect === shipmentDisplaySettings[0]?.id || indexShippingItem === -1}
                                >
                                    <FontAwesomeIcon size="lg" icon={faAngleUp} />
                                </button>
                            </div>
                            <div>
                                <button className="btn btn-outline-dark" aria-label="Mute" onClick={async () => {
                                    await handleIncreaseShipmentOrder(itemShippingSelect);
                                    await handleClickShipping(itemShippingSelect, false);
                                }} disabled={itemShippingSelect === shipmentDisplaySettings[shipmentDisplaySettings.length - 1]?.id || indexShippingItem === -1}>
                                    <FontAwesomeIcon size="lg" icon={faAngleDown} />
                                </button>
                            </div>
                            <div>
                                <button
                                    className="btn btn-outline-dark"
                                    aria-label="Mute"
                                    onClick={async () => {
                                        await dispatch(displayItemSettingActions.setLastShipmentDisplayOrder({id: itemShippingSelect, type: 'Shipping'}));
                                        await handleClickShipping(itemShippingSelect, true);
                                    }}
                                    disabled={itemShippingSelect === shipmentDisplaySettings[shipmentDisplaySettings.length - 1]?.id || indexShippingItem === -1}
                                >
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={faAngleDoubleDown}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-5">
                    <h5 className="pl-30 text-center text-bold">打重ね情報表示項目</h5>
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-sm btn-outline-primary button-set-default" onClick={() => {
                            dispatch(displayItemSettingActions.setDefaultSetting({type: 'Casting'}))
                        }}>初期状態に戻す</button>
                    </div>
                    <div className="setting-container scrollableContainer table-wrapper-scroll-y">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">表示</th>
                                    <th scope="col">項目</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    castingDisplaySettings?.length ? (
                                        castingDisplaySettings.map((setting: IDisplayItemSetting, index) => (
                                            <tr key={setting.id} className={setting.id === itemOverlappingSelect ? 'bg-info': ''} id={`casting-${setting.id}`}>
                                                <td>
                                                    <input type="checkbox" className="form-input" checked={setting.isDisplayed} onChange={({ currentTarget }) => handleChangeItemData(setting.id, currentTarget)} />

                                                </td>
                                                <td onClick={() => {
                                                    if (setting.id === itemOverlappingSelect) {
                                                        setIndexOverlappingItem(-1);
                                                        setItemOverlappingSelect('')
                                                    } else {
                                                        setIndexOverlappingItem(index);
                                                        setItemOverlappingSelect(setting.id);
                                                    }
                                                }}>
                                                    {setting.name}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={3}>データなし</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-1">
                    <div className="d-flex flex-column justify-content-center h-100">
                        <div className="d-flex flex-column h-50 justify-content-evenly">
                            <div>
                                <button className="btn btn-outline-dark" aria-label="Mute" disabled={itemOverlappingSelect === castingDisplaySettings[0]?.id || indexOverlappingItem === -1} 
                                    onClick={() => dispatch(displayItemSettingActions.setFirstShipmentDisplayOrder({id: itemOverlappingSelect, type: 'Casting'}))}>
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={faAngleDoubleUp}
                                    />
                                </button>
                            </div>
                            <div>
                                <button className="btn btn-outline-dark" aria-label="Mute" disabled={itemOverlappingSelect === castingDisplaySettings[0]?.id || indexOverlappingItem === -1}  onClick={() => handleDecreaseCastingOrder(itemOverlappingSelect)}>
                                    <FontAwesomeIcon size="lg" icon={faAngleUp} />
                                </button>
                            </div>
                            <div>
                                <button className="btn btn-outline-dark" aria-label="Mute" onClick={async () => {
                                    await handleIncreaseCastingOrder(itemOverlappingSelect);
                                    await handleClickCasting(itemOverlappingSelect, false);
                                }} disabled={itemOverlappingSelect === castingDisplaySettings[castingDisplaySettings.length - 1]?.id || indexOverlappingItem === -1}>
                                    <FontAwesomeIcon size="lg" icon={faAngleDown} />
                                </button>
                            </div>
                            <div>
                                <button
                                    className="btn btn-outline-dark"
                                    aria-label="Mute"
                                    onClick={async () => {
                                        await dispatch(displayItemSettingActions.setLastShipmentDisplayOrder({id: itemOverlappingSelect, type: 'Casting'}));
                                        await handleClickCasting(itemOverlappingSelect, true);
                                    }}
                                    disabled={itemOverlappingSelect === castingDisplaySettings[castingDisplaySettings.length - 1]?.id || indexOverlappingItem === -1}
                                >
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={faAngleDoubleDown}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="goHomeButtonContainer">
                <input type="button" value="登録" onClick={handleSave} className="goHomeButton" />
                <input type="button" value="戻る" onClick={() => { history.goBack() }} className="goHomeButton ml-5" />
            </div>
        </>
    );
};

export default connect((state: RootState) => ({
    displayItemSettingState: state.dipslayItemSetting
}))(DisplayItemSetting);
