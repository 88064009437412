import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { configureStore } from '@reduxjs/toolkit'

import appReducer from '@app/state_management/slices'
import authReducer from '@features/auth/state_management/slices'
import factoryReducer from '@features/setting/factory/state_management/slices'
import mixtureReducer from '@features/setting/mixture/state_management/slices'
import locationReducer from '@features/setting/location/state_management/slices'
import constructionModelSettingReducer from '@features/setting/generalmodel/state_management/slices'
import locationSelectorReducer from '@features/location/selector/state_management/slices'
import shipmentReducer from '@features/shipment/registration/state_management/slices'
import shippingInformationReducer from '@features/shippinginformation/state_management/slices'
import overlappingReducer from '@features/setting/overlapping/state_management/slices'
import displayItemSettingReducer from '@features/setting/displayitem/state_management/slices'
import fileCategoryReducer from '@features/setting/filecategory/state_management/slices'
import meshSettingReducer from '@features/setting/meshcolor/state_management/slices'
import userSettingReducer from '@features/setting/user/state_management/slices'
import warningTimeSettingReducer from '@features/setting/warningtime/state_management/slices'
import documentReducer from '@features/document/state_management/slices'
import exportReducer from '@features/export/state_management/slices'
import versionReducer from '@features/version/state_management/slices'
import compressionExperimentReducer from '@features/setting/compressionexperiment/state_management/slices'
import cardReaderSettingReducer from '@features/setting/cardreader/state_management/slices'
import slumpInfoReducer from '@features/setting/slump/state_management/slices'
import constructionReducer from '@features/construction/state_management/slices'

export const history = createBrowserHistory()

export const rootReducer = combineReducers({
    router: connectRouter(history),
    app: appReducer,
    auth: authReducer,
    factory: factoryReducer,
    mixture: mixtureReducer,
    location: locationReducer,
    locationSelector: locationSelectorReducer,
    shipment: shipmentReducer,
    shippingInformation: shippingInformationReducer,
    overlapping: overlappingReducer,
    constructionModelSetting: constructionModelSettingReducer,
    dipslayItemSetting: displayItemSettingReducer,
    fileCategory: fileCategoryReducer,
    meshSetting: meshSettingReducer,
    userSetting: userSettingReducer,
    warningTimeSetting: warningTimeSettingReducer,
    document: documentReducer,
    export: exportReducer,
    version: versionReducer,
    compressionExperiment: compressionExperimentReducer,
    cardReader: cardReaderSettingReducer,
    slumpInfo: slumpInfoReducer,
    construction: constructionReducer
})

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})

export default store
