import { RouterState } from "connected-react-router";
import "./NavMenu.css";
import { connect } from 'react-redux'
import {
    RootState,
    Dispatcher
} from 'AppTypes';

type NavMenuProps = {
    setShowMenu: (isOpen: boolean) => void,
    routerState: RouterState,
    dispatch: Dispatcher;
}

const NavMenu = ({ setShowMenu, routerState, dispatch }: NavMenuProps) => {

    const { location } = routerState;
   
    return (
        <>
            <header hidden={location.pathname === "/" || location.pathname === "/loginselector"}>
                <div className="row col-12">
                    <div className="col-9">
                        <nav className="navbar navbar-expand-lg">
                            <a className="navbar-brand" href="/locationselector">
                                {" "}
                                <img src="/assets/logo.png" alt="logo" className="ml-2" />
                            </a>
                        </nav>
                    </div>

                    <div className="col-3 d-flex justify-content-sm-end align-items-center">
                        <div>
                            <span className="align-middle">{localStorage.getItem("displayName") || localStorage.getItem("username") || ""}</span>
                            <button type="button" className="btn btn-default react-burger-menu-btn" onClick={() => setShowMenu(true)}>
                                <i className="fas fa-bars"></i>
                            </button>
                            {/* <a className="btn btn-success" aria-hidden="true" onClick={() => dispatch(authActions.logout())}>ログアウト</a> */}
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}


export default connect((state: RootState) => ({
    routerState: state.router
}))(NavMenu)

