import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { TLocationSelectorManagement } from "./types";
import { RootState, ILocationMeshModel } from 'AppTypes'

import { TConstructionModelSettingManagement } from "../../../setting/generalmodel/state_management/types";
import { STATUS_MESH } from '../../../../const/constants'
import moment from "moment";
import { fetchPumpList } from "@features/shippinginformation/state_management/slices";

export const initialState: TLocationSelectorManagement.TState = {
    locationList: {
        data: [],
    },
    locationListSuccess: [],
    constructionModelList: {
        data: [],
    },
    selectedConstructionModelId: "",
    showCompleted: false,
    isLoading: false,
    locationMesh: [],
    locationMeshHistory: {
        locationId: '',
        locationCode: '',
        constructionModelId: '',
        blockId: '',
        liftId: '',
        position: '',
        glbFilePath: '',
        isFinished: true,
        status: "BeforePumping",
        meshHistories: [],
    },
    showBlockLocation: 0,
    locationSelect: "",
    filter: {
        isShowMeshNotComplete: false,
        floorSelect: -1,
        showCompleted: false,
        listStatus: [],
    },
    statusFilter: "",
    floorActive: -1,
    isFetchLocationList: false,
    pumpSelect: "",
    modeShowFloor: 2,
    stepIndexHistoryPlay: 0,
    isGetMeshPlayHistory: false,
    listFloors: [],
    isModeSlim: false,
    isShowAlert: false,
    modeShow3d: true,
    activeMeshTable: '',
    activeLocationTable: '',
    isFull3dMode: false,
    listMeshActiveTable: [],
};

export const fetchLocationByConstructionModel = createAsyncThunk(
    "locationSelectorManagement/fetchLocationByConstructionModel",
    async (locationId: string, { rejectWithValue }) => {
        try {
            const response = await api.locationAPI.fetchLocationByConstructionModel(
                locationId
            );
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchConstructionModel = createAsyncThunk(
    "locationSelectorManagement/fetchConstructionModel",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.constructionModelAPI.fetchList();
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateLocationStatus = createAsyncThunk(
    "locationSelectorManagement/updateLocationStatus",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.locationAPI.updateStatus(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchLocationMesh = createAsyncThunk(
    "locationSelectorManagement/fetchLocationMesh",
    async (filter: any, { rejectWithValue, getState }) => {
        try {
            const params: any = {
                Ids: [filter.locationId]
            }
            if (!filter.ignoreFilter) {
                filter.ignoreFilter = false;
            }
            const state = getState() as RootState
            if (state.locationSelector.pumpSelect !== '' && filter.ingoreFilter) {
                params.MeshPumpId = state.locationSelector.pumpSelect;
            }
            const response = await api.locationAPI.GetWithMesh(params);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchLocationMeshAlert = createAsyncThunk(
    "locationSelectorManagement/fetchLocationMeshAlert",
    async (filter: any, { rejectWithValue, getState }) => {
        try {
            const params: any = {
                Ids: [filter.locationId]
            }
            if (!filter.ignoreFilter) {
                filter.ignoreFilter = false;
            }
            const state = getState() as RootState
            if (state.locationSelector.pumpSelect !== '' && filter.ingoreFilter) {
                params.MeshPumpId = state.locationSelector.pumpSelect;
            }
            const response = await api.locationAPI.GetWithMesh(params);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchLocationMeshHistory = createAsyncThunk(
    "locationSelectorManagement/fetchLocationMeshHistory",
    async (locationId: string, { rejectWithValue }) => {
        try {
            const response = await api.locationAPI.GetMeshHistory(locationId);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchLocationAll = createAsyncThunk(
    "locationSelectorManagement/fetchLocationAll",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.locationAPI.fetchAll();
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const changeModeLocation = createAsyncThunk(
    "locationSelectorManagement/changeModeLocation",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.locationAPI.changeMode(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const locationSelectorSlice = createSlice({
    name: "locationSelector",
    initialState,
    reducers: {
        setSelectedConstructionModelId(state, action) {
            state.selectedConstructionModelId = action.payload;
        },
        setShowCompleted(state, action) {
            state.showCompleted = action.payload;
            state.locationList.data.forEach((item) => {
                if (action.payload) {
                    if (!item.isFinished) {
                        item.isActive = true;
                    }
                } else {
                    item.isActive = true;
                }
            });
        },
        setDataLocation(state, action) {
            state.locationList.data[action.payload.index].isActive =
                action.payload.value;
        },
        setActiveLocation(state, action) {
            state.activeLocationTable = action.payload.value;
        },
        setShowLocationSuccess(state) {
            state.locationList.data.forEach((item) => {
                if (item?.status !== STATUS_MESH[2]) {
                    item.isActive = false;
                } else {
                    item.isActive = true;
                }
            });
        },
        resetLocationShow(state) {
            state.locationList.data.forEach((item) => {
                item.isActive = true;
            });
        },
        setShowLocationBlock(state, action) {
            state.showBlockLocation = action.payload;
        },
        setShowMeshSuccess(state) {
            state.locationMesh[0]?.meshes.forEach((item) => {
                if (item.status !== "FinishPumping") {
                    item.isHidden = true;
                } else {
                    item.isHidden = false;
                }
            });
        },
        setShowMeshWithFloor(state, action) {
            state.locationMesh[0]?.meshes.forEach((item) => {
                if (item.code && action.payload.layer < item.layer) {
                    item.isFloorSelect = true;
                } else {
                    item.isFloorSelect = false;
                }
            });
        },
        setShowMeshWithFloorHistory(state, action) {
            state.locationMeshHistory?.meshHistories.forEach((item) => {
                if (item.code && action.payload.layer < item.layer) {
                    item.isFloorSelect = true;
                } else {
                    item.isFloorSelect = false;
                }
            });
        },
        resetShowMesh(state) {
            state.filter = {
                isShowMeshNotComplete: false,
                floorSelect: -1,
                showCompleted: false,
                listStatus: [],
            };
            state.locationMesh[0]?.meshes.forEach((item) => {
                item.isHidden = false;
                item.isFloorSelect = false;
            });
        },
        resetShowMeshHistory(state) {
            state.locationMeshHistory?.meshHistories.forEach((item) => {
                item.isHidden = false;
                item.isFloorSelect = false;
            });
        },
        changeLocationSelect(state, action) {
            state.locationSelect = action.payload;
        },
        actionConstructionUpdate(state, action) {
            if (state.locationMesh[0]?.meshes) {
                state.filter = action.payload;
                for (const itemMesh of state.locationMesh[0]?.meshes) {
                    itemMesh.isHidden = false;
                    itemMesh.isFloorSelect = false;
                }
                if (action.payload.floorSelect > -1) {
                    state.locationMesh[0]?.meshes.forEach((item) => {
                        if (item?.code && item.layer !== action.payload.floorSelect) {
                            if (state.modeShowFloor === 1) {
                                item.isHidden = true;
                            } else if (state.modeShowFloor === 2 && action.payload.floorSelect < item.layer) {
                                item.isFloorSelect = true;
                            }
                        }
                    });
                }
                if (action.payload.listStatus?.length > 0) {
                    state.locationMesh[0]?.meshes.forEach((item) => {
                        if (!action.payload.listStatus.includes(item.status)) {
                            item.isHidden = true;
                        }
                    });
                }
                // if (action.payload.isShowMeshNotComplete && !state.modeShow3d) {
                //     state.locationMesh[0]?.meshes.forEach((item) => {
                //         if (item.status === STATUS_MESH[2]) {
                //             item.isHidden = true;
                //         }
                //     });
                // }
            }
        },
        changeShowMeshNotComplete(state, action) {
            state.filter.isShowMeshNotComplete = action.payload;
            if (action.payload) {
                const locationSuccess = state.locationList.data.filter((location: { status: string; }) => location.status !== STATUS_MESH[2]);
                state.locationListSuccess = locationSuccess;
            } else {
                state.locationListSuccess = state.locationList.data;
            }
        },
        changeFloorActive(state, action) {
            state.floorActive = action.payload
        },
        changeModeShow3d(state, action) {
            state.modeShow3d = action.payload
        },
        changePumpSelect(state, action) {
            state.pumpSelect = action.payload;
        },
        updateStatusMesh(state, action) {
            const indexMesh = state.locationMesh[0]?.meshes?.findIndex(item => item.id === action.payload.id);
            if (indexMesh > -1 && ((state.pumpSelect !== '') || state.pumpSelect == '')) {
                if (action.payload?.warningStatus) {
                    state.locationMesh[0].meshes[indexMesh].isSlim = action.payload.isSlim;
                    state.locationMesh[0].meshes[indexMesh].status = action.payload.status;
                    state.locationMesh[0].meshes[indexMesh].warningStatus = action.payload.warningStatus;
                } else {
                    state.locationMesh[0].meshes[indexMesh].status = action.payload.status ?? 'BeforePumping';
                    state.locationMesh[0].meshes[indexMesh].warningStatus = '';
                    state.locationMesh[0].meshes[indexMesh].isSlim = action.payload.isSlim;
                }
            }
        },
        setModeShowFloor(state, action) {
            state.modeShowFloor = action.payload
        },
        changeMeshHistory(state, action) {
            if (state.locationMeshHistory && state.locationMeshHistory?.meshHistories[0]) {
                const logTimeMeshFirst = state.locationMeshHistory?.meshHistories[0].logTime;
                let indexFind = 0;
                for (const [key, itemHistory] of state.locationMeshHistory.meshHistories.entries()) {
                    if (
                        moment(itemHistory.logTime).isBefore(moment(logTimeMeshFirst).add(action.payload.time, 'seconds')) ||
                        moment(itemHistory.logTime).isSame(moment(logTimeMeshFirst).add(action.payload.time, 'seconds'))
                    ) {
                        indexFind = key;
                    }
                }

                if (indexFind === 0) {
                    const findIndexMeshFirst = state.locationMesh[0].meshes.findIndex(itemMesh => itemMesh.id === state.locationMeshHistory.meshHistories[0].meshId);
                    if (findIndexMeshFirst > -1) {
                        state.locationMesh[0].meshes[findIndexMeshFirst].status = state.locationMeshHistory.meshHistories[0].status;
                        state.locationMesh[0].meshes[findIndexMeshFirst].warningStatus = state.locationMeshHistory.meshHistories[0]?.warningStatus || '';
                        state.locationMesh[0].meshes[findIndexMeshFirst].isSlim = state.locationMeshHistory.meshHistories[0].isSlim;
                    }
                } else {
                    for (const [key, itemHistory] of state.locationMeshHistory.meshHistories.entries()) {
                        if (key <= indexFind) {
                            const findIndexMesh = state.locationMesh[0].meshes.findIndex(itemMesh => itemMesh.id === itemHistory.meshId);
                            if (findIndexMesh > -1) {
                                state.locationMesh[0].meshes[findIndexMesh].status = itemHistory.status;
                                state.locationMesh[0].meshes[findIndexMesh].warningStatus = itemHistory?.warningStatus || '';
                                state.locationMesh[0].meshes[findIndexMesh].isSlim = itemHistory?.isSlim;
                            }
                        }
                    }
                }
                state.stepIndexHistoryPlay = indexFind;
            }
        },
        changeStepIndexHistory(state, action) {
            state.stepIndexHistoryPlay += action.payload;
            if (state.locationMeshHistory && state.locationMeshHistory?.meshHistories[0]) {
                for (const [key, itemHistory] of state.locationMeshHistory.meshHistories.entries()) {
                    if (key <= state.stepIndexHistoryPlay) {
                        const findIndexMesh = state.locationMesh[0].meshes.findIndex(itemMesh => itemMesh.id === itemHistory.meshId);
                        if (findIndexMesh > -1) {
                            state.locationMesh[0].meshes[findIndexMesh].status = itemHistory.status;
                            state.locationMesh[0].meshes[findIndexMesh].warningStatus = itemHistory?.warningStatus || '';
                            state.locationMesh[0].meshes[findIndexMesh].isSlim = itemHistory?.isSlim;
                        }
                    }
                }
            }
        },
        changeIsGetMeshPlayHistory(state, action) {
            state.isGetMeshPlayHistory = action.payload
        },
        setListFloors(state, action) {
            state.listFloors = action.payload
        },
        changeStepIndexHistoryFirst(state) {
            state.stepIndexHistoryPlay = 0;
            if (state.locationMesh[0]?.meshes) {
                for (const itemMesh of state.locationMesh[0].meshes) {
                    if ((itemMesh?.status !== STATUS_MESH[0]) || itemMesh?.warningStatus !== '') {
                        itemMesh.status = STATUS_MESH[0];
                        itemMesh.warningStatus = ''
                    }
                }
            }
        },
        changeStepIndexHistoryLast(state, action) {
            state.stepIndexHistoryPlay = action.payload;
            if (state.locationMeshHistory && state.locationMeshHistory?.meshHistories[0]) {
                for (const [key, itemHistory] of state.locationMeshHistory.meshHistories.entries()) {
                    if (key <= state.stepIndexHistoryPlay) {
                        const findIndexMesh = state.locationMesh[0].meshes.findIndex(itemMesh => itemMesh.id === itemHistory.meshId);
                        if (findIndexMesh > -1) {
                            state.locationMesh[0].meshes[findIndexMesh].status = itemHistory.status;
                            state.locationMesh[0].meshes[findIndexMesh].warningStatus = itemHistory?.warningStatus || '';
                        }
                    }
                }
            }
        },
        setModeSlim(state, action) {
            state.isModeSlim = action.payload;
        },
        setModeSlimSocket(state, action) {
            if (state.pumpSelect === action.payload.id) {
                state.isModeSlim = action.payload.isSlim;
            }
        },
        changeMeshActiveFromTable(state, action) {
            state.activeMeshTable = action.payload;
        },
        changeModeShow3DView(state, action) {
            state.isFull3dMode = action.payload;
        },
        changeMeshActiveListMesh(state, action) {
            if (state.listMeshActiveTable.includes(action.payload)) {
                state.listMeshActiveTable = []
            } else {
                state.listMeshActiveTable = [action.payload]
            }
        },
        changeMeshActiveList(state, action) {
            if (action.payload?.type === 1) {
                if (action.payload.listMesh?.length === 0) {
                    state.listMeshActiveTable = [];
                } else {
                    const listMeshActiveTable: string[] = [];
                    for (const itemMesh of action.payload.listMesh) {
                        if (!state.listMeshActiveTable.includes(itemMesh)) {
                            listMeshActiveTable.push(itemMesh)
                        }
                    }
                    state.listMeshActiveTable = listMeshActiveTable;
                }
            } else {
                for (const itemMesh of action.payload.listMesh) {
                    if (state.listMeshActiveTable.includes(itemMesh)) {
                        const newListMeshActiveTable = state.listMeshActiveTable.filter(itemMeshActive => itemMeshActive !== itemMesh);
                        state.listMeshActiveTable = newListMeshActiveTable;
                    }
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLocationByConstructionModel.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(
            fetchLocationByConstructionModel.fulfilled,
            (state, action) => {
                state.locationList.data = action.payload.data;
                state.locationListSuccess = action.payload.data;
                state.isLoading = false;
            }
        );

        builder.addCase(fetchConstructionModel.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(
            fetchConstructionModel.fulfilled,
            (
                state,
                action: PayloadAction<{
                    data: TConstructionModelSettingManagement.ConstructionModel[];
                }>
            ) => {
                state.constructionModelList.data = action.payload.data;
                state.isLoading = false;
                if (state.constructionModelList?.data) {
                    const constructionModel = state.constructionModelList?.data[0];
                    if (constructionModel) {
                        localStorage.setItem('currentModelId', constructionModel.id);
                        state.selectedConstructionModelId = constructionModel.id;
                    }
                }

            }
        );

        builder.addCase(fetchLocationMesh.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchLocationMesh.fulfilled, (state, action) => {
            const listMeshInfo: ILocationMeshModel[] = [];
            let statusAlert = false;
            let statusSlimMode = state.isModeSlim;
            action.payload.data.forEach((item: any) => {
                // statusSlimMode = item?.isSlim;
                if (item?.meshes?.length > 0) {
                    for (const mesh of item.meshes) {
                        if (statusSlimMode && mesh?.status === STATUS_MESH[1]) {
                            statusAlert = true;
                        }
                        if (statusSlimMode && mesh?.status === STATUS_MESH[1] && mesh?.isSlim) {
                            statusAlert = true;
                        }
                        if  (state.filter.floorSelect > -1) {
                            if (mesh?.code && mesh?.layer !== state.filter.floorSelect && state.modeShowFloor !== 2) {
                                mesh.isHidden = true;
                            } else {
                                mesh.isHidden = false;
                            }
                        }
                        if (state.isGetMeshPlayHistory) {
                            mesh.status = STATUS_MESH[0];
                            mesh.warningStatus = '';
                        } else {
                            mesh.warningStatus = mesh.warningStatus || '';
                        }
                    }
                }
                if (!statusSlimMode) {
                    statusAlert = item.hasMeshSlim;
                }
                listMeshInfo.push(item)
            });
            state.locationMesh = listMeshInfo;
            state.isLoading = false;
            state.isShowAlert = statusAlert;
            // state.isModeSlim = statusSlimMode;
        });
        builder.addCase(fetchLocationMesh.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(fetchLocationMeshAlert.fulfilled, (state, action) => {
            let statusAlert = false;
            let statusSlimMode = state.isModeSlim;
            action.payload.data.forEach((item: any) => {
                // statusSlimMode = item?.isSlim;
                if (item?.meshes?.length > 0) {
                    for (const mesh of item.meshes) {
                        if (statusSlimMode && mesh?.status === STATUS_MESH[1]) {
                            statusAlert = true;
                        }
                        if (statusSlimMode && mesh?.status === STATUS_MESH[1] && mesh?.isSlim) {
                            statusAlert = true;
                        }
                    }
                }
                if (!statusSlimMode) {
                    statusAlert = item.hasMeshSlim;
                }
            });
            state.isShowAlert = statusAlert;
            // state.isModeSlim = statusSlimMode;
        });

        builder.addCase(fetchLocationMeshHistory.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchLocationMeshHistory.fulfilled, (state, action) => {
            state.locationMeshHistory = action.payload.data;
            state.isLoading = false;
        });
        builder.addCase(fetchLocationMeshHistory.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(fetchLocationAll.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchLocationAll.fulfilled, (state, action) => {
            if (action.payload.data?.length > 0) {
                state.locationList = action.payload;
                state.isFetchLocationList = true
                state.locationListSuccess = action.payload.data;
                if (state.locationSelect === '') {
                    state.locationSelect = action.payload.data[0]?.id || "";
                    localStorage.setItem('currentLocationId', action.payload.data[0]?.id);
                    // state.isModeSlim = action.payload.data[0]?.isSlim || false;
                    // if (action.payload.data[0]?.id && state.locationMesh?.length === 0) {
                    //     fetchLocationMesh({locationId:  action.payload.data[0]?.id, igoreFilter: true});
                    // }
                }
            }
            state.isLoading = false;
        });
        builder.addCase(fetchLocationAll.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(changeModeLocation.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(changeModeLocation.fulfilled, (state, action) => {
            state.isModeSlim = !state.isModeSlim;
            state.isLoading = false;
            fetchLocationAll()
        });
        builder.addCase(changeModeLocation.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const {
    setSelectedConstructionModelId,
    setShowCompleted,
    setDataLocation,
    setShowLocationSuccess,
    resetLocationShow,
    setShowLocationBlock,
    resetShowMesh,
    setShowMeshSuccess,
    setShowMeshWithFloor,
    changeLocationSelect,
    actionConstructionUpdate,
    changeFloorActive,
    changePumpSelect,
    updateStatusMesh,
    setModeShowFloor,
    setShowMeshWithFloorHistory,
    resetShowMeshHistory,
    changeMeshHistory,
    changeStepIndexHistory,
    changeIsGetMeshPlayHistory,
    setListFloors,
    changeShowMeshNotComplete,
    changeStepIndexHistoryFirst,
    changeStepIndexHistoryLast,
    setModeSlim,
    setModeSlimSocket,
    changeModeShow3d,
    changeMeshActiveFromTable,
    setActiveLocation,
    changeModeShow3DView,
    changeMeshActiveList,
    changeMeshActiveListMesh,
} = locationSelectorSlice.actions;

export default locationSelectorSlice.reducer;
