import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { QUERY_PAGING } from "@share/const";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { connect } from "react-redux";
import { DocumentState, Dispatcher, RootState, FileCategoryState, FactoryState } from "AppTypes";
import { fileCategoryActions } from "../setting/filecategory/state_management/actions";
import { factoryActions } from "../setting/factory/state_management/actions";
import { success, error } from '@services/toast-service'
import { documentActions } from "./state_management/actions";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup'

type Props = {
    dispatch: Dispatcher;
    documentState: DocumentState;
    fileCategoryState: FileCategoryState;
    factoryState: FactoryState;
};

const documentUpdateValidationSchema = Yup.object().shape({
  registerTime: Yup.string()
    .required('文書管理日付を選択してください。'),
  factoryId: Yup.string()
    .required('出荷工場を選択してください。'),
  fileCategoryId: Yup.string()
    .required('文書分類を選択してください。'),
  note: Yup.string()
    .max(20, 'コメントは20文字以下で入力してください。'),

  file: Yup.mixed()
    .notRequired()
    .test("FILE_SIZE", "ファイルのサイズが大きすぎます。10MB以下にしてください。", 
      value => !value || (value && value.size <= 10485760))
    .test("FILE_FORMAT", "アップロードされたファイル形式はサポートしていません。", 
      value => {
        if (value) {
          //const regex = new RegExp("(.*?)\.(docx|doc|pdf|xml|bmp|ppt|xls|xlsx|document|sheet)$");
          //return regex.test(value?.type?.toLowerCase());
          return true;
        } else {
          return true;
        }
      })
})

const documentCreateValidationSchema = Yup.object().shape({
  registerTime: Yup.string()
    .required('文書管理日付を選択してください。'),
  factoryId: Yup.string()
    .required('出荷工場を選択してください。'),
  fileCategoryId: Yup.string()
    .required('文書分類を選択してください。'),
  note: Yup.string()
    .max(20, 'コメントは20文字以下で入力してください。'),

  file: Yup.mixed()
    .required('ファイルを指定してください。')
    .test("FILE_SIZE", "ファイルのサイズが大きすぎます。10MB以下にしてください。", 
      value => !value || (value && value.size <= 10485760))
    .test("FILE_FORMAT", "アップロードされたファイル形式はサポートされていません。", 
      value => {
        if (value) {
          //const regex = new RegExp("(.*?)\.(docx|doc|pdf|xml|bmp|ppt|xls|xlsx|document|sheet)$");
          //return regex.test(value?.type?.toLowerCase());
          return true;
        } else {
          return true;
        }
      })
})

const DocumentList: React.FC<Props> = ({ documentState, fileCategoryState, factoryState,  dispatch }) => {
    const [paramSearch, setParamSearch] = useState({
      RegisterDtFrom: '',
      RegisterDtTo: '',
      FileCategoryId: '',
      FactoryId: '',
      Note: '',
    })
    const [dataDocument, setDataDocument] = useState({
      factoryId: '',
      fileCategoryId: '',
      note: '',
      file: null,
      fileName: '',
      fileUrl: '',
      registerTime: '',
      id: '',
    });
    const [isShowModal, setShowModal] = useState(false)
    const [isModeDetail, setModetail] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const history = useHistory()

    useEffect(() => {
      dispatch(fileCategoryActions.fetchList(null));
      dispatch(factoryActions.fetchList({PageIndex: 1, PageSize: 2000}));
      dispatch(documentActions.fetchList(paramSearch));
    }, []);
    const handleAddDocument = async (dataUpload: any) => {
      try {
          let response;
          if (dataUpload.id === '') {
            response = await dispatch(documentActions.insert(dataUpload));
          } else {
            response = await dispatch(documentActions.update(dataUpload));
          }
          if (response?.payload && response?.payload.success) {
            dispatch(documentActions.fetchList(paramSearch));
            success("保存が完了しました。");
            setShowModal(false);
          } else {
            console.log(5555, response)
            error(response.payload.errors[0])
          }
      } catch (error: any) {
          console.log(error)
      }
    }

    const clearForm = () => {
      setDataDocument({
        id: '',
        factoryId: '',
        fileCategoryId: '',
        note: '',
        file: null,
        fileName: '',
        fileUrl: '',
        registerTime: '',
      })
    }

    const downloadFile = () => {
    //  const link = document.createElement('a');
    //  link.target = '_blank'
    //  link.href = `${process.env.REACT_APP_API_ENDPOINT}${dataDocument.fileUrl}`;
    //  document.body.appendChild(link);
    //  link.click();
    //  document.body.removeChild(link);
        const link = document.createElement('a');
        link.target = '_blank'
        link.href = `${process.env.REACT_APP_API_ENDPOINT}${dataDocument.fileUrl}`;
        link.download = dataDocument.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    const handleDelete = async (id: string) => {
        try {
            const response = await dispatch(documentActions.deleteOne(id));
            if (response.payload && response.payload.success) {
                success("削除しました。");
            } else {
                error(response.payload.errors[0])
            }
            dispatch(documentActions.fetchList(paramSearch));
        } catch (error: any) {
            error("削除ができませんでした。")
        }
    }

    
    const handleDeleteButtonClick = async (id: string) => {
        await dispatch(documentActions.setDeletingId(id));
        setShowDeleteConfirmModal(true);
    }
    const handleDeleteCancelButtonClick = async () => {
        setShowDeleteConfirmModal(false);
        await dispatch(documentActions.setDeletingId(''));
    }
    const handleDeleteConfirmButtonClick = async () => {
        const response = await dispatch(documentActions.deleteOne(documentState.deletingId));
        if (response.payload && response.payload.success) {
            await dispatch(documentActions.fetchList(null));
            setShowDeleteConfirmModal(false);
            success("削除しました。");
        } else {
            error(response.payload.errors[0])
        }
    }


    return (
        <div className="px-md-4 ms-sm-auto mt-3">
          <div className="settingItemPageTitle">
              <p>文書管理</p>
          </div>
          <div className="section bg-secondary bg-gradient">
            <div className="row py-2 px-2 gy-2 gx-3 align-items-center">
              <div className="col-auto row text-center">
                <span className="col-form-label-sm text-white">文書管理年月日</span>
              </div>
              <div className="col-auto row">
                <label className="visually-hidden col-sm-3 col-form-label col-form-label-sm text-white" htmlFor="RegisterDtFrom">日付From</label>
                <div className="col-sm-12">
                  <input type="date" className="form-control form-control-sm" id="RegisterDtFrom" placeholder="対象期間(From)" onChange={(event) => {
                    setParamSearch({ ...paramSearch, RegisterDtFrom: event.target.value ?? '' })
                  }} />
                </div>
              </div>
              <div className="col-auto row">
                <label className="col-sm-3 col-form-label col-form-label-sm text-white" htmlFor="RegisterDtTo">～</label>
                <div className="col-sm-9">
                  <input type="date" className="form-control form-control-sm" id="RegisterDtTo" placeholder="対象期間(To)" onChange={(event) => {
                    if (paramSearch.RegisterDtFrom && moment(paramSearch.RegisterDtFrom).isAfter(event.target.value)) {
                      error('検索対象期間は日付Fromよりも大きい日付Toの値を入力してください。')
                      return;
                    }
                    setParamSearch({ ...paramSearch, RegisterDtTo: event.target.value ?? '' })
                  }} />
                </div>
              </div>
              <div className="col-auto row">
                <label className="col-sm-5 col-form-label col-form-label-sm text-white" htmlFor="FileCategoryId">文書分類:</label>
                <div className="col-sm-7">
                  <select className="form-select form-select-sm" id="FileCategoryId" value={paramSearch.FileCategoryId} onChange={(event) => {
                    setParamSearch({ ...paramSearch, FileCategoryId: event.target.value ?? '' })
                  }}>
                    <option value=""></option>
                    {fileCategoryState.fileCategoryList?.map(fileCategory => fileCategory.isActive && (
                      <option key={fileCategory.id} value={fileCategory.id}>{fileCategory.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-auto row">
                <label className="col-sm-6 col-form-label col-form-label-sm text-white" htmlFor="FactoryId">出荷工場:</label>
                <div className="col-sm-6">
                  <select className="form-select form-select-sm" id="FactoryId" value={paramSearch.FactoryId} onChange={(event) => {
                    setParamSearch({ ...paramSearch, FactoryId: event.target.value ?? '' })
                  }}>
                    <option value=""></option>
                    {factoryState.factoryList?.data?.map(factory => (
                      <option key={factory.id} value={factory.id}>{factory.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-auto row">
                <label className="col-sm-3 col-form-label col-form-label-sm text-white p-0 pt-1" htmlFor="Note">コメント:</label>
               <div className="col-sm-9">
                <input type="text" className="form-control form-control-sm" id="Note" value={paramSearch.Note} onChange={(event) => {
                    setParamSearch({ ...paramSearch, Note: event.target.value ?? '' })
                  }} />
               </div>
              </div>
              <div className="col-auto">
                <button type="submit" className="btn btn-sm btn-primary w-80" onClick={() => {
                  dispatch(documentActions.fetchList(paramSearch));
                }}>検索</button>
              </div>
              <div className="col-auto">
                        <button type="button" className="btn btn-sm btn-success w-80" hidden={process.env.REACT_APP_MODE_RUN === 'viewer'} onClick={() => {
                  clearForm();
                  setShowModal(true);
                }}>追加</button>
              </div>
            </div>
          </div>

          <div className="section pt-3">
            <table className="table table-sm table-bordered text-center align-middle">
              <thead className="bg-primary bg-gradient bg-opacity-25">
                <tr>
                  <th scope="col">日</th>
                  <th scope="col">分類</th>
                  <th scope="col">出荷工場</th>
                  <th scope="col">ファイル名称</th>
                  <th scope="col">コメント</th>
                  <th scope="col">登録者</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {documentState.documentList?.data?.map((document) => (
                  <tr key={document.id}>
                    <td>{moment(document.registerTime).format('YYYY/MM/DD')}</td>
                    <td>{document.fileCategoryName}</td>
                    <td>{document.factoryName}</td>
                    <td>{document.fileName}</td>
                    <td>{document.note}</td>
                    <td>{document.createdByDisplayName}</td>
                    <td><button type="button" className="btn btn-sm w-80 btn-success" onClick={() => {
                      setShowModal(true);
                      setModetail(true);
                      setDataDocument({
                        id: document.id,
                        factoryId: document.factoryId,
                        fileCategoryId: document.fileCategoryId,
                        note: document.note,
                        file: null,
                        fileName: document.fileName,
                        fileUrl: document.fileUrl,
                        registerTime: document.registerTime ? moment(document.registerTime).format('YYYY-MM-DD') : '',
                      })
                        }}>詳細</button>　

                            <Button className="btn btn-sm w-80 btn-danger ml-4" onClick={() => { handleDeleteButtonClick(document.id) }} hidden={process.env.REACT_APP_MODE_RUN === 'viewer'}>削除</Button>

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>


            <Modal show={showDeleteConfirmModal} onClose={() => handleDeleteCancelButtonClick()} width="30%">
                <div className="modal-body">
                    <p>削除します。よろしいですか？</p>
                </div>
                <div className="modal-footer">
                    <input type="button" onClick={handleDeleteConfirmButtonClick} value="削除" />
                    <input type="button" className="ml-3" onClick={() => { handleDeleteCancelButtonClick() }} value="キャンセル" />
                </div>
            </Modal>


          <Modal show={isShowModal} aria-labelledby="contained-modal-title-vcenter" size="xl" centered dialogClassName="modal-60w">
            <Modal.Header>
              <Modal.Title>{isModeDetail ? '文書編集' : '文書登録'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                enableReinitialize
                initialValues={dataDocument}
                validationSchema={isModeDetail ? documentUpdateValidationSchema : documentCreateValidationSchema}
                onSubmit={(values, actions) => {
                  handleAddDocument(values);
                  actions.setSubmitting(false)
                }}
              >
                {({ values, errors, touched, setFieldValue }) => (
                  <Form className="form-update">
                    <div className="row form-group mb-3">
                      <label className="col-3 col-form-label">文書管理日付<span className="text-danger">*</span></label>
                      <div className="col-8">
                        <Field name="registerTime" className="form-control" placeholder="文書管理日付" type="date" />
                        {errors.registerTime && touched.registerTime ? (
                          <div className="validation-error"></div>
                        ) : null}
                        <ErrorMessage name="registerTime">
                            {msg => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="row form-group mb-3">
                      <label className="col-3 col-form-label">文書分類 <span className="text-danger">*</span></label>
                      <div className="col-8">
                        <Field as="select" className="form-select" name="fileCategoryId">
                          <option></option>
                          {fileCategoryState.fileCategoryList?.map(fileCategory => fileCategory.isActive && (
                            <option key={fileCategory.id} value={fileCategory.id}>{fileCategory.name}</option>
                          ))}
                        </Field>
                        <ErrorMessage name="fileCategoryId">
                          { msg => <div className="text-danger">{msg}</div> }
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="row form-group mb-3">
                      <label className="col-3 col-form-label">出荷工場 <span className="text-danger">*</span></label>
                      <div className="col-8">
                        <Field as="select" className="form-select" name="factoryId">
                          <option></option>
                          {factoryState.factoryList?.data?.map(factory => (
                            <option key={factory.id} value={factory.id}>{factory.name}</option>
                          ))}
                        </Field>
                        <ErrorMessage name="factoryId">
                          { msg => <div className="text-danger">{msg}</div> }
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="row form-group mb-3">
                      <label className="col-3 col-form-label" htmlFor="file">ファイル <span className="text-danger">*</span></label>
                      <div className="col-8">
                        <input type="file" id="file" name="file" accept="" onChange={(event: any) => {
                          setFieldValue("file", event.currentTarget.files[0] ?? null);
                          setFieldValue("fileName", event.currentTarget.files[0]?.name ?? '')
                        }} />
                        {(isModeDetail && values.fileName !== '') && (
                          <p className="mt-2">
                            {values.fileName} 
                            <span onClick={downloadFile} className="ml-3 pointer-events">
                              <i className="fas fa-download"></i>
                            </span>
                          </p>
                        )}
                        <ErrorMessage name="file">
                          { msg => <div className="text-danger">{msg}</div> }
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="row form-group mb-3">
                      <label className="col-3 col-form-label">コメント</label>
                      <div className="col-8">
                        <Field name="note" className="form-control" type="text" />
                        {errors.note && touched.note ? (
                          <div className="validation-error"></div>
                        ) : null}
                        <ErrorMessage name="note">
                            {msg => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="mt-4 text-center d-flex justify-content-evenly">
                      <Button className="w-120" variant="primary" type="submit" hidden={process.env.REACT_APP_MODE_RUN === 'viewer'}>
                        {isModeDetail ? '更新' : '登録'}
                      </Button>
                      <Button className="w-120" variant="secondary" onClick={() => {
                        setFieldValue("file", null);
                        setShowModal(false);
                        if (isModeDetail) {
                          clearForm();
                        }
                        setModetail(false);
                      }}>
                        キャンセル
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>

          <div className="goHomeButtonContainer">
            <input type="button" value="戻る" onClick={() => { history.goBack() }} className="goHomeButton" />
          </div>

        </div>
    );
};

export default connect((state: RootState) => ({
    documentState: state.document,
    factoryState: state.factory,
    fileCategoryState: state.fileCategory,
}))(DocumentList);
