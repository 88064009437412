import React, { useEffect, useState, ChangeEvent, useRef } from "react";
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux";
import { CompressionExperimentState, Dispatcher, RootState, AppState, IShippingInformationBrief, ICompressionExperiment } from "AppTypes";
import { compressionExperimentActions } from "./state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Modal } from "../../../components/Modal";
import { success, error } from '@services/toast-service'
import moment from "moment";

type Props = {
    dispatch: Dispatcher;
    compressionExperimentState: CompressionExperimentState;
    appState: AppState
};


const CompressionExperimentSetting: React.FC<Props> = ({ compressionExperimentState, appState, dispatch }) => {

    const { compressionExperimentList, plannedDate, insert, deleteId, update, shippingInformationList, isLoading, selectedShipmentId } = compressionExperimentState;


    const history = useHistory()
    const locationId = localStorage.getItem('currentLocationId') || appState.currentLocationId || ''

    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)

    useEffect(() => {

        if (locationId) {

            dispatch(compressionExperimentActions.fetchShippingInformationList({ locationId, isCompressiveExperimented: true }));

        } else {
            history.push("/locationselector");
        }

    }, [locationId]);



    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const shipmentId = params.get('shipmentId')

        if (shipmentId) {
            dispatch(compressionExperimentActions.setSelectedShipmentId(shipmentId));
            dispatch(compressionExperimentActions.fetchList(shipmentId));
        }
        else if (selectedShipmentId) {

            dispatch(compressionExperimentActions.fetchList(selectedShipmentId));

        }

    }, [selectedShipmentId]);

    const handleChangeUpdateData = (key: string, value: string | number) => {
        dispatch(compressionExperimentActions.setUpdateData({ key, value }));
    }

    const handleChangeInsertData = (key: string, value: string | number) => {


        dispatch(compressionExperimentActions.setInsertData({ key, value }));
    }


    const handleSelectShipment = (id: string) => {
        history.push('/setting/compressionexperiment');
        dispatch(compressionExperimentActions.setSelectedShipmentId(id));
    }

    const handleGoBack = () => {
        history.push("/location")
    }

    const handleUpdateButtonClick = async (id: string) => {
        await dispatch(compressionExperimentActions.setUpdatingId(id));
    }

    const handleDeleteButtonClick = async (id: string) => {
        await dispatch(compressionExperimentActions.setDeleteId(id));
        setShowDeleteConfirmModal(true);
    }

    const handleDeleteCancelButtonClick = async () => {
        setShowDeleteConfirmModal(false);
        await dispatch(compressionExperimentActions.setDeleteId(''));

    }

    const handleDeleteConfirmButtonClick = async () => {
        const response = await dispatch(compressionExperimentActions.deleteOne(deleteId));
        if (response.payload && response.payload.success) {
            await dispatch(compressionExperimentActions.fetchList(selectedShipmentId));
            setShowDeleteConfirmModal(false);
            success("保存が完了しました。");
        } else {
            error(response.payload.errors[0])
        }

    }

    const handleUpdateButtonSaveClick = async (id: string) => {
        try {
            const response = await dispatch(compressionExperimentActions.update(update));
            if (response.payload && response.payload.success) {
                await dispatch(compressionExperimentActions.fetchList(selectedShipmentId));
                success("保存が完了しました。");
                await dispatch(compressionExperimentActions.setUpdatingId(""));

            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }

    const handleUpdateCancelButtonClick = async (id: string) => {
        await dispatch(compressionExperimentActions.setUpdatingId(""));

    }

    const handleAddClick = async () => {
        if (!insert.experimentDate) {
            error("保存できませんでした。");
            return;
        }


        try {
            const response = await dispatch(compressionExperimentActions.insert({ ...insert, shippingInformationId: selectedShipmentId }));
            if (response.payload && response.payload.success) {
                await dispatch(compressionExperimentActions.fetchList(selectedShipmentId));
                success("保存が完了しました。");
                await dispatch(compressionExperimentActions.resetInsert());
            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }
    //const getDateNumber = (index: number, toDate?: string) => {
    //    if (index === 0) {
    //        return 7;
    //    } else if (index === 1) {
    //        return 28;
    //    } else {

    //        return ((index + 1) * 7) + 14;
    //    }
    //}
    const getDateNumber = (toDate: string) => {
        if (toDate && plannedDate) {
            const fDate = new Date(plannedDate);
            const tDate = new Date(toDate);

            const diff = tDate.getTime() - fDate.getTime();


            const diff_In_days = Math.trunc(diff / (1000 * 3600 * 24));
            return diff_In_days;
        }
        return -1;
    }


    return (
        <>
            <LoadingSpinner isShowing={isLoading} />
            <div className="settingItemPageTitle">
                <p>圧縮強度試験</p>
            </div>



            <div className="setting-container">

                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">打設場所</th>
                            <th scope="col">出荷工場</th>
                            <th scope="col">配合</th>
                            <th scope="col">車両No</th>
                            <th scope="col">打設開始時刻</th>
                            <th scope="col">打設終了時刻</th>
                            <th scope="col"></th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            shippingInformationList.length ? (

                                shippingInformationList.map((item: IShippingInformationBrief) => (
                                    <tr key={item.id} className={item.id === selectedShipmentId ? "bg-success" : ""}>
                                        <td scope="col">{item.code}</td>
                                        <td scope="col">{item.location}</td>
                                        <td scope="col">{item.factory}</td>
                                        <td scope="col">{item.mixture}</td>
                                        <td scope="col">{item.truckCode}</td>
                                        <td scope="col">{item.pumpStartTime ? moment(item.pumpStartTime).format("YYYY-MM-DD HH:mm") : ""}</td>
                                        <td scope="col">{item.pumpFinishTime ? moment(item.pumpFinishTime).format("YYYY-MM-DD HH:mm") : ""}</td>

                                        <td>

                                            <input type="button" value="詳細" onClick={() => handleSelectShipment(item.id)} />
                                        </td>
                                    </tr>
                                ))
                            ) : (<tr>
                                <td colSpan={7}>データなし</td>
                            </tr>)
                        }

                    </tbody>
                </table>
            </div >



            <div className="setting-container">

                <table className="table table-striped" hidden={!selectedShipmentId}>
                    <thead>
                        <tr>
                            <th scope="col">No</th>
                            <th scope="col">材齢 試験日</th>
                            <th scope="col">供試体質量(kg)</th>
                            <th scope="col">単位容量質量(kg/m3)</th>
                            <th scope="col">平均(kg/m3)</th>
                            <th scope="col">圧縮強度(N/mm2)</th>
                            <th scope="col">平均(N/mm2)</th>
                            <th scope="col">備考</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>

                        {
                            compressionExperimentList.length ? (

                                compressionExperimentList.map((item: ICompressionExperiment, index) => (


                                    <tr key={item.id}>
                                        <td scope="col">
                                            <p className="mt-2">1</p>
                                            <p className="mt-4">2</p>
                                            <p className="mt-4">3</p>
                                        </td>
                                        <td scope="col">
                                            <p>{
                                                (item.id !== update.id || index < 2) ?
                                                getDateNumber(moment(item.experimentDate).format("YYYY/MM/DD"))
                                                    :
                                                    getDateNumber(moment(update.experimentDate).format("YYYY/MM/DD"))


                                            } </p>
                                           
                                            <p>

                                                {
                                                    (item.id !== update.id || index < 2) ? (

                                                        <p>{moment(item.experimentDate).format("YYYY/MM/DD")}</p>

                                                    ) :
                                                        (
                                                            <input type="date" placeholder="yyyy/mm/dd" value={update.experimentDate && moment(update.experimentDate).format("YYYY-MM-DD")} onChange={(e) => { handleChangeUpdateData("experimentDate", e.target.value) }} className="form-control w-100" />


                                                        )

                                                }
                                            </p>

                                        </td>
                                        <td scope="col">
                                            {
                                                item.id !== update.id ? (

                                                    <>

                                                        <p>
                                                            {item.experimentAmount1?.toFixed(2) || 0}
                                                        </p>
                                                        <p>
                                                            {item.experimentAmount2?.toFixed(2) || 0}
                                                        </p>
                                                        <p>
                                                            {item.experimentAmount3?.toFixed(2) || 0}
                                                        </p>
                                                    </>

                                                ) :
                                                    (
                                                        <>
                                                            <p>
                                                                <input type="number" value={update.experimentAmount1?.toFixed(2) ?? 0} onChange={(e) => { handleChangeUpdateData("experimentAmount1", parseFloat(e.target.value)) }} className="form-control w-50" />
                                                            </p>
                                                            <p>
                                                                <input type="number" value={update.experimentAmount2?.toFixed(2) ?? 0} onChange={(e) => { handleChangeUpdateData("experimentAmount2", parseFloat(e.target.value)) }} className="form-control w-50" />
                                                            </p>
                                                            <p>
                                                                <input type="number" value={update.experimentAmount3?.toFixed(2) ?? 0} onChange={(e) => { handleChangeUpdateData("experimentAmount3", parseFloat(e.target.value)) }} className="form-control w-50" />
                                                            </p>
                                                        </>
                                                    )

                                            }

                                        </td>
                                        <td scope="col">
                                            {
                                                item.id !== update.id ? (

                                                    <>

                                                        <p>
                                                            {item.experimentAmountPerUnit1?.toFixed(2)}
                                                        </p>
                                                        <p>
                                                            {item.experimentAmountPerUnit2?.toFixed(2)}
                                                        </p>
                                                        <p>
                                                            {item.experimentAmountPerUnit3?.toFixed(2)}
                                                        </p>
                                                    </>

                                                ) :
                                                    (
                                                        <>
                                                            <p>
                                                                <input type="number" value={update.experimentAmountPerUnit1?.toFixed(2) ?? 0} onChange={(e) => { handleChangeUpdateData("experimentAmountPerUnit1", parseInt(e.target.value)) }} className="form-control w-50" />
                                                            </p>
                                                            <p>
                                                                <input type="number" value={update.experimentAmountPerUnit2?.toFixed(2) ?? 0} onChange={(e) => { handleChangeUpdateData("experimentAmountPerUnit2", parseInt(e.target.value)) }} className="form-control w-50" />
                                                            </p>
                                                            <p>
                                                                <input type="number" value={update.experimentAmountPerUnit3?.toFixed(2) ?? 0} onChange={(e) => { handleChangeUpdateData("experimentAmountPerUnit3", parseInt(e.target.value)) }} className="form-control w-50" />
                                                            </p>
                                                        </>
                                                    )

                                            }

                                        </td>
                                        <td scope="col" style={{ width: "5%" }}>

                                            {
                                                item.id !== update.id ? (
                                                    <p className="mt-5">
                                                        {
                                                            (((item.experimentAmountPerUnit1 || 0) + (item.experimentAmountPerUnit2 || 0) + (item.experimentAmountPerUnit3 || 0)) / 3).toFixed(2)
                                                        }
                                                    </p>

                                                ) :
                                                    (
                                                        <p className="mt-5">
                                                            {
                                                                (((update.experimentAmountPerUnit1 || 0) + (update.experimentAmountPerUnit2 || 0) + (update.experimentAmountPerUnit3 || 0)) / 3).toFixed(2)
                                                            }
                                                        </p>
                                                    )

                                            }




                                        </td>
                                        <td scope="col">
                                            {
                                                item.id !== update.id ? (

                                                    <>

                                                        <p>
                                                            {item.compressionValue1?.toFixed(2)}
                                                        </p>
                                                        <p>
                                                            {item.compressionValue2?.toFixed(2)}
                                                        </p>
                                                        <p>
                                                            {item.compressionValue3?.toFixed(2)}
                                                        </p>
                                                    </>

                                                ) :
                                                    (
                                                        <>
                                                            <p>
                                                                <input type="number" value={update.compressionValue1?.toFixed(2) ?? 0} onChange={(e) => { handleChangeUpdateData("compressionValue1", parseFloat(e.target.value)) }} className="form-control w-50" />
                                                            </p>
                                                            <p>
                                                                <input type="number" value={update.compressionValue2?.toFixed(2) ?? 0} onChange={(e) => { handleChangeUpdateData("compressionValue2", parseFloat(e.target.value)) }} className="form-control w-50" />
                                                            </p>
                                                            <p>
                                                                <input type="number" value={update.compressionValue3?.toFixed(2) ?? 0} onChange={(e) => { handleChangeUpdateData("compressionValue3", parseFloat(e.target.value)) }} className="form-control w-50" />
                                                            </p>
                                                        </>
                                                    )

                                            }

                                        </td>
                                        <td scope="col" style={{ width: "5%" }}>
                                            <p className="mt-5">

                                                {
                                                    item.id !== update.id ? (
                                                        <p className="mt-5">
                                                            {
                                                                (((item.compressionValue1 || 0) + (item.compressionValue2 || 0) + (item.compressionValue3 || 0)) / 3).toFixed(2)
                                                            }
                                                        </p>

                                                    ) :
                                                        (
                                                            <p className="mt-5">
                                                                {
                                                                    (((update.compressionValue1 || 0) + (update.compressionValue2 || 0) + (update.compressionValue3 || 0)) / 3).toFixed(2)
                                                                }
                                                            </p>
                                                        )

                                                }

                                            </p>
                                        </td>
                                        <td style={{ width: "10%" }}>
                                            {
                                                item.id !== update.id ? (

                                                    <p>
                                                        {item.note}
                                                    </p>

                                                ) :
                                                    (
                                                        <textarea className="form-control" rows={6} value={update.note} onChange={e => handleChangeUpdateData("note", e.target.value)}></textarea>
                                                    )

                                            }


                                        </td>
                                        <td style={{ width: "15%" }}>
                                            {
                                                item.id !== update.id ?
                                                    (
                                                        <>
                                                            <input type="button" name="btnRowEdit" value="編" onClick={() => handleUpdateButtonClick(item.id)} hidden={process.env.REACT_APP_MODE_RUN === 'viewer'} />
                                                            <input type="button" name="btnRowDelete" hidden={index === 0 || index === 1} value="削" onClick={() => handleDeleteButtonClick(item.id)} className="ml-3" />
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <input type="button" name="btnUpdateSave" value="更新" onClick={() => handleUpdateButtonSaveClick(item.id)} />
                                                            <input type="button" name="btnUpdateCancel" value="キャンセル" onClick={() => handleUpdateCancelButtonClick(item.id)} className="ml-3" />
                                                        </>
                                                    )

                                            }
                                        </td>
                                    </tr>


                                ))
                            ) : (<tr />)
                        }
                        {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                        <tr style={{ backgroundColor: "#5d7b9d" }}>
                            <td scope="col">
                                <p className="mt-2">1</p>
                                <p className="mt-4">2</p>
                                <p className="mt-4">3</p>
                            </td>
                            <td scope="col">
                                <p>{insert.experimentDate && getDateNumber(insert.experimentDate)}</p>
                                <p></p>
                                <p>

                                    <input type="date" placeholder="yyyy/mm/dd" value={insert.experimentDate && moment(insert.experimentDate).format("YYYY-MM-DD")} onChange={(e) => { handleChangeInsertData("experimentDate", e.target.value) }} className="form-control w-100" />

                                </p>

                            </td>
                            <td scope="col">
                                <p>
                                    <input type="number" value={insert.experimentAmount1?.toFixed(2) ?? '0.00'} onChange={(e) => { handleChangeInsertData("experimentAmount1", parseFloat(e.target.value)) }} className="form-control w-50" />
                                </p>
                                <p>
                                    <input type="number" value={insert.experimentAmount2?.toFixed(2) ?? '0.00'} onChange={(e) => { handleChangeInsertData("experimentAmount2", parseFloat(e.target.value)) }} className="form-control w-50" />
                                </p>
                                <p>
                                    <input type="number" value={insert.experimentAmount3?.toFixed(2) ?? '0.00'} onChange={(e) => { handleChangeInsertData("experimentAmount3", parseFloat(e.target.value)) }} className="form-control w-50" />
                                </p>
                            </td>
                            <td scope="col">

                                <p>
                                    <input type="number" value={insert.experimentAmountPerUnit1?.toFixed(2) ?? '0.00'} onChange={(e) => { handleChangeInsertData("experimentAmountPerUnit1", parseInt(e.target.value)) }} className="form-control w-50" />
                                </p>
                                <p>
                                    <input type="number" value={insert.experimentAmountPerUnit2?.toFixed(2) ?? '0.00'} onChange={(e) => { handleChangeInsertData("experimentAmountPerUnit2", parseInt(e.target.value)) }} className="form-control w-50" />
                                </p>
                                <p>
                                    <input type="number" value={insert.experimentAmountPerUnit3?.toFixed(2) ?? '0.00'} onChange={(e) => { handleChangeInsertData("experimentAmountPerUnit3", parseInt(e.target.value)) }} className="form-control w-50" />
                                </p>
                            </td>
                            <td scope="col">
                                <p className="mt-5">
                                    {
                                        (((insert.experimentAmountPerUnit1 || 0) + (insert.experimentAmountPerUnit2 || 0) + (insert.experimentAmountPerUnit3 || 0)) / 3).toFixed(2)
                                    }
                                </p>
                            </td>
                            <td scope="col">

                                <p>
                                    <input type="number" value={insert.compressionValue1?.toFixed(2) ?? '0.00'} onChange={(e) => { handleChangeInsertData("compressionValue1", parseFloat(e.target.value)) }} className="form-control w-50" />
                                </p>
                                <p>
                                    <input type="number" value={insert.compressionValue2?.toFixed(2) ?? '0.00'} onChange={(e) => { handleChangeInsertData("compressionValue2", parseFloat(e.target.value)) }} className="form-control w-50" />
                                </p>
                                <p>
                                    <input type="number" value={insert.compressionValue3?.toFixed(2) ?? '0.00'} onChange={(e) => { handleChangeInsertData("compressionValue3", parseFloat(e.target.value)) }} className="form-control w-50" />
                                </p>
                            </td>
                            <td scope="col">
                                <p className="mt-5">
                                    {
                                        (((insert.compressionValue1 || 0) + (insert.compressionValue2 || 0) + (insert.compressionValue3 || 0)) / 3).toFixed(2)
                                    }
                                </p>
                            </td>
                            <td>
                                <textarea className="form-control" rows={6} value={insert.note} onChange={e => handleChangeInsertData("note", e.target.value)}></textarea>
                            </td>
                            <td>

                                <input type="button" name="btnAdd" value="登録" onClick={handleAddClick} />
                            </td>
                        </tr>
                        )}
                    </tbody>
                </table>
            </div >
            <div className="goHomeButtonContainer">
                <input type="button" value="戻る" onClick={handleGoBack} className="goHomeButton" />
            </div>
            <Modal isActive={showDeleteConfirmModal} onClose={() => handleDeleteCancelButtonClick()} width="30%">
                <div className="modal-body">
                    <p>削除します。よろしいですか？</p>
                </div>
                <div className="modal-footer">
                    <input type="button" onClick={handleDeleteConfirmButtonClick} value="削除" />
                    <input type="button" className="ml-3" onClick={() => { handleDeleteCancelButtonClick() }} value="キャンセル" />
                </div>
            </Modal>
        </>
    );
};

export default connect((state: RootState) => ({
    compressionExperimentState: state.compressionExperiment,
    appSate: state.app
}))(CompressionExperimentSetting);
