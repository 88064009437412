import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { api } from '@services'
import { TAuthManagement } from "./type"


export const initialState: TAuthManagement.TState = {
    loginRedirectConfig: {
        endListUrl: "",
        tagManagementUrl: "",
        undeliveredListUrl: "",
        unloadingListUrl: "",
        unshippedListUrl: ""
    },
    isLoading: false,
    isLoggingIn: false,
    isLoggedIn: false,
    loginType: "",
    userId: "",
    userName: "",
    role: ""
}

export const fetchRedirectConfig = createAsyncThunk(
    "authManagement/fetchRedirectConfig",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.auth.fetchRedirectConfig();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);



const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (): void => {
            // localStorage.clear()
            localStorage.removeItem('jwtToken')
            localStorage.removeItem('userId')
            localStorage.removeItem('role')
            localStorage.removeItem('displayName')
            localStorage.removeItem('currentLocationId')
            sessionStorage.clear()
            window.location.replace('/position-select')
        },
        logoutOffice: (): void => {
            localStorage.removeItem('jwtToken')
            localStorage.removeItem('userId')
            localStorage.removeItem('role')
            localStorage.removeItem('displayName')
            localStorage.removeItem('currentLocationId')
            sessionStorage.clear()
            window.location.replace('/login?login-type=Office')
        },
        logoutWorkingSite: (): void => {
            localStorage.removeItem('jwtToken')
            localStorage.removeItem('userId')
            localStorage.removeItem('role')
            localStorage.removeItem('displayName')
            localStorage.removeItem('currentLocationId')
            sessionStorage.clear()
            window.location.replace('/login?login-type=WorkingSite')
        },
        setLoginType(state, action) {
            state.loginType = action.payload;
        },
        setIsLoggingIn(state, action) {
            state.isLoggingIn = action.payload
        },
        setIsLogedIn(state, action) {
            state.isLoggedIn = action.payload
        },
        setUserId(state, action) {
            state.userId = action.payload
        },
        setUserName(state, action) {
            state.userName = action.payload
        },
        setUserRole(state, action) {
            state.role = action.payload
        },
        setLoading(state, action) {
            state.isLoading = action.payload
        }
    },
    extraReducers: builder => {

        builder.addCase(
            fetchRedirectConfig.pending,
            (
                state
            ) => {
                state.isLoading = true;
            }
        );

        builder.addCase(
            fetchRedirectConfig.fulfilled,
            (
                state,
                action
            ) => {
                state.loginRedirectConfig = action.payload.data;
                state.isLoading = false;
            }
        );
    }
})

export const {
    logout,
    logoutOffice,
    logoutWorkingSite,
    setLoginType,
    setIsLogedIn,
    setIsLoggingIn,
    setUserId,
    setUserName,
    setUserRole,
    setLoading,
} = authSlice.actions

export default authSlice.reducer
