import React from 'react'

import './styles.css'

type TProp = {
  isActive: boolean,
  width?: string,
  className?: string,
  title?: string,
  onClose: () => void
};

export const Modal: React.FC<TProp> = ({ isActive, children, onClose, width, className, title }) => (
        <div className="modal" tabIndex={-1} role="dialog" style={{ display: isActive ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document" style={{ width: width ?? '80%' }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{title}</h5>
                {/*    <button type="button" className="close" data-dismiss="modal" aria-label="Close">*/}
                {/*        <span aria-hidden="true" onClick={onClose}>&times;</span>*/}
                {/*    </button>*/}
                </div>
                {children}
            </div>
        </div>
    </div>
)
