import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { IWarningTimeSetting } from "AppTypes";
import { TWarningTimeSettingManagement } from "./types";

export const initialState: TWarningTimeSettingManagement.TState = {
    isLoading: false,
    warningTimeSetting: {
        beginDeliveryLimit: 0,
        beginDeliveryWarningTime: 0,
        endDeliveryLimit: 0,
        endDeliveryWarningTime: 0,
        overlapLimitTime: 0,
        overlapLimitWarningTime: 0
    }
};

export const fetch = createAsyncThunk(
    "warningTimeSetting/fetch",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.warningTimeSettingAPI.fetch();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


 

export const update = createAsyncThunk(
    "warningTimeSetting/update",
    async (params: IWarningTimeSetting, { rejectWithValue }) => {
        try {
            const response = await api.warningTimeSettingAPI.update(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const warningTimeSettingSlice = createSlice({
    name: "warningTimeSetting",
    initialState,
    reducers: {
        setData(state, action) {
            const { key, value } = action.payload;
            const { warningTimeSetting } = state;
            if (warningTimeSetting) {
                warningTimeSetting[key] = value;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetch.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(
            fetch.fulfilled,
            (
                state,
                action: PayloadAction<{ data: IWarningTimeSetting }>
            ) => {
                state.warningTimeSetting = action.payload.data;
                state.isLoading = false;
            }
        );
    },
});

export const {
    setData
} = warningTimeSettingSlice.actions;

export default warningTimeSettingSlice.reducer;
