import React, { useCallback, useEffect, useState, useRef } from "react";
import { Switch, Route } from "react-router";
import { useHistory, Link, useLocation } from "react-router-dom";
import { RouterState } from "connected-react-router";
import { connect } from "react-redux";
import { useIdleTimer } from 'react-idle-timer'
import { RootState, AppState, LocationSelectorState, Dispatcher } from "AppTypes";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr"
import { slide as Menu, fallDown as DropMenu } from "react-burger-menu";
import { redirectToLoginIfNoValidAuthInfoStored } from "@utils/auth-guard";
import MainPage from "@features/main";
import NavMenu from "../../components/NavMenu";
import FactoryList from "@features/setting/factory/FactoryList";
import MixtureList from "@features/setting/mixture/MixtureList";
import BlockLiftSetting from "@features/setting/location/BlockLiftSetting";
import UserList from "@features/setting/user/UserList";
import FileCategoryList from "@features/setting/filecategory/FileCategoryList";
import WarningTimeSetting from "@features/setting/warningtime/WarningTimeSetting";
import MeshSetting from "@features/setting/meshcolor/MeshSetting";
import DisplayItemSetting from "@features/setting/displayitem/DisplayItemSetting";
import CardReaderSetting from "@features/setting/cardreader/CardReaderSetting";
import LocationSetting from "@features/setting/location/LocationSetting";
import GeneralModelSetting from "@features/setting/generalmodel/GeneralModelSetting";
import OverlappingList from "@features/setting/overlapping/OverlappingList";
import ShippingInformationEditor from "@features/shippinginformation/ShippingInformationEditor";
import ShipmentRegistration from "@features/shipment/registration/ShipmentRegistration";
import DocumentList from "@features/document/DocumentList";
import VersionPage from "@features/version/VersionPage";
import ThreeEditor from "@features/setting/3deditor/ThreeEditor";
import Setting from "@features/setting";
import PageNotFoundLayout from "../404/404";
import "./Main.css";
import LocationSelector from "../../features/location/selector/LocationSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "../../components/Modal";
import { Modal as ModalBootstrap } from 'react-bootstrap';
import CompressionExperimentSetting from "../../features/setting/compressionexperiment/CompressionExperimentSetting";
import SlumpSetting from "../../features/setting/slump/SlumpSetting";
import ConstructionPage from "../../features/construction/ConstructionPage";
import PlayHistory from "@features/playHistory/PlayHistory";
import { success, error } from '@services/toast-service'
import { locationSelectorActions } from "@features/location/selector/state_management/actions";
import { overlappingActions } from "@features/setting/overlapping/state_management/actions";
import { shippingInformationActions } from "@features/shippinginformation/state_management/actions";
import { shipmentActions } from "@features/shipment/registration/state_management/actions";
import { authActions } from '@features/auth/state_management/actions'
import moment from "moment";
import { api } from "@services"
import { STATUS_MESH, TYPE_MESSAGE_SOCKET, WARNING_STATUS } from '../../const/constants';
import LoadingSpinner from "../../components/LoadingSpinner";
import { isTablet } from "react-device-detect";
import { listRouteNotCheckLocation } from "@utils/func";

type Props = {
    dispatch: Dispatcher;
    appState: AppState;
    routerState: RouterState;
    locationSelectorState: LocationSelectorState;
};
let messageAlert = ''

const Main: React.FC<Props> = ({
    routerState: {
        location: { pathname: locationPathname },
    },
    appState,
    locationSelectorState,
    dispatch,
}) => {
    const history = useHistory();
    const [connection, setConnection] = useState<null | HubConnection>(null)
    const locationId = localStorage.getItem('currentLocationId') || appState.currentLocationId || ''
    const [showMenu, setShowMenu] = React.useState(false);
    const [showSettingDropDown, setShowSettingDropDown] = React.useState(false);
    const [show3DSettingDropDown, setShow3DSettingDropDown] =
        React.useState(false);
    const [showReportSettingDropDown, setShowReportSettingDropDown] =
        React.useState(false);
    const [showExperimentDropDown, setShowExperimentDropDown] =
        React.useState(false);
    const [showVersionModal, setShowVersionModal] = React.useState(false);
    const location = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const showMessage = React.useRef(false)
    const role = localStorage.getItem('role');
    const [meshSocketId, setMeshSocketId] = useState('');
    const [showAlertSocket, setShowAlertSocket] = useState(false);
    const isUpdateShippingInformation = useRef(false);
    
    const onAction = (event: any) => {
        // Do something when a user triggers a watched event
        if (getLastActiveTime() && moment().subtract(1, 'minute').isAfter(getLastActiveTime())) {
            const locationId = localStorage.getItem('currentLocationId')
            dispatch(overlappingActions.fetchList({LocationId: locationId}));
        }
    }
    

    const {
        start,
        getLastActiveTime,
      } = useIdleTimer({
        onAction,
        timeout: 1000 * 60 * 20,
        promptBeforeIdle: 0,
        events: [
          'mousemove',
          'keydown',
          'wheel',
          'DOMMouseScroll',
          'mousewheel',
          'mousedown',
          'touchstart',
          'touchmove',
          'MSPointerDown',
          'MSPointerMove',
          'visibilitychange',
          'focus'
        ],
        immediateEvents: [],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: false,
        name: 'idle-timer',
        syncTimers: 0,
        leaderElection: false
      })

    useEffect(() => {
        start()
    }, [])

    useEffect(() => {
        const connect = new HubConnectionBuilder()
          .withUrl(`${process.env.REACT_APP_API_ENDPOINT}eagleplushub`)
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: retryContext => {
                if (retryContext.elapsedMilliseconds < 60000) {
                    // If we've been reconnecting for less than 60 seconds so far,
                    // wait between 0 and 10 seconds before the next reconnect attempt.
                    if (isTablet) {
                        setShowAlertSocket(true)
                    }
                    return Math.random() * 10000;
                } else {
                    console.log('nextRetryDelayInMilliseconds null')
                    // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                    return null;
                }
            }
          })
          .build();
    
        setConnection(connect)
    },[])

    const renderTextStatus = (mesh: any) => {
        let textStatus = '未打設';
        if (mesh?.status === STATUS_MESH[1] && !mesh?.isSlim) {
            textStatus = "打設中";
        } else if (mesh?.status === STATUS_MESH[2] && !mesh?.isSlim) {
            textStatus = "打設終了";
        } else if (mesh?.status === STATUS_MESH[1] && mesh?.isSlim) {
            textStatus = "薄層打設中";
        } else if (mesh?.status === STATUS_MESH[2] && mesh?.isSlim) {
            textStatus = "薄層打設終了";
        }
        if (mesh?.warningStatus === WARNING_STATUS[0]) {
            textStatus = "警告時間超過";
        } else if (mesh?.warningStatus === WARNING_STATUS[1]) {
            textStatus = "制限時間超過";
        }
        return textStatus
    }

    const updateMeshOnSocket = useCallback((message) => {
        const { pathname } = window.location
        if (pathname === '/construction' || pathname === '/location' || pathname === '/three-editor' && meshSocketId === '') {
            const locationId = localStorage.getItem('currentLocationId')
            setMeshSocketId(message?.data?.id);
            if (locationId && message?.data?.locationId === locationId && (pathname === '/location' || pathname === '/three-editor' || pathname === '/construction')) {
                dispatch(locationSelectorActions.fetchLocationMeshAlert({ locationId: locationId, ignoreFilter: true }));
                dispatch(locationSelectorActions.updateStatusMesh(message?.data))
                // update list 運搬情報 and 打重ね情報
                dispatch(overlappingActions.fetchList({LocationId: locationId}));
                dispatch(shippingInformationActions.fetchList({locationId: locationId}));
                setMeshSocketId('');
                // get all list pump
                dispatch(shippingInformationActions.fetchPumpList({ LocationId: locationId }))
            }
            if (
                !showMessage.current
                && ((locationId && message?.data?.locationId === locationId && (pathname === '/location' || pathname === '/three-editor' || pathname === '/construction')))
            ) {
                const messageShow = `${message?.data?.code} が${renderTextStatus(message?.data)}に変わりました。`
                if (messageAlert !== messageShow) {
                    messageAlert = messageShow;
                    if (message?.data?.warningStatus) {
                        error(messageShow);
                    } else {
                        success(messageShow);
                    }
                }
                showMessage.current = true;
                const timer = setTimeout(() => {
                    showMessage.current = false;
                }, 2500);
                return timer
            }
        }
    }, [])

    const updateModeSlim = useCallback(async (dataMessage: any) => {
        try {
            const locationId = localStorage.getItem('currentLocationId')
            const pumpSocket = await dispatch(shippingInformationActions.fetchPumpList({ LocationId: locationId }));
            if (pumpSocket.payload?.length > 0) {
                const itemPump = pumpSocket.payload.find((item: { id: any; }) => item.id === dataMessage.id);
                if (itemPump) {
                    dispatch(locationSelectorActions.setModeSlimSocket(dataMessage))
                }
            }
        } catch (error) {
            console.log('updateModeSlim: ', error)
        }
    }, [])

    useEffect(() => {
        if (connection) {
            connection
            .start()
            .then(async () => {
                await connection.invoke("JoinGroup","EaglePlus")
                console.log("join room EaglePlus")
            })
            .catch((error) => console.log('error: ',error))
            connection.on("ReceiveMessage", (message: any) => {
                console.log(message)
                if (message.eventCode === TYPE_MESSAGE_SOCKET[0]) {
                    console.log('message Mesh', message)
                    updateMeshOnSocket(message)
                } else if (message.eventCode === TYPE_MESSAGE_SOCKET[1] || message.eventCode === TYPE_MESSAGE_SOCKET[2]) {
                    // update shipping information
                    if (
                        message.data.locationId === localStorage.getItem('currentLocationId') &&
                        !isUpdateShippingInformation.current &&
                        window.location.pathname === '/location'
                    ) {
                        isUpdateShippingInformation.current = true;
                        dispatch(shippingInformationActions.fetchList({
                            locationId: message.data.locationId
                        }));
                        dispatch(shipmentActions.fetchFactoryList());
                        dispatch(shipmentActions.fetchPumpList({ locationId: message.data.locationId }));
                        dispatch(shipmentActions.fetchProcessChartData({ locationId: message.data.locationId }));
                        dispatch(shipmentActions.fetchOrderByLocation(message.data.locationId));
                        console.log('message ShippingInformation', message)
                        const timeoutCallApiShipping = setTimeout(() => {
                            isUpdateShippingInformation.current = false;
                        }, 1000);
                        return timeoutCallApiShipping;
                    }
                    if (
                        message.data.locationId === localStorage.getItem('currentLocationId') &&
                        !isUpdateShippingInformation.current &&
                        window.location.pathname === '/construction'
                    ) {
                        isUpdateShippingInformation.current = true;
                        dispatch(shippingInformationActions.fetchList({
                            locationId: message.data.locationId
                        }));
                        dispatch(shipmentActions.fetchPumpList({ locationId: message.data.locationId }));
                        console.log('message ShippingInformation', message)
                        const timeoutCallApiShipping = setTimeout(() => {
                            isUpdateShippingInformation.current = false;
                        }, 1000);
                        return timeoutCallApiShipping;
                    }
                } else if (message.eventCode === TYPE_MESSAGE_SOCKET[3]) {
                    // get all list pump
                    updateModeSlim(message.data);
                }
            });

            connection.onclose(() => {
                connection.start()
            });

            connection.onreconnecting(() => {
                console.log("onreconnecting")
                connection.invoke("JoinGroup","EaglePlus")
            })
        }
    }, [connection, isUpdateShippingInformation])

    useEffect(() => {
        redirectToLoginIfNoValidAuthInfoStored();
        if (!locationId && role !== 'WorkingSite' && !listRouteNotCheckLocation.includes(window.location.pathname)) {
            history.push("/locationselector");
        }
    }, [locationSelectorState.locationSelect]);

    const changeStateMenu = ({ isOpen }: any) => {
        setShowMenu(isOpen);
    };

    const handleOpenSettingDropdownClick = (e: any) => {
        e.preventDefault();
        setShowSettingDropDown(!showSettingDropDown);
    };

    const handleOpenSetting3DDropdownClick = (e: any) => {
        e.preventDefault();
        setShow3DSettingDropDown(!show3DSettingDropDown);
    };

    const handleOpenReportSettingDropdownClick = (e: any) => {
        e.preventDefault();
        setShowReportSettingDropDown(!showReportSettingDropDown);
    };

    const handleOpenExperimentSettingDropdownClick = (e: any) => {
        e.preventDefault();
        setShowExperimentDropDown(!showExperimentDropDown);
    };

    //reports
    const handleDownloadCastingRecordReport = async () => {
        setIsLoading(true);

        try {
            const fileBlob = await api.locationAPI.exportCastingRecordReport(locationId);
            const fileUrl = window.URL.createObjectURL(fileBlob)
            const link = document.createElement('a')
            link.href = fileUrl
            link.download = `${moment(new Date()).format('YYYYMMDD')}_打設記録表.xlsx`
            document.body.appendChild(link)
            link.click()
            success('ダウンロードが完了しました。')
            document.body.removeChild(link)
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    }

    // const handleDownloadCastingManagementReport = async () => {

    //     setIsLoading(true);

    //     try {
    //         const fileBlob = await api.locationAPI.exportCastingManagementReport(locationId);
    //         const fileUrl = window.URL.createObjectURL(fileBlob)
    //         const link = document.createElement('a')
    //         link.href = fileUrl
    //         link.download = `${moment(new Date()).format('YYYYMMDD')}_打設管理表.xlsx`
    //         document.body.appendChild(link)
    //         link.click()
    //         success('ダウンロード完了しました')
    //         document.body.removeChild(link)
    //         setIsLoading(false);

    //     } catch {
    //         setIsLoading(false);
    //     }
    // }

    // const handleDownloadCastingTimeReport = async () => {

    //     setIsLoading(true);
    //     try {
    //         const fileBlob = await api.locationAPI.exportCastingTimeReport(locationId);
    //         const fileUrl = window.URL.createObjectURL(fileBlob)
    //         const link = document.createElement('a')
    //         link.href = fileUrl
    //         link.download = `${moment(new Date()).format('YYYYMMDD')}_打重ね時間管理表.xlsx`
    //         document.body.appendChild(link)
    //         link.click()
    //         success('ダウンロード完了しました')
    //         document.body.removeChild(link)
    //         setIsLoading(false);
    //     } catch {
    //         setIsLoading(false);
    //     }
    // }

    // const handleDownloadCastingDetailReport = async () => {

    //     setIsLoading(true);
    //     try {
    //         const fileBlob = await api.locationAPI.exportCastingDetailReport(locationId);
    //         const fileUrl = window.URL.createObjectURL(fileBlob)
    //         const link = document.createElement('a')
    //         link.href = fileUrl
    //         link.download = `${moment(new Date()).format('YYYYMMDD')}_打設詳細表.xlsx`
    //         document.body.appendChild(link)
    //         link.click()
    //         success('ダウンロード完了しました')
    //         document.body.removeChild(link)
    //         setIsLoading(false);
    //     } catch {
    //         setIsLoading(false);
    //     }
    // }
    const handleDownloadShipmentReport = async () => {

        setIsLoading(true);
        try {
            const fileBlob = await api.shippingInformationAPI.exportShipmentReport();
            const fileUrl = window.URL.createObjectURL(fileBlob)
            const link = document.createElement('a')
            link.href = fileUrl
            link.download = `${moment(new Date()).format('YYYYMMDD')}_打設情報一覧.xlsx`
            document.body.appendChild(link)
            link.click()
            success('ダウンロード完了しました')
            document.body.removeChild(link)
            setIsLoading(false);

        } catch {
            setIsLoading(false);
        }
    }

    const reloadAction = () => {
        const locationSelect = locationSelectorState.locationSelect;
        const pumpSelect = locationSelectorState.pumpSelect;
        const mode3d = locationSelectorState.modeShow3d;
        const modeShowFloor = locationSelectorState.modeShow3d;
        const floorActive = locationSelectorState.floorActive;
        const isShowMeshNotComplete = locationSelectorState.filter.isShowMeshNotComplete;
        if (role === 'WorkingSite') {
            window.location.href = `/construction?locationSelect=${locationSelect}&pumpSelect=${pumpSelect}&mode3d=${mode3d}&modeShowFloor=${modeShowFloor}&floorActive=${floorActive}&isShowMeshNotComplete=${isShowMeshNotComplete}`;
        } else {
            window.location.reload();
        }
    }

    return (
        <>
            <LoadingSpinner isShowing={isLoading} />
            {/*<div className={role === 'WorkingSite' && locationSelectorState.isShowAlert && locationSelectorState.isModeSlim ? 'wrapper alert-slim-mode' : 'wrapper'}>*/}
            <div className={role === 'WorkingSite' && locationSelectorState.isModeSlim ? 'wrapper alert-slim-mode' : 'wrapper'}>
                <div className="content-wrapper">
                    <Menu isOpen={showMenu} onStateChange={changeStateMenu} right>
                        <nav className="bm-item-list">
                            <ul className="nav">
                                <li className="nav-item">
                                    <Link
                                        className="bm-item"
                                        to="/setting"
                                        onClick={(e) => {
                                            handleOpenSettingDropdownClick(e);
                                        }}
                                    >
                                        設定
                                        <FontAwesomeIcon
                                            icon={showSettingDropDown ? faAngleUp : faAngleDown}
                                            className="pull-right white"
                                        />
                                    </Link>
                                    <ul className="nav nav-child" hidden={!showSettingDropDown}>
                                        {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                                        <li className="nav-item">
                                            <Link
                                                className="bm-item"
                                                onClick={() => setShowMenu(false)}
                                                to="/setting/user"
                                            >
                                                ユーザ設定
                                            </Link>
                                        </li>
                                        )}
                                        {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                                        <li className="nav-item">
                                            <Link
                                                className="bm-item"
                                                onClick={() => setShowMenu(false)}
                                                to="/setting/factory"
                                            >
                                                出荷工場設定
                                            </Link>
                                        </li>
                                        )}
                                        {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                                        <li className="nav-item">
                                            <Link
                                                className="bm-item"
                                                onClick={() => setShowMenu(false)}
                                                to="/setting/mixture"
                                            >
                                                配合設定
                                            </Link>
                                        </li>
                                        )}
                                        {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                                        <li className="nav-item">
                                            <Link
                                                className="bm-item"
                                                onClick={() => setShowMenu(false)}
                                                to="/setting/filecategory"
                                            >
                                                文書管理分類設定
                                            </Link>
                                        </li>
                                        )}
                                        {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                                        <li className="nav-item">
                                            <Link
                                                className="bm-item"
                                                onClick={() => setShowMenu(false)}
                                                to="/setting/warningtime"
                                            >
                                                アラート時間設定
                                            </Link>
                                        </li>
                                        )}
                                        <li className="nav-item">
                                            <Link
                                                className="bm-item"
                                                onClick={() => setShowMenu(false)}
                                                to="/setting/mesh"
                                            >
                                                メッシュカラー設定
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link
                                                className="bm-item"
                                                onClick={() => setShowMenu(false)}
                                                to="/setting/displayitem"
                                            >
                                                表示項目設定
                                            </Link>
                                        </li>
                                        {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                                        <li className="nav-item">
                                            <Link
                                                className="bm-item"
                                                onClick={() => setShowMenu(false)}
                                                to="/setting/blocklift"
                                            >
                                                打設場所名称設定
                                            </Link>
                                        </li>
                                        )}
                                        {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                                        <li className="nav-item">
                                            <Link
                                                className="bm-item"
                                                onClick={() => setShowMenu(false)}
                                                to="/setting/cardreader"
                                            >
                                                ICタグリーダ設定
                                            </Link>
                                        </li>
                                        )}

                                    </ul>
                                </li>
                                {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                                    <li className="nav-item">
                                        <Link
                                            className="bm-item"
                                            to="/setting"
                                            onClick={(e) => {
                                                handleOpenSetting3DDropdownClick(e);
                                            }}
                                        >
                                            3Dモデル管理
                                            <FontAwesomeIcon
                                                icon={show3DSettingDropDown ? faAngleUp : faAngleDown}
                                                className="pull-right white"
                                            />
                                        </Link>
                                        <ul
                                            className="nav nav-child"
                                            hidden={!show3DSettingDropDown}
                                        >
                                            <li className="nav-item">
                                                <Link
                                                    className="bm-item"
                                                    onClick={() => setShowMenu(false)}
                                                    to="/setting/generalmodel"
                                                >
                                                    全体モデル登録
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    className="bm-item"
                                                    onClick={() => setShowMenu(false)}
                                                    to="/setting/location"
                                                >
                                                    打設場所モデル登録
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <Link
                                        className="bm-item"
                                        onClick={() => setShowMenu(false)}
                                        to="/document"
                                    >
                                        文書管理
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className="bm-item"
                                        onClick={(e) => {
                                            handleOpenReportSettingDropdownClick(e);
                                        }}
                                        to="/"
                                    >
                                        帳表出力
                                        <FontAwesomeIcon
                                            icon={
                                                showReportSettingDropDown ? faAngleUp : faAngleDown
                                            }
                                            className="pull-right white"
                                        />
                                    </Link>
                                    <ul
                                        className="nav nav-child"
                                        hidden={!showReportSettingDropDown}
                                    >
                                        <li className="nav-item">
                                            <a
                                                className="bm-item"
                                                onClick={handleDownloadShipmentReport}
                                            >
                                                打設情報一覧
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="bm-item"
                                        onClick={() => {
                                            setShowVersionModal(true);
                                        }}
                                    >
                                        バージョン
                                    </a>
                                </li>
                                <li className="nav-item nav-item-end">
                                    <a className="bm-item" aria-hidden="true" onClick={() => dispatch(authActions.logoutOffice())}>ログアウト</a>
                                </li>
                            </ul>
                        </nav>
                    </Menu>
                    <div className="content">
                        {!location.pathname.includes("/construction") && (
                            <NavMenu setShowMenu={setShowMenu} />
                        )}
                        <main>
                            <Switch>
                                <Route path="/" component={LocationSelector} exact />
                                <Route path="/location" component={MainPage} exact />
                                <Route path="/document" component={DocumentList} exact />
                                <Route path="/setting" component={Setting} exact />
                                <Route path="/setting/factory" component={FactoryList} exact />
                                <Route path="/setting/mixture" component={MixtureList} exact />
                                <Route
                                    path="/setting/blocklift"
                                    component={BlockLiftSetting}
                                    exact
                                />
                                {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                                    <Route
                                        path="/setting/location"
                                        component={LocationSetting}
                                        exact
                                    />
                                )}
                                <Route
                                    path="/locationselector"
                                    component={LocationSelector}
                                    exact
                                />
                                {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                                    <Route
                                        path="/setting/generalmodel"
                                        component={GeneralModelSetting}
                                        exact
                                    />
                                )}
                                <Route path="/setting/user" component={UserList} exact />
                                <Route
                                    path="/setting/filecategory"
                                    component={FileCategoryList}
                                    exact
                                />
                                <Route
                                    path="/setting/warningtime"
                                    component={WarningTimeSetting}
                                    exact
                                />
                                <Route path="/setting/mesh" component={MeshSetting} exact />
                                <Route
                                    path="/setting/displayitem"
                                    component={DisplayItemSetting}
                                    exact
                                />
                                <Route
                                    path="/setting/cardreader"
                                    component={CardReaderSetting}
                                    exact
                                />

                                <Route path="/version" component={VersionPage} exact />
                                <Route
                                    path="/setting/compressionexperiment"
                                    component={CompressionExperimentSetting}
                                    exact
                                />
                                <Route
                                    path="/setting/slumpinfo"
                                    component={SlumpSetting}
                                    exact
                                />
                                {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                                    <Route
                                        path="/construction"
                                        component={ConstructionPage}
                                        exact
                                    />
                                )}
                                <Route
                                    path="/setting/staking"
                                    component={OverlappingList}
                                    exact
                                />
                                <Route
                                    path="/shipment/registration"
                                    component={ShipmentRegistration}
                                    exact
                                />
                                <Route
                                    path="/shippinginformationeditor"
                                    component={ShippingInformationEditor}
                                    exact
                                />
                                <Route path="/three-editor" component={ThreeEditor} exact />
                                <Route
                                    path="/replay-location-history"
                                    component={PlayHistory}
                                    exact
                                />
                                <Route component={PageNotFoundLayout} />
                            </Switch>
                        </main>
                    </div>
                </div>
            </div>
            {!location.pathname.includes("/construction") && (
                <div className="footer">(C) SKY SYSTEM</div>
            )}
            <Modal
                isActive={showVersionModal}
                title="情報"
                onClose={() => {
                    setShowMenu(false);
                    setShowVersionModal(false);
                }}
                width="30%"
            >
                <div className="modal-body">
                    <h5>grouse3D</h5>
                    <p>
                        バージョン　<strong>{process.env.REACT_APP_VERSION}</strong>
                    </p>
                    <p>
                        Copyright by 株式会社スカイシステム
                    </p>
                </div>
                <div className="modal-footer">
                    <input
                        type="button"
                        className="ml-3"
                        onClick={() => {
                            setShowMenu(false);
                            setShowVersionModal(false);
                        }}
                        value="閉じる"
                    />
                </div>
            </Modal>
            <ModalBootstrap
                show={showAlertSocket}
                onHide={() => {
                    setShowAlertSocket(false);
                }}
                width="30%"
            >
                <ModalBootstrap.Header closeButton />
                <ModalBootstrap.Body>
                    <p>
                        画面スリープにより、表示の自動更新が無効になっています。
                    </p>
                    <p>
                        ページの再読み込みを行って下さい。
                    </p>
                </ModalBootstrap.Body>
                <ModalBootstrap.Footer>
                <div className="d-flex justify-content-around w-100">
                        <div>
                            <button type="button" className="btn btn-sm btn-primary w-120" onClick={reloadAction}>OK</button>
                        </div>
                    </div>
                </ModalBootstrap.Footer>
            </ModalBootstrap>
        </>
    );
};

export default connect((state: RootState) => ({
    appState: state.app,
    routerState: state.router,
    locationSelectorState: state.locationSelector,
}))(Main);
