import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Formik,
    FormikHelpers,
    Form,
    Field,
    ErrorMessage
} from 'formik'
import classNames from 'classnames'
import { useHistory, useLocation } from 'react-router-dom'
import { RouterState } from 'connected-react-router'
import { connect } from 'react-redux'
import { success } from '@services/toast-service'
import {
    AuthState,
    Dispatcher,
    LoginFormValue,
    RootState,
} from 'AppTypes'
import { authActions } from './state_management/actions'
import { LoginFormValidationSchema } from './validations'
import './Login.css'
import { api } from "@services"
import { LOGINTYPE } from '@share/const'
import { useQuery } from '@utils/func'
import { object } from 'yup'

type Props = {
    dispatch: Dispatcher,
    authState: AuthState,
    routerState: RouterState
}


const Login: React.FC<Props> = ({
    authState: {
        isLoading,
        loginType,
    },
    dispatch
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [errorServer, setErrorServer] = useState('')
    const query = useQuery();
    const loginTypeParam = query.get('login-type')

    useEffect(() => {
        if (loginTypeParam !== null) {
            if (loginTypeParam === LOGINTYPE.OFFICE) {
                dispatch(authActions.setLoginType(LOGINTYPE.OFFICE));
            } else if (loginTypeParam === LOGINTYPE.WORKINGSITE) {
                dispatch(authActions.setLoginType(LOGINTYPE.WORKINGSITE));
            }
        } else if (loginType === '' && loginTypeParam === null) {
            history.push("/position-select");
        }
    }, [])

    const loginFormInitialValues: LoginFormValue = {
        userName: (localStorage.getItem('isRememberOffice') === 'true' || localStorage.getItem('isRememberWorking') === 'true')
            ? (loginType === LOGINTYPE.OFFICE ? (localStorage.getItem('usernameOffice') ?? '') : (localStorage.getItem('usernameWorking') ?? ''))
            : '',
        passWord: (localStorage.getItem('isRememberOffice') === 'true' || localStorage.getItem('isRememberWorking') === 'true')
            ? (loginType === LOGINTYPE.OFFICE ? (localStorage.getItem('passwordOffice') ?? '') : (localStorage.getItem('passwordWorking') ?? ''))
            : '',
        isRemember: loginType === LOGINTYPE.OFFICE ? localStorage.getItem('isRememberOffice') === 'true' : localStorage.getItem('isRememberWorking') === 'true',
    }

    const getRedirectUrlByRole = (role: string) => {
        if (role === "Office") {
            return "/locationselector";
        } else if (role === "WorkingSite") {
            return "/construction";
        }
        return "/";
    }

    const dispatchAuthenticate = async (values: LoginFormValue) => {
        try {
            setErrorServer('')
            dispatch(authActions.setIsLoggingIn(true));
            dispatch(authActions.setLoading(true));
            const response = await api.auth.authenticate({
                userName: values.userName,
                passWord: values.passWord,
                loginType: loginType
            });
            const { data } = response;
            if (data && data.statusCode === 200) {
                const { jwtToken, role, id, username, displayName, refreshToken } = response.data.data;
                if (values.isRemember) {
                    if (loginType === LOGINTYPE.OFFICE) {
                        localStorage.setItem('passwordOffice', values.passWord);
                        localStorage.setItem('usernameOffice', values.userName);
                        localStorage.setItem('isRememberOffice', values?.isRemember?.toString().toLowerCase());
                    }
                    if (loginType === LOGINTYPE.WORKINGSITE) {
                        localStorage.setItem('passwordWorking', values.passWord);
                        localStorage.setItem('usernameWorking', values.userName);
                        localStorage.setItem('isRememberWorking', values?.isRemember?.toString().toLowerCase());
                    }
                    
                } else {
                    if (loginType === LOGINTYPE.OFFICE) {
                        localStorage.removeItem('passwordOffice');
                        localStorage.removeItem('usernameOffice');
                        localStorage.removeItem('isRememberOffice');
                    }
                    if (loginType === LOGINTYPE.WORKINGSITE) {
                        localStorage.removeItem('passwordWorking');
                        localStorage.removeItem('usernameWorking');
                        localStorage.removeItem('isRememberWorking');
                    }
                }
                success('ログインできました。')
                if (jwtToken)
                    localStorage.setItem('jwtToken', jwtToken)
                if (id)
                    localStorage.setItem('userId', id)
                if (role)
                    localStorage.setItem('role', role)

                if (username)
                    localStorage.setItem('username', username)

                if (displayName)
                    localStorage.setItem('displayName', displayName)

                if (refreshToken)
                    localStorage.setItem('refreshToken', refreshToken)

                dispatch(authActions.setIsLogedIn(true));
                dispatch(authActions.setUserId(id));
                dispatch(authActions.setUserName(username));
                dispatch(authActions.setUserRole(role));

                const redirectToUrl = getRedirectUrlByRole(role);

                history.push(redirectToUrl)

            } else if (data.statusCode === 403) { //login with wrong type
                setErrorServer(t(data.errors[0]))
            }
            else {
                setErrorServer(t('loginForm.loginFailed'))
            }
            dispatch(authActions.setLoading(false));
        } catch (error: any) {
            dispatch(authActions.setLoading(false));
            setErrorServer(error.response.data);
        }
    }

    return (
        <div className="login-background">
            <div className="header-login"> {loginType === LOGINTYPE.OFFICE ? '事務所' : '現場'}ログイン</div>
            <div className="login-container">
                {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                    <img src="/assets/grouse_logo.png" alt="grouse3D" />
                )}
                {process.env.REACT_APP_MODE_RUN === 'viewer' && (
                    <img src="/assets/grouseviewer_logo.png" alt="grouse3D" />
                )}
                <Formik
                    enableReinitialize
                    initialValues={loginFormInitialValues}
                    validationSchema={LoginFormValidationSchema}
                    onSubmit={(values: LoginFormValue, actions) => {
                        dispatchAuthenticate(values)
                        actions.setSubmitting(false)
                    }}
                >
                    {({ errors, touched, submitForm }: any) => (
                        <form className="login-form">
                            <h1 className="h3 mb-4 fw-normal text-center text-white font-weight">{/*{loginType === LOGINTYPE.OFFICE ? '事務所ログイン' : '現場ログイン'}*/}</h1>
                            <div className="login-row">
                                <div className="login-label">{t('loginForm.userID')}</div>
                                <div className="login-input">
                                    <Field name="userName" className={classNames('login-input', { error: errors.userName && touched.userName })} type="text" />
                                    <ErrorMessage name="userName">
                                        {msg => <div className="text-danger">{t(msg)}</div>}
                                    </ErrorMessage>
                                </div>
                            </div>
                            <div className="login-row">
                                <div className="login-label">{t('loginForm.password')}</div>
                                <div className="login-input">
                                    <Field name="passWord" className={classNames('login-input', { error: errors.passWord && touched.passWord })} type="password" />
                                    <ErrorMessage name="passWord">
                                        {msg => <div className="text-danger">{t(msg)}</div>}
                                    </ErrorMessage>
                                </div>
                            </div>
                            <div className="login-row checkbox mb-3">
                                <label className="d-flex">
                                    <Field className="checkbox-remember" type="checkbox" name="isRemember" /> <span className="ml-5 text-white fs-6"> 次回ログイン省略</span>
                                </label>
                            </div>
                            {errorServer !== '' && <div className="text-danger mb-4 text-center">{errorServer}</div>}
                            <div className="d-flex justify-content-evenly">
                                <button className="btn btn-primary w-120" type="submit" onClick={submitForm} disabled={isLoading || Object.keys(errors).length > 0}>
                                    {t('loginForm.loginBtn')}
                                </button>

                                {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                                    <button className="ml-5 btn btn-secondary w-120" type="button" onClick={() => {
                                        history.push("/position-select");
                                    }}>
                                        戻る
                                    </button>
                                )}
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default connect((state: RootState) => ({
    authState: state.auth,
    routerState: state.router
}))(Login)
