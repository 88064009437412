import { TOverlappingManagement } from '@features/setting/overlapping/state_management/types'
import axios from '@utils/axios-client'
import { appendParams } from '@utils/func'

class OverlappingAPI {
    prefix = '/api/v1/Mesh'

    async fetchList(params: any) {
        const url = appendParams(`${this.prefix}/GetAll`, { ...params })
        return axios.get(url)
    }

    async insert(params: TOverlappingManagement.Overlapping) {
        const url = `${this.prefix}/Create`
        return await axios.post(url, params)
    }

    async update(params: TOverlappingManagement.Overlapping) {
        const url = `${this.prefix}/Update`
        return await axios.put(url, params)
    }

    async updateList(params: any) {
        const url = `${this.prefix}/UpdateList`
        return await axios.put(url, params)
    }

    delete(id: string) {
        const url = `${this.prefix}/Delete?id=${id}`
        return axios.delete(url)
    }
}

export const overlappingAPI = new OverlappingAPI()