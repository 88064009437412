import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { QUERY_PAGING } from "@share/const";
import { connect } from "react-redux";
import { CardReaderState, Dispatcher, IDevice, IFactoryGetAllModel, ILocationListAllModel, IPump, RootState } from "AppTypes";
import { cardReaderActions } from "./state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Modal } from "../../../components/Modal";
import { success, error } from '@services/toast-service'
import { TCardReaderManagement } from "./state_management/types";

type Props = {
    dispatch: Dispatcher;
    cardReaderState: CardReaderState;
};


const CardReaderSetting: React.FC<Props> = ({ cardReaderState, dispatch }) => {
    const { update, isLoading, deviceList, factoryList, locationList, pumpList } = cardReaderState;
    const history = useHistory();
    useEffect(() => {
        dispatch(cardReaderActions.fetchDeviceList());
        dispatch(cardReaderActions.fetchFactoryList());
        dispatch(cardReaderActions.fetchLocationList());
        dispatch(cardReaderActions.fetchPumpList());
    }, []);

    const getRoleName = (roleCode: number) => {
        if (roleCode === 2) {
            return "出荷時刻管理";
        } else if (roleCode === 3) {
            return "現着時刻管理";
        } else if (roleCode === 4) {
            return "荷卸時刻管理";
        }
        return "";
    }

    const handleUpdateButtonClick = async (id: string) => {
        await dispatch(cardReaderActions.setUpdatingId(id));
    }

    const handleUpdateCancelButtonClick = async (id: string) => {
        await dispatch(cardReaderActions.setUpdatingId(''));

    }


    const handleUpdateButtonSaveClick = async (id: string) => {
        
        if (update.roleCode === 2 && !update.param1) {
            error("工場を選択してください");
            return;
        }

        if (update.roleCode === 4 && !update.param1) {
            error("ポンプ車を選択してください");
            return;
        }

        try {
            const response = await dispatch(cardReaderActions.update(update));
            if (response.payload && response.payload.success) {
                await dispatch(cardReaderActions.fetchDeviceList());
                await dispatch(cardReaderActions.resetUpdate({}));
                success("保存が完了しました");


            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }


    const handleChangeUpdateData = (key: string, value: string | number) => {
        dispatch(cardReaderActions.setUpdateData({ key, value }));
    }


    const getParamDisplay = (device: IDevice) => {
        if (device.managementStage == 2) {
            return device.factoryName;
        } else if (device.managementStage == 4) {
            return device.pumpName;
        }
        return "";
    }


    const renderList = deviceList?.length ? (
        deviceList.map((device: IDevice) => (
            <tr key={device.id}>
                <td>
                    {
                        device.deviceID !== update.deviceID ?
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="編" onClick={() => handleUpdateButtonClick(device.deviceID)} />

                                </>
                            )
                            :
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="更新" onClick={() => handleUpdateButtonSaveClick(device.id)} />
                                    <input type="button" name="btnRowDelete" value="キャンセル" onClick={() => handleUpdateCancelButtonClick(device.id)} className="ml-3" />
                                </>
                            )

                    }

                </td>
                <td>{device.deviceID}</td>
                <td>
                    {
                        device.deviceID !== update.deviceID ? getRoleName(device.managementStage) :
                            (
                                <select
                                    className="form-select w-100"
                                    defaultValue={update.roleCode}
                                    onClick={(e: { currentTarget: HTMLSelectElement }) => { handleChangeUpdateData("roleCode", parseInt(e.currentTarget.value)) }}
                                >
                                    <option value="2">出荷時刻管理</option>
                                    <option value="3">現着時刻管理</option>
                                    <option value="4">荷卸時刻管理</option>

                                </select>

                            )

                    }

                </td>
                <td>
                    {
                        (device.deviceID !== update.deviceID) ? getParamDisplay(device) :
                            (
                                (update.roleCode === 2 || update.roleCode === 4) ?
                                    (update.roleCode !== 4 ?
                                        (<select
                                            className="form-select w-100"
                                            defaultValue={update.param1 ?? ""}
                                            onClick={(e: { currentTarget: HTMLSelectElement }) => { handleChangeUpdateData("param1", e.currentTarget.value) }}
                                        >
                                            <option value=""></option>
                                            {factoryList.length && update.roleCode === 2 && factoryList.map((f: IFactoryGetAllModel) => (
                                                <option key={f.code} value={f.code}>{f.name}</option>
                                            ))}
                                        </select>)
                                        :
                                        (<select
                                            className="form-select w-100"
                                            defaultValue={update.param1 ?? ""}
                                            onClick={(e: { currentTarget: HTMLSelectElement }) => { handleChangeUpdateData("param1", e.currentTarget.value) }}
                                        >
                                            <option value=""></option>
                                            <option value="1">ポンプ車1</option>
                                            <option value="2">ポンプ車2</option>
                                            <option value="3">ポンプ車3</option>
                                            <option value="4">ポンプ車4</option>
                                            <option value="5">ポンプ車5</option>
                                            <option value="6">ポンプ車6</option>
                                            <option value="7">ポンプ車7</option>
                                            <option value="8">ポンプ車8</option>
                                            <option value="9">ポンプ車9</option>
                                        </select>)
                                    )
                                    :
                                    (<p></p>)
                            )
                    }
                </td>
                <td>
                    {
                        device.deviceID !== update.deviceID ? device.locationName :
                            (
                                <select
                                    className="form-select w-100"
                                    defaultValue={update.locationCode ?? ""}
                                    onClick={(e: { currentTarget: HTMLSelectElement }) => { handleChangeUpdateData("locationCode", e.currentTarget.value) }}
                                >
                                    <option value=""></option>

                                    {locationList.length && locationList.map((l: ILocationListAllModel) => (
                                        <option key={l.code} value={l.code}>{l.blockName} {l.liftName}</option>
                                    ))}
                                </select>)

                    }
                </td>

            </tr>
        ))
    ) : (
        <tr>
            <td colSpan={5}>データなし</td>
        </tr>
    );
    return (
        <>
            <LoadingSpinner isShowing={false} />
            <div className="settingItemPageTitle">
                <p>ICタグリーダ設定</p>
            </div>
            <div className="content setting-container">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th />
                            <th scope="col">管理番号</th>
                            <th scope="col">役割</th>
                            <th scope="col">工場／ポンプ車</th>
                            <th scope="col">打設場所</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderList}
                    </tbody>
                </table>
            </div>
            <div className="goHomeButtonContainer">
                <input type="button" value="戻る" onClick={() => { history.goBack() }} className="goHomeButton" />
            </div>
        </>
    );
};

export default connect((state: RootState) => ({
    cardReaderState: state.cardReader
}))(CardReaderSetting);
