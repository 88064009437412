import axios from '@utils/axios-client'
import { appendParams } from '@utils/func'

class DocumentAPI {
    prefix = '/api/v1/Document'

    async fetchList(params: any) {
        const url = appendParams(`${this.prefix}/GetTop`, params)
        return axios.get(url)
    }

    async create(dataUpload: any) {
        const url = `${this.prefix}/Create`;
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "File",
            dataUpload.file,
            dataUpload.file.name
        );
        formData.append('FactoryId', dataUpload.factoryId);
        formData.append('FileCategoryId', dataUpload.fileCategoryId);
        formData.append('Note', dataUpload.note);
        formData.append('RegisterTime', dataUpload.registerTime);

        const response = await axios.post(url, formData);
        return response;
    }

    
    async update(dataUpload: any) {
        const url = `${this.prefix}/Update`;
        const formData = new FormData();

        // Update the formData object
        formData.append('Id', dataUpload.id);
        if (dataUpload.file) {
            formData.append(
                "File",
                dataUpload.file,
                dataUpload.file.name
            );
        } else {
            formData.append(
                "File",
                ''
            );
        }

        formData.append('FactoryId', dataUpload.factoryId);
        formData.append('FileCategoryId', dataUpload.fileCategoryId);
        formData.append('Note', dataUpload.note);
        formData.append('RegisterTime', dataUpload.registerTime);

        const response = await axios.put(url, formData);
        return response;
    }

    async delete(id: string) {
        const url = `${this.prefix}/Delete?id=${id}`
        return axios.delete(url)
    }
}

export const documentAPI = new DocumentAPI()