import { TShipmentManagement } from '@features/shipment/registration/state_management/types'
import axios from '@utils/axios-client'
import { appendParams } from '@utils/func'

import {
    IPump
} from 'AppTypes'

class PumpAPI {
    prefix = '/api/v1/Pump'
    
    async fetchList(params: any) {
        const url = appendParams(`${this.prefix}/GetAll`, { ...params })
        const response = await axios.get(url)
        return response.data;
    }

    
}

export const pumpAPI = new PumpAPI()