import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { Resizable } from 're-resizable'
import moment from 'moment';
import ShippingInformationList from '@features/shippinginformation/ShippingInformationList';
import { locationSelectorActions } from "@features/location/selector/state_management/actions";
import { overlappingActions } from "@features/setting/overlapping/state_management/actions";
import ShipmentOrderDetail from '@features/main/HomeComponent/ShipmentOrderDetail';
import ProcessChart from '@features/main/HomeComponent/ProcessChart';
import { RootState, AppState, LocationSelectorState, Dispatcher, ShippingInformationState, ShipmentState } from "AppTypes";
import { api } from "@services"
import { success, error } from '@services/toast-service'
import ThreeViewer from './HomeComponent/3dViewer';
import ListMesh from './HomeComponent/ListMesh'
import LoadingSpinner from "../../components/LoadingSpinner";
import { shippingInformationActions } from "@features/shippinginformation/state_management/actions";

type Props = {
    dispatch: Dispatcher;
    appState: AppState;
    locationSelectorState: LocationSelectorState;
    shippingInformationState: ShippingInformationState;
    shipmentState: ShipmentState;
};

const MainPage: React.FC<Props> = ({
    appState,
    locationSelectorState,
    dispatch,
    shippingInformationState,
    shipmentState,
}) => {
    const { dashboardChartExpanded, dashboardShipmentInfoExpaned } = appState;
    const history = useHistory();
    const locationId = localStorage.getItem('currentLocationId') || appState.currentLocationId || '';
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!locationId) {
            history.push("/locationselector");
        }
    }, [appState.currentLocationId]);

    //reports
    const handleDownloadCastingRecordReport = async () => {
        setIsLoading(true);

        try {
            const fileBlob = await api.locationAPI.exportCastingRecordReport(locationId);
            const fileUrl = window.URL.createObjectURL(fileBlob)
            const link = document.createElement('a')
            link.href = fileUrl
            link.download = `${moment(new Date()).format('YYYYMMDD')}_打設記録表.xlsx`
            document.body.appendChild(link)
            link.click()
            success('ダウンロードが完了しました')
            document.body.removeChild(link)
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    }

    const handleDownloadCastingManagementReport = async () => {
        setIsLoading(true);
        try {
            const fileBlob = await api.locationAPI.exportCastingManagementReport(locationId);
            const fileUrl = window.URL.createObjectURL(fileBlob)
            const link = document.createElement('a')
            link.href = fileUrl
            link.download = `${moment(new Date()).format('YYYYMMDD')}_打設管理表.xlsx`
            document.body.appendChild(link)
            link.click()
            success('ダウンロードが完了しました')
            document.body.removeChild(link)
            setIsLoading(false);

        } catch {
            setIsLoading(false);
        }
    }

    const handleDownloadCastingTimeReport = async () => {
        setIsLoading(true);
        try {
            const fileBlob = await api.locationAPI.exportCastingTimeReport(locationId);
            const fileUrl = window.URL.createObjectURL(fileBlob)
            const link = document.createElement('a')
            link.href = fileUrl
            link.download = `${moment(new Date()).format('YYYYMMDD')}_打重ね時間管理表.xlsx`
            document.body.appendChild(link)
            link.click()
            success('ダウンロードが完了しました')
            document.body.removeChild(link)
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    }

    const handleDownloadCastingDetailReport = async () => {

        setIsLoading(true);
        try {
            const fileBlob = await api.locationAPI.exportCastingDetailReport(locationId);
            const fileUrl = window.URL.createObjectURL(fileBlob)
            const link = document.createElement('a')
            link.href = fileUrl
            link.download = `${moment(new Date()).format('YYYYMMDD')}_打設詳細表.xlsx`
            document.body.appendChild(link)
            link.click()
            success('ダウンロードが完了しました')
            document.body.removeChild(link)
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    }
    const handleDownloadShipmentReport = async () => {
        setIsLoading(true);
        try {
            const fileBlob = await api.shippingInformationAPI.exportShipmentReport();
            const fileUrl = window.URL.createObjectURL(fileBlob)
            const link = document.createElement('a')
            link.href = fileUrl
            link.download = `${moment(new Date()).format('YYYYMMDD')}_打設情報一覧.xlsx`
            document.body.appendChild(link)
            link.click()
            success('ダウンロードが完了しました')
            document.body.removeChild(link)
            setIsLoading(false);

        } catch {
            setIsLoading(false);
        }
    }

    return (
        <div className="App home-office px-1">
            <LoadingSpinner isShowing={isLoading} />
            {!locationSelectorState.isFull3dMode && (
                <div className="col-12 row">
                    <nav className="ml-2 d-flex">
                        <div className="dropdown main-dropdown-menu">
                            <button className="border-end-0">
                                <Link
                                    className="shipment"
                                    to="/shipment/registration"
                                >
                                    出荷登録
                                </Link>
                            </button>
                            <div className="menu-main">
                                <button className="border-end-0">
                                    <a
                                        className="setting"
                                        onClick={() => {}}
                                        aria-hidden="true"
                                    >
                                        試験登録
                                    </a>
                                </button>
                                <ul>
                                    <li className="border-bottom-0">
                                        <Link
                                            className=""
                                            to="/setting/slumpinfo"
                                        >
                                            スランプ
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className=""
                                            to="/setting/compressionexperiment"
                                        >
                                            圧縮強度
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <button className="border-end-0">
                                <Link
                                    className=""
                                    to="/replay-location-history"
                                >
                                    再生
                                </Link>
                            </button>
                            <div className="menu-main">
                                <button className="border-end-0">
                                    <a
                                        className="setting"
                                        onClick={() => {}}
                                        aria-hidden="true"
                                    >
                                        帳表
                                    </a>
                                </button>
                                <ul>
                                    <li className="border-bottom-0">
                                        <a
                                            aria-hidden="true"
                                            onClick={handleDownloadCastingRecordReport}
                                        >
                                            コンクリート試験記録
                                        </a>
                                    </li>
                                    <li className="border-bottom-0">
                                        <a
                                            aria-hidden="true"
                                            onClick={handleDownloadCastingManagementReport}
                                        >
                                            打設管理表（車両毎の運搬時間及び試験記録）
                                        </a>
                                    </li>
                                    <li className="border-bottom-0">
                                        <a
                                            aria-hidden="true"
                                            onClick={handleDownloadCastingTimeReport}
                                        >
                                            打重ね時間管理表
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            aria-hidden="true"
                                            onClick={handleDownloadCastingDetailReport}
                                        >
                                            打設管理表（打設メッシュ毎の運搬時間及び試験記録）
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            {process.env.REACT_APP_MODE_RUN !== 'viewer' && (
                                <div className="menu-main">
                                    <button className="">
                                        <a
                                            className="setting"
                                            onClick={() => { }}
                                            aria-hidden="true"
                                        >
                                            データ修正
                                        </a>
                                    </button>
                                    <ul>
                                        <li className="border-bottom-0">
                                            <Link
                                                className=""
                                                to="/shippinginformationeditor"
                                            >
                                                運搬情報編集
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className=""
                                                to="/setting/staking"
                                            >
                                                打重ね情報編集
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            )}
                            {shipmentState?.processChartData?.isCompression && (<span className="text-danger ml-5 text-bold">本日圧縮強度試験の登録が必要です</span>)}
                        </div>
                    </nav>
                </div>
            )}
            
            <div className="col-12 row">
                {!locationSelectorState.isFull3dMode && (
                    <div className={dashboardShipmentInfoExpaned ? "col-6 p-0" : "col-3 p-0"} hidden={dashboardChartExpanded}>
                        <ShipmentOrderDetail />
                    </div>
                )}
                {!locationSelectorState.isFull3dMode && (
                    <div className={dashboardChartExpanded ? "col-6 p-0" : "col-3 p-0"} hidden={dashboardShipmentInfoExpaned}>
                        <ProcessChart />
                    </div>
                )}
                <div className={locationSelectorState.isFull3dMode ? 'col-12 p-0 view-3d-full' : 'col-6 p-0 view-3d-content'}>
                    <div className="header-three-view bg-warning col-12 p-0 d-flex">
                        <div className="text-center col-9 p-0 fw-bold text-cursor" aria-hidden="true" onClick={() => dispatch(locationSelectorActions.changeModeShow3DView(!locationSelectorState.isFull3dMode))}>打重ね</div>
                        <div className="col-3 p-0">
                            <select className="form-select form-select-sm" value={locationSelectorState.pumpSelect} onChange={event => {
                                dispatch(locationSelectorActions.changePumpSelect(event.target.value));
                                // dispatch(locationSelectorActions.fetchLocationMesh({ locationId: locationSelectorState.locationSelect, ignoreFilter: false }));
                                dispatch(overlappingActions.fetchList({ LocationId: locationId, PumpId: event.target.value }));
                                dispatch(shippingInformationActions.changePumpSelect(event.target.value));
                            }}>
                                <option value="">全体</option>
                                {shippingInformationState.pumpList?.map(itemPump => (
                                    <option key={itemPump.id} value={itemPump.id}>{itemPump.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <ThreeViewer />
                </div>
            </div>
            {!locationSelectorState.isFull3dMode && (
                <div className="d-flex">
                    <Resizable
                        style={{ width: '100%', margin: 0, boxSizing: 'border-box' }}
                        defaultSize={{
                            width: '50%',
                            height: '100%',
                        }}
                        maxWidth="100%"
                        minWidth="1"
                    >
                        <ShippingInformationList />
                    </Resizable>
                    <div style={{ width: '100%', height: '100%', minWidth: '1px', margin: 0, boxSizing: 'border-box' }}><ListMesh /></div>
                </div>
            )}
        </div>
    )
};
export default connect((state: RootState) => ({
    appState: state.app,
    locationSelectorState: state.locationSelector,
    shippingInformationState: state.shippingInformation,
    shipmentState: state.shipment,
}))(MainPage);
