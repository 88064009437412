import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { IFileCategoryModel } from "AppTypes";
import { TFileCategoryManagement } from "./types";


export const initialState: TFileCategoryManagement.TState = {
    fileCategoryList: [],
    deletingId: "",
    isLoading: false,
    update: {
        id: "",
        code: "",
        name: "",
        isActive: true
    },
    insert: {
        id: "",
        code: "",
        name: "",
        isActive: true
    }
};

export const fetchList = createAsyncThunk(
    "fileCategoryManagement/fetchList",
    async (isActive: boolean | null, { rejectWithValue }) => {
        try {
            const response = await api.fileCategoryAPI.fetchAll(isActive);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const insert = createAsyncThunk(
    "fileCategoryManagement/insert",
    async (params: IFileCategoryModel, { rejectWithValue }) => {
        try {
            const response = await api.fileCategoryAPI.insert(params);
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }

    }
);

export const update = createAsyncThunk(
    "fileCategoryManagement/update",
    async (params: IFileCategoryModel, { rejectWithValue }) => {
        try {
            const response = await api.fileCategoryAPI.update(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const deleteOne = createAsyncThunk(
    "fileCategoryManagement/deleteOne",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.fileCategoryAPI.delete(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const fileCategorySlice = createSlice({
    name: "fileCategory",
    initialState,
    reducers: {
        setInsertData(state, action) {
            const { key, value } = action.payload;
            const { insert } = state;
            if (insert) {
                insert[key] = value;
            }

        },
        setUpdatingData(state, action) {
            const { key, value } = action.payload;
            const { update } = state;
            if (update) {
                update[key] = value;
            }
        },
        resetDetail(state, _) {
            state.update = initialState.update;
        },
        resetInsert(state, _) {
            state.insert = initialState.insert;
        },
        setUpdatingId(state, action) {
            if (action.payload) {
                const updatingItem = state.fileCategoryList.find(x => x.id === action.payload)
                if (updatingItem) {
                    state.update = updatingItem;
                }
            } else {
                state.update = initialState.update;
            }

        },
        setDeletingId(state, action) {
            state.deletingId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(
            fetchList.fulfilled,
            (
                state,
                action: PayloadAction<{ data: IFileCategoryModel[] }>
            ) => {
                state.fileCategoryList = action.payload.data;
                state.isLoading = false;
            }
        );
    },
});

export const {
    setInsertData,
    resetDetail,
    setUpdatingId,
    setUpdatingData,
    setDeletingId,
    resetInsert
} = fileCategorySlice.actions;

export default fileCategorySlice.reducer;
