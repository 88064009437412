import { TFactoryManagement } from '@features/setting/factory/state_management/types'
import axios from '@utils/axios-client'
import { appendParams } from '@utils/func'

class FactoryAPI {
    prefix = '/api/v1/Factory'

    fetchList(params: TPagination.SendToServer) {
        const url = appendParams(`${this.prefix}/GetPagedList`, { ...params })
        return axios.get<TFactoryManagement.TState['factoryList']>(url)
    }

    async fetchAll() {
        const url = `${this.prefix}/GetAll`;
        const response = await axios.get<{ data: TFactoryManagement.FactoryGetAllModel[] }>(url)
        return response.data;
    }

    async insert(params: TFactoryManagement.FactoryUpsert) {
        const url = `${this.prefix}/Create`
        return await axios.post(url, params)
    }

    async update(params: TFactoryManagement.FactoryUpsert) {
        const url = `${this.prefix}/Update`
        return await axios.put(url, params)
    }

    delete(id: string) {
        const url = `${this.prefix}/Delete?id=${id}`
        return axios.delete(url)
    }
}

export const factoryAPI = new FactoryAPI()