import * as Yup from 'yup'
import { regularUserId } from '@share/const'

const loginFormValidationSchema = Yup.object().shape({
  userName: Yup.string()
    .required('loginForm.userIDField.required')
    .matches(regularUserId, 'loginForm.userIDField.format'),
    passWord: Yup.string()
    .required('loginForm.passwordField.required')
})

export default loginFormValidationSchema
