import axios from '@utils/axios-client'
import { IDevice, } from 'AppTypes';
import { TCardReaderManagement } from '../../features/setting/cardreader/state_management/types';

class CardReaderAPI {
    prefix = '/api/v1/CardReader'

     

    async fetchAll() {
        const url = `${this.prefix}/GetAll`;
        const response = await axios.get<{ data: IDevice[] }>(url)
        return response.data;
    }

     

    async update(params: TCardReaderManagement.DeviceUpdate) {
        const url = `${this.prefix}/Update`
        return await axios.put(url, params)
    }

}

export const cardReaderAPI = new CardReaderAPI()