import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { TVersionManagement } from "./types";

export const initialState: TVersionManagement.TState = {

};



const versionSlice = createSlice({
    name: "version",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {

    },
});

export const {

} = versionSlice.actions;

export default versionSlice.reducer;
