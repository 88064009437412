import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { IDevice, IFactoryGetAllModel, ILocationListAllModel, IPump } from "AppTypes";
import { TCardReaderManagement } from "./types";

export const initialState: TCardReaderManagement.TState = {
    deviceList: [],
    factoryList: [],
    pumpList: [],
    locationList: [],
    update: {
        deviceID: "",
        locationCode: "",
        param1: "",
        roleCode: 2
    },
    isLoading: false
};

export const fetchLocationList = createAsyncThunk(
    "cardreaderSetting/fetchLocationList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.locationAPI.fetchAll();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const fetchPumpList = createAsyncThunk(
    "cardreaderSetting/fetchPumpList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.pumpAPI.fetchList({});
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchFactoryList = createAsyncThunk(
    "cardreaderSetting/fetchFactoryList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.factoryAPI.fetchAll();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchDeviceList = createAsyncThunk(
    "cardreaderSetting/fetchDeviceList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.cardReaderAPI.fetchAll();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const update = createAsyncThunk(
    "cardreaderSetting/update",
    async (param: TCardReaderManagement.DeviceUpdate, { rejectWithValue }) => {
        try {
            const response = await api.cardReaderAPI.update(param);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const cardReaderSlice = createSlice({
    name: "cardreader",
    initialState,
    reducers: {
        setUpdateData(state, action) {
            const { update } = state;
            const { key, value } = action.payload;
            if (update) {
                update[key] = value;
            }
        },
        setUpdatingId(state, action) {
            if (action.payload) {
                const updatingItem = state.deviceList.find(x => x.deviceID === action.payload)
                if (updatingItem) {

                    let param1: string | null = null;
                    if (updatingItem.managementStage === 2) {
                        param1 = updatingItem.factoryCode;
                    } else if (updatingItem.managementStage === 3) {
                        param1 = null;
                    } else if (updatingItem.managementStage === 4) {
                        param1 = updatingItem.pumpCode;
                    }

                    state.update = {
                        deviceID: updatingItem.deviceID,
                        locationCode: updatingItem.locationCode,
                        param1: param1,
                        roleCode: updatingItem.managementStage
                    };
                }
            } else {
                state.update = initialState.update;
            }

        },
        resetUpdate(state, action) {
            state.update = initialState.update;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLocationList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(
            fetchLocationList.fulfilled,
            (
                state,
                action: PayloadAction<ILocationListAllModel[]>
            ) => {
                state.locationList = action.payload;
                state.isLoading = false;
            }
        );

        builder.addCase(fetchPumpList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(
            fetchPumpList.fulfilled,
            (
                state,
                action
            ) => {
                state.pumpList = action.payload;
                state.isLoading = false;
            }
        );


        builder.addCase(fetchFactoryList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(
            fetchFactoryList.fulfilled,
            (
                state,
                action: PayloadAction<IFactoryGetAllModel[]>
            ) => {
                state.factoryList = action.payload;
                state.isLoading = false;
            }
        );


        builder.addCase(fetchDeviceList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(
            fetchDeviceList.fulfilled,
            (
                state,
                action: PayloadAction<IDevice[]>
            ) => {
                state.deviceList = action.payload;
                state.isLoading = false;
            }
        );
    },
});

export const {
    setUpdateData,
    setUpdatingId,
    resetUpdate
} = cardReaderSlice.actions;

export default cardReaderSlice.reducer;
