import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { QUERY_PAGING } from "@share/const";
import { Tooltip } from 'reactstrap';
import { TFileCategoryManagement } from "./state_management/types";
import { connect } from "react-redux";
import { FileCategoryState, Dispatcher, RootState } from "AppTypes";
import { fileCategoryActions } from "./state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Modal } from "../../../components/Modal";
import { success, error } from '@services/toast-service'

type Props = {
    dispatch: Dispatcher;
    fileCategoryState: FileCategoryState;
};


const FileCategoryList: React.FC<Props> = ({ fileCategoryState, dispatch }) => {
    const { fileCategoryList, update, insert, deletingId, isLoading } = fileCategoryState;
    const history = useHistory()
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    useEffect(() => {
        dispatch(fileCategoryActions.fetchList(null));
    }, []);


    const handleAddFileCategoryClick = async () => {
        if (!insert.code) {
            error("文書管理分類コードを入力してください。");
            return;
        }
        if (insert.code.length > 4) {
            error("文書管理分類コードは4文字以下で入力してください。");
            return;
        }
        if (!insert.name) {
            error("	文書管理分類名を入力してください。");
            return;
        }
        if (insert.name.length > 10) {
            error("文書管理分類名は10文字以下で入力してください。");
            return;
        }

        try {
            const response = await dispatch(fileCategoryActions.insert(insert));
            if (response.payload && response.payload.success) {
                await dispatch(fileCategoryActions.fetchList(null));
                await dispatch(fileCategoryActions.resetInsert({}));
                success("保存が完了しました。");
            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }
    const handleUpdateButtonClick = async (id: string) => {
        await dispatch(fileCategoryActions.setUpdatingId(id));
    }

    const handleUpdateCancelButtonClick = async (id: string) => {
        await dispatch(fileCategoryActions.setUpdatingId(''));

    }
    const handleDeleteCancelButtonClick = async () => {
        setShowDeleteConfirmModal(false);
        await dispatch(fileCategoryActions.setDeletingId(''));

    }


    const handleUpdateButtonSaveClick = async (id: string) => {
        if (!update.code) {
            error("文書管理分類コードを入力してください。");
            return;
        }
        if (update.code.length > 4) {
            error("文書管理分類コードは4文字以下で入力してください。");
            return;
        }
        if (!update.name) {
            error("	文書管理分類名を入力してください。");
            return;
        }
        if (update.name.length > 10) {
            error("文書管理分類名は10文字以下で入力してください。");
            return;
        }
        try {
            const response = await dispatch(fileCategoryActions.update(update));
            if (response.payload && response.payload.success) {
                await dispatch(fileCategoryActions.fetchList(null));
                await dispatch(fileCategoryActions.resetDetail({}));
                success("保存が完了しました。");
                await dispatch(fileCategoryActions.setUpdatingId(""));

            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }

    const handleDeleteButtonClick = async (id: string) => {
        await dispatch(fileCategoryActions.setDeletingId(id));
        setShowDeleteConfirmModal(true);
    }

    const handleChangeDetailValue = (key: string, value: string | number) => {
        dispatch(fileCategoryActions.setInsertData({ key, value }));
    }

    const handleChangeRowDataValue = (key: string, value: string | number) => {
        dispatch(fileCategoryActions.setUpdatingData({ key, value }));
    }

    const handleChangeInsertItemIsActive = async (checkbox: HTMLInputElement) => {
        dispatch(fileCategoryActions.setInsertData({ key: 'isActive', value: checkbox.checked }));
    }

    const handleChangeUpdatingItemIsActive = async (checkbox: HTMLInputElement) => {
        dispatch(fileCategoryActions.setUpdatingData({ key: 'isActive', value: checkbox.checked }));
    }

    const handleDeleteConfirmButtonClick = async () => {
        const response = await dispatch(fileCategoryActions.deleteOne(fileCategoryState.deletingId));
        if (response.payload && response.payload.success) {
            await dispatch(fileCategoryActions.fetchList(null));
            setShowDeleteConfirmModal(false);
            success("保存が完了しました。");
        } else {
            error(response.payload.errors[0])
        }


    }


    const renderList = fileCategoryList?.length ? (
        fileCategoryList.map((fileCategory: TFileCategoryManagement.FileCategory) => (
            <tr key={fileCategory.id}>
                <td>
                    {
                        fileCategory.id !== update.id ?
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="編" onClick={() => handleUpdateButtonClick(fileCategory.id ?? "")} />
                                    <input type="button" name="btnRowDelete" value="削" onClick={() => handleDeleteButtonClick(fileCategory.id ?? "")} className="ml-3" />
                                </>
                            )
                            :
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="更新" onClick={() => handleUpdateButtonSaveClick(fileCategory.id ?? "")} />
                                    <input type="button" name="btnRowDelete" value="キャンセル" onClick={() => handleUpdateCancelButtonClick(fileCategory.id ?? "")} className="ml-3" />
                                </>
                            )

                    }

                </td>
                <td>{fileCategory.code}</td>
                <td>
                    {
                        fileCategory.id !== update.id ? fileCategory.name :
                            (<input type="text" required className="form-input" value={update.name} onChange={(e) => { handleChangeRowDataValue('name', e.target.value) }} />)

                    }

                </td>

                <td>
                    {
                        fileCategory.id !== update.id ? (fileCategory.isActive ? "有効" : "無効") :
                            (<input type="checkbox" className="form-input" checked={update.isActive} onChange={({ currentTarget }) => handleChangeUpdatingItemIsActive(currentTarget)} />)

                    }


                </td>
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan={6}>データなし</td>
        </tr>
    );
    return (
        <>
            <LoadingSpinner isShowing={isLoading} />
            <div className="settingItemPageTitle">
                <p>文書管理分類設定　
                    <i className="fas fa-question-circle" id="showDescription" aria-hidden="true"></i>
                    <Tooltip placement="top" isOpen={tooltipOpen} autohide={false} target="showDescription" toggle={() => setTooltipOpen(!tooltipOpen)}>
                        <div className="w-100">文書カテゴリ名称の設定を行います。<br />設定した名称は文書管理機能で使用します。</div>
                    </Tooltip>
                </p>
            </div>
            <div className="content setting-container">
                <table className="table table-striped" style={{
                    width: "70%",
                    margin: "0 auto"
                }}>
                    <thead>
                        <tr>
                            <th />
                            <th scope="col">文書管理分類コード</th>
                            <th scope="col">文書管理分類名</th>
                            <th scope="col">
                                <span className="mr-2">状態</span>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderList}
                        <tr className="insertform">
                            <td>
                                <input type="button" name="btnAdd" value="追加" onClick={handleAddFileCategoryClick} />
                            </td>
                            <td>
                                <input type="text" required className="form-input" value={insert.code} onChange={(e) => { handleChangeDetailValue('code', e.target.value) }} />
                            </td>
                            <td><input type="text" className="form-input" value={insert.name} onChange={(e) => { handleChangeDetailValue('name', e.target.value) }} /></td>

                            <td>
                                <input type="checkbox" className="form-input" checked={insert.isActive} onChange={({ currentTarget }) => handleChangeInsertItemIsActive(currentTarget)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="goHomeButtonContainer">
                <input type="button" value="戻る" onClick={() => { history.goBack() }} className="goHomeButton" />
            </div>

            <Modal isActive={showDeleteConfirmModal} onClose={() => handleDeleteCancelButtonClick()} width="30%">
                <div className="modal-body">
                    <p>削除します。よろしいですか？</p>
                </div>
                <div className="modal-footer">
                    <input type="button" onClick={handleDeleteConfirmButtonClick} value="削除" />
                    <input type="button" className="ml-3" onClick={() => { handleDeleteCancelButtonClick() }} value="キャンセル" />
                </div>
            </Modal>
        </>
    );
};

export default connect((state: RootState) => ({
    fileCategoryState: state.fileCategory
}))(FileCategoryList);
