import axios from '@utils/axios-client';
import { IUserModel, IUserUpdate } from 'AppTypes';

class UserAPI {
    prefix = '/api/v1/User'

    async fetchList() {
        const url = `${this.prefix}/GetAll`;
        const response = await axios.get<{ data: IUserModel[] }>(url)
        return response.data
    }

     

    async insert(params: IUserUpdate) {
        const url = `${this.prefix}/Register`
        return await axios.post(url, params)
    }

    async update(params: IUserUpdate) {
        const url = `${this.prefix}/Update`
        return await axios.put(url, params)
    }

    delete(id: string) {
        const url = `${this.prefix}/Delete?id=${id}`
        return axios.delete(url)
    }
}

export const userAPI = new UserAPI()