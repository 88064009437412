import React, { useEffect } from 'react';
import { Table } from 'reactstrap';
import { connect } from "react-redux";
import { OverlappingState, Dispatcher, RootState, DisplayItemSettingState, IDisplayItemSetting, AppState, LocationSelectorState } from "AppTypes";
import { useHistory, useLocation } from "react-router-dom";
import { overlappingActions } from "@features/setting/overlapping/state_management/actions";
import { TOverlappingManagement } from '../../setting/overlapping/state_management/types';
import { STATUS_MESH, WARNING_STATUS } from '../../../const/constants';
import moment from "moment";
import { convertMinuteToHour } from "@utils/format";
import { locationSelectorActions } from '@features/location/selector/state_management/actions';
import { shippingInformationActions } from '@features/shippinginformation/state_management/actions';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

type Props = {
    dispatch: Dispatcher;
    overlappingState: OverlappingState;
    displayItemSettingState: DisplayItemSettingState;
    locationSelectorState: LocationSelectorState;
    appState: AppState;
};

const ListMesh: React.FC<Props> = ({ overlappingState, displayItemSettingState, appState, locationSelectorState, dispatch }) => {
    const history = useHistory();
    const { data } = overlappingState?.overlappingList;
    const { displayItemSettingList } = displayItemSettingState;
    const query = useQuery();
    const locationId = localStorage.getItem('currentLocationId') || appState.currentLocationId || ''
    useEffect(() => {
        dispatch(overlappingActions.fetchList({
            LocationId: locationId
        }));
    }, [locationId]);

    const compare = (a: IDisplayItemSetting, b: IDisplayItemSetting) => {
        if (a.displayOrder < b.displayOrder) {
            return -1;
        }
        if (a.displayOrder > b.displayOrder) {
            return 1;
        }
        return 0;
    }

    const renderBgClass = (mesh: any) => {
        let className = "";
        let textStatus = "未打設";
        if (mesh.status === STATUS_MESH[1]) {
          className = "bg-during-casting";
          textStatus = "打設中";
        } else if (mesh.status === STATUS_MESH[2]) {
          className = "bg-end-casting";
          textStatus = "打設終了";
        }
        if (mesh?.warningStatus === WARNING_STATUS[0]) {
          className = "bg-warning-time-exceeded";
          textStatus = "警告時間超過";
        } else if (mesh?.warningStatus === WARNING_STATUS[1]) {
          className = "bg-time-limit-exceeded";
          textStatus = "制限時間超過";
        }
        if (locationSelectorState.listMeshActiveTable.includes(mesh.code)) {
            className += " text-danger";
            const itemScroll = document.getElementById(mesh.code);
            if (itemScroll) {
                setTimeout(function () {
                    itemScroll.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "nearest"
                    });
                }, 100);
            }
        }
    
        return { class: className, text: textStatus };
      };
    

    const castingSettings = displayItemSettingList?.filter(x => x.isDisplayed && x.type === "Casting").sort(compare);
    const sortedSettings = castingSettings?.map(x => {
        return { ...x, code: x.code.toUpperCase() };
    })

    const renderHeaders = sortedSettings?.length ? (
        sortedSettings?.map((setting: IDisplayItemSetting) => (
            <td key={setting.id}>
                {
                    setting.name
                }
            </td>
        ))
    ) : (

        <th></th>

    );

    const convertMinutesToHours = (minutes: number) => {
        if (minutes === 0) {
            return "--:--";
        }

        let dateStr = ""; 
        if (minutes < 0) {
            minutes = minutes * -1;
            dateStr = "-";
        }

        const hour = Math.floor(minutes / 60);
        const min = minutes % 60;
        if (min < 10) {
            return `${dateStr}${hour}:0${min}`;
        }

        return `${dateStr}${hour}:${min}`;
    }

    const clickRowTable = (item: TOverlappingManagement.Overlapping, event: any) => {
        event.preventDefault();
        if (item.code) {
            dispatch(locationSelectorActions.changeMeshActiveListMesh(item.code));
            if (!locationSelectorState.listMeshActiveTable.includes(item.code)) {
                dispatch(shippingInformationActions.setShippingFromMesh({
                    type: 1,
                    meshCode: item.code,
                }))
            } else {
                dispatch(shippingInformationActions.setShippingFromMesh({
                    type: 2,
                    meshCode: item.code,
                }))
            }
        }
    }

    return (
        <>
            <div className="bg-header-block">
                <div className="title-header-block">打重ね情報一覧</div>
            {/*    <div className="button-setting" aria-hidden="true" onClick={() => history.push(`/setting/staking?locationId=${localStorage.getItem('currentLocationId')}`)}>*/}
            {/*        <i className="fas fa-cog"></i>*/}
            {/*    </div>*/}
            </div>
            <div className="table-mesh-content scrollableContainer table-wrapper-scroll-x">
                <Table bordered className="text-center table-mesh" style={{ width: "1000px", whiteSpace: "nowrap" }}>
                    <thead>
                        <tr>
                            {renderHeaders}
                        </tr>
                    </thead>
                    <tbody>
                        {data && data?.map(function (ci: TOverlappingManagement.Overlapping) {
                            const itemProperties = Object.keys(ci);
                            return (
                                <tr key={ci.id} className={renderBgClass(ci).class} onClick={(event) => clickRowTable(ci, event)} id={ci.code ?? ci.id}>
                                    {
                                        sortedSettings?.map(function (setting: IDisplayItemSetting) {
                                            const prop = itemProperties.find(x => x.toUpperCase() === setting.code);

                                            if (prop) {
                                                if (setting.code === "STATUS" && ci["status"]) {
                                                    return (<td key={ci.id}>{renderBgClass(ci).text} </td>)
                                                } else {
                                                    if (setting.dataType === "String") {
                                                        return (<td key={`${ci.id}-${setting.id}`}>{ci[prop]}</td>)
                                                    } else if (setting.dataType === "Number") {
                                                        if (setting.code === "REMAININGTIME") {
                                                            return (<td key={`${ci.id}-${setting.id}`}>{convertMinutesToHours(parseInt(ci[prop]))}</td>)
                                                        } else {
                                                            return (<td key={`${ci.id}-${setting.id}`}>{ci[prop]?.toFixed(2)}</td>)
                                                        }

                                                    }
                                                    else if (setting.dataType === "DateTime") {

                                                        return (<td key={`${ci.id}-${setting.id}`}>{ci[prop] ? moment(ci[prop]).format("YYYY/MM/DD HH:mm") : ""}</td>)

                                                    } else if (setting.dataType === "TimeSpan") {
                                                        return (<td key={`${ci.id}-${setting.id}`}>{ci[prop] ? convertMinuteToHour(parseInt(ci[prop])) : ""}</td>)

                                                    }
                                                    else if (setting.dataType === "Boolean") {
                                                        if (ci[prop] === true) {
                                                            return (<td key={`${ci.id}-${setting.id}`} >●</td>)
                                                        } else {
                                                            <td key={`${ci.id}-${setting.id}`} />
                                                        }
                                                    }
                                                    else {
                                                        return (<td key={`${ci.id}-${setting.id}`} />)
                                                    }
                                                }

                                            }
                                            return (<td key={`${ci.id}-${setting.id}`} />)
                                        })
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>

        </>
    )
}

export default connect((state: RootState) => ({
    overlappingState: state.overlapping,
    displayItemSettingState: state.dipslayItemSetting,
    appState: state.app,
    locationSelectorState: state.locationSelector,
}))(ListMesh);

