import { TMixtureManagement } from '@features/setting/mixture/state_management/types'
import axios from '@utils/axios-client'
import { appendParams } from '@utils/func'

class MixtureAPI {
    prefix = '/api/v1/Mixture'

    fetchList(params: TPagination.SendToServer) {
        const url = appendParams(`${this.prefix}/GetPagedList`, { ...params })
        return axios.get<TMixtureManagement.TState['mixtureList']>(url)
    }

    fetchAll() {
        const url = `${this.prefix}/GetAll`;
        return axios.get<TMixtureManagement.MixtureGetAllModel[]>(url)
    }

    async insert(params: TMixtureManagement.Mixture) {
        const url = `${this.prefix}/Create`
        return await axios.post(url, params)
    }

    async update(params: TMixtureManagement.Mixture) {
        const url = `${this.prefix}/Update`
        return await axios.put(url, params)
    }

    delete(id: string) {
        const url = `${this.prefix}/Delete?id=${id}`
        return axios.delete(url)
    }
}

export const mixtureAPI = new MixtureAPI()