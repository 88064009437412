import React, { Suspense, useState, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { connect } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import { LocationSelectorState, AppState, Dispatcher, RootState, ConstructionState, ShippingInformationState, MeshSettingState } from "AppTypes";
import { constructionActions } from "@features/construction/state_management/actions";
import { Canvas } from '@react-three/fiber'
import { useGLTF, useCursor, useProgress, Html, Stage, Center } from '@react-three/drei'
import { proxy, useSnapshot } from 'valtio'
import { success, error } from '@services/toast-service'
import { locationSelectorActions } from "@features/location/selector/state_management/actions";
import { overlappingActions } from "@features/setting/overlapping/state_management/actions";
import { meshSettingActions } from "@features/setting/meshcolor/state_management/actions";
import { CASTING_HEIGHT, STATUS_MESH, WARNING_STATUS } from '../../../const/constants'
import { useQuery } from '@utils/func';
import { Camera2DControls } from './Camera2DControls';

const cameraControlOptions: any = {
    zoomStep: 0.2,
    zoomMin: 0.2,
    zoomMax: 4,
    moveStep: 0.2,
    rotationStep: Math.PI / 25,
    defaultOptions: {
        position: [-103.55, 2.1, 38.62],
        fov: 50,
        rotation: [0, 0, 0],
        zoom: 1,
    }
};

type Props = {
    dispatch: Dispatcher;
    locationSelectorState: LocationSelectorState;
    appState: AppState;
    constructionState: ConstructionState;
    shippingInformationState: ShippingInformationState;
    meshSettingState: MeshSettingState;
};

// Reactive state model, using Valtio ...
const modes = ['translate']
const state = proxy({
    current: null as any,
    mode: 0, fileGLB: '',
    blockActive: null,
    currentItem: null as any,
    showPumpStartConfirmModal: false,
    showFinishedMeshConfirmModal: false,
    nextState: STATUS_MESH[0],
    pumpId: '',
    showChangeMeshStateAtPumpingConfirmModal: false,
    isCancel: false,
})

function Loader() {
    const { progress } = useProgress();
    return <Html center style={{ width: '100px' }}>{progress.toFixed(0)} %</Html>;
}

function Model({ item, index, modeShow, colorPump, meshSettingState, pumpId, isModeSlim }: any) {
    // Ties this component to the state model
    const snap = useSnapshot(state)
    const meshRef = useRef<THREE.Mesh>(null!)
    // Fetching the GLTF, nodes is a collection of all the meshes
    // It's cached/memoized, it only gets loaded and parsed
    const { nodes } = useGLTF(`${process.env.REACT_APP_GLB_ENDPOINT}${state.fileGLB}`)
    // Feed hover state into useCursor, which sets document.body.style.cursor to pointer|auto
    const [hovered, setHovered] = useState(false)
    useCursor(hovered)

    let itemMesh: any = null;
    let opacityMesh = 1;
    if (nodes !== undefined) {
        for (const [_, value] of Object.entries(nodes)) {
            if (value?.parent?.name === `Block${item.code}` || value?.parent?.name === `${item.code}`) {
                let color = '#FFFAFA';
                if (item.pumpId !== pumpId) {
                    opacityMesh = 0.8;
                    color = '#B0B3B5';
                } else {
                    if (item.warningStatus !== '') {
                        const findValueWarning = meshSettingState?.meshSettingList?.find((itemSetting: any) => itemSetting.code === item.warningStatus);
                        if (findValueWarning) {
                            opacityMesh = 1 - (findValueWarning.transferTime / 100);
                            if (findValueWarning.code === WARNING_STATUS[0]) {
                                color = '#f766ff';
                            } else if (findValueWarning.code === WARNING_STATUS[1]) {
                                color = '#F50505';
                            }
                        }
                    } else {
                        const findValueStatus = meshSettingState?.meshSettingList?.find((itemSetting: any) => itemSetting.code === item.status);
                        if (findValueStatus) {
                            opacityMesh = 1 - (findValueStatus.transferTime / 100);
                            if (findValueStatus.code === STATUS_MESH[0]) {
                                color = '#FFFAFA';
                            } else if (!item.isSlim && findValueStatus.code === STATUS_MESH[1]) {
                                color = '#FFFF00';
                            } else if (!item.isSlim && findValueStatus.code === STATUS_MESH[2]) {
                                color = '#00B400';
                            } else if (item.isSlim && findValueStatus.code === STATUS_MESH[1]) {
                                color = '#fdfc06';
                            } else if (item.isSlim && findValueStatus.code === STATUS_MESH[2]) {
                                color = '#2670c1';
                            }
                        }
                    }


                }
                if (colorPump !== '') {
                    color = colorPump;
                }
                itemMesh = {
                    ...item,
                    name: value.name,
                    color: color,
                    indexMesh: index
                }
            }
        }
    }

    if (itemMesh?.name === '' || itemMesh?.name === undefined) {
        return null;
    }

    return (
        <mesh
            ref={meshRef}
            // Click sets the mesh as the new target
            onClick={(e) => {
                e.stopPropagation();
                if (isModeSlim) {
                    state.current = itemMesh.code;
                    state.currentItem = itemMesh;
                    state.pumpId = pumpId
                } else {
                    if (itemMesh.pumpId !== pumpId) {
                        //n?u click vao mesh khac pump
                        if (itemMesh.status === STATUS_MESH[1]) {
                            error('打設開始済みです。');
                            return;
                        } else if (itemMesh.status === STATUS_MESH[2]) {
                            error('打設済みです。');
                            return;
                        }
    
                        state.current = itemMesh.code;
                        state.currentItem = itemMesh;
    
                        state.showPumpStartConfirmModal = true;
                        state.nextState = STATUS_MESH[1]
                        state.pumpId = pumpId
    
                    } else {
                        state.current = itemMesh.code;
                        state.currentItem = itemMesh;
                        if (itemMesh.status === STATUS_MESH[2] && !itemMesh.isSlim) {
                            //n?u mesh ?a ? tr?ng thai ?? xong thi chuy?n tr?ng thai sang ?ang ??
                            state.showFinishedMeshConfirmModal = true;
                        } else if (itemMesh.status === STATUS_MESH[2] && itemMesh.isSlim) {
                            state.nextState = STATUS_MESH[1]
                        }
                    }
                }
            }}
            // If a click happened but this mesh wasn't hit we null out the target,
            // This works because missed pointers fire before the actual hits
            onPointerMissed={(e) => {
                if (e.type === 'click') {
                    state.current = null;
                    state.currentItem = null;
                }
            }}
            // Right click cycles through the transform modes
            onContextMenu={(e) => snap.current === itemMesh.name && (e.stopPropagation(), (state.mode = (snap.mode + 1) % modes.length))}
            onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
            onPointerOut={(e) => setHovered(false)}
            name={itemMesh.name}
            /* @ts-ignore */
            geometry={nodes[itemMesh.name].geometry}
            /* @ts-ignore */
            material={nodes[itemMesh.name].material}
            position={nodes[itemMesh.name].position}
            material-transparent={true}
            // material-opacity={0.8}
            dispose={null}
            material-precision={'highp'}
            isMesh
        >
            <meshStandardMaterial color={snap.current === itemMesh.name ? '#ff6080' : itemMesh.color} opacity={opacityMesh} transparent={true} />
        </mesh>
    )
}
const rotationGroup = new THREE.Euler(Math.PI / 1.8, 5.1, 0);
const ModelGroup = ({ locationSelectorState, setBlockActive, modeShow, shippingInformationState, meshSettingState }: any) => {
    // get color when change pump
    let colorPump = ''
    if (locationSelectorState.pumpSelect) {
        const findPumpItem = shippingInformationState.pumpList.find((itemPump: { code: any; }) => itemPump.code === locationSelectorState.pumpSelect);
        if (findPumpItem) colorPump = findPumpItem.color;
    }

    return (
        <group dispose={null} rotation={rotationGroup}>
            {
                locationSelectorState.locationMesh
                && locationSelectorState.locationMesh[0]?.glbFilePath
                && locationSelectorState.locationMesh[0].meshes?.map((meshItem: any, index: number) => (
                    meshItem.layer === (locationSelectorState.floorActive + 1) && (
                        <Model
                            item={meshItem}
                            key={meshItem.id}
                            setBlockActive={(indexMesh: number) => {
                                setBlockActive(indexMesh);
                            }}
                            index={index}
                            modeShow={modeShow}
                            colorPump={meshItem.pumpId === locationSelectorState.pumpSelect ? colorPump : ''}
                            meshSettingState={meshSettingState}
                            pumpId={locationSelectorState.pumpSelect}
                            isModeSlim={locationSelectorState.isModeSlim}
                        />
                    )
                ))
            }
        </group>
    )
}

const ModelGroupBefore = ({ locationSelectorState, setBlockActive, modeShow, shippingInformationState, meshSettingState }: any) => {
    // get color when change pump
    let colorPump = ''
    if (locationSelectorState.pumpSelect) {
        const findPumpItem = shippingInformationState.pumpList.find((itemPump: { code: any; }) => itemPump.code === locationSelectorState.pumpSelect);
        if (findPumpItem) colorPump = findPumpItem.color;
    }

    return (
        <group dispose={null} rotation={rotationGroup}>
            {
                locationSelectorState.locationMesh
                && locationSelectorState.locationMesh[0]?.glbFilePath
                && locationSelectorState.locationMesh[0].meshes?.map((meshItem: any, index: number) => (
                    meshItem.layer === (locationSelectorState.floorActive) && (
                        <Model
                            item={meshItem}
                            key={meshItem.id}
                            setBlockActive={(indexMesh: number) => {
                                setBlockActive(indexMesh);
                            }}
                            index={index}
                            modeShow={modeShow}
                            colorPump={meshItem.pumpId === locationSelectorState.pumpSelect ? colorPump : ''}
                            meshSettingState={meshSettingState}
                            pumpId={locationSelectorState.pumpSelect}
                            isModeSlim={locationSelectorState.isModeSlim}
                        />
                    )
                ))
            }
        </group>
    )
}

const Mode2dEditor: React.FC<Props> = ({ locationSelectorState, constructionState, dispatch, shippingInformationState, meshSettingState }) => {
    const query = useQuery();
    const customControlRef: any = useRef();
    const [isGetListFloor, setGetListFloor] = useState(false)
    const snap = useSnapshot(state);
    const [stepConfirm, setStepConfirm] = useState(1);

    const ref: any = useRef();
    const refBefore: any = useRef();
    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(0);
    const [isConfirmChangeSlim, setConfirmChangeSlim] = useState(false);
    const [isStartMeshModeSlim, setStartMeshModeSlim] = useState(false);
    const [castingHeightValue, setCastingHeightValue] = useState(CASTING_HEIGHT[0]);
    const floorActive = query.get('floorActive');

    const virtualCamera = useRef<Camera2DControls['camera']>();
    const virtualCameraBefore = useRef<Camera2DControls['camera']>();
    const cameraControlRef = useRef<Camera2DControls | null>(null);
    const cameraControlRefBefore = useRef<Camera2DControls | null>(null);

    useEffect(() => {
        const getSettingLocal = localStorage.getItem('meshSetting');
        if (meshSettingState?.meshSettingList?.length == 0 && !meshSettingState.isLoading && !getSettingLocal) {
            dispatch(meshSettingActions.fetch());
        }
        if (locationSelectorState.locationSelect !== '' && !constructionState.statusCallGetListMesh) {
            setGetListFloor(false)
            dispatch(constructionActions.setStatusCallGetListMesh(true))
            dispatch(locationSelectorActions.fetchLocationMesh({ locationId: locationSelectorState.locationSelect, ignoreFilter: false }));
        }
        if (locationSelectorState?.locationMesh?.length > 0
            && locationSelectorState.locationMesh[0].meshes?.length > 0
            && !isGetListFloor) {
            const dataFloors: any[] = [];
            const dataFloorNotSort: any[] = [];
            locationSelectorState.locationMesh[0].meshes?.forEach((item: any) => {
                const findFloor = dataFloors.find(itemFloor => itemFloor.layer === item.layer)
                if (findFloor === undefined) {
                    dataFloors.push({
                        layer: item.layer,
                        codeFloor: item.code?.substr(0, 5)
                    });
                    dataFloorNotSort.push({
                        layer: item.layer,
                        codeFloor: item.code?.substr(0, 5)
                    });
                }
            })
            dataFloors.sort((itemBefore, itemAfter) => itemAfter.layer - itemBefore.layer)
            const dataSetFloors = dataFloors.filter(floor => floor.layer < dataFloors.length)
            dispatch(locationSelectorActions.setListFloors(dataSetFloors))
            setActiveMesh(dataFloors[dataFloors.length - 1]?.layer || 1)
            setGetListFloor(true);

            const timer = setTimeout(() => {
                if (shippingInformationState.pumpList[0]) {
                    dispatch(locationSelectorActions.changePumpSelect(shippingInformationState.pumpList[0].id));
                }
            }, 3000);

            if (floorActive) {
                dispatch(locationSelectorActions.changeFloorActive(Number(floorActive)));
                dispatch(locationSelectorActions.actionConstructionUpdate({
                    ...locationSelectorState.filter,
                    floorSelect: Number(floorActive),
                }))
            } else {
                setActiveMesh(dataFloors[dataFloors.length - 1]?.layer || 1)
            }

            return () => {
                clearTimeout(timer);
            };
        }
    }, [locationSelectorState.locationMesh, locationSelectorState.locationSelect]);

    useEffect(() => {
        if (cameraControlRef.current && cameraControlRefBefore.current) {
            cameraControlRef.current?.truck(0, -6);
            cameraControlRefBefore.current?.truck(0, -6);
        }
    }, [])

    if (locationSelectorState?.locationMesh?.length === 0) {
        return null;
    }

    if (locationSelectorState?.locationMesh?.length > 0) {
        state.fileGLB = locationSelectorState.locationMesh[0]?.glbFilePath;
    }

    const setActiveMesh = (index: number) => {
        state.current = null;
        state.currentItem = null
        if (index !== locationSelectorState.floorActive) {
            dispatch(locationSelectorActions.changeFloorActive(index));
            dispatch(locationSelectorActions.actionConstructionUpdate({
                ...locationSelectorState.filter,
                floorSelect: index,
            }))
        }
    }

    const renderTextConfirm = () => {
        let text = '打設を開始します。よろしいですか？'
        if (locationSelectorState.isModeSlim) {
            if (state.nextState === STATUS_MESH[2] && state.currentItem?.status === STATUS_MESH[1]) {
                text = '薄層打設を終了します。よろしいですか？'
            } else if (state.nextState === STATUS_MESH[2] && state.currentItem?.status === STATUS_MESH[2]) {
                text = '薄層打設を開始します。よろしいですか？'
            } else if (state.nextState === STATUS_MESH[0]) {
                text = '薄層打設開始を取り消します。よろしいですか？'
            } else if (state.nextState === STATUS_MESH[1] && state.currentItem?.status === STATUS_MESH[2] && !isStartMeshModeSlim) {
                text = '薄層打設済みですが、取り消しますか？'
            } else if (state.nextState === STATUS_MESH[1] && state.currentItem?.status === STATUS_MESH[2] && isStartMeshModeSlim) {
                text = '薄層打設を開始します。よろしいですか？'
            }
        } else {
            if (state.nextState === STATUS_MESH[2]) {
                text = '打設を終了します。よろしいですか？'
            } else if (state.nextState === STATUS_MESH[0]) {
                text = '打設開始を取り消します。よろしいですか？'
            } else if (state.nextState === STATUS_MESH[1] && state.currentItem?.status === STATUS_MESH[2] && !state.currentItem?.isSlim) {
                text = '打設終了を取り消します。よろしいですか？'
            }
        }

        return text
    }

    const setNextState = (status: string) => {
        if (status === STATUS_MESH[0]) {
            state.nextState = STATUS_MESH[1];
        } else if (status === STATUS_MESH[1]) {
            state.nextState = STATUS_MESH[2];
        }
    }

    const setStatusAPI = async () => {
        try {
            const dataSend: any = {
                id: state.currentItem.id,
                pumpId: locationSelectorState.pumpSelect || null,
                status: state.nextState,
                isCancel: state.isCancel,
            }
            if (locationSelectorState.isModeSlim) {
                dataSend.castingHeight = castingHeightValue;
            }
            const response = await dispatch(constructionActions.fetchUpdateMesh(dataSend));
            if (response?.payload?.success) {
                setCastingHeightValue(CASTING_HEIGHT[0])
                setStepConfirm(1)
                // success(`${state.currentItem?.code}メッシュを更新しました。`)
                state.current = null;
                state.currentItem = null;
                state.pumpId = '';
                state.nextState = '';
                state.isCancel = false;
                setStartMeshModeSlim(false)
                dispatch(locationSelectorActions.fetchLocationMesh({ locationId: locationSelectorState.locationSelect, ignoreFilter: true }));
                dispatch(
                    overlappingActions.fetchList({
                        LocationId: locationSelectorState.locationSelect,
                    })
                );
            } else {
                error(response?.payload?.errors[0])
            }
        } catch (err) {
            error('システムに問題が発生しました。')
        }
    }

    const changeModeSlimAction = async () => {
        setConfirmChangeSlim(false);
        await dispatch(locationSelectorActions.changeModeLocation({
            id: locationSelectorState.locationSelect,
            isSlim: !locationSelectorState.isModeSlim,
            pumpId: locationSelectorState.pumpSelect,
        }));
        dispatch(locationSelectorActions.fetchLocationMesh({ locationId: locationSelectorState.locationSelect, ignoreFilter: true }));
    }

    return (
        <div className="col-12 p-0 view-2d-construction">
            {constructionState.modeShow !== 1 && (<div className="close-button-edit-mode" aria-hidden="true" onClick={() => {
                dispatch(constructionActions.setModeShow(1));
                dispatch(locationSelectorActions.resetShowMesh())
                setStepConfirm(1)
                state.nextState = STATUS_MESH[0];
            }}><i className="fas fa-times"></i></div>)}
            <Modal show={snap.current !== null}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="text-center">
                {stepConfirm === 1 && (
                        <div>
                            <div className="mb-2"><h5>メッシュ：{state.currentItem?.code}</h5></div>
                            {(locationSelectorState.isModeSlim && (state.currentItem?.status === STATUS_MESH[0]
                                || state.currentItem?.status === STATUS_MESH[2])) && (
                                 <div className="mb-1 row">
                                    <label htmlFor="casting-height" className="col-sm-4 col-form-label">薄層打設高さ：</label>
                                    <div className="col-sm-4">
                                        <select className="form-select form-select-sm" id="casting-height" value={castingHeightValue} onChange={event => setCastingHeightValue(Number(event.target.value))}>
                                            <option value={CASTING_HEIGHT[0]}>{CASTING_HEIGHT[0]}</option>
                                            <option value={CASTING_HEIGHT[1]}>{CASTING_HEIGHT[1]}</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-1">cm</div>
                                </div>
                               
                            )}
                            <div className="d-flex justify-content-evenly">
                                {state.currentItem?.status === STATUS_MESH[0] && (
                                    <button className="btn btn-mesh-action btn-primary" onClick={() => {
                                        state.nextState = STATUS_MESH[1];
                                        setStatusAPI()
                                    }}>{locationSelectorState.isModeSlim ? '薄層打設開始' : '打設開始'}</button>
                                )}
                                {(state.currentItem?.status === STATUS_MESH[1] && state.currentItem?.isSlim) && (
                                    <button className="btn btn-mesh-action btn-primary" onClick={() => {
                                        state.nextState = STATUS_MESH[2];
                                        setStatusAPI()
                                    }}>{locationSelectorState.isModeSlim ? '薄層打設終了' : '打設終了'}
                                    </button>
                                )}
                                {(state.currentItem?.status === STATUS_MESH[1] && !state.currentItem?.isSlim && !locationSelectorState.isModeSlim) && (
                                    <button className="btn btn-mesh-action btn-primary" onClick={() => {
                                        state.nextState = STATUS_MESH[2];
                                        setStatusAPI()
                                    }}>{locationSelectorState.isModeSlim ? '薄層打設終了' : '打設終了'}
                                    </button>
                                )}
                                {(state.currentItem?.status === STATUS_MESH[1]) && (
                                    <button className="btn btn-mesh-action btn-danger" onClick={() => {
                                        state.nextState = STATUS_MESH[0];
                                        setStatusAPI()
                                    }}>{locationSelectorState.isModeSlim ? '薄層開始取消' : '打設終了取消'}</button>
                                )}
                                {(locationSelectorState.isModeSlim && state.currentItem?.status === STATUS_MESH[2]) && (
                                    <button className="btn btn-mesh-action btn-primary" onClick={() => {
                                        state.nextState = STATUS_MESH[1];
                                        setStartMeshModeSlim(true);
                                        setStatusAPI()
                                    }}>薄層打設開始</button>
                                )}
                                {(state.currentItem?.status === STATUS_MESH[2] && !state.currentItem?.isSlim) && (
                                    <button className="btn btn-mesh-action btn-danger" onClick={() => {
                                        state.nextState = STATUS_MESH[1];
                                        setStartMeshModeSlim(false);
                                        setStatusAPI()
                                    }}>{locationSelectorState.isModeSlim ? '薄層終了取消' : '終了取消'}</button>
                                )}
                                {(state.currentItem?.status === STATUS_MESH[2] && state.currentItem?.isSlim && locationSelectorState.isModeSlim) && (
                                    <button className="btn btn-mesh-action btn-danger" onClick={() => {
                                        state.nextState = STATUS_MESH[1];
                                        state.isCancel = true;
                                        setStatusAPI()
                                    }}>薄層終了取消</button>
                                )}
                                {(state.currentItem?.status === STATUS_MESH[2] && state.currentItem?.isSlim && !locationSelectorState.isModeSlim) && (
                                    <button className="btn btn-mesh-action btn-primary" onClick={() => {
                                        state.nextState = STATUS_MESH[1];
                                        setStatusAPI()
                                    }}>打設開始</button>
                                )}
                                <button className="btn btn-mesh-action btn-secondary" onClick={() => {
                                    state.currentItem = null;
                                    state.current = null;
                                    state.nextState = STATUS_MESH[0];
                                    setStartMeshModeSlim(false);
                                }}>キャンセル</button>
                            </div>
                        </div>
                    )}
                    {stepConfirm === 2 && (
                        <div className="content-confirm">
                            <div className="">{renderTextConfirm()}</div>
                            <div className="d-flex justify-content-evenly pt-2">
                                <button className="btn btn-mesh-action btn-primary mr-2" onClick={setStatusAPI}>OK</button>
                                <button className="btn btn-mesh-action btn-secondary" onClick={() => {
                                    setStepConfirm(1)
                                    state.isCancel = false;
                                    setStartMeshModeSlim(false);
                                }}>キャンセル</button>
                            </div>
                        </div>
                    )}

                </Modal.Body>
            </Modal>
            <div
                className="three-3d-editor-container view-3d-block-construction"
                onTouchStart={event => setTouchStart(event.targetTouches[0].clientX)}
                onTouchMove={event => setTouchEnd(event.targetTouches[0].clientX)}
                onTouchEnd={() => {
                    if (touchStart - touchEnd > 150
                        && locationSelectorState.floorActive < locationSelectorState?.listFloors[locationSelectorState.listFloors.length - 1].layer
                    ) {
                        setActiveMesh(locationSelectorState.floorActive + 1)
                    }

                    if (touchStart - touchEnd < -150
                        && locationSelectorState.floorActive > locationSelectorState?.listFloors[0].layer
                    ) {
                        setActiveMesh(locationSelectorState.floorActive - 1)
                    }
                }}
            >
                <div id="instructions" className="text-center bg-white fw-bold">{locationSelectorState.floorActive <= locationSelectorState?.listFloors?.length ? `${locationSelectorState.floorActive + 1}層` : ''}</div>
                {locationSelectorState.locationMesh[0]?.glbFilePath && (
                    <Canvas className="view-3d-block" shadows dpr={[1, 2]} camera={{ fov: 45 }}>
                        <pointLight position={[10, 10, 10]} intensity={0.8} />
                        <hemisphereLight color="#A6A7A5" groundColor="#696B67" position={cameraControlOptions.defaultOptions.position} intensity={0.85} />
                        <Suspense fallback={<Loader />}>
                            <Stage controls={ref} contactShadow={{ blur: 1, opacity: 0 }} environment={null} intensity={1}>
                                <group dispose={null}>
                                    <ModelGroup
                                        locationSelectorState={locationSelectorState}
                                        customControlRef={customControlRef}
                                        modeShow={constructionState.modeShow}
                                        shippingInformationState={shippingInformationState}
                                        meshSettingState={meshSettingState}
                                    />
                                    <Camera2DControls ref={cameraControlRef} camera={virtualCamera.current} />
                                </group>
                            </Stage>
                        </Suspense>
                    </Canvas>
                )}
                <div className="three-3d-editor-panel three-3d-editor-panel-construction three-2d-editor-panel">
                    <div className="controls">
                        <div className="control">
                            <div className="default-action" onClick={() => {
                                if (cameraControlRef.current) {
                                    // setShowModel(true);
                                    cameraControlRef.current.reset();
                                    cameraControlRefBefore.current?.reset();
                                }
                            }}>
                                リセット
                            </div>
                        </div>
                        <div className="control">
                            <div className="zoom-actions">
                                <div className="action in" onClick={() => {
                                    cameraControlRef.current?.zoom(0.1, true);
                                    cameraControlRefBefore.current?.zoom(0.1, true);
                                }}>
                                    <i className="fa fa-plus"></i>
                                </div>
                                <div className="action out" onClick={() => {
                                   cameraControlRef.current?.zoom(-0.1, true);
                                   cameraControlRefBefore.current?.zoom(-0.1, true);
                                }}>
                                    <i className="fa fa-minus"></i>
                                </div>
                            </div>
                        </div>
                    {/*    <div className="control">*/}
                    {/*        <div className="rotate-actions">*/}
                    {/*            <img src="/assets/icons/direction.jpg"/>*/}
                    {/*            <div className="action up" onClick={() => {*/}
                    {/*                cameraControlRef.current?.truck(0, 1);*/}
                    {/*                cameraControlRefBefore.current?.truck(0, 1);*/}
                    {/*            }}>*/}
                    {/*            </div>*/}
                    {/*            <div className="action down" onClick={() => {*/}
                    {/*                cameraControlRef.current?.truck(0, -1);*/}
                    {/*                cameraControlRefBefore.current?.truck(0, -1);*/}
                    {/*            }}>*/}
                    {/*            </div>*/}
                    {/*            <div className="action right" onClick={() => {*/}
                    {/*                cameraControlRef.current?.truck(-1, 0);*/}
                    {/*                cameraControlRefBefore.current?.truck(-1, 0);*/}
                    {/*            }}>*/}
                    {/*            </div>*/}
                    {/*            <div className="action left" onClick={() => {*/}
                    {/*                cameraControlRef.current?.truck(1, 0);*/}
                    {/*                cameraControlRefBefore.current?.truck(1, 0);*/}
                    {/*            }}>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    </div>
                </div>
                <div className="three-2d-list-mesh">
                    <div className="list-mesh">
                        {locationSelectorState.locationMesh[0]?.glbFilePath
                            && locationSelectorState?.listFloors?.map((floor, index) => (
                                <div key={index} className={Number(floor.layer) === locationSelectorState.floorActive ? `active-mesh` : ''} aria-hidden="true" onClick={() => setActiveMesh(floor.layer)}>{floor.layer}</div>
                            ))
                        }
                    </div>
                </div>
                <div className="content-slim content-slim-2d">
                    <div className="form-check form-switch">
                        <input className="form-check-input input-lg radio-switcher" type="checkbox" role="switch" id="chkChangeModeSlim" checked={locationSelectorState.isModeSlim} onChange={() => setConfirmChangeSlim(true)} />
                        <label className="form-check-label" htmlFor="chkChangeModeSlim">薄層</label>
                    </div>
                </div>
            </div>
            <div className="three-3d-editor-container view-3d-block-construction">
                <div id="instructions" className="text-center bg-white fw-bold">{locationSelectorState.floorActive > 0 ? `${locationSelectorState.floorActive}層` : ''}</div>
                {locationSelectorState.locationMesh[0]?.glbFilePath && locationSelectorState.floorActive > 0 && (
                    <Canvas className="view-3d-block" shadows dpr={[1, 2]} camera={{ fov: 45 }}>
                        <pointLight position={[10, 10, 10]} intensity={0.8} />
                        <hemisphereLight color="#A6A7A5" groundColor="#696B67" position={cameraControlOptions.defaultOptions.position} intensity={0.85} />
                        <Suspense fallback={<Loader />}>
                            <Stage controls={refBefore} contactShadow={{ blur: 1, opacity: 0 }} environment={null}>
                                <group dispose={null}>
                                    <ModelGroupBefore
                                        locationSelectorState={locationSelectorState}
                                        customControlRef={customControlRef}
                                        modeShow={constructionState.modeShow}
                                        shippingInformationState={shippingInformationState}
                                        meshSettingState={meshSettingState}
                                    />
                                    <Camera2DControls ref={cameraControlRefBefore} camera={virtualCameraBefore.current} />
                                </group>
                            </Stage>
                        </Suspense>
                    </Canvas>
                )}
            </div>
            <Modal show={state.showPumpStartConfirmModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="text-center">
                    <p>
                        メッシュ：{state.currentItem?.code}
                    </p>
                    {!locationSelectorState.isModeSlim && (<p>別のポンプ車に割り当てられています。</p>)}
                    <p>{!locationSelectorState.isModeSlim ? '薄層打設を開始しますか？' : '打設を開始しますか？'}</p>
                    <div className="content-confirm">

                        <div className="d-flex justify-content-evenly pt-2">
                            <button className="btn btn-mesh-action btn-primary mr-2" onClick={
                                () => {
                                    setNextState(state.currentItem.status);
                                    setStatusAPI();
                                    state.showPumpStartConfirmModal = false;
                                }

                            }>打設開始</button>
                            <button className="btn btn-mesh-action btn-secondary" onClick={() => {
                                state.showPumpStartConfirmModal = false;
                                state.current = null;
                                state.currentItem = null;
                            }}>キャンセル</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <Modal show={state.showFinishedMeshConfirmModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="text-center">

                    <p>
                        メッシュ：{state.currentItem?.code}

                    </p>

                    <div className="content-confirm">

                        <div className="d-flex justify-content-evenly pt-2">
                            <button className="btn btn-mesh-action btn-primary mr-2" onClick={async () => {
                                state.nextState = STATUS_MESH[1];
                                await setStatusAPI();
                                state.showFinishedMeshConfirmModal = false;
                            }
                            }>終了取消</button>
                            <button className="btn btn-mesh-action btn-secondary" onClick={() => {
                                state.showFinishedMeshConfirmModal = false;
                                state.current = null;
                                state.currentItem = null;
                            }}>キャンセル</button>
                        </div>
                    </div>


                </Modal.Body>
            </Modal>

            <Modal show={state.showChangeMeshStateAtPumpingConfirmModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="text-center">

                    <p>
                        メッシュ：{state.currentItem?.code}

                    </p>

                    <div className="content-confirm">

                        <div className="d-flex justify-content-evenly pt-2">
                            <button className="btn btn-mesh-action btn-primary mr-2" onClick={async () => {
                                state.nextState = STATUS_MESH[2];
                                await setStatusAPI();
                                state.showChangeMeshStateAtPumpingConfirmModal = false;
                            }
                            }>打設終了</button>
                            <button className="btn btn-mesh-action btn-danger mr-2" onClick={async () => {
                                state.nextState = STATUS_MESH[0];
                                await setStatusAPI();
                                state.showChangeMeshStateAtPumpingConfirmModal = false;
                            }
                            }>開始取消</button>
                            <button className="btn btn-mesh-action btn-secondary" onClick={() => {
                                state.showChangeMeshStateAtPumpingConfirmModal = false;
                                state.current = null;
                                state.currentItem = null;
                            }}>キャンセル</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal show={isConfirmChangeSlim}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="text-center">
                    <p>
                        {locationSelectorState.isModeSlim ? '薄層打設を終了します。よろしいですか？' : '薄層打設に切り替えます。よろしいですか。'}
                        <br />
                        {locationSelectorState.isModeSlim ? '薄層打設中のメッシュは薄層打設終了に更新されます。' : ''}
                    </p>
                    <div className="content-confirm">

                        <div className="d-flex justify-content-evenly pt-2">
                            <button className="btn btn-mesh-action btn-primary mr-2" onClick={changeModeSlimAction}>OK</button>
                            <button className="btn btn-mesh-action btn-secondary" onClick={() => {
                                setConfirmChangeSlim(false);
                            }}>キャンセル</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}



export default connect((state: RootState) => ({
    locationSelectorState: state.locationSelector,
    appState: state.app,
    constructionState: state.construction,
    shippingInformationState: state.shippingInformation,
    meshSettingState: state.meshSetting,
}))(Mode2dEditor);
