import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { TDocumentManagement } from "./types";
import { PAGINGATION_DEFAULT } from "@share/const";

export const initialState: TDocumentManagement.TState = {
    documentList: {
        data: [],
        ...PAGINGATION_DEFAULT,
    },
    isLoading: false,
    deletingId: ''
};

export const fetchList = createAsyncThunk(
    "documentManagement/fetchList",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.documentAPI.fetchList(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const insert = createAsyncThunk(
    "documentManagement/insert",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.documentAPI.create(params);
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }

    }
);

export const update = createAsyncThunk(
    "documentManagement/update",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.documentAPI.update(params);
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }

    }
);

export const deleteOne = createAsyncThunk(
    "documentManagement/deleteOne",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.documentAPI.delete(id);
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }

    }
);

const documentSlice = createSlice({
    name: "document",
    initialState,
    reducers: {
        setDeletingId(state, action) {
            state.deletingId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchList.pending, (state) => {
          state.isLoading = true;
        });
        builder.addCase(
          fetchList.fulfilled,(state,action) => {
            state.documentList = action.payload;
            state.isLoading = false;
          }
        );
        builder.addCase(fetchList.rejected, (state) => {
          state.isLoading = false;
        });
    },
});

export const {
    setDeletingId
} = documentSlice.actions;

export default documentSlice.reducer;
