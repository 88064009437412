import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "reactstrap";
import {
  ShippingInformationState,
  Dispatcher,
  RootState,
  DisplayItemSettingState,
  IDisplayItemSetting,
  AppState,
  LocationSelectorState,
} from "AppTypes";
import { connect } from "react-redux";
import { shippingInformationActions } from "@features/shippinginformation/state_management/actions";
import { displayItemSettingActions } from "@features/setting/displayitem/state_management/actions";
import { TShippingInformationManagement } from "@features/shippinginformation/state_management/types";
import moment from "moment";

import { convertMinuteToHour } from "@utils/format";

type Props = {
  dispatch: Dispatcher;
  shippingInformationState: ShippingInformationState;
  displayItemSettingState: DisplayItemSettingState;
  locationSelectorState: LocationSelectorState;
  appState: AppState;
};

const ShippingInformationList: React.FC<Props> = ({
  shippingInformationState,
  appState,
  displayItemSettingState,
  locationSelectorState,
  dispatch,
}) => {
  const { shippingInformationList } = shippingInformationState;
  const { displayItemSettingList } = displayItemSettingState;
  const history = useHistory();
  const locationId =
    localStorage.getItem("currentLocationId") ||
    appState.currentLocationId ||
    "";
  const [sortDirection, setSortDirection] = useState<string>("ASC");
  const [sortColumn, setSortColumn] = useState<string>("");

  const compare = (a: IDisplayItemSetting, b: IDisplayItemSetting) => {
    if (a.displayOrder < b.displayOrder) {
      return -1;
    }
    if (a.displayOrder > b.displayOrder) {
      return 1;
    }
    return 0;
  };
  useEffect(() => {
    dispatch(displayItemSettingActions.fetchList(null));
    if (locationSelectorState.locationSelect) {
      dispatch(shippingInformationActions.fetchList({
        locationId: locationSelectorState.locationSelect
      }));
    }
  }, [locationSelectorState.locationSelect]);

  const shippingSettings = displayItemSettingList
    ?.filter((x) => x.isDisplayed && x.type === "Shipping")
    .sort(compare);
  const sortedSettings = shippingSettings?.map((x) => {
    return { ...x, code: x.code.toUpperCase() };
  });

  const statuses = [
    { key: "NotStartYet", value: "まだ" },
    { key: "Moving", value: "まだ" },
    { key: "Arrived", value: "現着" },
    { key: "Unloading", value: "荷卸中" },
    { key: "Completed", value: "終了" },
  ];

  const sortList = (id: string) => {
    const displayItem = shippingSettings.find((x) => x.id === id);
    if (!displayItem) return;

    const sortCol = displayItem.code;

    let sortDir = sortDirection;

    if (sortCol === sortColumn) {
      if (sortDirection === "ASC") {
        sortDir = "DESC";
      } else if (sortDirection === "DESC") {
        sortDir = "ASC";
      }
    } else {
      sortDir = "ASC";
    }

    setSortDirection(sortDir);
    setSortColumn(sortCol);

    dispatch(
      shippingInformationActions.fetchList({
        locationId: locationSelectorState.locationSelect,
        sortColumn: sortCol,
        sortDirection: sortDir,
      })
    );
  };

  const getBackgoundClass = (status: string) => {
    if (status === "Completed") {
      return "bg-success";
    } else if (status === "Unloading") {
      return "bg-warning";
    } else if (status === "Moving") {
      return "bg-moving";
    } else if (status === "Arrived") {
      return "bg-info";
    } else {
      return "text-primary";
    }
  };

  const renderHeaders = sortedSettings?.length ? (
    sortedSettings.map((setting: IDisplayItemSetting) => (
      <td
        key={setting.id}
        onClick={() => sortList(setting.id)}
        className={
          setting.code.toUpperCase() === sortColumn.toUpperCase()
            ? "sortedClicked cursor-pointer"
            : "cursor-pointer"
        }
      >
        {setting.name}
      </td>
    ))
  ) : (
    <th></th>
  );

  return (
    <>
      <div className="bg-warning text-center" style={{ fontWeight: "bold" }}>
        運搬情報
      </div>
      <div className="table-mesh-content scrollableContainer table-wrapper-scroll-x">
        <Table
          bordered
          className="text-center table-mesh"
          style={{ width: "1000px", whiteSpace: "nowrap" }}
        >
          <thead>
            <tr>
              {renderHeaders}
            </tr>
          </thead>
          <tbody>
            {shippingInformationList &&
              shippingInformationList?.map(function (
                si: TShippingInformationManagement.ShippingInformation
              ) {
                const itemProperties = Object.keys(si);
                return (
                  <tr key={si.id} className={getBackgoundClass(si["status"])}>
                    {sortedSettings?.map(function (
                      setting: IDisplayItemSetting
                    ) {
                      const prop = itemProperties.find(
                        (x) => x.toUpperCase() === setting.code.toUpperCase()
                      );

                      if (prop) {
                        if (setting.dataType === "String") {
                          return (
                            <td key={`${si.id}-${setting.id}`}>{si[prop]}</td>
                          );
                        } else if (setting.dataType === "Number") {
                          return (
                            <td key={`${si.id}-${setting.id}`}>
                              {si[prop]?.toFixed(2)}
                            </td>
                          );
                        } else if (setting.dataType === "DateTime") {
                          return (
                            <td key={`${si.id}-${setting.id}`}>
                              {si[prop]
                                ? moment(si[prop]).format("YYYY/MM/DD HH:mm")
                                : ""}
                            </td>
                          );
                        } else if (setting.dataType === "TimeSpan") {
                          return (
                            <td key={`${si.id}-${setting.id}`}>
                              {si[prop]
                                ? convertMinuteToHour(parseInt(si[prop]))
                                : ""}
                            </td>
                          );
                        } else if (setting.dataType === "Boolean") {
                          if (si[prop] === true) {
                            return <td key={`${si.id}-${setting.id}`}>●</td>;
                          } else {
                            <td key={`${si.id}-${setting.id}`} />;
                          }
                        } else {
                          return <td key={`${si.id}-${setting.id}`} />;
                        }
                      }

                      return <td key={`${si.id}-${setting.id}`} />;
                    })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default connect((state: RootState) => ({
  shippingInformationState: state.shippingInformation,
  locationSelectorState: state.locationSelector,
  displayItemSettingState: state.dipslayItemSetting,
  appState: state.app,
}))(ShippingInformationList);
