import { TLocationManagement } from '@features/setting/location/state_management/types'
import axios from '@utils/axios-client'
import {
    IBlockModel
} from 'AppTypes'

class BlockAPI {
    prefix = '/api/v1/Block'
 

    async fetchAll() {
        const url = `${this.prefix}/GetAll`;
        const response = await axios.get<{
            data: IBlockModel[]
        }>(url)
        return response;
    }

   

    async insert(params: TLocationManagement.BlockModel) {
        const url = `${this.prefix}/Create`
        return await axios.post(url, params)
    }

    async update(params: TLocationManagement.BlockModel) {
        const url = `${this.prefix}/Update`
        return await axios.put(url, params)
    }

     
    delete(id: string) {
        const url = `${this.prefix}/Delete?id=${id}`
        return axios.delete(url)
    }

    
}

export const blockAPI = new BlockAPI()