import React from 'react'
import { connect } from 'react-redux'
import { AppState, RootState, Dispatcher } from 'AppTypes'

type Props = {
  isShowing?: boolean
  appState: AppState
  dispatch: Dispatcher
}

const LoadingSpinner: React.FC<Props> = ({
  isShowing = false
}) => {
  if (!isShowing) return null
  return (
    <div className="spinner">
      <div className="outer">
        <div className="lds-dual-ring" />
      </div>
    </div>
  )
}

export default connect((state: RootState) => ({
  appState: state.app
}))(LoadingSpinner)
