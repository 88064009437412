/* @ts-ignore */

import React, { Suspense, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import * as THREE from 'three';
import { connect } from "react-redux";
import { LocationSelectorState, AppState, Dispatcher, RootState, MeshSettingState } from "AppTypes";
import { Canvas, useThree } from '@react-three/fiber'
import { OrbitControls, PerspectiveCamera, useGLTF, useCursor, useProgress, Html, GizmoHelper, GizmoViewcube, Stage } from '@react-three/drei'
import { proxy, useSnapshot } from 'valtio'
import { useHistory } from "react-router-dom";
import { spaceMesh } from "@utils/func";
import { locationSelectorActions } from "@features/location/selector/state_management/actions";
import { meshSettingActions } from "@features/setting/meshcolor/state_management/actions";
import { CameraControls } from './CameraControl';
import { STATUS_MESH, WARNING_STATUS } from '../../../const/constants'

export const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
};

export const cameraControlOptions: any = {
    zoomStep: 0.2,
    zoomMin: 0.2,
    zoomMax: 4,
    moveStep: 0.2,
    rotationStep: Math.PI / 25,
    defaultOptions: {
        position: [0, 0, 0],
        fov: 40,
        rotation: [0, 0, 0],
        zoom: 1
    }
};

const faces = ['右', '左', '上', '下', '前', '後']
export const args = {
    alignment: 'bottom-right',
    color: '#fdd867',
    colorX: 'red',
    colorY: 'green',
    colorZ: 'blue',
    controls: 'OrbitControls',
    faces,
    gizmo: 'GizmoViewcube',
    hideNegativeAxes: false,
    hoverColor: 'lightgray',
    labelColor: 'red',
    marginX: 80,
    marginY: 80,
    opacity: 1,
    strokeColor: '#020101',
    textColor: '#000000',
    font: '40px Arial',
}


type Props = {
    dispatch: Dispatcher;
    locationSelectorState: LocationSelectorState;
    appState: AppState;
    meshSettingState: MeshSettingState;
};

// Reactive state model, using Valtio ...
export const modes = ['translate']
export const state = proxy({ current: null as any, mode: 0, fileGLB: '', blockActive: null })
const listMeshName: any[] = []

export function Loader() {
    const { active, progress } = useProgress();
    return <Html center style={{ width: '100px' }}>{progress.toFixed(0)} %</Html>;
}

export function Model({ item, setBlockActive, index, meshSettingState, isHidden, isModeSlim }: any) {
    // Ties this component to the state model
    const snap = useSnapshot(state)
    const meshRef = useRef<THREE.Mesh>(null!)
    // Fetching the GLTF, nodes is a collection of all the meshes
    // It's cached/memoized, it only gets loaded and parsed
    const { nodes } = useGLTF(`${process.env.REACT_APP_GLB_ENDPOINT}${state.fileGLB}`)
    // Feed hover state into useCursor, which sets document.body.style.cursor to pointer|auto
    const [hovered, setHovered] = useState(false)
    useCursor(hovered)

    let itemMesh: any = null;
    let opacityMesh = 1;
    for (const [key, value] of Object.entries(nodes)) {
        if (value?.parent?.name === `Block${item.code}` || value?.parent?.name === `${item.code}`) {
            let color = '#FFFAFA';
            if (item?.warningStatus !== '') {
                const findValueWarning = meshSettingState?.meshSettingList?.find((itemSetting: any) => itemSetting.code === item.warningStatus);
                if (findValueWarning) {
                    opacityMesh = 1 - (findValueWarning.transferTime / 100);
                    if (findValueWarning.code === WARNING_STATUS[0]) {
                        color = '#f766ff';
                    } else if (findValueWarning.code === WARNING_STATUS[1]) {
                        color = '#F50505';
                    }
                }
            } else {
                const findValueStatus = meshSettingState?.meshSettingList?.find((itemSetting: any) => itemSetting.code === item.status);

                if (findValueStatus) {
                    opacityMesh = 1 - (findValueStatus.transferTime / 100);
                    if (findValueStatus.code === STATUS_MESH[0]) {
                        color = '#FFFAFA';
                    } else if (!item.isSlim && findValueStatus.code === STATUS_MESH[1]) {
                        color = '#FFFF00';
                    } else if (!item.isSlim && findValueStatus.code === STATUS_MESH[2]) {
                        color = '#9ACD32';
                    } else if (item.isSlim && findValueStatus.code === STATUS_MESH[1]) {
                        color = '#fdfc06';
                    } else if (item.isSlim && findValueStatus.code === STATUS_MESH[2]) {
                        color = '#2670c1';
                    }
                }
            }
            itemMesh = {
                ...item,
                name: value.name,
                color: color,
                indexMesh: index
            }
        }
    }

    if (itemMesh.name === '' || itemMesh.name === undefined) {
        return null;
    }

    return (
        <mesh
            castShadow
            receiveShadow
            ref={meshRef}
            // Click sets the mesh as the new target
            onClick={(e) => {
                e.stopPropagation();
                state.current = itemMesh.name;
                setBlockActive(itemMesh.indexMesh);
                meshRef.current?.rotation?.set(0, 0, 0)
            }}
            // If a click happened but this mesh wasn't hit we null out the target,
            // This works because missed pointers fire before the actual hits
            onPointerMissed={(e) => {
                if (e.type === 'click') {
                    state.current = null;
                    setBlockActive(null);
                }
            }}
            // Right click cycles through the transform modes
            onContextMenu={(e) => snap.current === itemMesh.name && (e.stopPropagation(), (state.mode = (snap.mode + 1) % modes.length))}
            onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
            onPointerOut={(e) => setHovered(false)}
            name={itemMesh.name}
            /* @ts-ignore */
            geometry={nodes[itemMesh.name].geometry}
            /* @ts-ignore */
            material={nodes[itemMesh.name].material}
            /* @ts-ignore */
            // material-color={snap.current === itemMesh.name ? '#ff6080' : itemMesh.color}
            position={itemMesh.isFloorSelect ? new THREE.Vector3(nodes[itemMesh.name].position.x, nodes[itemMesh.name].position.y + spaceMesh(nodes[itemMesh.name].position.y), nodes[itemMesh.name].position.z) : nodes[itemMesh.name].position}
            material-transparent={true}
            // material-opacity={0.8}
            dispose={null}
            rotation={nodes[itemMesh.name].rotation}
            material-precision={'highp'}
            visible={!isHidden}
            isMesh
        >
            <meshStandardMaterial
                color={snap.current === itemMesh.name ? '#ff6080' : itemMesh.color}
                opacity={opacityMesh}
                transparent={true}
                visible={!isHidden}
            />
        </mesh>
    )
}

export const Controls = () => {
    // Get notified on changes to state
    const snap = useSnapshot(state)
    const scene = useThree((state) => state.scene)

    return (
        <>
            {/* As of drei@7.13 transform-controls can refer to the target by children, or the object prop */}
            {/* {snap.current && <TransformControls object={scene.getObjectByName(snap.current)} mode={modes[snap.mode]} />} */}
            <OrbitControls makeDefault minPolarAngle={0} maxPolarAngle={Math.PI / 1.75} rotation={scene.rotation} reverseOrbit={false} />
        </>
    )
}

export const ModelGroup = ({ locationSelectorState, setBlockActive, customControlRef, meshSettingState }: any) => {
    return (
        <group>
            {
                locationSelectorState.locationMesh
                && locationSelectorState.locationMesh[0]?.glbFilePath
                && locationSelectorState.locationMesh[0].meshes?.map((meshItem: any, index: number) => (
                    <Model
                        item={meshItem}
                        key={meshItem.id}
                        setBlockActive={(indexMesh: number) => setBlockActive(indexMesh)}
                        index={index}
                        meshSettingState={meshSettingState}
                        isHidden={meshItem.isHidden}
                        isModeSlim={locationSelectorState.isModeSlim}
                    />
                ))
            }
        </group>
    )
}

const ThreeEditor: React.FC<Props> = ({ locationSelectorState, appState, dispatch, meshSettingState }) => {
    const [getFirst, setGetFirst] = useState(false)
    const locationId = localStorage.getItem('currentLocationId') || appState.currentLocationId || '';
    const customControlRef: any = useRef();
    const [blockActive, setBlockActive] = useState<number>()
    const history = useHistory();
    const [listFloors, setListFloors] = useState<any[]>([]);
    const [isGetListFloor, setGetListFloor] = useState(false);
    const [isShowModel, setShowModel] = useState(false);
    const virtualCamera = useRef<CameraControls['camera']>()
    const cameraControlRef = useRef<CameraControls | null>(null)

    useEffect(() => {
        dispatch(meshSettingActions.fetch());
        if (locationId !== '' && !getFirst && locationSelectorState.locationMesh.length === 0) {
            setGetFirst(true);
            dispatch(locationSelectorActions.fetchLocationMesh({ locationId: locationSelectorState.locationSelect, ignoreFilter: false }));
        }
        if (locationSelectorState.locationMesh.length > 0
            && locationSelectorState.locationMesh[0].meshes?.length > 0
            && !isGetListFloor) {
            const dataFloors: any[] = []
            setBlockActive(-1);
            dispatch(locationSelectorActions.resetShowMesh())
            locationSelectorState.locationMesh[0].meshes?.forEach((item: any) => {
                const findFloor = dataFloors.find(itemFloor => itemFloor.layer === item.layer)
                if (findFloor === undefined) {
                    dataFloors.push({
                        layer: item.layer,
                        codeFloor: item.code?.substr(0, 5)
                    })
                }
            })
            dataFloors.sort((itemBefore, itemAfter) => itemAfter.layer - itemBefore.layer)
            const dataSetFloors = dataFloors.filter(floor => floor.layer < dataFloors.length)
            setListFloors(dataSetFloors)
            setGetListFloor(true)
        }
    }, [locationId, locationSelectorState.locationMesh]);

    useEffect(
        () => {
            const timer = setTimeout(() => {
                setShowModel(false);
            }, 150);
            return () => clearTimeout(timer);
        },
        [isShowModel]
    );

    if (locationSelectorState.locationMesh.length === 0) {
        return null;
    }

    if (locationSelectorState.locationMesh.length > 0) {
        state.fileGLB = locationSelectorState.locationMesh[0]?.glbFilePath;
    }

    const setActiveMesh = (index: number) => {
        if (index === blockActive) {
            setBlockActive(-1);
            dispatch(locationSelectorActions.resetShowMesh())
        } else {
            setBlockActive(index);
            // setActiveFloor(listFloors[index]);
            dispatch(locationSelectorActions.setShowMeshWithFloor({
                floorCode: listFloors[index].codeFloor,
                layer: listFloors[index].layer,
            }));
        }
    }

    const showSuccessBlocks = () => {
        dispatch(locationSelectorActions.setShowMeshSuccess())
    }

    const showAllBlocks = () => {
        dispatch(locationSelectorActions.resetShowMesh())
    }

    return (
        <div className="col-12 p-0 view-3d-full">
            <div className="bg-warning text-center fw-bold text-cursor" aria-hidden="true" onClick={() => history.push('/location')}>打重ね</div>
            <div className="three-3d-editor-container">
                <Canvas className="view-3d-block" shadows dpr={[1, 2]} camera={{ fov: 50 }}>
                    <pointLight position={[10, 10, 10]} intensity={0.8} />
                    <hemisphereLight color="#A6A7A5" groundColor="#696B67" position={cameraControlOptions.defaultOptions.position} intensity={0.85} />
                    <Suspense fallback={<Loader />}>
                        <Stage contactShadow={{ blur: 1, opacity: 0, position: [0, 0, 0] }} intensity={1} environment={null} preset="rembrandt" >
                            <group dispose={null}>
                                {!isShowModel && (
                                    <ModelGroup locationSelectorState={locationSelectorState} setBlockActive={setBlockActive} meshSettingState={meshSettingState} />
                                )}
                            </group>
                        </Stage>
                        <GizmoHelper alignment="bottom-right" margin={[args.marginX, args.marginY]}>
                            <GizmoViewcube
                                {...args}
                                onClick={() => null}
                            />
                        </GizmoHelper>
                    </Suspense>
                    {/* <Controls /> */}
                    <CameraControls ref={cameraControlRef} />
                </Canvas>
                <div className="three-3d-list-mesh">
                    <div className="list-mesh">
                        {locationSelectorState.locationMesh[0]?.glbFilePath
                            && listFloors.map((floor, index) => (
                                <div key={index} className={index === blockActive ? `active-mesh text-center` : 'text-center'} aria-hidden="true" onClick={() => setActiveMesh(index)}>{floor.layer}</div>
                            ))
                        }
                    </div>
                </div>
                <div className="three-3d-editor-panel">
                    <div className="controls">
                        <div className="control">
                            <div className="default-action" onClick={() => {
                                if (cameraControlRef.current) {
                                    cameraControlRef.current.reset();
                                }
                            }}>
                                正面
                            </div>
                        </div>
                        <div className="control">
                            <div className="zoom-actions">
                                <div className="action in" onClick={() => {
                                    if (cameraControlRef.current) {
                                        cameraControlRef.current.zoom(0.25, true);
                                    }
                                }}>
                                    <i className="fa fa-plus"></i>
                                </div>
                                <div className="action out" onClick={() => {
                                    if (cameraControlRef.current) {
                                        cameraControlRef.current.zoom(-0.25, true);
                                    }
                                }}>
                                    <i className="fa fa-minus"></i>
                                </div>
                            </div>
                        </div>
                        <div className="control">
                            <div className="default-action" onClick={showSuccessBlocks}>
                                実績
                            </div>
                        </div>
                        <div className="control">
                            <div className="default-action" onClick={showAllBlocks}>
                                全体
                            </div>
                        </div>
                        {/* <div className="control">
              <div className="rotate-actions">
                <img src="/assets/icons/direction.jpg"/>
                <div className="action up" aria-hidden="true" onClick={() => {
                  customControlRef.current?.move('up');
                }}>
                </div>
                <div className="action down" aria-hidden="true" onClick={() => {
                  customControlRef.current?.move('down');
                }}>
                </div>
                <div className="action right" aria-hidden="true" onClick={() => {
                  customControlRef.current?.move('right');
                }}>
                </div>
                <div className="action left" aria-hidden="true" onClick={() => {
                  customControlRef.current?.move('left');
                }}>
                </div>
              </div>
            </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}



export default connect((state: RootState) => ({
    locationSelectorState: state.locationSelector,
    appState: state.app,
    meshSettingState: state.meshSetting,
}))(ThreeEditor);
