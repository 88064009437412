import { TShipmentManagement } from '@features/shipment/registration/state_management/types'
import axios from '@utils/axios-client'
import { IOrderModel, IOrderByLocation } from "AppTypes"
import { appendParams } from '@utils/func'

class OrderAPI {
    orderPrefix = '/api/v1/Order'
    orderDetailPrefix = '/api/v1/OrderDetail'



    fetchOrderByDate(params: any) {
        const url = appendParams(`${this.orderPrefix}/GetByDate`, { ...params });
        return axios.get<{ data: IOrderModel }>(url)
    }

    fetchOrderByLocation(locationId: string) {
        const url = `${this.orderPrefix}/GetByLocation?locationId=${locationId}`;
        return axios.get<{ data: IOrderByLocation }>(url)
    }

    async insertDetail(params: any) {
        const url = `${this.orderDetailPrefix}/Create`
        return await axios.post(url, params)
    }


    async updateDetail(params: TShipmentManagement.OrderDetailUpsert) {
        const url = `${this.orderDetailPrefix}/Update`
        return await axios.put(url, params)
    }

    async updatePlannedVolume(params: any) {
        const url = `${this.orderPrefix}/UpdatePlannedVolume`
        return await axios.patch(url, params)
    }

    deleteDetail(id: string) {
        const url = `${this.orderDetailPrefix}/Delete?id=${id}`
        return axios.delete(url)
    }

}

export const orderAPI = new OrderAPI()