import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { TLocationSelectorManagement } from "./state_management/types";
import { connect } from "react-redux";
import { Formik, Field } from 'formik'
import { Dispatcher, RootState, LocationSelectorState, ILocationListAllModel, ILocationConstructionModel } from "AppTypes";
import { locationSelectorActions } from "./state_management/actions";
import { appActions } from "@app/state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import "./LocationSelector.css"
import ThreeDEditor from "./3DEditor";
import moment from "moment";

type Props = {
    dispatch: Dispatcher;
    locationSelectorState: LocationSelectorState;
};


const LocationSelector: React.FC<Props> = ({ locationSelectorState, dispatch }) => {
    const { locationList, constructionModelList, showCompleted, selectedConstructionModelId } = locationSelectorState;

    const history = useHistory()
    const role = localStorage.getItem("role");
    const currentModelId = localStorage.getItem("currentModelId");

    useEffect(() => {
        dispatch(locationSelectorActions.fetchConstructionModel());
        if (constructionModelList?.data?.length > 0 && currentModelId) {
            dispatch(locationSelectorActions.setSelectedConstructionModelId(currentModelId));
        }
    }, []);

    useEffect(() => {
        if (role === "WorkingSite") {
            history.push("/construction");
        }
        if (selectedConstructionModelId) {
            dispatch(locationSelectorActions.fetchLocationByConstructionModel(selectedConstructionModelId));
        }
    }, [selectedConstructionModelId]);


    const handleChangeConstructionModel = (id: string) => {
        localStorage.setItem('currentModelId', id);
        dispatch(locationSelectorActions.setSelectedConstructionModelId(id));
    }

    const handleSelectLocationClick = async (id: string) => {
        await dispatch(appActions.setCurrentLocationId(id));
        localStorage.setItem('currentLocationId', id);
        dispatch(locationSelectorActions.setSelectedConstructionModelId(''))
        if (role === "Office") {
            history.push("/location")
        } else if (role === "WorkingSite") {
            history.push("/construction");
        }
    }

    const handleOnCheckShowCompleted = async (checked: boolean) => {
        dispatch(locationSelectorActions.setShowCompleted(checked));
    }


    const renderList = locationList.data && locationList.data.length ? (
        <Formik
            enableReinitialize
            initialValues={locationList.data}
            onSubmit={(values, actions) => {
                actions.setSubmitting(false)
            }}
        >{
                ({ values, setFieldValue }) => (
                    values.map((loc: ILocationConstructionModel, index: number) => (
                        <tr key={loc.id}>
                            <td onClick={() => {
                                dispatch(locationSelectorActions.setDataLocation({ index: index, value: !loc.isActive }))
                            }}>
                                <Field type="checkbox" className="form-checkbox" name={`${index}.isActive`} />
                            </td>
                            <td onClick={() => {
                                dispatch(locationSelectorActions.setActiveLocation({ index: index, value: locationSelectorState.activeLocationTable === loc.id ? '' : loc.id }))
                            }}>
                                {`${loc.blockName}${loc.liftName}`}
                            </td>
                            <td>
                                {`${loc.plannedDate}`} 
                            </td>
                            <td>
                                <input type="button" name="btnAdd" value="選択" onClick={() => { handleSelectLocationClick(loc.id) }} />
                            </td>
                        </tr>
                    ))
                )}
        </Formik>
    ) : (
        <tr>
            <td colSpan={3}>データなし</td>
        </tr>
    );
    return (
        <>

            <LoadingSpinner isShowing={locationSelectorState.isLoading} />
            <div className="settingItemPageTitle">
                <p>打設場所選択</p>
            </div>


            <div className="d-flex locationSelector-container p-2">
                <div className="col-4">
                    <div>
                        <table className="table locationselectForm">
                            <tbody>
                                <tr>
                                    <td width="35%">
                                        <label htmlFor="slGeneralModel">全体モデル</label>
                                    </td>
                                    <td>
                                        <select className="form-select ml-3" id="slGeneralModel" value={selectedConstructionModelId} onChange={e => handleChangeConstructionModel(e.currentTarget.value)}>
                                            {constructionModelList?.data?.length && constructionModelList?.data?.map(cm => (
                                                <option key={cm.id} value={cm.id}>{cm.name}</option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                {/*    <td width="35%">*/}
                                {/*        <label className="form-check-label" htmlFor="chkShowCompleted">終了分を表示</label>*/}
                                {/*    </td>*/}
                                {/*    <td>*/}
                                {/*        <div className="form-check form-switch">*/}
                                {/*            <label className="form-check-label" htmlFor="chkShowCompleted"></label>*/}
                                {/*            <input className="form-check-input input-lg" type="checkbox" role="switch" id="chkShowCompleted" checked={showCompleted} onChange={() => handleOnCheckShowCompleted(!showCompleted)} />*/}
                                {/*        </div>*/}
                                {/*    </td>*/}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table className="table table-bordered">
                            <thead className="bg-thead">
                                <tr>
                                    <th scope="col">表示</th>
                                    <th scope="col">打設場所</th>
                                    <th scope="col">打設日</th>
                                    <th scope="col">選択</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderList}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="col-8">
                    <div className="content editor-container">
                        <ThreeDEditor
                            locationListData={locationList.data}
                            showSuccessBlocks={() => dispatch(locationSelectorActions.setShowLocationSuccess())}
                            showAllBlocks={() => dispatch(locationSelectorActions.resetLocationShow())}
                            constructionModelList={constructionModelList.data}
                            selectedConstructionModelId={selectedConstructionModelId}
                            locationSelectorState={locationSelectorState}
                        />
                    </div>
                </div>
            </div>


        </>
    );
};

export default connect((state: RootState) => ({
    locationSelectorState: state.locationSelector,
}))(LocationSelector);
