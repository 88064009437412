import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { TLocationManagement } from "./types";
import { IBlockModel, IConstructionModel, ILiftModel } from "AppTypes";

export const initialState: TLocationManagement.TState = {
    blockList: [],
    blockInsert: {
        id: "",
        name: ""
    },
    blockUpdate: {
        id: "",
        name: ""
    },
    blockDeletingId: "",
    blockUpdatingId: "",
    liftList: [],
    liftInsert: {
        id: "",
        name: ""
    },
    liftUpdate: {
        id: "",
        name: ""
    },
    liftDeletingId: "",
    liftUpdatingId: "",
    constructionModelList: [],
    locationList: [],
    updatingLocation: {
        id: "",
        code: "",
        blockId: "",
        liftId: ""
    },
    insertingLocation: {
        id: "",
        code: "",
        blockId: "",
        liftId: ""
    },
    selectedConstructionModelId: "",
    deletingLocationId: "",
    dwgUploadFile: null,
    glbUploadFile: null,
    pngUploadFiles: null,
    glbUploadUpdateFile: null,
    dwgUploadUpdateFile: null,
    pngUploadUpdateFiles: null,
    isLoading: false
};

export const fetchBlockList = createAsyncThunk(
    "locationManagement/fetchBlockList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.blockAPI.fetchAll();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchLiftList = createAsyncThunk(
    "locationManagement/fetchLiftList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.liftAPI.fetchAll();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const insertBlock = createAsyncThunk(
    "locationManagement/insertBlock",
    async (params: IBlockModel, { rejectWithValue }) => {
        try {
            const response = await api.blockAPI.insert(params);
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }

    }
);


export const updateBlock = createAsyncThunk(
    "locationManagement/updateBlock",
    async (params: IBlockModel, { rejectWithValue }) => {
        try {
            const response = await api.blockAPI.update(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const deleteOneBlock = createAsyncThunk(
    "locationManagement/deleteOneBlock",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.blockAPI.delete(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const insertLift = createAsyncThunk(
    "locationManagement/insertLift",
    async (params: ILiftModel, { rejectWithValue }) => {
        try {
            const response = await api.liftAPI.insert(params);
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }

    }
);


export const updateLift = createAsyncThunk(
    "locationManagement/updateLift",
    async (params: ILiftModel, { rejectWithValue }) => {
        try {
            const response = await api.liftAPI.update(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const deleteOneLift = createAsyncThunk(
    "locationManagement/deleteOneLift",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.liftAPI.delete(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);



export const fetchConstructionModel = createAsyncThunk(
    "locationManagement/fetchConstructionModel",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.constructionModelAPI.fetchList();
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }

);

export const fetchLocationList = createAsyncThunk(
    "locationManagement/fetchLocationList",
    async (constructionModelId: string, { rejectWithValue }) => {
        try {
            const response = await api.locationAPI.fetchLocationByConstructionModel(constructionModelId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const insertLocation = createAsyncThunk(
    "locationManagement/insertLocation",
    async (params: FormData, { rejectWithValue }) => {
        try {
            const response = await api.locationAPI.insert(params);
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }

    }
);

export const updateLocation = createAsyncThunk(
    "locationManagement/updateLocation",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.locationAPI.update(params);
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }

    }
);

export const deleteOneLocation = createAsyncThunk(
    "locationManagement/deleteOneLocation",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.locationAPI.delete(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


const locationSlice = createSlice({
    name: "location",
    initialState,
    reducers: {
        setInsertBlockData(state, action) {
            const { key, value } = action.payload;
            const data = state.blockInsert;
            data[key] = value;
        },
        setUpdatingBlockData(state, action) {
            const { key, value } = action.payload;
            const data = state.blockUpdate;
            data[key] = value;
        },
        resetBlockUpdate(state) {
            state.blockUpdate = initialState.blockUpdate;
        },
        resetBlockInsert(state) {
            state.blockInsert = initialState.blockInsert;
        },
        setUpdatingBlockId(state, action) {
            if (action.payload) {
                const updatingItem = state.blockList.find(x => x.id === action.payload)
                if (updatingItem) {
                    const { id, name } = updatingItem;
                    state.blockUpdate = {
                        id, name, isActive: true
                    }

                }
            } else {
                state.blockUpdate = initialState.blockUpdate;
            }
            state.blockUpdatingId = action.payload;
        },
        setDeletingBlockId(state, action) {
            state.blockDeletingId = action.payload;
        },

        setInsertLiftData(state, action) {
            const { key, value } = action.payload;
            const data = state.liftInsert;
            if (data) {
                data[key] = value;
            }
        },
        setUpdatingLiftData(state, action) {
            const { key, value } = action.payload;
            const data = state.liftUpdate;
            if (data) {
                data[key] = value;
            }
        },
        resetLiftUpdate(state) {
            state.liftUpdate = initialState.liftUpdate;
        },
        resetLiftInsert(state) {
            state.liftInsert = initialState.liftInsert;
        },
        setUpdatingLiftId(state, action) {
            if (action.payload) {
                const updatingItem = state.liftList.find(x => x.id === action.payload)
                if (updatingItem) {
                    const { id, name } = updatingItem;
                    state.liftUpdate = {
                        id, name, isActive: true
                    }

                }
            } else {
                state.liftUpdate = initialState.liftUpdate;
            }
            state.liftUpdatingId = action.payload;
        },
        setDeletingLiftId(state, action) {
            state.liftDeletingId = action.payload;
        },
        setUpdatingLocationData(state, action) {
            const { key, value } = action.payload;
            const data = state.updatingLocation;
            if (data) {
                data[key] = value;
            }

        },
        setInsertingtLocationData(state, action) {
            const { key, value } = action.payload;
            const data = state.insertingLocation;
            if (data) {
                data[key] = value;
            }
        },
        setDwgUploadFile(state, action) {
            const file = action.payload;
            if (file && file.name && file.size) {
                state.dwgUploadFile = file;
            }
        },

        setGlbUploadFile(state, action) {
            const file = action.payload;
            if (file && file.name && file.size) {
                state.glbUploadFile = file;
            }
        },
        setPngUploadFiles(state, action) {
            const files = action.payload;
            if (files && files.length) {
                state.pngUploadFiles = files;
            }
        },
        clearDwgFileUpload(state) {
            state.dwgUploadFile = null;
        },
        clearGlbFileUpload(state) {
            state.glbUploadFile = null;
        },
        setDwgUploadUpdateFile(state, action) {
            const file = action.payload;
            if (file && file.name && file.size) {
                state.dwgUploadUpdateFile = file;
            }
        },
        setGlbUploadUpdateFile(state, action) {
            const file = action.payload;
            if (file && file.name && file.size) {
                state.glbUploadUpdateFile = file;
            }
        },
        setPngUploadUpdateFiles(state, action) {
            const files = action.payload;

            if (files.length && files) {
                state.pngUploadUpdateFiles = files;
            }
        },
        clearDwgUpdateFileUpload(state) {
            state.dwgUploadUpdateFile = null;
        },
        clearGlbUpdateFileUpload(state) {
            state.glbUploadUpdateFile = null;
        },
        setSelectedConstructionModelId(state, action) {
            state.selectedConstructionModelId = action.payload;
        },
        setUpdatingLocationId(state, action) {
            if (action.payload) {
                const updatingItem = state.locationList.find(x => x.id === action.payload)
                if (updatingItem) {
                    const { id, blockId, liftId, code } = updatingItem;
                    state.updatingLocation = { id, blockId, liftId, code };
                }
            } else {
                state.updatingLocation = initialState.updatingLocation;
            }
        },
        resetLocationUpdate(state) {
            state.updatingLocation = initialState.updatingLocation;
        },
        resetLocationInsert(state) {
            state.insertingLocation = initialState.insertingLocation;
        },
        setDeletingLocationId(state, action) {
            state.deletingLocationId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLiftList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(
            fetchLiftList.fulfilled,
            (
                state,
                action: PayloadAction<{ data: ILiftModel[] }>
            ) => {
                state.liftList = action.payload.data;
                state.isLoading = false;
            }
        );

        builder.addCase(fetchBlockList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(
            fetchBlockList.fulfilled,
            (
                state,
                action: PayloadAction<{ data: IBlockModel[] }>
            ) => {
                state.blockList = action.payload.data;
                state.isLoading = false;
            }
        );

        builder.addCase(
            fetchConstructionModel.pending,
            (
                state
            ) => {
                state.isLoading = true;
            }
        );

        builder.addCase(
            fetchConstructionModel.fulfilled,
            (
                state,
                action,
            ) => {
                state.constructionModelList = action.payload.data;
                if (!state.selectedConstructionModelId) {
                    state.selectedConstructionModelId = action.payload.data[0].id;
                }
                state.isLoading = false;
            }
        );

        builder.addCase(
            fetchLocationList.fulfilled,
            (
                state,
                action
            ) => {
                state.locationList = action.payload;

            }
        );

    },
});

export const {
    setInsertBlockData,
    resetBlockUpdate,
    setUpdatingBlockId,
    setUpdatingBlockData,
    setDeletingBlockId,
    resetBlockInsert,
    setInsertLiftData,
    resetLiftUpdate,
    setUpdatingLiftId,
    setUpdatingLiftData,
    setDeletingLiftId,
    resetLiftInsert,
    setUpdatingLocationData,
    setDwgUploadFile,
    setPngUploadFiles,
    clearDwgFileUpload,
    setInsertingtLocationData,
    setSelectedConstructionModelId,
    setUpdatingLocationId,
    resetLocationUpdate,
    resetLocationInsert,
    setDeletingLocationId,
    setGlbUploadFile,
    clearGlbFileUpload,
    setDwgUploadUpdateFile,
    setGlbUploadUpdateFile,
    setPngUploadUpdateFiles,
    clearDwgUpdateFileUpload,
    clearGlbUpdateFileUpload
} = locationSlice.actions;

export default locationSlice.reducer;
