import React, { useEffect, useRef, useState, ChangeEvent } from "react";
import { useHistory } from 'react-router-dom'
import { TConstructionModelSettingManagement } from "./state_management/types";
import { connect } from "react-redux";
import { ConstructionModelSettingState, Dispatcher, RootState } from "AppTypes";
import { constructionModelSettingActions } from "./state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Modal } from "../../../components/Modal";
import { success, error } from '@services/toast-service'


type Props = {
    dispatch: Dispatcher;
    constructionModelState: ConstructionModelSettingState;
};


const GeneralModelSetting: React.FC<Props> = ({ constructionModelState, dispatch }) => {

    const { uploadFile, isLoading, constructionModelList, updatingId, updatingItem, glbUploadFile, deleteId } = constructionModelState;
    const uploadButtonRef = useRef<HTMLInputElement>(null);
    const uploadGlbFileButtonRef = useRef<HTMLInputElement>(null);

    const history = useHistory()
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
    useEffect(() => {
        dispatch(constructionModelSettingActions.fetchList());

    }, []);


    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget?.value && e.currentTarget.files?.[0]) {
            dispatch(constructionModelSettingActions.setUploadFile(e.currentTarget.files?.[0]));
        }
    }

    const handleAddConstructionModel = async () => {
        if (!uploadFile || !uploadFile.name || uploadFile?.size <= 0) {
            error("dwgファイルを選択してください。");
            return;
        }
        if (!uploadFile.name.endsWith(".dwg")) {
            error("dwgファイル以外は登録できません。");
            return;
        }
        if (!glbUploadFile || !glbUploadFile.name || glbUploadFile?.size <= 0) {
            error("glbファイルを選択してください。");
            return;
        }
        if (!glbUploadFile.name.endsWith(".glb")) {
            error("glbファイル以外は登録できません。");
            return;
        }

        try {
            const response = await dispatch(constructionModelSettingActions.upload({
                dwgFile: uploadFile,
                glbFile: glbUploadFile,
            }));
            if (response.payload && response.payload.success) {
                await dispatch(constructionModelSettingActions.fetchList());
                await dispatch(constructionModelSettingActions.clearUploadFile());
                success("保存が完了しました。");
            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }
    }

    const handleUpdateLiftButtonClick = async (id: string) => {
        await dispatch(constructionModelSettingActions.setUpdatingId(id));
    }

    const handleUpdateCancelButtonClick = async () => {
        await dispatch(constructionModelSettingActions.setUpdatingId(''));

    }
    const handleDeleteCancelButtonClick = async () => {
        setShowDeleteConfirmModal(false);
        await dispatch(constructionModelSettingActions.setUpdatingId(''));
    }


    const handleUpdateButtonSaveClick = async () => {
        if (!updatingItem) {
            error("全体モデル名を入力してください。");
            return;
        }
        if (updatingItem.name.length > 10) {
            error("全体モデル名は10文字以下で入力してください。");
            return;
        }
        try {
            const response = await dispatch(constructionModelSettingActions.update(updatingItem));
            if (response.payload && response.payload.success) {
                await dispatch(constructionModelSettingActions.fetchList());
                await dispatch(constructionModelSettingActions.resetUpdate());
                success("保存が完了しました。");


            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }

    const handleDeleteButtonClick = async (id: string) => {
        await dispatch(constructionModelSettingActions.setDeletingId(id));
        setShowDeleteConfirmModal(true);
    }
    const handleDeleteButtonSaveClick = async () => {
        try {
            const response = await dispatch(constructionModelSettingActions.deleteOne(deleteId));
            if (response.payload && response.payload.success) {
                await dispatch(constructionModelSettingActions.fetchList());
                setShowDeleteConfirmModal(false);
                success("削除しました。");
            } else {
                error(response.payload.errors[0])
            }
        } catch (error: any) {
            error("削除ができませんでした。")
        }
    }


    const handleChangeUpdateData = (key: string, value: string | number) => {
        dispatch(constructionModelSettingActions.handleChangeUpdateData({ key, value }));
    }

    const handleGlbFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget?.value && e.currentTarget.files?.[0]) {
            dispatch(constructionModelSettingActions.setGlbUploadFile(e.currentTarget.files?.[0]));
        }
    }

    const handleClickUploadGlbButton = () => {
        if (uploadGlbFileButtonRef && uploadGlbFileButtonRef.current) {
            uploadGlbFileButtonRef.current.click();
        }

    }

    const handleClickUploadButton = () => {
        if (uploadButtonRef && uploadButtonRef.current) {
            uploadButtonRef.current.click();
        }

    }


    const renderList = constructionModelList?.length ? (
        constructionModelList.map((model: TConstructionModelSettingManagement.ConstructionModel) => (
            <tr key={model.id}>
                <td>
                    {
                        model.id !== updatingId ?
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="編" onClick={() => handleUpdateLiftButtonClick(model.id)} />
                                    <input type="button" name="btnRowDelete" value="削" onClick={() => handleDeleteButtonClick(model.id)} className="ml-3" />
                                </>
                            )
                            :
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="更新" onClick={() => handleUpdateButtonSaveClick()} />
                                    <input type="button" name="btnCancelUpdate" value="キャンセル" onClick={() => handleUpdateCancelButtonClick()} className="ml-3" />
                                </>
                            )

                    }

                </td>

                <td>
                    {
                        model.id !== updatingId ? model.name :
                            (
                                <input type="text" required className="form-input" value={updatingItem.name} onChange={(e) => { handleChangeUpdateData('name', e.target.value) }} />

                            )

                    }

                </td>
                <td />
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan={6}>データなし</td>
        </tr>
    );


    return (
        <>
            <LoadingSpinner isShowing={isLoading} />
            <div className="settingItemPageTitle">
                <p>全体モデル登録</p>
            </div>
            <div className="row">

                <div className="setting-container">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th />
                                <th scope="col">全体モデル</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderList}
                            <tr className="insertform">
                                <td>
                                    <input type="button" name="btnAdd" value="登録" onClick={handleAddConstructionModel} />
                                </td>
                                <td className="form-inline">
                                    <input
                                        ref={uploadButtonRef}
                                        type="file"
                                        className="form-control"
                                        name="uploadFile"
                                        id="uploadFile"
                                        hidden
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleImageChange(e)}
                                    />
                                    <input type="button" name="btnRowEdit" onClick={handleClickUploadButton} value="dwgファイル選択" />
                                    <p className="fileName">{uploadFile?.name ?? ""}</p>
                                </td>
                                <td className="form-inline">
                                    <input
                                        ref={uploadGlbFileButtonRef}
                                        type="file"
                                        className="form-control"
                                        name="glbUploadFile"
                                        id="glbUploadFile"
                                        hidden
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleGlbFileChange(e)}
                                    />
                                    <input type="button" className="ml-3" name="btnGlbFileUpload" onClick={handleClickUploadGlbButton} value="glbファイル選択" />
                                    <p className="fileName">{glbUploadFile?.name ?? ""}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>


            <div className="goHomeButtonContainer">
                <input type="button" value="戻る" onClick={() => { history.goBack() }} className="goHomeButton" />
            </div>

            <Modal isActive={showDeleteConfirmModal} onClose={() => handleDeleteCancelButtonClick()} width="30%">
                <div className="modal-body">
                    <p>削除します。よろしいですか？</p>
                </div>
                <div className="modal-footer">
                    <input type="button" onClick={handleDeleteButtonSaveClick} value="削除" />
                    <input type="button" className="ml-3" onClick={() => { handleDeleteCancelButtonClick() }} value="キャンセル" />
                </div>
            </Modal>

        </>
    );
};

export default connect((state: RootState) => ({
    constructionModelState: state.constructionModelSetting,
}))(GeneralModelSetting);
