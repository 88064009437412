import React, { useEffect, useState, ChangeEvent, useRef } from "react";
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux";
import { LocationState, Dispatcher, RootState, ILocationListAllModel, IBlockModel, ILiftModel, ILocationConstructionModel } from "AppTypes";
import { locationActions } from "./state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Modal } from "../../../components/Modal";
import { success, error } from '@services/toast-service'
import "./LocationSetting.css";
 
type Props = {
    dispatch: Dispatcher;
    locationState: LocationState;
};


const LocationSetting: React.FC<Props> = ({ locationState, dispatch }) => {

    const { dwgUploadUpdateFile, glbUploadUpdateFile, pngUploadUpdateFiles, dwgUploadFile, glbUploadFile, pngUploadFiles, blockList, liftList, constructionModelList, selectedConstructionModelId, locationList, deletingLocationId, updatingLocation, insertingLocation } = locationState;


    const history = useHistory()
    const uploadDwgFileButtonRef = useRef<HTMLInputElement>(null);
    const uploadGlbFileButtonRef = useRef<HTMLInputElement>(null);

    const uploadDwgFileUpdateButtonRef = useRef<HTMLInputElement>(null);
    const uploadGlbFileUpdateButtonRef = useRef<HTMLInputElement>(null);
    const uploadPngFilesUpdateButtonRef = useRef<HTMLInputElement>(null);

    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
    useEffect(() => {
        dispatch(locationActions.fetchBlockList());
        dispatch(locationActions.fetchLiftList());
        dispatch(locationActions.fetchConstructionModel());

    }, []);

    useEffect(() => {
        if (selectedConstructionModelId) {
            dispatch(locationActions.fetchLocationList(selectedConstructionModelId));
        }
    }, [selectedConstructionModelId]);

    const handleDwgFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget?.value && e.currentTarget.files?.[0]) {
            dispatch(locationActions.setDwgUploadFile(e.currentTarget.files?.[0]));
        }
    }

    const handleGlbFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget?.value && e.currentTarget.files?.[0]) {
            dispatch(locationActions.setGlbUploadFile(e.currentTarget.files?.[0]));
        }
    }

    const handlePngFilesChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget?.value && e.currentTarget.files?.length) {
            dispatch(locationActions.setPngUploadFiles(e.currentTarget.files));
        }
    }

    const handleGlbRowFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget?.value && e.currentTarget.files?.[0]) {
            dispatch(locationActions.setGlbUploadUpdateFile(e.currentTarget.files?.[0]));
        }
    }
    const handlePngRowFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget?.value && e.currentTarget.files?.length) {
            dispatch(locationActions.setPngUploadUpdateFiles(e.currentTarget.files));
        }
    }
    const handleDwgRowFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget?.value && e.currentTarget.files?.[0]) {
            dispatch(locationActions.setDwgUploadUpdateFile(e.currentTarget.files?.[0]));
        }
    }

    const handleClickUploadDwgButton = () => {
        if (uploadDwgFileButtonRef && uploadDwgFileButtonRef.current) {
            uploadDwgFileButtonRef.current.click();
        }

    }
    const handleClickUploadGlbButton = () => {
        if (uploadGlbFileButtonRef && uploadGlbFileButtonRef.current) {
            uploadGlbFileButtonRef.current.click();
        }

    }
    const handleClickUploadDwgUpdateButton = () => {
        if (uploadDwgFileUpdateButtonRef && uploadDwgFileUpdateButtonRef.current) {
            uploadDwgFileUpdateButtonRef.current.click();
        }

    }
    const handleClickUploadGlbUpdateButton = () => {
        if (uploadGlbFileUpdateButtonRef && uploadGlbFileUpdateButtonRef.current) {
            uploadGlbFileUpdateButtonRef.current.click();
        }

    }


    const handleAddClick = async () => {

        if (!selectedConstructionModelId) {
            error("全体モデルを選択してください。");
            return;
        }
        if (!insertingLocation.blockId) {
            error("ブロックを選択してください。");
            return;
        }
        if (!insertingLocation.liftId) {
            error("リフトを選択してください。");
            return;
        }

        if (!dwgUploadFile || !dwgUploadFile.name || dwgUploadFile?.size <= 0) {
            error("dwgファイルを選択してください。");
            return;
        }
        if (!dwgUploadFile.name.endsWith(".dwg")) {
            error("dwgファイル以外は登録できません。");
            return;
        }
        if (!glbUploadFile || !glbUploadFile.name || glbUploadFile?.size <= 0) {
            error("glbファイルを選択してください。");
            return;
        }
        if (!glbUploadFile.name.endsWith(".glb")) {
            error("glbファイル以外は登録できません。");
            return;
        }
        if (!pngUploadFiles || pngUploadFiles.length <= 0) {
            error("pngファイルを選択してください。");
            return;
        }
        for (var i = 0; i < pngUploadFiles?.length; i++) {
            if (!pngUploadFiles[i].name.endsWith(".png")) {
                error("pngファイル以外は登録できません。");
                return;
            }
        }

        //if (!selectedConstructionModelId || !insertingLocation || !insertingLocation.blockId || !insertingLocation.liftId) {
        //    error("無効なデータ");
        //    return;
        //}

        const formData = new FormData();

        // Update the formData object 
        formData.append(
            "dwgFile",
            dwgUploadFile,
            dwgUploadFile.name
        );

        // Update the formData object 
        formData.append(
            "glbFile",
            glbUploadFile,
            glbUploadFile.name
        );

        //png
        if (pngUploadFiles) {
            for (var i = 0; i < pngUploadFiles?.length; i++) {
                formData.append(
                    "pngFiles",
                    pngUploadFiles[i],
                    pngUploadFiles[i].name
                );
            }
        }

        formData.append(
            "blockId",
            insertingLocation.blockId

        );

        formData.append(
            "liftId",
            insertingLocation.liftId

        );


        formData.append(
            "constructionModelId",
            selectedConstructionModelId

        );
        try {
            const response = await dispatch(locationActions.insertLocation(formData));
            if (response.payload && response.payload.success) {
                await dispatch(locationActions.fetchLocationList(selectedConstructionModelId));
                await dispatch(locationActions.resetLocationInsert());
                success("保存が完了しました。");
            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }

    const handleUpdateButtonClick = async (id: string) => {
        await dispatch(locationActions.setUpdatingLocationId(id));
    }

    const handleUpdateCancelButtonClick = async (id: string) => {
        await dispatch(locationActions.resetLocationUpdate());

    }
    const handleDeleteCancelButtonClick = async () => {
        setShowDeleteConfirmModal(false);
        await dispatch(locationActions.setDeletingLiftId(''));

    }

    const handleUpdateButtonSaveClick = async (id: string) => {

        if (!selectedConstructionModelId) {
            error("全体モデルを選択してください。");
            return;
        }
        if (!updatingLocation.blockId) {
            error("ブロックを選択してください。");
            return;
        }
        if (!updatingLocation.liftId) {
            error("リフトを選択してください。");
            return;
        }

        //if (!dwgUploadUpdateFile || !dwgUploadUpdateFile.name || dwgUploadUpdateFile?.size <= 0 || !dwgUploadUpdateFile.name.endsWith(".dwg")) {
        //    error("dwgファイルが正しく選択されているか確認してください。");
        //    return;
        //}
        //if (!glbUploadUpdateFile || !glbUploadUpdateFile.name || glbUploadUpdateFile?.size <= 0 || !glbUploadUpdateFile.name.endsWith(".glb")) {
        //    error("glbファイルが正しく選択されているか確認してください。");
        //    return;
        //}


        //if (!selectedConstructionModelId || !updatingLocation || !updatingLocation.blockId || !updatingLocation.liftId) {
        //    error("無効なデータ");
        //    return;
        //}
 

        const formData = new FormData();

        // Update the formData object 
        //formData.append(
        //    "dwgFile",
        //    dwgUploadUpdateFile,
        //    dwgUploadUpdateFile.name
        //);

        // Update the formData object 
        //formData.append(
        //    "glbFile",
        //    glbUploadUpdateFile,
        //    glbUploadUpdateFile.name
        //);

        //png
        //if (pngUploadUpdateFiles) {
        //    for (var i = 0; i < pngUploadUpdateFiles?.length; i++) {
        //        formData.append(
        //            "pngFiles",
        //            pngUploadUpdateFiles[i],
        //            pngUploadUpdateFiles[i].name
        //        );
        //    }
        //}

        formData.append(
            "blockId",
            updatingLocation.blockId
        );

        formData.append(
            "liftId",
            updatingLocation.liftId
        );


        formData.append(
            "id",
            updatingLocation.id
        );
        try {
            const response = await dispatch(locationActions.updateLocation(formData));
            if (response.payload && response.payload.success) {
                await dispatch(locationActions.fetchLocationList(selectedConstructionModelId));
                await dispatch(locationActions.resetLocationUpdate());
                await dispatch(locationActions.clearDwgUpdateFileUpload());
                await dispatch(locationActions.clearGlbUpdateFileUpload());
                success("保存が完了しました。");
            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }
    }

    const handleDeleteButtonClick = async (id: string) => {
        await dispatch(locationActions.setDeletingLocationId(id));
        setShowDeleteConfirmModal(true);
    }


    const handleChangeInsertValue = (key: string, value: string | number) => {
        dispatch(locationActions.setInsertingtLocationData({ key, value }));
    }

    const handleChangeRowDataValue = (key: string, value: string | number) => {
        dispatch(locationActions.setUpdatingLocationData({ key, value }));
    }

    const handleChangeConstructionModel = (id: string) => {
        dispatch(locationActions.setSelectedConstructionModelId(id));
    }

    const handleDeleteConfirmButtonClick = async () => {
        const response = await dispatch(locationActions.deleteOneLocation(deletingLocationId));
        if (response.payload && response.payload.success) {
            await dispatch(locationActions.fetchLocationList(selectedConstructionModelId));
            setShowDeleteConfirmModal(false);
            success("保存が完了しました。");
        } else {
            setShowDeleteConfirmModal(false);
            if (response.payload.errors.length > 0) {
                error(response.payload.errors[0]);
            }
            else {
                error("削除できません。");
            }
        }

    }


    const renderList = locationList?.length ? (
        locationList.map((location: ILocationConstructionModel) => (
            <tr key={location.id}>
                <td>
                    {
                        location.id !== updatingLocation.id ?
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="編" onClick={() => handleUpdateButtonClick(location.id)} />
                                    <input type="button" name="btnRowDelete" value="削" onClick={() => handleDeleteButtonClick(location.id)} className="ml-3" />
                                </>
                            )
                            :
                            (
                                <>
                                    <input type="button" name="btnUpdateSave" value="更新" onClick={() => handleUpdateButtonSaveClick(location.id)} />
                                    <input type="button" name="btnUpdateCancel" value="キャンセル" onClick={() => handleUpdateCancelButtonClick(location.id)} className="ml-3" />
                                </>
                            )

                    }

                </td>

                <td>
                    {
                        location.id !== updatingLocation.id ? location.blockName :
                            (
                                <select
                                    className="form-select-office"
                                    defaultValue={updatingLocation.blockId}
                                    onBlur={(e: { currentTarget: HTMLSelectElement }) => { handleChangeRowDataValue("blockId", e.currentTarget.value) }}
                                >
                                    <option value=""></option>
                                    {blockList.length && blockList.map((p: IBlockModel) => (
                                        <option key={p.id} value={p.id} selected={p.id === updatingLocation?.blockId}>{p.name}</option>
                                    ))}
                                </select>
                            )

                    }

                </td>
                <td>
                    {
                        location.id !== updatingLocation.id ? location.liftName :
                            (
                                <select
                                    className="form-select-office"
                                    defaultValue={updatingLocation.liftId}
                                    onBlur={(e: { currentTarget: HTMLSelectElement }) => { handleChangeRowDataValue("liftId", e.currentTarget.value) }}
                                >
                                    <option value=""></option>
                                    {liftList.length && liftList.map((p: ILiftModel) => (
                                        <option key={p.id} value={p.id} selected={p.id === updatingLocation?.blockId}>{p.name}</option>
                                    ))}
                                </select>
                            )

                    }

                </td>
                <td colSpan={location.id !== updatingLocation.id ? 3 : 1}>
                    {/*{*/}
                    {/*    location.id !== updatingLocation.id ? location.code :*/}
                    {/*        (*/}
                    {/*            <input type="text" required className="form-input" value={updatingLocation.code} onChange={(e) => { handleChangeRowDataValue('code', e.target.value) }} />*/}

                    {/*        )*/}

                    {/*}*/}
                    {location.code}
                </td>
                {
                    location.id === updatingLocation.id &&
                    (
                        <td>
                            {/*<input*/}
                            {/*    ref={uploadDwgFileUpdateButtonRef}*/}
                            {/*    type="file"*/}
                            {/*    className="form-control"*/}
                            {/*    name="dwgUploadUpdateFile"*/}
                            {/*    id="dwgUploadUpdateFile"*/}
                            {/*    hidden*/}
                            {/*    onChange={(e: ChangeEvent<HTMLInputElement>) => handleDwgRowFileChange(e)}*/}
                            {/*/>*/}
                            {/*<input type="button" onClick={handleClickUploadDwgUpdateButton} value="dwgファイルを選択" />*/}
                            {/*<p style={{ color: "black" }}>{dwgUploadUpdateFile?.name ?? ""}</p>*/}
                            {/*<input*/}
                            {/*    ref={uploadGlbFileUpdateButtonRef}*/}
                            {/*    type="file"*/}
                            {/*    className="form-control"*/}
                            {/*    name="glbUploadUpdateFile"*/}
                            {/*    id="glbUploadUpdateFile"*/}
                            {/*    hidden*/}
                            {/*    onChange={(e: ChangeEvent<HTMLInputElement>) => handleGlbRowFileChange(e)}*/}
                            {/*/>*/}
                            {/*<input type="button" className="ml-3" onClick={handleClickUploadGlbUpdateButton} value="glbファイルを選択" />*/}
                            {/*<p style={{ color: "black" }}>{glbUploadUpdateFile?.name ?? ""}</p>*/}




                        </td>
                    )

                }

                {
                    location.id === updatingLocation.id &&
                    (

                        <td>
                            
                        {/*    <input*/}

                        {/*        type="file"*/}
                        {/*        className="form-control"*/}
                        {/*        name="pngUploadUpdateFiles"*/}
                        {/*        id="pngUploadUpdateFiles"*/}
                        {/*        accept="image/png"*/}
                        {/*        style={{*/}
                        {/*            width: "80%",*/}
                        {/*            height: "30px"*/}
                        {/*        }}*/}
                        {/*        multiple*/}
                        {/*        onChange={(e: ChangeEvent<HTMLInputElement>) => handlePngRowFileChange(e)}*/}
                        {/*    />*/}
                        {/*    <small>pngファイルを選択</small>*/}
                        </td>

                    )

                }




            </tr>
        ))
    ) : (
        <tr>
            <td colSpan={6}>データなし</td>
        </tr>
    );


    return (
        <>
            <LoadingSpinner isShowing={locationState.isLoading} />
            <div className="settingItemPageTitle">
                <p>打設場所モデル登録</p>
            </div>
            <div className="row">

                <div className="col-6 constructionModelSelectContainer">
                    <label htmlFor="slGeneralModel">全体モデル</label>
                {/*    <select className="form-select ml-3" id="slGeneralModel" defaultValue={selectedConstructionModelId} onChange={e => handleChangeConstructionModel(e.currentTarget.value)}>*/}
                {/*        <option value=""></option>*/}
                {/*        {constructionModelList.length && constructionModelList.map(cm => (*/}
                {/*            <option key={cm.id} value={cm.id}>{cm.name}</option>*/}
                {/*        ))}*/}
                {/*    </select>*/}
                    <select className="form-select ml-3" id="slGeneralModel" value={selectedConstructionModelId} onChange={e => handleChangeConstructionModel(e.currentTarget.value)}>
                        {constructionModelList?.length && constructionModelList?.map(cm => (
                            <option key={cm.id} value={cm.id}>{cm.name}</option>
                        ))}
                    </select>
                </div>


            </div>

            <div className="setting-container">

                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th />
                            <th scope="col">ブロック</th>
                            <th scope="col">リフト</th>
                            <th scope="col">打設場所コード</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderList}

                        <tr className="insertform">
                            <td />
                            <td className="bg-Success">
                                ブロック
                            </td>
                            <td className="bg-Success">
                                リフト
                            </td>
                            <td className="bg-Success" colSpan={2}>
                                モデル図
                            </td>

                            <td className="bg-Success">
                                pngファイル
                            </td>
                        </tr>
                        <tr className="insertform">
                            <td>
                                <input type="button" name="btnAdd" value="登録" onClick={handleAddClick} />
                            </td>
                            <td>

                                <select
                                    className="form-select-office"
                                    defaultValue={insertingLocation.blockId}
                                    onBlur={(e: { currentTarget: HTMLSelectElement }) => { handleChangeInsertValue("blockId", e.currentTarget.value) }}
                                >
                                    <option value=""></option>
                                    {blockList.length && blockList.map((p: IBlockModel) => (
                                        <option key={p.id} value={p.id} selected={p.id === insertingLocation?.blockId}>{p.name}</option>
                                    ))}
                                </select>


                            </td>
                            <td>

                                <select
                                    className="form-select-office"
                                    defaultValue={insertingLocation.liftId}
                                    onBlur={(e: { currentTarget: HTMLSelectElement }) => { handleChangeInsertValue("liftId", e.currentTarget.value) }}
                                >
                                    <option value=""></option>
                                    {liftList.length && liftList.map((p: ILiftModel) => (
                                        <option key={p.id} value={p.id} selected={p.id === insertingLocation?.liftId}>{p.name}</option>
                                    ))}
                                </select>


                            </td>
                            <td>

                                <input
                                    ref={uploadDwgFileButtonRef}
                                    type="file"
                                    className="form-control"
                                    name="dwgUploadFile"
                                    id="dwgUploadFile"
                                    hidden
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleDwgFileChange(e)}
                                />
                                <input type="button" name="btnDwgFileUpload" onClick={handleClickUploadDwgButton} value="dwgファイル選択" />
                                <p className="fileName">{dwgUploadFile?.name ?? ""}</p>

                            </td>
                            <td>
                                <input
                                    ref={uploadGlbFileButtonRef}
                                    type="file"
                                    className="form-control"
                                    name="glbUploadFile"
                                    id="glbUploadFile"
                                    hidden
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleGlbFileChange(e)}
                                />
                                <input type="button" className="ml-3" name="btnGlbFileUpload" onClick={handleClickUploadGlbButton} value="glbファイル選択" />
                                <p className="fileName">{glbUploadFile?.name ?? ""}</p>
                            </td>
                            <td>
                                <input
                                    type="file"
                                    className="form-control"
                                    name="pngUploadFiles"
                                    id="pngUploadFiles"
                                    accept="image/png"
                                    multiple
                                    style={{
                                        width: "80%",
                                        height: "30px"
                                    }}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handlePngFilesChange(e)}
                                />

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>



            <div className="goHomeButtonContainer">
                <input type="button" value="戻る" onClick={() => { history.goBack() }} className="goHomeButton" />
            </div>



            <Modal isActive={showDeleteConfirmModal} onClose={() => handleDeleteCancelButtonClick()} width="30%">
                <div className="modal-body">
                    <p>削除します。よろしいですか？</p>
                </div>
                <div className="modal-footer">
                    <input type="button" onClick={handleDeleteConfirmButtonClick} value="削除" />
                    <input type="button" className="ml-3" onClick={() => { handleDeleteCancelButtonClick() }} value="キャンセル" />
                </div>
            </Modal>
        </>
    );
};

export default connect((state: RootState) => ({
    locationState: state.location,
}))(LocationSetting);
