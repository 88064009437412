import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { TSlumpInfoManagement } from "./types";
import { ISlumpInfo, IShippingInformationBrief } from "AppTypes";

export const initialState: TSlumpInfoManagement.TState = {

    slumpInfo: {
        id: "",
        mixture: "",
        transferAmount: 0,
        transferTime: "",
        arrivalTime: "",
        slumpAmount: null,
        airAmount: null,
        conTemperature: null,
        outsideTemperature: null,
        unitWaterVolume: null,
        cloAmount: null,
        truckCode: ""
    },
    shippingInformationList: [],
    isLoading: false
};

export const fetchSlumpInfo = createAsyncThunk(
    "slumpInfo/fetchSLumpInfo",
    async (shippingInformationId: string, { rejectWithValue }) => {
        try {
            const response = await api.shippingInformationAPI.fetchSlumpInfo(shippingInformationId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateSlumpInfo = createAsyncThunk(
    "slumpInfo/updateSlumpInfo",
    async (params: ISlumpInfo, { rejectWithValue }) => {
        try {
            const response = await api.shippingInformationAPI.updateSlumpInfo(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchShippingInfoList = createAsyncThunk(
    "slumpInfo/fetchShippingInfoList",
    async (locationId: string, { rejectWithValue }) => {
        try {
            const response = await api.shippingInformationAPI.fetchListBrief({ locationId: locationId });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const slumpInfoSlice = createSlice({
    name: "slumpInfo",
    initialState,
    reducers: {

        setSlumpInfoData(state, action) {
            const { slumpInfo } = state;
            const { key, value } = action.payload;

            if (slumpInfo) {
                slumpInfo[key] = value;
            }

        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchSlumpInfo.pending,
            (state) => {
                state.isLoading = true;
            }

        );

        builder.addCase(
            fetchSlumpInfo.fulfilled,
            (
                state,
                action: PayloadAction<ISlumpInfo>
            ) => {
                state.slumpInfo = action.payload;
                state.isLoading = false;
            }
        );

        builder.addCase(
            fetchShippingInfoList.pending,
            (state) => {
                state.isLoading = true;
            }

        );

        builder.addCase(
            fetchShippingInfoList.fulfilled,
            (
                state,
                action: PayloadAction<IShippingInformationBrief[]>
            ) => {
                state.shippingInformationList = action.payload;
                state.isLoading = false;
            }
        );
    },
});

export const {
    setSlumpInfoData
} = slumpInfoSlice.actions;

export default slumpInfoSlice.reducer;
