import { createSlice } from '@reduxjs/toolkit'
import { TAppManagement } from './types'

export const initialState: TAppManagement.TState = {
    currentLocationId: "",
    dashboardChartExpanded: false,
    dashboardShipmentInfoExpaned: false
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setCurrentLocationId(state, action) {
            state.currentLocationId = action.payload;
        },
        setDashboardChartExpaned(state, action) {
            state.dashboardChartExpanded = action.payload;
        },
        setDashboardShipmentInfoExpaned(state, action) {
            state.dashboardShipmentInfoExpaned = action.payload;
        }
    },
    extraReducers: builder => {

    }
})

 export const {
     setCurrentLocationId,
     setDashboardChartExpaned,
     setDashboardShipmentInfoExpaned
 } = appSlice.actions

export default appSlice.reducer
