import { TConstructionModelSettingManagement } from '@features/setting/generalmodel/state_management/types'
import axios from '@utils/axios-client'

class ConstructionModelAPI {
    prefix = '/api/v1/ConstructionModel'

    async fetchList() {
        const url = `${this.prefix}/GetAll`;
        const response = await axios.get<{ data: TConstructionModelSettingManagement.ConstructionModel[] }>(url);
        return response.data;
    }

    async upload(dataUpload: any) {
        const url = `${this.prefix}/Upload`;

        const formData = new FormData();

        // Update the formData object 
        formData.append(
            "file",
            dataUpload.dwgFile,
            dataUpload.dwgFile.name
        );
        formData.append(
            "glbFile",
            dataUpload.glbFile,
            dataUpload.glbFile.name
        ); 

        const response = await axios.post(url, formData); 
        return response;
    }

    

    async update(params: TConstructionModelSettingManagement.ConstructionModel) {
        const url = `${this.prefix}/Update`
        return await axios.put(url, params)
    }

    async delete(id: string) {
        const url = `${this.prefix}/Delete?id=${id}`
        return await axios.delete(url)
    }
}

export const constructionModelAPI = new ConstructionModelAPI()