import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { TExportManagement } from "./types";

export const initialState: TExportManagement.TState = {

};





const exportSlice = createSlice({
    name: "export",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {

    },
});

export const {

} = exportSlice.actions;

export default exportSlice.reducer;
