import React, {forwardRef, Suspense, useEffect, useImperativeHandle, useRef, useState} from 'react';
import * as THREE from 'three';
import {Canvas, useThree, useLoader} from '@react-three/fiber'
import { ILocationListAllModel, ILocationConstructionModel, IConstructionModel, Dispatcher } from "AppTypes";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {OrbitControls, TransformControls, useGLTF, Html, useProgress, GizmoHelper, GizmoViewcube, Stage } from '@react-three/drei'
import {proxy, useSnapshot} from 'valtio'
import {blocks} from './mock/data'
import "./3DEditor.css"
// Reactive state model, using Valtio ...
const modes = ['translate']
const state = proxy({current: null, mode: 0})
export const cameraControlOptions: any = {
  zoomStep: 0.2,
  zoomMin: 0.2,
  zoomMax: 4,
  moveStep: 0.2,
  rotationStep: Math.PI / 25,
  defaultOptions: {
    position: [0, 0, 0],
    fov: 40,
    rotation: [0, 0, 0],
    zoom: 1
  }
};

const faces = ['右', '左', '上', '下', '前', '後']
export const args = {
  alignment: 'bottom-right',
  color: '#fdd867',
  colorX: 'red',
  colorY: 'green',
  colorZ: 'blue',
  controls: 'OrbitControls',
  faces,
  gizmo: 'GizmoViewcube',
  hideNegativeAxes: false,
  hoverColor: 'lightgray',
  labelColor: 'red',
  marginX: 80,
  marginY: 80,
  opacity: 1,
  strokeColor: '#020101',
  textColor: '#000000',
  font: '40px Arial',
}

function Controls() {
// Get notified on changes to state
  const snap = useSnapshot(state)
  const scene = useThree((state) => state.scene)
  return (
    <>
      {/* As of drei@7.13 transform-controls can refer to the target by children, or the object prop */}
      {/* {snap.current && <TransformControls object={scene.getObjectByName(snap.current)} mode={modes[snap.mode]}/>} */}
      {/* makeDefault makes the controls known to r3f, now transform-controls can auto-disable them when active */}
      <OrbitControls makeDefault minPolarAngle={0} maxPolarAngle={Math.PI / 1.75}/>
    </>
  )
}

function Loader() {
  const { progress } = useProgress();
  return <Html center>{progress} %</Html>;
}

type IModelGLB = {
  locationItem: any,
  position: [number, number, number] | [string, string, string],
  isChangeColor?: boolean,
}

export const ModelGLB = ({ locationItem, position, isChangeColor }: IModelGLB) => {
  if (locationItem?.glbFilePath !== '' && locationItem?.glbFilePath !== undefined) {
    const gltf = useLoader(GLTFLoader, `${process.env.REACT_APP_GLB_ENDPOINT}${locationItem.glbFilePath}`);
    gltf.scene.traverse(( object ) => {
      /* @ts-ignore */
      if (object.isMesh) {
          /* @ts-ignore */
        object.material.transparent = true;
          /* @ts-ignore */
        object.material.opacity = 0.7;
        if (isChangeColor) {
          /* @ts-ignore */
          object.material.color.set("SaddleBrown")
        } else {
          /* @ts-ignore */
          object.material.color.set("gray")
        }
      }
    });
    return (
      <Suspense fallback={null}>
        <primitive object={gltf.scene} />
      </Suspense>
    )
  } else {
    return null
  }
};

export const ModelGLBAll = ({ locationItem }: IModelGLB) => {
  if (locationItem?.glbFilePath !== '' && locationItem?.glbFilePath !== undefined) {
    const gltf = useLoader(GLTFLoader, `${process.env.REACT_APP_GLB_ENDPOINT}${locationItem.glbFilePath}`);
    gltf.scene.traverse(( object ) => {
      /* @ts-ignore */
      if (object.isMesh) {
          /* @ts-ignore */
        object.material.transparent = true;
          /* @ts-ignore */
        object.material.opacity = 0.3;
      }
    });
    return (
      <Suspense fallback={null}>
        <primitive object={gltf.scene} />
      </Suspense>
    )
  } else {
    return null
  }
};

type ThreeDEditorProps = {
  locationListData: any[];
  showSuccessBlocks: () => void;
  showAllBlocks: () => void;
  constructionModelList: IConstructionModel[];
  selectedConstructionModelId: string;
  locationSelectorState: any,
}

export const CustomControls = forwardRef((props, ref) => {
  const {scene, camera} = useThree();
  const [initScene, setScene] = useState<any>();
  useEffect(() => {
    const backup = scene.clone(false);
    setScene(backup);
  }, [])
  useImperativeHandle(ref, () => (
    {
      zoomIn() {
        camera.zoom = (camera.zoom + cameraControlOptions.zoomStep) > cameraControlOptions.zoomMax ? cameraControlOptions.zoomMax : (camera.zoom + cameraControlOptions.zoomStep);
        camera.updateProjectionMatrix();
      },
      zoomOut() {
        camera.zoom = (camera.zoom - cameraControlOptions.zoomStep) < cameraControlOptions.zoomMin ? cameraControlOptions.zoomMin : (camera.zoom - cameraControlOptions.zoomStep);
        camera.updateProjectionMatrix();
      },
      // rotateLeft() {
      //   scene.rotation.y = scene.rotation.y - cameraControlOptions.rotationStep;
      // },
      // rotateUpwards() {
      //   scene.rotation.x = scene.rotation.x - cameraControlOptions.rotationStep;
      // },
      // rotateDownwards() {
      //   scene.rotation.x = scene.rotation.x + cameraControlOptions.rotationStep;
      // },
      // rotateRight() {
      //   scene.rotation.y = scene.rotation.y + cameraControlOptions.rotationStep;
      // },
      move(type: 'up' | 'down' | 'right' | 'left'){
        const {x, y , z} = scene.position;
        switch (type){
          case 'up':
            scene.position.setY(y + cameraControlOptions.moveStep);
            break;
          case 'down':
            scene.position.setY(y - cameraControlOptions.moveStep);
            break;
          case 'left':
            scene.position.setZ(z + cameraControlOptions.moveStep);
            break;
          case 'right':
            scene.position.setZ(z - cameraControlOptions.moveStep);
            break;
          default:
            break;
        }
        scene.updateMatrix();
      },
      reset() {
        camera.zoom = cameraControlOptions.defaultOptions.zoom;
        const [rX, rY, rZ] = cameraControlOptions.defaultOptions.rotation;
        scene.rotation.set(rX, rY, rZ);
        camera.updateProjectionMatrix();
      }
    }
  ))
  return <></>;
})

const ThreeDEditor = ({locationListData, showSuccessBlocks, showAllBlocks, constructionModelList, selectedConstructionModelId, locationSelectorState}: ThreeDEditorProps) => {
  const customControlRef: any = useRef();
  const ref: any = useRef();
  const [isShowGeneralMode, setShowGeneralMode] = useState(true);
  const reset = () => {
    customControlRef.current?.reset();
    showAllBlocks();
  }
  const [isShowModel, setShowModel] = useState(selectedConstructionModelId !== undefined);
  useEffect(
    () => {
      const timer = setTimeout(() => {
        setShowModel(false);
      }, 150);
      return () => clearTimeout(timer);
    },
    [isShowModel]
  )

  const itemModel = constructionModelList.find(item => item.id === selectedConstructionModelId);

  return (
    <div className="three-3d-editor-container">
      <Canvas className="view-3d-block" dpr={[1, 2]} camera={{ fov: 50 }}>
        <Suspense fallback={<Loader />}>
          <Stage controls={ref} contactShadow={{ blur: 1, opacity: 0, position: [0, 0, 0] }} intensity={1} environment={null} preset="rembrandt">
            <group>
              <pointLight position={[10, 10, 10]} intensity={0.8}/>
              <hemisphereLight color="#A6A7A5" groundColor="#696B67" position={[4, 1, 6]} intensity={0.85}/>
                {!isShowModel && isShowGeneralMode && itemModel && itemModel.glbFilePath && Number(itemModel?.minPoint?.split(',')[0]) !== 0 && (
                  <ModelGLBAll
                    locationItem={itemModel}
                    position={[
                      Number(itemModel?.minPoint?.split(',')[0] || 0),
                      Number(itemModel?.minPoint?.split(',')[2] || 0),
                      Number(itemModel?.minPoint?.split(',')[1] || 0),
                    ]}
                  />
                )}
                {!isShowModel && locationListData.map(item =>
                  (item?.glbFilePath !== '' && item.glbFilePath !== undefined && item.isActive && Number(itemModel?.minPoint?.split(',')[0]) !== 0) && (
                    <ModelGLB
                      key={item.id}
                      locationItem={item}
                      position={[
                        Number(item?.minPoint?.split(',')[0] || 0),
                        Number(item?.minPoint?.split(',')[2] || 0),
                        Number(item?.minPoint?.split(',')[1] || 0),
                      ]}
                      isChangeColor={locationSelectorState?.activeLocationTable === item.id}
                    />
                  )
                )}
            </group>
          </Stage>
          <GizmoHelper alignment="bottom-right" margin={[args.marginX, args.marginY]}>
            <GizmoViewcube
              {...args}
              onClick={() => null}
            />
          </GizmoHelper>
        </Suspense>
        <Controls/>
        <CustomControls ref={customControlRef}/>
      </Canvas>
      <div className="three-3d-editor-panel">
        <div className="controls">
          <div className="control">
            <div className="default-action" onClick={() => {
              reset();
              setShowModel(true);
            }}>
              リセット
            </div>
          </div>
          <div className="control">
            <div className="zoom-actions">
              <div className="action in" onClick={customControlRef.current?.zoomIn}>
                <i className="fa fa-plus"></i>
              </div>
              <div className="action out" onClick={customControlRef.current?.zoomOut}>
                <i className="fa fa-minus"></i>
              </div>
            </div>
          </div>
          <div className="control">
            <div className="default-action" onClick={() => {
              customControlRef.current?.reset();
              showSuccessBlocks();
            }}>
              実績
            </div>
          </div>
          <div className="control">
            <div className="default-action" onClick={() => {
              customControlRef.current?.reset();
              setShowGeneralMode(!isShowGeneralMode)
            }}>
              全体
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThreeDEditor;
