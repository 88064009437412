import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { QUERY_PAGING } from "@share/const";
import { TFactoryManagement } from "./state_management/types";
import { connect } from "react-redux";
import { FactoryState, Dispatcher, RootState } from "AppTypes";
import { factoryActions } from "./state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Modal } from "../../../components/Modal";
import { success, error } from '@services/toast-service'
import { VALIDATE_NUMBER } from "@const/constants";

type Props = {
    dispatch: Dispatcher;
    factoryState: FactoryState;
};


const FactoryList: React.FC<Props> = ({ factoryState, dispatch }) => {
    const { data } = factoryState.factoryList;
    const { detail } = factoryState;
    const { insert } = factoryState;
    const history = useHistory()
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
    useEffect(() => {
        dispatch(factoryActions.fetchList(QUERY_PAGING));
    }, []);


    const handleAddFactoryClick = async () => {
        if ((insert.data.name && insert.data.name.length > 20) || !insert.data.name) {
            error("出荷工場名は20文字以下で入力してください。");
            return;
        }
        if ((insert.data.symbol && insert.data.symbol.length > 2) || !insert.data.symbol) {
            error("記号は2文字以下で入力してください。");
            return;
        }
        if ((insert.data.transportTime && insert.data.transportTime > 9999) ||
            !insert.data.transportTime ||
            (insert.data.transportTime && !VALIDATE_NUMBER.test(insert.data.transportTime.toString()))
        ) {
            error("輸送時間は4桁以下の数値で入力してください。");
            return;
        }
        try {
            const { name, symbol, transportTime, isActive } = insert.data;

            const response = await dispatch(factoryActions.insert({ id: null, name, symbol, transportTime, isActive, code: ""}));
            if (response.payload && response.payload.success) {
                await dispatch(factoryActions.fetchList(QUERY_PAGING));
                await dispatch(factoryActions.resetInsert({}));
                success("保存が完了しました。");
            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }
    const handleUpdateButtonClick = async (id: string) => {
        await dispatch(factoryActions.setUpdatingId(id));
    }

    const handleUpdateCancelButtonClick = async (id: string) => {
        await dispatch(factoryActions.setUpdatingId(''));

    }
    const handleDeleteCancelButtonClick = async () => {
        setShowDeleteConfirmModal(false);
        await dispatch(factoryActions.setDeletingId(''));

    }


    const handleUpdateButtonSaveClick = async (id: string) => {
        if ((detail.data.name && detail.data.name.length > 20) || !detail.data.name) {
            error("出荷工場名は20文字以下で入力してください。");
            return;
        }
        if ((detail.data.symbol && detail.data.symbol.length > 2) || !detail.data.symbol) {
            error("記号は2文字以下で入力してください。");
            return;
        }
        if ((detail.data.transportTime && detail.data.transportTime > 9999) ||
            !detail.data.transportTime ||
            (detail.data.transportTime && !VALIDATE_NUMBER.test(detail.data.transportTime.toString()))
        ) {
            error("輸送時間は4桁以下の数値で入力してください。");
            return;
        }
        try {
            const response = await dispatch(factoryActions.update(detail.data));
            if (response.payload && response.payload.success) {
                await dispatch(factoryActions.fetchList(QUERY_PAGING));
                await dispatch(factoryActions.resetDetail({}));
                success("保存が完了しました。");
                await dispatch(factoryActions.setUpdatingId(''));

            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }

    const handleDeleteButtonClick = async (id: string) => {
        await dispatch(factoryActions.setDeletingId(id));
        setShowDeleteConfirmModal(true);
    }

    const handleChangeDetailValue = (key: string, value: string | number) => {
        dispatch(factoryActions.setInsertData({ key, value }));
    }

    const handleChangeRowDataValue = (key: string, value: string | number) => {
        dispatch(factoryActions.setUpdatingData({ key, value }));
    }

    const handleChangeInsertItemIsActive = async (checkbox: HTMLInputElement) => {
        dispatch(factoryActions.setInsertData({ key: 'isActive', value: checkbox.checked }));
    }

    const handleChangeUpdatingItemIsActive = async (checkbox: HTMLInputElement) => {
        dispatch(factoryActions.setUpdatingData({ key: 'isActive', value: checkbox.checked }));
    }

    const handleDeleteConfirmButtonClick = async () => {
        const response = await dispatch(factoryActions.deleteOne(factoryState.deletingId));
        if (response.payload && response.payload.success) {
            await dispatch(factoryActions.fetchList(QUERY_PAGING));
            setShowDeleteConfirmModal(false);
            success("保存が完了しました。");
        } else {
            error(response.payload.errors[0])
        }


    }


    const renderList = data?.length ? (
        data.map((factory: TFactoryManagement.Factory) => (
            <tr key={factory.id}>
                <td>
                    {
                        factory.id !== factoryState.updatingId ?
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="編" onClick={() => handleUpdateButtonClick(factory.id)} />
                                    <input type="button" name="btnRowDelete" value="削" onClick={() => handleDeleteButtonClick(factory.id)} className="ml-3" />
                                </>
                            )
                            :
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="更新" onClick={() => handleUpdateButtonSaveClick(factory.id)} />
                                    <input type="button" name="btnRowDelete" value="キャンセル" onClick={() => handleUpdateCancelButtonClick(factory.id)} className="ml-3" />
                                </>
                            )

                    }

                </td>
                <td>{factory.code}</td>
                <td>
                    {
                        factory.id !== factoryState.updatingId ? factory.name :
                            (<input type="text" required className="form-input" value={detail.data.name} onChange={(e) => { handleChangeRowDataValue('name', e.target.value) }} />)

                    }

                </td>
                <td>
                    {
                        factory.id !== factoryState.updatingId ? factory.symbol :
                            (<input type="text" required className="form-input" value={detail.data.symbol} onChange={(e) => { handleChangeRowDataValue('symbol', e.target.value) }} />)

                    }
                </td>
                <td>
                    {
                        factory.id !== factoryState.updatingId ? factory.transportTime :
                            (<input type="number" required className="form-input" value={detail.data.transportTime ? `${detail.data.transportTime}` : ''} onChange={(e) => { handleChangeRowDataValue('transportTime', parseInt(e.target.value)) }} />)

                    }
                </td>
                <td>
                    {
                        factory.id !== factoryState.updatingId ? (factory.isActive ? "有効" : "無効") :
                            (<input type="checkbox" className="form-input" checked={detail.data.isActive} onChange={({ currentTarget }) => handleChangeUpdatingItemIsActive(currentTarget)} />)

                    }


                </td>
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan={6}>データなし</td>
        </tr>
    );
    return (
        <>
            <LoadingSpinner isShowing={factoryState.isLoading} />
            <div className="settingItemPageTitle">
                <p>出荷工場設定</p>
            </div>
            <div className="content setting-container">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th />
                            <th scope="col">出荷工場コード</th>
                            <th scope="col">出荷工場名</th>
                            <th scope="col">記号</th>
                            <th scope="col">輸送時間（分）</th>
                            <th scope="col">状態</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderList}
                        <tr className="insertform">
                            <td>
                                <input type="button" name="btnAdd" value="追加" onClick={handleAddFactoryClick} />
                            </td>
                            <td />
                            <td><input type="text" className="form-input" value={insert.data.name} onChange={(e) => { handleChangeDetailValue('name', e.target.value) }} /></td>
                            <td><input type="text" className="form-input" value={insert.data.symbol} onChange={(e) => { handleChangeDetailValue('symbol', e.target.value) }} /></td>
                            <td><input type="number" className="form-input" value={insert.data.transportTime ? `${insert.data.transportTime}` : ''} onChange={(e) => { handleChangeDetailValue('transportTime', parseInt(e.target.value)) }} /></td>
                            <td>
                                <input type="checkbox" className="form-input" checked={insert.data.isActive} onChange={({ currentTarget }) => handleChangeInsertItemIsActive(currentTarget)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="goHomeButtonContainer">
                <input type="button" value="戻る" onClick={() => { history.goBack() } } className="goHomeButton" />
            </div>

            <Modal isActive={showDeleteConfirmModal} onClose={() => handleDeleteCancelButtonClick()} width="30%">
                <div className="modal-body">
                    <p>削除します。よろしいですか？</p>
                </div>
                <div className="modal-footer">
                    <input type="button" onClick={handleDeleteConfirmButtonClick} value="削除" />
                    <input type="button" className="ml-3" onClick={() => { handleDeleteCancelButtonClick() }} value="キャンセル" />
                </div>
            </Modal>
        </>
    );
};

export default connect((state: RootState) => ({
    factoryState: state.factory,
}))(FactoryList);
