import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { QUERY_PAGING } from "@share/const";
import { TMixtureManagement } from "./state_management/types";
import { connect } from "react-redux";
import { MixtureState, Dispatcher, RootState } from "AppTypes";
import { mixtureActions } from "./state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Modal } from "../../../components/Modal";
import { success, error } from '@services/toast-service'

type Props = {
    dispatch: Dispatcher;
    mixtureState: MixtureState;
};


const MixtureList: React.FC<Props> = ({ mixtureState, dispatch }) => {
    const { data } = mixtureState.mixtureList;
    const { detail } = mixtureState;
    const { insert } = mixtureState;
    const history = useHistory()
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
    useEffect(() => {
        dispatch(mixtureActions.fetchList(QUERY_PAGING));
    }, []);

    const pattern = /^\d+$/;
    const handleAddMixtureClick = async () => {

        const { data } = insert;
        const pattern = /^\d+$/;

        if (!data.code) {
            error("配合コードを入力してください。");
            return;
        } else if (data.code.length > 5) {

            error("配合コードは5桁以下の数値で入力してください。");
            return;
        } else if (!pattern.test(data.code)) {
            error("配合コードは数値で入力してください。");
            return;
        }

        if (!data.name1) {
            error("配合名1を入力してください");
            return;
        }
        if (data.name1.length > 20) {
            error("配合名1 は20文字以下で入力してください。");
            return;
        }
        if (data.name2 &&data.name2.length > 20) {
            error("配合名2 は20文字以下で入力してください。");
            return;
        }


        try {
            const response = await dispatch(mixtureActions.insert(data));
            if (response.payload && response.payload.success) {
                await dispatch(mixtureActions.fetchList(QUERY_PAGING));
                await dispatch(mixtureActions.resetInsert({}));
                success("保存が完了しました。");
            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }
    const handleUpdateButtonClick = async (id: string) => {
        await dispatch(mixtureActions.setUpdatingId(id));
    }

    const handleUpdateCancelButtonClick = async (id: string) => {
        await dispatch(mixtureActions.setUpdatingId(''));

    }
    const handleDeleteCancelButtonClick = async () => {
        setShowDeleteConfirmModal(false);
        await dispatch(mixtureActions.setDeletingId(''));

    }


    const handleUpdateButtonSaveClick = async (id: string) => {

        const { data } = detail;
       
        if (!data.code) {
            error("配合コードを入力してください");
            return;
        } else if (data.code.length > 5) {

            error("配合コードは5桁以下の数値で入力してください。");
            return;
        } else if (!pattern.test(data.code)) {
            error("配合コードは数値で入力してください。");
            return;
        }


        if (!data.name1) {
            error("配合名1を入力してください");
            return;
        }
        if (data.name1.length > 20) {
            error("配合名1 は20文字以下で入力してください。");
            return;
        }
        if (data.name2 && data.name2.length > 20) {
            error("配合名2 は20文字以下で入力してください。");
            return;
        }


        try {
            const response = await dispatch(mixtureActions.update(data));
            if (response.payload && response.payload.success) {
                await dispatch(mixtureActions.fetchList(QUERY_PAGING));
                await dispatch(mixtureActions.resetDetail({}));
                success("保存が完了しました。");
                await dispatch(mixtureActions.setUpdatingId(''));

            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }

    const handleDeleteButtonClick = async (id: string) => {
        await dispatch(mixtureActions.setDeletingId(id));
        setShowDeleteConfirmModal(true);
    }

    const handleChangeDetailValue = (key: string, value: string | number) => {
        dispatch(mixtureActions.setInsertData({ key, value }));
    }

    const handleChangeRowDataValue = (key: string, value: string | number) => {
        dispatch(mixtureActions.setUpdatingData({ key, value }));
    }

    const handleChangeInsertItemIsActive = async (checkbox: HTMLInputElement) => {
        dispatch(mixtureActions.setInsertData({ key: 'isActive', value: checkbox.checked }));
    }

    const handleChangeUpdatingItemIsActive = async (checkbox: HTMLInputElement) => {
        dispatch(mixtureActions.setUpdatingData({ key: 'isActive', value: checkbox.checked }));
    }

    const handleDeleteConfirmButtonClick = async () => {
        const response = await dispatch(mixtureActions.deleteOne(mixtureState.deletingId));
        if (response.payload && response.payload.success) {
            await dispatch(mixtureActions.fetchList(QUERY_PAGING));
            setShowDeleteConfirmModal(false);
            success("保存が完了しました。");
        } else {
            error(response.payload.errors[0])
        }


    }


    const renderList = data?.length ? (
        data.map((mixture: TMixtureManagement.Mixture) => (
            <tr key={mixture.id}>
                <td>
                    {
                        mixture.id !== mixtureState.updatingId ?
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="編" onClick={() => handleUpdateButtonClick(mixture.id)} />
                                    <input type="button" name="btnRowDelete" value="削" onClick={() => handleDeleteButtonClick(mixture.id)} className="ml-3" />
                                </>
                            )
                            :
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="更新" onClick={() => handleUpdateButtonSaveClick(mixture.id)} />
                                    <input type="button" name="btnRowDelete" value="キャンセル" onClick={() => handleUpdateCancelButtonClick(mixture.id)} className="ml-3" />
                                </>
                            )

                    }

                </td>
                <td>{mixture.code}</td>
                <td>
                    {
                        mixture.id !== mixtureState.updatingId ? mixture.name1 :
                            (<input type="text" required className="form-input" value={detail.data.name1} onChange={(e) => { handleChangeRowDataValue('name1', e.target.value) }} />)

                    }

                </td>
                <td>
                    {
                        mixture.id !== mixtureState.updatingId ? mixture.name2 :
                            (<input type="text" required className="form-input" value={detail.data.name2} onChange={(e) => { handleChangeRowDataValue('name2', e.target.value) }} />)

                    }
                </td>
                <td>
                    {
                        mixture.id !== mixtureState.updatingId ? (mixture.isActive ? "有効" : "無効") :
                            (<input type="checkbox" className="form-input" checked={detail.data.isActive} onChange={({ currentTarget }) => handleChangeUpdatingItemIsActive(currentTarget)} />)

                    }


                </td>
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan={6}>データなし</td>
        </tr>
    );
    return (
        <>
            <LoadingSpinner isShowing={mixtureState.isLoading} />
            <div className="settingItemPageTitle">
                <p>配合設定</p>
            </div>
            <div className="content setting-container">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th />
                            <th scope="col">配合コード</th>
                            <th scope="col">配合名1</th>
                            <th scope="col">配合名2</th>
                            <th scope="col">状態</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderList}
                        <tr className="insertform">
                            <td>
                                <input type="button" name="btnAdd" value="追加" onClick={handleAddMixtureClick} />
                            </td>
                            <td>
                                <input type="text" required className="form-input" value={insert.data.code} onChange={(e) => { handleChangeDetailValue('code', e.target.value) }} />
                            </td>
                            <td><input type="text" className="form-input" value={insert.data.name1} onChange={(e) => { handleChangeDetailValue('name1', e.target.value) }} /></td>
                            <td><input type="text" className="form-input" value={insert.data.name2} onChange={(e) => { handleChangeDetailValue('name2', e.target.value) }} /></td>
                            <td>
                                <input type="checkbox" className="form-input" checked={insert.data.isActive} onChange={({ currentTarget }) => handleChangeInsertItemIsActive(currentTarget)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="goHomeButtonContainer">
                <input type="button" value="戻る" onClick={() => { history.goBack() }} className="goHomeButton" />
            </div>

            <Modal isActive={showDeleteConfirmModal} onClose={() => handleDeleteCancelButtonClick()} width="30%">
                <div className="modal-body">
                    <p>削除します。よろしいですか？</p>
                </div>
                <div className="modal-footer">
                    <input type="button" onClick={handleDeleteConfirmButtonClick} value="削除" />
                    <input type="button" className="ml-3" onClick={() => { handleDeleteCancelButtonClick() }} value="キャンセル" />
                </div>
            </Modal>
        </>
    );
};

export default connect((state: RootState) => ({
    mixtureState: state.mixture,
}))(MixtureList);
