import axios, { AxiosRequestConfig } from 'axios'

import { isJwtTokenExpired } from '@utils/jwt-token'

const excludedAuthHeaderParts = [
    '/api/v1/Authentication/Login',
    '/api/v1/Authentication/RefreshToken',
]

const shouldIncludesAuthToken = (url: string | undefined): boolean => {
    let result = true

    if (url && url.length)
        excludedAuthHeaderParts.forEach(part => {
            if (url.startsWith(part)) result = false
        })

    return result
}

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
    headers: { "Content-Type": "application/json" },
    timeout: 30000
})

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
          prom.reject(error);
      } else {
          prom.resolve(token);
      }
    });

    failedQueue = [];
};

axiosInstance.interceptors.response.use(
    response => response,
    async error => {
        const { status } = error.response
        const originalRequest = error.config;

        if (status === 401 && !originalRequest._retry) {
          if (isRefreshing) {
            return new Promise(function(resolve, reject) {
                failedQueue.push({ resolve, reject });
            })
              .then(token => {
                  originalRequest.headers['Authorization'] = 'Bearer ' + token;
                  return axios(originalRequest);
              })
              .catch(err => {
                  return Promise.reject(err);
              });
          }

          originalRequest._retry = true;
          isRefreshing = true;

          return new Promise(function(resolve, reject) {
            axiosInstance
              .post('/api/v1/User/refreshToken', null, {
                headers: {
                  refreshToken: localStorage.getItem('refreshToken')}
                })
              .then(({ data }) => {
                if (data.success) {
                  const jwtToken = data.data.jwtToken
                  localStorage.setItem('jwtToken', jwtToken)
                  if (data.data.refreshToken) {
                    localStorage.setItem('refreshToken', data.data.refreshToken)
                  }
                  axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken;
                  originalRequest.headers['Authorization'] = 'Bearer ' + jwtToken;
                  processQueue(null, jwtToken);
                  resolve(axios(originalRequest));
                } else {
                  localStorage.clear()
                  window.location.replace('/login')
                }
              })
              .catch(err => {
                processQueue(err, null);
                reject(err);
                localStorage.clear()
                window.location.replace('/login')
              })
              .then(() => {
                isRefreshing = false;
              });
          });
      }

      return Promise.reject(error)
    }
)

/* eslint no-debugger: "off" */
/* eslint no-console: "off" */
axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      config.timeout = 10000;
        if (shouldIncludesAuthToken(config.url)) {
          let jwtToken: string = localStorage.getItem('jwtToken') ?? ''

          if (jwtToken && !isJwtTokenExpired(jwtToken)) {
            config.headers.Authorization = `Bearer ${jwtToken}`
          }
        }

        return config
    },
    (error: any) => {
      Promise.reject(error)
    }
)

export default axiosInstance
