import { TShipmentManagement } from '@features/shipment/registration/state_management/types'
import axios from '@utils/axios-client'

class MeshAPI {
    prefix = '/api/v1/Mesh';
    prefixLocation = '/api/v1/Location'

    async fetchList() {
        const url = `${this.prefix}/GetAll`;
        const response = await axios.get(url)
        return response.data;
    }

    async siteUpdate(params: any) {
        const url = `${this.prefix}/SiteUpdate`
        return await axios.put(url, params)
    }

    async resetBeforePumping(params: any) {
        const url = `${this.prefix}/resetBeforePumping`
        return await axios.put(url, params)
    }

    async siteUpdateList(params: any) {
        const url = `${this.prefix}/SiteUpdateList`
        return await axios.put(url, params)
    }

    async revertByPump(pumpId: string) {
        const url = `${this.prefix}/RevertByPump?pumpId=${pumpId}`
        return await axios.put(url)
    }

    async locationUpdateStatus(params: any) {
        const url = `${this.prefixLocation}/UpdateStatus`
        return await axios.put(url, params)
    }
}

export const meshAPI = new MeshAPI()