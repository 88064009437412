export const STATUS_MESH = [
  'BeforePumping',
  'DuringPumping',
  'FinishPumping',
]

export const WARNING_STATUS = [
  'WarningTimeExceeded',
  'TimeLimitExceeded'
]

export const CASTING_HEIGHT = [
  10,
  20
]

export const VALIDATE_ALPHANUM = /^[A-Za-z0-9]*$/i

export const VALIDATE_ALPHANUM_FULLWIDTH = /^[A-Za-z0-9０-９]*$/i

export const VALIDATE_FLOAT_TEMPERATURE = /^[-]?([0-9]{0,2})(\.[0-9]{0,2})?$/i

export const VALIDATE_NUMBER = /^[0-9]*$/i

export const VALIDATE_FLOAT = /^[0-9.-]*$/i

export const TYPE_MESSAGE_SOCKET = [
  'Mesh',
  'ShippingInformation',
  'OrderDetail',
  'Pump',
];

export const settingShippingDefault = [
  'ScheduledTransferStartTime', // 出荷予定時刻
  'PumpCode', // 	ポンプ車
  'FactoryName', // 出荷工場
  'TruckCode', // 車両番号
  'Mixture', // 配合
  'IsExperimented', // 試験
  'TransferAmount', // 	出荷量
  'CommulativeTransferAmount', // 出荷量累計
  'TransferTime', // 出荷時刻
  'ArrivalTime', // 現場到着
  'PumpStartTime', // 荷卸開始
  'PumpFinishTime', // 荷卸終了
  'ShipmentPumpStartTime', // 出荷-荷卸開始
  'ShipmentPumpFinishTime', // 出荷-荷卸終了
  'IsActive', // 	キャンセル
  'SlumpAmount', // 	スランプ
  'AirAmount', // 	空気量
  'ConTemperature', // Con温度
  'OutsideTemperature', // 外気温
  'UnitWaterVolume', // 単位水量
  'CloAmount', // 塩化物量
  'IsCompressiveExperimented', // 圧縮強度No
];

export const settingCastingDefault = [
  'Code', // メッシュ番号
  'Status', // 	状態
  'RemainingTime', // 残り時間
  'PumpName', // ポンプ車
  'PumpStartTime', // 打設開始時刻
  'PumpFinishTime', // 打設終了時刻
  'OverlapTime', // 打ち重ね時間
  'MixtureName', // 配合
  'MixerNos', // 荷卸車両
]
