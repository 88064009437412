import axios from '@utils/axios-client'
import { IMeshSetting, IWarningTimeSetting } from 'AppTypes';

class MeshSettingAPI {
    prefix = '/api/v1/MeshSetting'

    async fetch() {
        const url = `${this.prefix}/GetAll`;
        return await axios.get<{ data: IMeshSetting[] }>(url)
    }

    async updateRange(params: IMeshSetting[]) {

        const postedData = { items: params.map(x => ({ id: x.id, transferTime: x.transferTime })) };
        const url = `${this.prefix}/UpdateRange`
        return await axios.put(url, postedData)
    }

    
}

export const meshSettingAPI = new MeshSettingAPI()