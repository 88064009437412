import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { TLocationManagement } from "./state_management/types";
import { connect } from "react-redux";
import { LocationState, Dispatcher, RootState } from "AppTypes";
import { locationActions } from "./state_management/actions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Modal } from "../../../components/Modal";
import { success, error } from '@services/toast-service'


type Props = {
    dispatch: Dispatcher;
    locationState: LocationState;
};


const BlockLiftSetting: React.FC<Props> = ({ locationState, dispatch }) => {

    const { blockList, blockInsert, blockUpdate, liftList, liftUpdate, liftInsert, isLoading, blockDeletingId, liftDeletingId } = locationState;


    const history = useHistory()
    const [showDeleteBlockConfirmModal, setShowDeleteBlockConfirmModal] = useState(false)
    const [showDeleteLiftConfirmModal, setShowDeleteLiftConfirmModal] = useState(false)
    useEffect(() => {
        dispatch(locationActions.fetchBlockList());
        dispatch(locationActions.fetchLiftList());

    }, []);


    const handleAddBlockClick = async () => {
        try {
            if (blockInsert.name === '') {
                error("ブロック名を入力してください。");
                return;
            }
            if (blockInsert.name !== '' && blockInsert.name.length > 10) {
                error("ブロック名は10文字以下で入力してください。");
                return;
            }
            const response = await dispatch(locationActions.insertBlock(blockInsert));
            if (response.payload && response.payload.success) {
                await dispatch(locationActions.fetchBlockList());
                await dispatch(locationActions.resetBlockInsert());
                success("保存が完了しました。");
            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }
    const handleUpdateBlockButtonClick = async (id: string) => {
        await dispatch(locationActions.setUpdatingBlockId(id));
    }

    const handleUpdateBlockCancelButtonClick = async (id: string) => {
        await dispatch(locationActions.setUpdatingBlockId(''));

    }
    const handleDeleteBlockCancelButtonClick = async () => {
        setShowDeleteBlockConfirmModal(false);
        await dispatch(locationActions.setDeletingBlockId(''));

    }


    const handleUpdateBlockButtonSaveClick = async (id: string) => {
        try {
            if (blockUpdate.name === '') {
                error("ブロック名を入力してください。");
                return;
            }
            if (blockUpdate.name !== '' && blockUpdate.name.length > 10) {
                error("ブロック名は10文字以下で入力してください。");
                return;
            }
            const response = await dispatch(locationActions.updateBlock(blockUpdate));
            if (response.payload && response.payload.success) {
                await dispatch(locationActions.fetchBlockList());
                await dispatch(locationActions.resetBlockUpdate());
                success("保存が完了しました。");
                await dispatch(locationActions.setUpdatingBlockId(''));

            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }

    const handleDeleteBlockButtonClick = async (id: string) => {
        await dispatch(locationActions.setDeletingBlockId(id));
        setShowDeleteBlockConfirmModal(true);
    }

    const handleChangeBlockInsertValue = (key: string, value: string | number) => {
        dispatch(locationActions.setInsertBlockData({ key, value }));
    }

    const handleChangeBlockRowDataValue = (key: string, value: string | number) => {
        dispatch(locationActions.setUpdatingBlockData({ key, value }));
    }


    const handleDeleteBlockConfirmButtonClick = async () => {
        const response = await dispatch(locationActions.deleteOneBlock(blockDeletingId));
        if (response.payload && response.payload.success) {
            await dispatch(locationActions.fetchBlockList());
            setShowDeleteBlockConfirmModal(false);
            success("保存が完了しました。");
        } else {
            error(response.payload.errors[0])
        }
    }

    const renderBlockList = blockList?.length ? (
        blockList.map((block: TLocationManagement.BlockModel) => (
            <tr key={block.id}>
                <td>
                    {
                        block.id !== locationState.blockUpdatingId ?
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="編" onClick={() => handleUpdateBlockButtonClick(block.id)} />
                                    <input type="button" name="btnRowDelete" value="削" onClick={() => handleDeleteBlockButtonClick(block.id)} className="ml-3" />
                                </>
                            )
                            :
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="更新" onClick={() => handleUpdateBlockButtonSaveClick(block.id)} />
                                    <input type="button" name="btnRowDelete" value="キャンセル" onClick={() => handleUpdateBlockCancelButtonClick(block.id)} className="ml-3" />
                                </>
                            )
                    }

                </td>
                <td>
                    {
                        block.id !== locationState.blockUpdatingId ? block.name :
                            (
                                <input type="text" required className="form-input" value={blockUpdate.name} onChange={(e) => { handleChangeBlockRowDataValue('name', e.target.value) }} />

                            )
                    }
                </td>

            </tr>
        ))
    ) : (
        <tr>
            <td colSpan={2}>データなし</td>
        </tr>
    );

    const handleAddLiftClick = async () => {
        try {
            if (liftInsert.name === '') {
                error("リフト名を入力してください。");
                return;
            }
            if (liftInsert.name !== '' && liftInsert.name.length > 10) {
                error("リフト名は10文字以下で入力してください。");
                return;
            }
            const response = await dispatch(locationActions.insertLift(liftInsert));
            if (response.payload && response.payload.success) {
                await dispatch(locationActions.fetchLiftList());
                await dispatch(locationActions.resetLiftInsert());
                success("保存が完了しました。");
            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }

    }
    const handleUpdateLiftButtonClick = async (id: string) => {
        await dispatch(locationActions.setUpdatingLiftId(id));
    }

    const handleUpdateLiftCancelButtonClick = async (id: string) => {
        await dispatch(locationActions.setUpdatingLiftId(''));

    }
    const handleDeleteLiftCancelButtonClick = async () => {
        setShowDeleteLiftConfirmModal(false);
        await dispatch(locationActions.setDeletingLiftId(''));

    }

    const handleUpdateLiftButtonSaveClick = async (id: string) => {
        try {
            if (liftUpdate.name === '') {
                error("リフト名を入力してください。");
                return;
            }
            if (liftUpdate.name !== '' && liftUpdate.name.length > 10) {
                error("リフト名は10文字以下で入力してください。");
                return;
            }
            const response = await dispatch(locationActions.updateLift(liftUpdate));
            if (response.payload && response.payload.success) {
                await dispatch(locationActions.fetchLiftList());
                await dispatch(locationActions.resetLiftUpdate());
                success("保存が完了しました。");
                await dispatch(locationActions.setUpdatingLiftId(''));

            } else {
                error(response.payload.errors[0])
            }

        } catch (error: any) {
            error(error)
        }
    }

    const handleDeleteLiftButtonClick = async (id: string) => {
        await dispatch(locationActions.setDeletingLiftId(id));
        setShowDeleteLiftConfirmModal(true);
    }


    const handleChangeLiftInsertValue = (key: string, value: string | number) => {
        dispatch(locationActions.setInsertLiftData({ key, value }));
    }

    const handleChangeLiftRowDataValue = (key: string, value: string | number) => {
        dispatch(locationActions.setUpdatingLiftData({ key, value }));
    }

    const handleDeleteLiftConfirmButtonClick = async () => {
        const response = await dispatch(locationActions.deleteOneLift(liftDeletingId));
        if (response.payload && response.payload.success) {
            await dispatch(locationActions.fetchLiftList());
            setShowDeleteLiftConfirmModal(false);
            success("保存が完了しました。");
        } else {
            error(response.payload.errors[0])
        }
    }

    const renderLiftList = liftList?.length ? (
        liftList.map((lift: TLocationManagement.BlockModel) => (
            <tr key={lift.id}>
                <td>
                    {
                        lift.id !== locationState.liftUpdatingId ?
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="編" onClick={() => handleUpdateLiftButtonClick(lift.id)} />
                                    <input type="button" name="btnRowDelete" value="削" onClick={() => handleDeleteLiftButtonClick(lift.id)} className="ml-3" />
                                </>
                            )
                            :
                            (
                                <>
                                    <input type="button" name="btnRowEdit" value="更新" onClick={() => handleUpdateLiftButtonSaveClick(lift.id)} />
                                    <input type="button" name="btnRowDelete" value="キャンセル" onClick={() => handleUpdateLiftCancelButtonClick(lift.id)} className="ml-3" />
                                </>
                            )
                    }

                </td>
                <td>
                    {
                        lift.id !== locationState.liftUpdatingId ? lift.name :
                            (
                                <input type="text" required className="form-input" value={liftUpdate.name} onChange={(e) => { handleChangeLiftRowDataValue('name', e.target.value) }} />
                            )
                    }
                </td>

            </tr>
        ))
    ) : (
        <tr>
            <td colSpan={6}>データなし</td>
        </tr>
    );


    return (
        <>
            <LoadingSpinner isShowing={locationState.isLoading} />
            <div className="settingItemPageTitle">
                <p>打設場所名称設定</p>
            </div>
            <div className="row">
                <div className="col-2 col-xs-0" />

                <div className="col-4 setting-container">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th />
                                <th scope="col">ブロック名</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderBlockList}
                            <tr className="insertform">
                                <td>
                                    <input type="button" name="btnAdd" value="追加" onClick={handleAddBlockClick} />
                                </td>
                                <td>
                                    <input type="text" required className="form-input" value={blockInsert.name} onChange={(e) => { handleChangeBlockInsertValue('name', e.target.value) }} />
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-4 setting-container">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th />
                                <th scope="col">リフト名</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderLiftList}
                            <tr className="insertform">
                                <td>
                                    <input type="button" name="btnAdd" value="追加" onClick={handleAddLiftClick} />
                                </td>
                                <td>
                                    <input type="text" required className="form-input" value={liftInsert.name} onChange={(e) => { handleChangeLiftInsertValue('name', e.target.value) }} />
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-2 col-sm-0" />
            </div>


            <div className="goHomeButtonContainer">
                <input type="button" value="戻る" onClick={() => { history.goBack() } } className="goHomeButton" />
            </div>

            <Modal isActive={showDeleteBlockConfirmModal} onClose={() => handleDeleteBlockCancelButtonClick()} width="30%">
                <div className="modal-body">
                    <p>削除します。よろしいですか？</p>
                </div>
                <div className="modal-footer">
                    <input type="button" onClick={handleDeleteBlockConfirmButtonClick} value="削除" />
                    <input type="button" className="ml-3" onClick={() => { handleDeleteBlockCancelButtonClick() }} value="キャンセル" />
                </div>
            </Modal>

            <Modal isActive={showDeleteLiftConfirmModal} onClose={() => handleDeleteLiftCancelButtonClick()} width="30%">
                <div className="modal-body">
                    <p>削除します。よろしいですか？</p>
                </div>
                <div className="modal-footer">
                    <input type="button" onClick={handleDeleteLiftConfirmButtonClick} value="削除" />
                    <input type="button" className="ml-3" onClick={() => { handleDeleteLiftCancelButtonClick() }} value="キャンセル" />
                </div>
            </Modal>
        </>
    );
};

export default connect((state: RootState) => ({
    locationState: state.location,
}))(BlockLiftSetting);
