import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { TShippingInformationManagement } from "./types";
import LocationSelector from "@features/location/selector/LocationSelector";
import { locationSelectorActions } from "@features/location/selector/state_management/actions";

export const initialState: TShippingInformationManagement.TState = {
    shippingInformationList: [],
    shippingInformationListBackup: [],
    pumpList: [],
    mixerTruckList: [],
    isLoading: false,
    listShippingId: [],
    pumpSelect: '',
    isModeSlim: false,
};

export const fetchList = createAsyncThunk(
    "shippingInformationManagement/fetchList",
    async (param: any, { rejectWithValue }) => {
        try {
            const response = await api.shippingInformationAPI.fetchList(param);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchListForEditor = createAsyncThunk(
    "shippingInformationManagement/fetchListForEditor",
    async (param: any, { rejectWithValue }) => {
        try {
            const response = await api.shippingInformationAPI.fetchListForEditor(param);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchPumpList = createAsyncThunk(
    "shippingInformationManagement/fetchPumpList",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.pumpAPI.fetchList(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchMixerTruckList = createAsyncThunk(
    "shippingInformationManagement/fetchMixerTruckList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.mixerTruckAPI.fetchList();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

 

export const updateRange = createAsyncThunk(
    "shippingInformationManagement/updateRange",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.shippingInformationAPI.updateRange(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const deleteOne = createAsyncThunk(
    "shippingInformationManagement/deleteOne",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.shippingInformationAPI.delete(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteRange = createAsyncThunk(
    "shippingInformationManagement/deleteRange",
    async (ids: string[], { rejectWithValue }) => {
        try {
            const response = await api.shippingInformationAPI.deleteRange(ids);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const insertCompressionExperiments = createAsyncThunk(
    "shippingInformationManagement/insertCompressionExperiments",
    async (shipmentId: string, { rejectWithValue }) => {
        try {
            const response = await api.compressionExperimentAPI.insertByShipment(shipmentId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const deleteComressionExperiments = createAsyncThunk(
    "shippingInformationManagement/deleteComressionExperiments",
    async (shipmentId: string, { rejectWithValue }) => {
        try {
            const response = await api.compressionExperimentAPI.deleteByShipment(shipmentId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


const shippingInformationSlice = createSlice({
    name: "shippinginformation",
    initialState,
    reducers: {
        resetData(state) {
            state.shippingInformationList = state.shippingInformationListBackup;
        },
        setCellValue(state, action) {
            const { id, key, value } = action.payload;

            const shippingInfor = state.shippingInformationList.find(x => x.id === id);
            if (shippingInfor) {
                shippingInfor[key] = value;
            }
        },
        setDeleteCheckedRow(state, action) {
            const { id, checked } = action.payload;

            const shippingInfor = state.shippingInformationList.find(x => x.id === id);
            if (shippingInfor) {
                shippingInfor.checked = checked;
            }
        },
        updateShippingInformation(state, action) {
            const dataIndex = state.shippingInformationList.findIndex(item => item.id === action.payload.id);
            if (dataIndex > -1) {
                state.shippingInformationList[dataIndex] = action.payload;
            }
        },
        setShippingToList(state, action) {
            if (!state.listShippingId.includes(action.payload)) {
                // const listNewShippingId = state.listShippingId.filter(item => item !== action.payload);
                state.listShippingId = [action.payload]
            } else {
                state.listShippingId = [];
            }
        },
        setShippingFromMesh(state, action) {
            if (action.payload.type === 1) {
                state.listShippingId = [];
                for (const itemShipping of state.shippingInformationList) {
                    if (itemShipping.meshCodes?.includes(action.payload.meshCode)) {
                        if (!state.listShippingId.includes(itemShipping.id)) {
                            state.listShippingId.push(itemShipping.id)
                        }
                    }
                }
            } else {
                for (const itemShipping of state.shippingInformationList) {
                    if (itemShipping.meshCodes?.includes(action.payload.meshCode)) {
                        if (state.listShippingId.includes(itemShipping.id)) {
                            const listNewShippingId = state.listShippingId.filter(item => item !== itemShipping.id);
                            state.listShippingId = listNewShippingId
                        }
                    }
                }
            }
        },
        changePumpSelect(state, action) {
            state.pumpSelect = action.payload;
            if (state.pumpList?.length > 0) {
                let stateSlim = false;
                for (const itemPump of state.pumpList) {
                    if (state.pumpSelect !== '') {
                        if (state.pumpSelect === itemPump.id && itemPump.isSlim) {
                            stateSlim = true;
                        }
                    } else {
                        if (itemPump.isSlim) {
                            stateSlim = true;
                        }
                    }
                }

                if (stateSlim !== state.isModeSlim) {
                    state.isModeSlim = stateSlim
                }  
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchList.pending,
            (state) => {
                state.isLoading = true;
            }
        );
        builder.addCase(
            fetchList.fulfilled,
            (
                state,
                action: PayloadAction<TShippingInformationManagement.TState["shippingInformationList"]>
            ) => {
                state.shippingInformationList = action.payload;
                state.shippingInformationListBackup = action.payload;
                state.isLoading = false;
            }
        );


        builder.addCase(
            fetchListForEditor.pending,
            (state) => {
                state.isLoading = true;
            }
        );
        builder.addCase(
            fetchListForEditor.fulfilled,
            (
                state,
                action: PayloadAction<TShippingInformationManagement.TState["shippingInformationList"]>
            ) => {
                state.shippingInformationList = action.payload;
                state.shippingInformationListBackup = action.payload;
                state.isLoading = false;
            }
        );

        builder.addCase(
            fetchMixerTruckList.fulfilled,
            (
                state,
                action: PayloadAction<{ data: TShippingInformationManagement.TState["mixerTruckList"] }>
            ) => {
                state.mixerTruckList = action.payload.data;

            }
        );

        builder.addCase(
            fetchPumpList.fulfilled,
            (
                state,
                action: PayloadAction<TShippingInformationManagement.TState["pumpList"]>
            ) => {
                state.pumpList = action.payload;
                if (action.payload?.length > 0) {
                    let stateSlim = false;
                    for (const itemPump of action.payload) {
                        if (state.pumpSelect !== '') {
                            if (state.pumpSelect === itemPump.id && itemPump.isSlim) {
                                stateSlim = true;
                            }
                        } else {
                            if (itemPump.isSlim) {
                                stateSlim = true;
                            }
                        }
                    }
    
                    if (stateSlim !== state.isModeSlim) {
                        state.isModeSlim = stateSlim
                    }  
                }
            }
        );

 
    }
});

export const {
    resetData,
    setCellValue,
    setDeleteCheckedRow,
    updateShippingInformation,
    setShippingToList,
    setShippingFromMesh,
    changePumpSelect,
} = shippingInformationSlice.actions;

export default shippingInformationSlice.reducer;
