import React, { Suspense, useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react';
import * as THREE from 'three';
import { connect } from "react-redux";
import { LocationSelectorState, AppState, Dispatcher, RootState, MeshSettingState, ShippingInformationState, IPump } from "AppTypes";
import { Canvas, useThree } from '@react-three/fiber'
import { OrbitControls, TransformControls, useGLTF, useCursor, useProgress, Html, GizmoHelper, GizmoViewcube, Stage } from '@react-three/drei'
import { proxy, useSnapshot } from 'valtio'
import { spaceMesh } from "@utils/func";
import { shippingInformationActions } from "@features/shippinginformation/state_management/actions";
import { locationSelectorActions } from "@features/location/selector/state_management/actions";
import { args } from '@features/setting/3deditor/ThreeEditor';
import { CameraControls } from '@features/setting/3deditor/CameraControl';
import { meshSettingActions } from "@features/setting/meshcolor/state_management/actions";
import { WARNING_STATUS, STATUS_MESH } from '../../../const/constants'

type Props = {
    dispatch: Dispatcher;
    locationSelectorState: LocationSelectorState;
    appState: AppState;
    meshSettingState: MeshSettingState;
    shippingInformationState: ShippingInformationState
};

const cameraControlOptions: any = {
    zoomStep: 0.2,
    zoomMin: 0.2,
    zoomMax: 4,
    moveStep: 0.2,
    rotationStep: Math.PI / 25,
    defaultOptions: {
        position: [0, 14, 72],
        fov: 40,
        rotation: [0.0666, 0, 0],
        zoom: 1
    }
};


// Reactive state model, using Valtio ...
const modes = ['translate']
const state = proxy({ current: null, mode: 0, fileGLB: '', currentItem: null })
const listMeshName: any[] = []

function Loader() {
    const { active, progress } = useProgress();
    return <Html center>{progress} %</Html>;
}

function Model({ item, isMeshActive, index, meshSettingState, isModeSlim, pumpId, isHidden, setActiveMeshToTable }: any) {
    // Ties this component to the state model
    const snap = useSnapshot(state)
    const meshRef = useRef<THREE.Mesh>(null!)
    // Fetching the GLTF, nodes is a collection of all the meshes
    // It's cached/memoized, it only gets loaded and parsed
    const { nodes } = useGLTF(`${process.env.REACT_APP_GLB_ENDPOINT}${state.fileGLB}`)
    // const { nodes } = useGLTF('/a-18-08-layer.glb')
    // Feed hover state into useCursor, which sets document.body.style.cursor to pointer|auto
    const [hovered, setHovered] = useState(false)
    useCursor(hovered)
    let itemMesh: any = null;
    let opacityMesh = 1;
    for (const [key, value] of Object.entries(nodes)) {
        if (value?.parent?.name === `Block${item.code}` || value?.parent?.name === `${item.code}`) {
            let color = '#FFFAFA';
            if (item.pumpId !== pumpId) {
                opacityMesh = 0.8;
                color = '#B0B3B5';
            }
            // find color with setting system
            if (item.warningStatus !== '') {
                const findValueWarning = meshSettingState?.meshSettingList?.find((itemSetting: any) => itemSetting.code === item.warningStatus);
                if (findValueWarning) {
                    opacityMesh = 1 - (findValueWarning.transferTime / 100);
                    if (findValueWarning.code === WARNING_STATUS[0]) {
                        color = '#f766ff';
                    } else if (findValueWarning.code === WARNING_STATUS[1]) {
                        color = '#F50505';
                    }
                }
            } else {
                const findValueStatus = meshSettingState?.meshSettingList?.find((itemSetting: any) => itemSetting.code === item.status);
                if (findValueStatus) {
                    opacityMesh = 1 - (findValueStatus.transferTime / 100);
                    if (findValueStatus.code === STATUS_MESH[0]) {
                        color = '#FFFAFA';
                    } else if (!item.isSlim && findValueStatus.code === STATUS_MESH[1]) {
                        color = '#FFFF00';
                    } else if (!item.isSlim && findValueStatus.code === STATUS_MESH[2]) {
                        color = '#00B400';
                    } else if (item.isSlim && findValueStatus.code === STATUS_MESH[1]) {
                        color = '#fdfc06';
                    } else if (item.isSlim && findValueStatus.code === STATUS_MESH[2]) {
                        color = '#2670c1';
                    }
                }
            }
            if (isMeshActive) {
                opacityMesh = 1.0;
                color = '#53616E';
            }
            itemMesh = {
                ...item,
                name: value.name,
                color: color,
                indexMesh: index
            }
            const valueMesh = {
                name: value.name,
                indexMesh: index
            }
            if (!listMeshName.includes(valueMesh)) {
                listMeshName.push({
                    name: value.name,
                    indexMesh: index
                })
            }
        }
    }

    if (itemMesh?.name === '' || itemMesh?.name === undefined) {
        return null;
    }

    return (
        <mesh
            castShadow
            receiveShadow
            ref={meshRef}
            // Click sets the mesh as the new target
            onClick={(e) => {
                e.stopPropagation();
                // state.currentItem = itemMesh.name;
                // setBlockActive(itemMesh.indexMesh);
                meshRef.current?.rotation?.set(0, 0, 0);
                setActiveMeshToTable(item.code)
            }}
            // If a click happened but this mesh wasn't hit we null out the target,
            // This works because missed pointers fire before the actual hits
            onPointerMissed={(e) => {
                if (e.type === 'click') {
                    state.current = null;
                    // setBlockActive(null);
                }
            }}
            // Right click cycles through the transform modes
            onContextMenu={(e) => snap.current === itemMesh.name && (e.stopPropagation(), (state.mode = (snap.mode + 1) % modes.length))}
            onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
            onPointerOut={(e) => setHovered(false)}
            name={itemMesh.name}
            /* @ts-ignore */
            geometry={nodes[itemMesh.name].geometry}
            /* @ts-ignore */
            material={nodes[itemMesh.name].material}
            /* @ts-ignore */
            // material-color={snap.current === itemMesh.name ? '#ff6080' : itemMesh.color}
            position={itemMesh.isFloorSelect ? new THREE.Vector3(nodes[itemMesh.name].position.x, nodes[itemMesh.name].position.y + spaceMesh(nodes[itemMesh.name].position.y), nodes[itemMesh.name].position.z) : nodes[itemMesh.name].position}
            material-transparent={true}
            // material-opacity={0.8}
            dispose={null}
            rotation={nodes[itemMesh.name].rotation}
            material-precision={'highp'}
            visible={!isHidden}
            isMesh
        >
            <meshStandardMaterial color={snap.current === itemMesh.name ? '#ff6080' : itemMesh.color} visible={!isHidden} opacity={opacityMesh} transparent={true} />
        </mesh>
    )
}

const Controls = forwardRef((_, ref: any) => {
    // Get notified on changes to state
    const snap = useSnapshot(state)
    const scene = useThree((state) => state.scene)
    scene.rotation?.set(0, 0, 0)
    return (
        <>
            {/* As of drei@7.13 transform-controls can refer to the target by children, or the object prop */}
            {snap.current && <TransformControls object={scene.getObjectByName(snap.current)} mode={modes[snap.mode]} />}
            {/* makeDefault makes the controls known to r3f, now transform-controls can auto-disable them when active */}
            <OrbitControls ref={ref} makeDefault minPolarAngle={0} maxPolarAngle={Math.PI / 1.75} />
        </>
    )
})


const ModelGroup = ({ locationSelectorState, customControlRef, meshSettingState, shippingInformationState, setActiveMeshToTable, isModeSlim }: any) => {
    const refGroup: any = useRef();
    // get color when change pump
    let colorPump = ''
    if (locationSelectorState.pumpSelect && shippingInformationState.pumpList) {
        const findPumpItem = shippingInformationState.pumpList?.find((itemPump: { code: any; }) => itemPump.code === locationSelectorState.pumpSelect);
        if (findPumpItem) colorPump = findPumpItem.color;
    }

    return (
        <group ref={refGroup}>
            {
                locationSelectorState.locationMesh
                && locationSelectorState.locationMesh[0]?.glbFilePath
                && locationSelectorState.locationMesh[0].meshes?.map((meshItem: any, index: number) => {
                    if (locationSelectorState.modeShowFloor === 1 && meshItem?.isHidden) {
                        return null;
                    } else {
                        return <Model
                            item={meshItem}
                            key={meshItem.id}
                            setBlockActive={(indexMesh: number) => {
                                customControlRef.current?.resetRotate();
                            }}
                            index={index}
                            meshSettingState={meshSettingState}
                            isModeSlim={isModeSlim}
                            colorPump={meshItem.pumpId === locationSelectorState.pumpSelect ? colorPump : ''}
                            pumpId={locationSelectorState.pumpSelect}
                            isHidden={meshItem.isHidden || (locationSelectorState.pumpSelect !== '' &&  meshItem.pumpId !== locationSelectorState.pumpSelect)}
                            isMeshActive={locationSelectorState.listMeshActiveTable.includes(meshItem.code)}
                            setActiveMeshToTable={setActiveMeshToTable}
                        />
                    }
                })
            }
        </group>
    )
}

const ThreeViewer: React.FC<Props> = ({ locationSelectorState, appState, dispatch, meshSettingState, shippingInformationState }) => {
    const [getFirst, setGetFirst] = useState(false)
    const locationId = localStorage.getItem('currentLocationId') || appState.currentLocationId || '';
    const [blockActive, setBlockActive] = useState<number>()
    const [listFloors, setListFloors] = useState<any[]>([]);
    const [isGetListFloor, setGetListFloor] = useState(false);
    const customControlRef: any = useRef();
    const ref: any = useRef();
    const [isShowModel, setShowModel] = useState(false);
    const isFirstSet = useRef(false);
    const virtualCamera = useRef<CameraControls['camera']>()
    const cameraControlRef = useRef<CameraControls | null>(null);

    useEffect(() => {
        dispatch(meshSettingActions.fetch());
        if (locationId !== '' && !getFirst) {
            dispatch(locationSelectorActions.changeIsGetMeshPlayHistory(false));
            setBlockActive(-1);
            dispatch(locationSelectorActions.resetShowMesh())
            setGetFirst(true);
            dispatch(locationSelectorActions.fetchLocationMesh({ locationId: locationId, ignoreFilter: false }));
            dispatch(shippingInformationActions.fetchPumpList({ LocationId: locationId }));
        }
        if (locationSelectorState.locationMesh.length > 0
            && locationSelectorState.locationMesh[0].meshes?.length > 0
            && !isGetListFloor) {
            const dataFloors: any[] = []
            locationSelectorState.locationMesh[0].meshes?.forEach((item: any) => {
                const findFloor = dataFloors.find(itemFloor => itemFloor.layer === item.layer)
                if (findFloor === undefined) {
                    dataFloors.push({
                        layer: item.layer,
                        codeFloor: item.code?.substr(0, 5)
                    })
                }
            })
            dataFloors.sort((itemBefore, itemAfter) => itemAfter.layer - itemBefore.layer)
            const dataSetFloors = dataFloors.filter(floor => floor.layer < dataFloors.length)
            setListFloors(dataSetFloors)
            setGetListFloor(true)
        }
    }, [locationId, locationSelectorState.locationMesh]);

    useEffect(
        () => {
            const timer = setTimeout(() => {
                setShowModel(false);
            }, 150);
            return () => clearTimeout(timer);
        },
        [isShowModel]
    );

    const reset = () => {
        customControlRef.current?.reset();
        setShowModel(true);
    }

    if (locationSelectorState.locationMesh === undefined || locationSelectorState.locationMesh?.length === 0) {
        return null;
    }

    if (locationSelectorState.locationMesh?.length > 0) {
        state.fileGLB = locationSelectorState.locationMesh[0]?.glbFilePath
    }

    const changeAction = () => {
        state.current = null;
    }

    const setActiveMesh = (index: number) => {
        // if (index === blockActive) {
        //     setBlockActive(-1);
        //     dispatch(locationSelectorActions.resetShowMesh())
        // } else {
        //     setBlockActive(index);
        //     // setActiveFloor(listFloors[index]);
        //     dispatch(locationSelectorActions.setShowMeshWithFloor({
        //         floorCode: listFloors[index].codeFloor,
        //         layer: listFloors[index].layer,
        //     }));
        // }
        if (index === locationSelectorState.floorActive) {
            setBlockActive(-1);
            dispatch(locationSelectorActions.changeFloorActive(-1))
            dispatch(locationSelectorActions.actionConstructionUpdate({
                ...locationSelectorState.filter,
                floorSelect: -1,
            }))
        } else {
            setBlockActive(index);
            dispatch(locationSelectorActions.changeFloorActive(index));
            dispatch(locationSelectorActions.actionConstructionUpdate({
                ...locationSelectorState.filter,
                floorSelect: listFloors[index].layer,
            }))
        }
    }

    const setActiveMeshToTable = (meshCode: string) => {
        dispatch(locationSelectorActions.changeMeshActiveListMesh(meshCode));
        if (!locationSelectorState.listMeshActiveTable?.includes(meshCode)) {
            dispatch(shippingInformationActions.setShippingFromMesh({
                type: 1,
                meshCode: meshCode,
            }))
        } else {
            dispatch(shippingInformationActions.setShippingFromMesh({
                type: 2,
                meshCode: meshCode,
            }))
        }
    }

    return (
        <div className={shippingInformationState.isModeSlim ? 'three-3d-editor-container alert-slim-mode position-relative' : 'three-3d-editor-container position-relative'}>
            <div className="switch-show-mode switch-show-mode-home">
                <div className="d-flex align-items-center justify-content-start">
                    <div className="text-white text-show-mode">層の表示方法</div>
                    <div className="ml-5">
                        <input
                            type="radio"
                            className="btn-check"
                            name="options-outlined"
                            id="full-layer"
                            autoComplete="off"
                            checked={locationSelectorState.modeShowFloor === 2}
                            onChange={() => {
                                dispatch(locationSelectorActions.setModeShowFloor(2));
                                if (locationSelectorState.floorActive > -1) {
                                    dispatch(locationSelectorActions.actionConstructionUpdate(locationSelectorState.filter));
                                }
                            }}
                        />
                        <label className={`btn btn-show-mode btn-sm ${locationSelectorState.modeShowFloor === 2 ? 'btn btn-outline-warning' : 'btn-light'}`} htmlFor="full-layer">全層</label>
                        <input
                            type="radio"
                            className="btn-check"
                            name="options-outlined"
                            id="washing-tub"
                            autoComplete="off"
                            checked={locationSelectorState.modeShowFloor === 1}
                            onChange={() => {
                                dispatch(locationSelectorActions.setModeShowFloor(1));
                                if (locationSelectorState.floorActive > -1) {
                                    dispatch(locationSelectorActions.actionConstructionUpdate(locationSelectorState.filter));
                                }
                            }}
                        />
                        <label className={`btn btn-show-mode ml-5 btn-sm ${locationSelectorState.modeShowFloor === 1 ? 'btn btn-outline-warning' : 'btn-light'}`} htmlFor="washing-tub">選択層のみ</label>
                    </div>
                </div>
            </div>
            {locationSelectorState.isShowAlert && !shippingInformationState.isModeSlim && (<div className="alert-message-slim text-white position-absolute alert-message-slim-home">薄層打設済みのメッシュがあります。</div>)}
            <Canvas className="view-3d-block" shadows dpr={[1, 2]} camera={{ fov: 50 }}>
                <pointLight position={[10, 10, 10]} intensity={0.8} />
                <hemisphereLight color="#A6A7A5" groundColor="#696B67" position={cameraControlOptions.defaultOptions.position} intensity={0.85} />
                <Suspense fallback={<Loader />}>
                    <Stage controls={ref} contactShadow={{ blur: 1, opacity: 0, position: [0, 0, 0] }} environment={null}>
                        <group dispose={null}>
                            {!isShowModel && (
                                <ModelGroup
                                    locationSelectorState={locationSelectorState}
                                    customControlRef={customControlRef}
                                    meshSettingState={meshSettingState}
                                    shippingInformationState={shippingInformationState}
                                    setActiveMeshToTable={setActiveMeshToTable}
                                    isModeSlim={shippingInformationState.isModeSlim}
                                />
                            )}
                        </group>
                    </Stage>
                    <GizmoHelper alignment="bottom-right" margin={[args.marginX, args.marginY]}>
                        <GizmoViewcube
                            {...args}
                            onClick={() => null}
                        />
                    </GizmoHelper>
                </Suspense>
                <CameraControls ref={cameraControlRef} camera={virtualCamera.current} />
            </Canvas>
            <div className="three-3d-list-mesh three-3d-list-mesh-home">
                <div className="list-mesh list-mesh-home">
                    {locationSelectorState.locationMesh[0]?.glbFilePath
                        && listFloors.map((floor, index) => (
                            <div key={index} className={index === blockActive ? `active-mesh text-center` : 'text-center'} aria-hidden="true" onClick={() => setActiveMesh(index)}>{floor.layer}</div>
                        ))
                    }
                </div>
            </div>
            <div className="three-3d-editor-panel main-3d-editor">
                <div className="controls">
                    <div className="control">
                        <div className="default-action" onClick={() => {
                            if (cameraControlRef.current) {
                                cameraControlRef.current.reset();
                            }
                        }}>
                            リセット
                        </div>
                    </div>
                    <div className="control">
                        <div className="zoom-actions">
                            <div className="action in" onClick={() => {
                                if (cameraControlRef.current) {
                                    cameraControlRef.current.zoom(0.25, true);
                                }
                            }}>
                                <i className="fa fa-plus"></i>
                            </div>
                            <div className="action out" onClick={() => {
                                if (cameraControlRef.current) {
                                    cameraControlRef.current.zoom(-0.25, true);
                                }
                            }}>
                                <i className="fa fa-minus"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect((state: RootState) => ({
    locationSelectorState: state.locationSelector,
    appState: state.app,
    meshSettingState: state.meshSetting,
    shippingInformationState: state.shippingInformation,
}))(ThreeViewer);
