import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { PAGINGATION_DEFAULT } from "@share/const";
import { TMixtureManagement } from "./types";

export const initialState: TMixtureManagement.TState = {
    mixtureList: {
        data: [],
        ...PAGINGATION_DEFAULT,
    },
    isLoading: false,
    detail: {
        data: {
            id: "",
            code: "",
            name1: "",
            name2: "",
            isActive: true,
        },
    },
    insert: {
        data: {
            id: "",
            code: "",
            name1: "",
            name2: "",
            isActive: true,
        },
    },
    updatingId: '',
    deletingId: ''
};

export const fetchList = createAsyncThunk(
    "mixtureManagement/fetchList",
    async (params: TPagination.SendToServer, { rejectWithValue }) => {
        try {
            const response = await api.mixtureAPI.fetchList(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const insert = createAsyncThunk(
    "mixtureManagement/insert",
    async (params: TMixtureManagement.Mixture, { rejectWithValue }) => {
        try {
            const response = await api.mixtureAPI.insert(params);
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }

    }
);

export const update = createAsyncThunk(
    "mixtureManagement/update",
    async (params: TMixtureManagement.Mixture, { rejectWithValue }) => {
        try {
            const response = await api.mixtureAPI.update(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const deleteOne = createAsyncThunk(
    "mixtureManagement/deleteOne",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.mixtureAPI.delete(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const mixtureSlice = createSlice({
    name: "mixture",
    initialState,
    reducers: {
        setInsertData(state, action) {
            const { key, value } = action.payload;
            const { data } = state.insert;
            data[key] = value;
        },
        setUpdatingData(state, action) {
            const { key, value } = action.payload;
            const { data } = state.detail;
            data[key] = value;
        },
        resetDetail(state, _) {
            state.detail = initialState.detail;
        },
        resetInsert(state, _) {
            state.insert = initialState.insert;
        },
        setUpdatingId(state, action) {
            if (action.payload) {
                const updatingItem = state.mixtureList.data.find(x => x.id === action.payload)
                if (updatingItem) {
                    state.detail.data = updatingItem;                  
                }
            } else {
                state.detail = initialState.detail;
            }
            state.updatingId = action.payload;
        },
        setDeletingId(state, action) {
            state.deletingId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(
            fetchList.fulfilled,
            (
                state,
                action: PayloadAction<TMixtureManagement.TState["mixtureList"]>
            ) => {
                state.mixtureList = action.payload;
                state.isLoading = false;
            }
        );
    },
});

export const {
    setInsertData,
    resetDetail,
    setUpdatingId,
    setUpdatingData,
    setDeletingId,
    resetInsert
} = mixtureSlice.actions;

export default mixtureSlice.reducer;
