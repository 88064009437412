
export const isLocalStorageStoredValidInfo = () => {
    const jwtToken = localStorage.getItem('jwtToken')
    const userId = localStorage.getItem('userId')

    return (!!jwtToken && !!userId)
}

export const redirectToLoginIfNoValidAuthInfoStored = (locationPathname: string = '/locationselector') => {
    if (!isLocalStorageStoredValidInfo())
        window.location.replace(`/position-select?redirectTo=${encodeURIComponent(locationPathname)}`)
}

export const redirectToDashboard = () => {
    if (isLocalStorageStoredValidInfo())
        window.location.replace(`/locationselector`)
}

