import * as auth from './api/auth'
import { factoryAPI } from './api/factory'
import { mixtureAPI } from './api/mixture'
import { overlappingAPI } from './api/overlapping'
import { locationAPI } from './api/location'
import { liftAPI } from './api/lift'
import { blockAPI } from './api/block'
import { constructionModelAPI } from './api/constructionmodel'
import { orderAPI } from './api/order'
import { pumpAPI } from './api/pump'
import { shippingInformationAPI } from './api/shippinginformation'
import { mixerTruckAPI } from './api/mixertruck'
import { cardReaderAPI } from './api/cardreader'
import { warningTimeSettingAPI } from './api/warningtimesetting'
import { meshSettingAPI } from './api/meshsetting'
import { displayItemSettingAPI } from './api/displayitemsetting'
import { userAPI } from './api/user'
import { compressionExperimentAPI } from './api/compressionexperiment'
import { meshAPI } from './api/mesh'
import { fileCategoryAPI } from './api/filecategory'
import { documentAPI } from './api/document'
export * as logger from './logger-service'
export * as toast from './toast-service'

export const api = {
    auth,
    factoryAPI,
    mixtureAPI,
    locationAPI,
    pumpAPI,
    orderAPI,
    shippingInformationAPI,
    mixerTruckAPI,
    overlappingAPI,
    cardReaderAPI,
    constructionModelAPI,
    blockAPI,
    liftAPI,
    warningTimeSettingAPI,
    meshSettingAPI,
    displayItemSettingAPI,
    userAPI,
    compressionExperimentAPI,
    meshAPI,
    fileCategoryAPI,
    documentAPI,
}
