import { TFactoryManagement } from '@features/setting/factory/state_management/types'
import axios from '@utils/axios-client'
import { IWarningTimeSetting } from 'AppTypes';

class WarningTimeSettingAPI {
    prefix = '/api/v1/WarningTimeSetting'

    fetch() {
        const url = `${this.prefix}/Get`;
        return axios.get<{ data: IWarningTimeSetting }>(url)
    }

    async update(params: IWarningTimeSetting) {
        const url = `${this.prefix}/Update`
        return await axios.put(url, params)
    }

    
}

export const warningTimeSettingAPI = new WarningTimeSettingAPI()