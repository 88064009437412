import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";

import { TConstructionModelSettingManagement } from "./types";

export const initialState: TConstructionModelSettingManagement.TState = {
    constructionModelList: [],
    uploadFile: null,
    updatingId: "",
    updatingItem: {
        id: "",
        name: "",
        glbFilePath: "",
        minPoint: "",
        maxPoint: "",
    },
    deleteId: "",
    isLoading: false,
    glbUploadFile: null,
};

export const fetchList = createAsyncThunk(
    "constructionModelSettingManagement/fetchList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.constructionModelAPI.fetchList();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const upload = createAsyncThunk(
    "constructionModelSettingManagement/upload",
    async (dataUpload: any, { rejectWithValue }) => {
        try {
            const response = await api.constructionModelAPI.upload(dataUpload);
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }

    }
);


export const update = createAsyncThunk(
    "constructionModelSettingManagement/update",
    async (params: TConstructionModelSettingManagement.ConstructionModel, { rejectWithValue }) => {
        try {
            const response = await api.constructionModelAPI.update(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteOne = createAsyncThunk(
    "constructionModelSettingManagement/deleteOne",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.constructionModelAPI.delete(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const constructionModelSettingSlice = createSlice({
    name: "constructionModelSetting",
    initialState,
    reducers: {
        setUpdatingId(state, action) {
            state.updatingId = action.payload;
            const selectedItem = state.constructionModelList.find(x => x.id == action.payload);
            if (selectedItem) {
                state.updatingItem.id = selectedItem.id;
                state.updatingItem.name = selectedItem.name;
            }
        },
        resetUpdate(state) {
            state.updatingId = "";
            state.updatingItem = initialState.updatingItem;
        },
        handleChangeUpdateData(state, action) {
            const { key, value } = action.payload;

            const { updatingItem } = state;
            if (updatingItem) {
                updatingItem[key] = value;
            }

        },
        setUploadFile(state, action) {
            const file = action.payload;
            if (file && file.name && file.size) {
                state.uploadFile = file;
            }
        },
        setGlbUploadFile(state, action) {
            const file = action.payload;
            if (file && file.name && file.size) {
                state.glbUploadFile = file;
            }
        },
        clearUploadFile(state) {
            state.uploadFile = null;
            state.glbUploadFile = null;
        },
        setDeletingId(state, action) {
            state.deleteId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(
            fetchList.fulfilled,
            (
                state,
                action: PayloadAction<TConstructionModelSettingManagement.ConstructionModel[]>
            ) => {
                state.constructionModelList = action.payload;
                state.isLoading = false;
            }
        );

    },
});

export const {
    setUpdatingId,
    resetUpdate,
    handleChangeUpdateData,
    setUploadFile,
    clearUploadFile,
    setGlbUploadFile,
    setDeletingId,
} = constructionModelSettingSlice.actions;

export default constructionModelSettingSlice.reducer;
