import axios from '@utils/axios-client'
import { appendParams } from '@utils/func'
import { IDisplayItemSetting } from "AppTypes";

class DisplayItemSettingAPI {
    prefix = '/api/v1/DisplayItemSetting'



    async fetchAll(type: number | null) {
        const url = `${this.prefix}/GetAll?type=${type ? type : ''}`;
        const response = await axios.get<{ data: IDisplayItemSetting[] }>(url)
        return response.data;
    }

    async updateRange(data: IDisplayItemSetting[]) {
        const url = `${this.prefix}/UpdateRange`
        return await axios.put(url, { data })
    }


}

export const displayItemSettingAPI = new DisplayItemSettingAPI()