import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { PAGINGATION_DEFAULT } from "@share/const";
import { TOverlappingManagement } from "./types";

export const initialState: TOverlappingManagement.TState = {
  overlappingList: {
    data: [],
    ...PAGINGATION_DEFAULT,
  },
  isLoading: false,
  updatingId: "",
  deletingId: "",
};

export const fetchList = createAsyncThunk(
  "overlappingManagement/fetchList",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await api.overlappingAPI.fetchList(params);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const insert = createAsyncThunk(
  "overlappingManagement/insert",
  async (params: TOverlappingManagement.Overlapping, { rejectWithValue }) => {
    try {
      const response = await api.overlappingAPI.insert(params);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const update = createAsyncThunk(
  "overlappingManagement/update",
  async (params: TOverlappingManagement.Overlapping, { rejectWithValue }) => {
    try {
      const response = await api.overlappingAPI.update(params);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateList = createAsyncThunk(
  "overlappingManagement/updateList",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await api.overlappingAPI.updateList(params);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const deleteOne = createAsyncThunk(
  "overlappingManagement/deleteOne",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.overlappingAPI.delete(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const overlappingSlice = createSlice({
  name: "overlapping",
  initialState,
  reducers: {
    setDeletingId(state, action) {
      state.deletingId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchList.fulfilled, (state,action) => {
        state.overlappingList = action.payload;
        state.isLoading = false;
      }
    );
    builder.addCase(fetchList.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  setDeletingId,
} = overlappingSlice.actions;

export default overlappingSlice.reducer;
