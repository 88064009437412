import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { IUserModel, IUserUpdate } from "AppTypes";
import { TUserSettingManagement } from "./types";
import { IFactoryGetAllModel } from "AppTypes";

export const initialState: TUserSettingManagement.TState = {
    userList: [],
    insert: {
        id: "",
        userName: "",
        passWord: "",
        displayName: "",
        role: "",
        factoryId: null,
        isActive: true
    },
    update: {
        id: "",
        userName: "",
        passWord: "",
        displayName: "",
        role: "",
        factoryId: null,
        isActive: true
    },
    deleteId: "",
    factoryList: [],
    isLoading: false
};


export const fetchList = createAsyncThunk(
    "userManagement/fetchList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.userAPI.fetchList();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const insert = createAsyncThunk(
    "userManagement/insert",
    async (params: IUserUpdate, { rejectWithValue }) => {
        try {
            const response = await api.userAPI.insert(params);
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }

    }
);

export const update = createAsyncThunk(
    "userManagement/update",
    async (params: IUserUpdate, { rejectWithValue }) => {
        try {
            const response = await api.userAPI.update(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const deleteOne = createAsyncThunk(
    "userManagement/deleteOne",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.userAPI.delete(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchFactoryList = createAsyncThunk(
    "userManagement/fetchFactoryList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.factoryAPI.fetchAll();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const userSettingSlice = createSlice({
    name: "userSetting",
    initialState,
    reducers: {
        setInsertData(state, action) {
            const { key, value } = action.payload;
            const { insert } = state;
            if (insert) {
                insert[key] = value;
            }

        },
        setUpdateData(state, action) {
            const { key, value } = action.payload;
            const { update } = state;
            if (update) {
                update[key] = value;
            }

        },
        resetUpdate(state, _) {
            state.update = initialState.update;
        },
        resetInsert(state, _) {
            state.insert = initialState.insert;
        },
        setDeletingId(state, action) {
            state.deleteId = action.payload;
        },
        setUpdatingId(state, action) {
            if (action.payload) {
                const updatingItem = state.userList.find(x => x.id === action.payload)
                if (updatingItem) {
                    state.update = {
                        id: updatingItem.id,
                        userName: updatingItem.userName,
                        passWord: "",
                        displayName: updatingItem.displayName,
                        role: updatingItem.role,
                        factoryId: updatingItem.factoryId,
                        isActive : updatingItem.isActive
                    };
                }
            } else {
                state.update = initialState.update;
            }

        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(
            fetchList.fulfilled,
            (
                state,
                action: PayloadAction<IUserModel[]>
            ) => {
                state.userList = action.payload;
                state.isLoading = false;
            }
        );

        builder.addCase(
            fetchFactoryList.fulfilled,
            (
                state,
                action: PayloadAction<IFactoryGetAllModel[]>
            ) => {
                state.factoryList = action.payload;

            }
        );
    },
});

export const {
    setInsertData,
    setUpdateData,
    resetUpdate,
    resetInsert,
    setDeletingId,
    setUpdatingId
} = userSettingSlice.actions;

export default userSettingSlice.reducer;
