import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import Chart from "react-apexcharts"
import { ShipmentState, AppState, Dispatcher, RootState, ILocationListAllModel, IPump } from "AppTypes";
import { shipmentActions } from "../../shipment/registration/state_management/actions";
import { appActions } from "../../../app/state_management/actions";
import { TFactoryManagement } from '../../setting/factory/state_management/types';
import moment from 'moment';

type Props = {
    dispatch: Dispatcher;
    shipmentState: ShipmentState;
    appState: AppState;
};

const labelXAxisFull = ['06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30',
    '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30',
    '15:00', '15:30', '16:00', '16:30', '17:00', '17:30',
    '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00'];

const labelXAxisLess = ['06:00', '07:00', '08:00', '09:00',
    '10:00', '11:00', '12:00', '13:00', '14:00',
    '15:00', '16:00', '17:00',
    '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00']

const options = {
    chart: {
        height: "100%",
        type: 'line' as const,
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        }
    },
    colors: ['#5F9FF2', '#FCB545', '#E0420F'],
    stroke: {
        curve: 'straight' as const
    },
    dataLabels: {
        enabled: false
    },
    grid: {
        borderColor: '#e7e7e7',
        row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
        },
    },
    xaxis: {
        title: {
            text: '',
            offsetY: -10,
        },
        type: 'category' as const,
        tickPlacement: 'between',
        tickAmount: 15,
        categories: labelXAxisFull,
        labels: {
            formatter: (val: any) => {
                return moment(val).format('HH:mm');
            },
            offsetY: 10,
        },
    },
    yaxis: {
        title: {
            text: '打設量（m3）'
        },
        min: 0,
    },
    legend: {
        position: 'top' as const,
        horizontalAlign: 'right' as const,
        floating: true,
        offsetY: -25,
        offsetX: -5,
    },
    tooltip: {
        x: {
            format: 'YYYY-MM-DD HH:mm:ss'
        }
    }
}

const ProcessChart: React.FC<Props> = ({ shipmentState, appState, dispatch }) => {
    const { dashboardChartExpanded } = appState;
    const locationId = localStorage.getItem('currentLocationId') || appState.currentLocationId || ''
    const { processChartData } = shipmentState;
    const [selectedFilter, setSelectedFilter] = useState("");
    const { factoryList, pumpList } = shipmentState;
    const truckList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const optionFull = {
        ...options,
        xaxis: {
            ...options.xaxis,
            categories: processChartData?.periodTimes?.length > 0 ? processChartData.periodTimes : labelXAxisFull,
            tickAmount: 30
        },
        tooltip: {
            ...options.tooltip,
            x: {
                formatter: (val: any, config: any) => {
                    if (processChartData?.periodTimes?.length > 0) {
                        return processChartData.periodTimes[config.dataPointIndex];
                    }
                    return '';
                },
            }
        }
    }
    const optionLess = {
        ...options,
        xaxis: {
            ...options.xaxis,
            categories: processChartData?.periodTimes?.length > 0 ? processChartData.periodTimes : labelXAxisLess,
        },
        tooltip: {
            ...options.tooltip,
            x: {
                formatter: (val: any, config: any) => {
                    if (processChartData?.periodTimes?.length > 0) {
                        return processChartData.periodTimes[config.dataPointIndex];
                    }
                    return '';
                },
            }
        }
    }

    const optionPlantFull = {
        ...optionFull,
        colors: ['#5F9FF2', '#E0420F']
    }

    const optionPlantLess = {
        ...optionLess,
        colors: ['#5F9FF2', '#E0420F']
    }

    const optionPumpFull = {
        ...optionFull,
        colors: ['#E0420F']
    }

    const optionPumpLess = {
        ...optionFull,
        colors: ['#E0420F']
    }


    const series = [
        {
            name: '実打設',
            data: processChartData?.finishedAmounts || [], //blue
        },
        {
            name: '計画打設量',
            data: processChartData?.plannedVolume || [] //orange
        },
        {
            name: '打設予定量',
            data: processChartData?.plannedAmounts || []//red
        },
    ]


    const filteredSeries = [
        {
            name: '実打設',
            data: processChartData?.finishedAmounts || [], //blue
        },
        {
            name: '打設予定量',
            data: processChartData?.plannedAmounts || []//red
        },
    ]

    const filteredPump = [
        {
            name: '実打設',
            data: processChartData?.finishedAmounts || [], //blue
        },
    ]


    useEffect(() => {
        dispatch(shipmentActions.fetchFactoryList());
        dispatch(shipmentActions.fetchPumpList({ locationId }));
        if (locationId) {
            dispatch(shipmentActions.fetchProcessChartData({ locationId }));
        }
    }, [selectedFilter, locationId]);

    const handleExpandProcessChart = () => {
        dispatch(appActions.setDashboardChartExpaned(!dashboardChartExpanded));
    }

    const handleChangeSelectedFilter = (value: string) => {
        setSelectedFilter(value);
    }

    const handleChangeFilterOption = (value: string) => {
        if (locationId) {
            if (selectedFilter === "FACTORY") {
                dispatch(shipmentActions.fetchProcessChartData({ locationId, factoryId: value }));
            } else if (selectedFilter === "TRUCK") {
                dispatch(shipmentActions.fetchProcessChartData({ locationId, truckCode: value }));
            } else if (selectedFilter === "PUMP") {
                dispatch(shipmentActions.fetchProcessChartData({ locationId, pumpId: value }));
            }
        }
    }

    const renderSeries = () => {
        let dataSeries: ApexAxisChartSeries = series;
        if (selectedFilter === 'FACTORY' && filteredSeries.length > 0) {
            dataSeries = filteredSeries;
        } else if (selectedFilter === 'PUMP' && filteredPump.length > 0) {
            dataSeries = filteredPump;
        }
        return dataSeries;
    }

    const renderOption = () => {
        let optionRender = optionFull;
        if (dashboardChartExpanded) {
            if (selectedFilter === 'FACTORY' && filteredSeries.length > 0) {
                optionRender = optionPlantFull;
            }
        } else {
            optionRender = optionLess
            if (selectedFilter === 'FACTORY' && filteredSeries.length > 0) {
                optionRender = optionPlantLess;
            }
        }
        return optionRender;
    }

    return (
        <>
            <div className="bg-warning text-center cursor-pointer" style={{ fontWeight: "bold", cursor: "pointer" }} onClick={handleExpandProcessChart}>進捗グラフ</div>
            <div className="row container-fluid mt-3">
                <div className="col-1" />
                <div className="col-5">
                    <select className="form-select chartFilter" onChange={(e) => handleChangeSelectedFilter(e.target.value)}>
                        <option value="">全体</option>
                        <option value="FACTORY">工場毎</option>
                        
                        <option value="PUMP">ポンプ車毎</option>
                    </select>
                </div>
                <div className="col-5">
                    <select className="form-select chartFilter" onChange={(e) => { handleChangeFilterOption(e.target.value) }}>
                        <option value=""></option>
                        {
                            selectedFilter === "FACTORY" && factoryList?.length && factoryList.map((f: TFactoryManagement.FactoryGetAllModel) => (
                                <option key={f.id} value={f.id}>{f.name}</option>
                            ))
                        }

                        {
                            selectedFilter === "PUMP" && pumpList?.length && pumpList.map((p: any) => (
                                <option key={p.id} value={p.id}>{p.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div id="chart">
                <Chart options={renderOption()} series={renderSeries()} type="line" height={'80%'} />
            </div>
        </>
    )
}
export default connect((state: RootState) => ({
    shipmentState: state.shipment,
    appState: state.app
}))(ProcessChart);