import React from 'react'
import { Switch, Route } from 'react-router'
import { ToastContainer } from 'react-toastify'
import Login from '@features/auth/Login'
import Main from './layouts/Main'

// Style
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import 'rc-picker/assets/index.css'

// Layouts
import Unauthorized from './layouts/Unauthorized'
import './App.css'
import LoginSelector from "../features/auth/LoginSelector";
import P403 from './403/403';

type Props = {}

const App: React.FC<Props> = () => (
  <>
    <Switch>
      <Route exact path="/unauthorized" component={Unauthorized} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/403" component={P403} />
      <Route path="/position-select" component={LoginSelector} exact />
      <Route path="/" component={Main} />
    </Switch>
    <ToastContainer position="bottom-right" />
  </>
)

export default App
