
import _ from 'lodash'
import React from 'react'
import { useLocation } from "react-router-dom"

export const appendParams = (url: string, paramObj: Object) => {
  let finalUrl = url
  // Picking the boolean value
  const pickTheBoolean = _.pickBy(paramObj, _.isBoolean)
  // because of the identity remove the falsy value (include false), we add the false value to root obj again
  const removedEmptyVal = { ..._.pickBy(paramObj, _.identity), ..._.pickBy(paramObj, (value, key) => key === 'Status' && !_.isString(value)), ...pickTheBoolean }
  Object.keys(removedEmptyVal).forEach((key, index) => {
    const val = paramObj[key as keyof typeof paramObj] as any
    if (val !== '') {
      const startString = index === 0 ? '?' : '&'
      finalUrl += `${startString}${key}=${val}`
    }
  })
  return finalUrl
}

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const spaceMesh = (locationY: number) => {
  let numberSpace = 1;
  if (Number(locationY) > 5 && Number(locationY) < 51) {
    numberSpace += 5
  } else if (Number(locationY) > 50 && Number(locationY) < 101) {
    numberSpace += 15
  } else if (Number(locationY) > 100 && Number(locationY) <= 1000) {
    numberSpace += 50
  } else if (Number(locationY) > 1000 && Number(locationY) < 10000) {
    numberSpace += 500
  } else if (Number(locationY) < 0) {
    numberSpace = 2
  } else if (Number(locationY) > 0 && Number(locationY) < 2) {
    numberSpace = (locationY / 3)
  }  else if (Number(locationY) > 2 && Number(locationY) < 6) {
    numberSpace = (locationY / 6)
  }
  return numberSpace
}

export const listRouteNotCheckLocation = [
  '/setting/user',
  '/setting/factory',
  '/setting/mixture',
  '/setting/filecategory',
  '/document',
  '/setting/warningtime',
  '/setting/mesh',
  '/setting/displayitem',
  '/setting/blocklift',
  '/setting/cardreader',
]
