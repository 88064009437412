export const DATE_FORMAT = 'YYYY.MM.DD'
export const contructionName = ''
export const systemName = ''
export const defaultDatetimeFormat = 'HH:mm DD/MM/YYYY'
export const PAGINATION_PAGE_SIZE = 1000
export const regularNumber = /^[0-9]+$/
export const regularFloat = /^[+-]?([0-9]*[.])?[0-9]+$/
export const regularUserId = /^[a-zA-Z0-9]+$/


export const PAGINGATION_DEFAULT: TPagination.ReceiveFromServer = {
    pageIndex: 1,
    pageSize: PAGINATION_PAGE_SIZE,
    totalRecords: 0,
    totalPage: 0,
    hasNextPage: false,
    hasPreviousPage: false
}

export const QUERY_PAGING: TPagination.SendToServer = {
    PageIndex: 1,
    PageSize: PAGINATION_PAGE_SIZE
}


export const LOGINTYPE = {
    OFFICE: "Office",
    WORKINGSITE: "WorkingSite",
    FACTORY: "Factory",
    LOCATION: "Location",
    PUMPINGMANAGEMENT: "PumpingManagement",
    LOCATIONPUMPINGMANAGEMENT: "LocationPumpingManagement",
    ICTAGMANAGEMENT: "ICTagManagement"
}
