import React from 'react'
import { useTranslation } from 'react-i18next'
import './403.css'

type Props = {}

const P403: React.FC<Props> = () => {
    const { t } = useTranslation()

    return (
        <div id="unexpected-error" style={{ flexDirection: 'column' }}>
            <section className="unexpected-error-block">
                <div className="no-gutters align-items-center">
                    <div className="col-12 unexpected-error-content p-4">
                        <h1>403</h1>
                        <p>{t('messages.pageNotFoundMsg')}</p>
                    </div>
                </div>
            </section>
            <div aria-hidden="true" className="plaintext-button">
                <a className="btn btn-primary" aria-hidden="true" onClick={() => window.history.back()}>
                    {t('actions.goBack')}
                </a>
            </div>
            <div aria-hidden="true" className="plaintext-button mt-3">
                <a className="btn btn-info" aria-hidden="true" onClick={() => window.location.href = '/position-select'}>
                    ログイン
                </a>
            </div>
        </div>
    )
}

export default P403
