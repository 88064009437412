import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "@services";
import { TShipmentManagement } from "./types";
import moment from 'moment'

import {
    ILocationListAllModel,
    IPump,
    IOrderModel,
    IOrderByLocation,
    IProcessChartData,
    IFactoryGetAllModel,
    IWarningTimeSetting
} from 'AppTypes'

export const initialState: TShipmentManagement.TState = {
    pumpList: [],
    locationList: [],
    mixtureList: [],
    factoryList: [],
    order: {
        id: "",
        plannedDate: "",
        locationId: "",
        plannedVolume: 0,
        unloadTimeLimit: 120,
        isActive: true,
        details: [] as TShipmentManagement.OrderDetail[]
    },
    orderDetailInsert: {
        id: "",
        factoryId: "",
        mixtureId: "",
        plannedTransferAmount: 0,
        scheduledShippingStartTime: "",
        scheduledShippingEndTime: "",
        transportTime: 0,
        loadPerTruck: 0,
        locationId: "",
        plannedDate: ""

    },
    orderDetailUpdate: {
        id: "",
        factoryId: "",
        mixtureId: "",
        plannedTransferAmount: 0,
        scheduledShippingStartTime: "",
        scheduledShippingEndTime: "",
        transportTime: 0,
        loadPerTruck: 0,
        locationId: "",
        plannedDate: ""
    },
    isLoading: false,
    updatingOrderDetailId: "",
    deletingOrderDetailId: "",
    selectedPlannedDate: moment(new Date()).format("YYYY-MM-DD"),
    selectedPumpId: "",
    orderByLocation: {
        id: "",
        plannedDate: "",
        blockName: "",
        plannedVolume: 0,
        code: "",
        details: [] as TShipmentManagement.OrderDetail[],
        shipmentStatusReport: {
            completedShipmentCount: 0,
            completedTransferedAmount: 0,
            plannedCompletedTime: null,
            totalTransferedAmount: 0,
            unRegisteredAmount: 0
        },
        weatherInfo: "",
        minTemprature: 0,
        maxTemprature: 0,
        avgTemprature: 0,
    },
    weatherInfoUpdating: "",
    processChartData: {
        plannedVolume: [],
        plannedAmounts: [],
        finishedAmounts: [],
        isCompression: false,
        periodTimes: [],
    },
    warningTimeSetting: {
        beginDeliveryLimit: 120,
        beginDeliveryWarningTime: 110,
        endDeliveryLimit: 120,
        endDeliveryWarningTime: 110,
        overlapLimitTime: 120,
        overlapLimitWarningTime: 110
    }
};

export const fetchOrder = createAsyncThunk(
    "shipmentManagement/fetchOrder",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.orderAPI.fetchOrderByDate(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchOrderByLocation = createAsyncThunk(
    "shipmentManagement/fetchOrderByLocation",
    async (locationId: string, { rejectWithValue }) => {
        try {
            const response = await api.orderAPI.fetchOrderByLocation(locationId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);



export const fetchPumpList = createAsyncThunk(
    "shipmentManagement/fetchPumpList",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.pumpAPI.fetchList(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchFactoryList = createAsyncThunk(
    "shipmentManagement/fetchFactoryList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.factoryAPI.fetchAll();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const fetchMixtureList = createAsyncThunk(
    "shipmentManagement/fetchMixtureList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.mixtureAPI.fetchAll();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchLocationList = createAsyncThunk(
    "shipmentManagement/fetchLocationList",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.locationAPI.fetchAll();
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const insertOrderDetail = createAsyncThunk(
    "shipmentManagement/insertOrderDetail",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.orderAPI.insertDetail(params);
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data)
        }

    }
);

export const updateOrderDetail = createAsyncThunk(
    "shipmentManagement/updateOrderDetail",
    async (params: TShipmentManagement.OrderDetailUpsert, { rejectWithValue }) => {
        try {
            const response = await api.orderAPI.updateDetail(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteOrderDetail = createAsyncThunk(
    "shipmentManagement/deleteOrderDetail",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.orderAPI.deleteDetail(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const updateOrderPlannedVolume = createAsyncThunk(
    "shipmentManagement/updateOrderPlannedVolume",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.orderAPI.updatePlannedVolume(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateLocationLimitTime = createAsyncThunk(
    "shipmentManagement/updateLocationLimitTime",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.locationAPI.updateTimeLimit(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateLocationPump = createAsyncThunk(
    "shipmentManagement/updateLocationPump",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.locationAPI.updatePump(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const updateLocationWeatherInfo = createAsyncThunk(
    "shipmentManagement/updateLocationWeatherInfo",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.locationAPI.updateWeatherInfo(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchProcessChartData = createAsyncThunk(
    "shipmentManagement/fetchProcessChartData",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await api.shippingInformationAPI.fetchProcessChartData(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchWarningTimeSetting = createAsyncThunk(
    "shipmentManagement/fetchWarningTimeSetting",
    async (locationId: string, { rejectWithValue }) => {
        try {
            const response = await api.locationAPI.getWarningTimeSettings(locationId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const shipmentSlice = createSlice({
    name: "shipment",
    initialState,
    reducers: {
        setUpdatingOrderDetailData(state, action) {
            const { key, value } = action.payload;
            if (state.orderDetailUpdate) {
                state.orderDetailUpdate[key] = value;
            }
        },
        setInsertingOrderDetailData(state, action) {
            const { key, value } = action.payload;
            if (state.orderDetailInsert) {
                state.orderDetailInsert[key] = value;
            }
        },


        setLocationData(state, action) {
            const { key, value, selectedLocationId } = action.payload;
            const selectedLocation = state.locationList.find(x => x.id === selectedLocationId);
            if (selectedLocation) {
                selectedLocation[key] = value;
            }
        },

        setWarningTimeSetting(state, action) {
            const { key, value } = action.payload;
            const { warningTimeSetting } = state;
            warningTimeSetting[key] = value;

        },
        setOrderData(state, action) {
            const { key, value } = action.payload;
            if (state.order) {
                state.order[key] = value;
            }
        },
        setUpdatingId(state, action) {

            if (action.payload) {
                const updatingItem = state.order.details.find(x => x.id === action.payload)
                if (updatingItem) {
                    const {
                        id,
                        orderId,
                        factoryId,
                        mixtureId,
                        plannedTransferAmount,
                        scheduledShippingStartTime,
                        scheduledShippingEndTime,
                        transportTime,
                        loadPerTruck
                    } = updatingItem;

                    state.orderDetailUpdate = {
                        ...state.orderDetailUpdate,
                        id,
                        orderId,
                        factoryId,
                        mixtureId,
                        plannedTransferAmount,
                        scheduledShippingStartTime: moment(scheduledShippingStartTime).format("YYYY-MM-DDTHH:mm"),
                        scheduledShippingEndTime: moment(scheduledShippingEndTime).format("YYYY-MM-DDTHH:mm"),
                        transportTime,
                        loadPerTruck
                    }
                }

            } else {
                state.orderDetailUpdate = initialState.orderDetailUpdate;
            }


            state.updatingOrderDetailId = action.payload;
        },
        setDeletingId(state, action) {
            state.deletingOrderDetailId = action.payload;
        },
        resetOrderDetailInsert(state, action) {
            state.orderDetailInsert = initialState.orderDetailInsert;
        },
        resetUpdatingOrderDetail(state, action) {
            state.orderDetailUpdate = initialState.orderDetailUpdate;
            state.updatingOrderDetailId = ";"
        },
        setSelectedPlannedDate(state, action) {
            state.selectedPlannedDate = action.payload;
        },
        setWeatherInfoData(state, action) {
            const { orderByLocation } = state;
            const { key, value } = action.payload;
            if (orderByLocation) {
                orderByLocation[key] = value;
            }

        },
        setWeatherInfoUpdating(state, action) {
            state.weatherInfoUpdating = action.payload;

        },
        updateShipmentOrderDetail(state, action) {
            const indexData = state.orderByLocation.details.findIndex(item => item.id === action.payload.id);
            if (indexData > -1) {
                state.orderByLocation.details[indexData] = action.payload;
            }
        }
    },
    extraReducers: (builder) => {

        builder.addCase(
            fetchPumpList.fulfilled,
            (
                state,
                action: PayloadAction<IPump[]>
            ) => {
                state.pumpList = action.payload;

            }
        );

        builder.addCase(
            fetchMixtureList.fulfilled,
            (
                state,
                action: PayloadAction<any>
            ) => {
                state.mixtureList = action.payload.data;

            }
        );


        builder.addCase(
            fetchFactoryList.fulfilled,
            (
                state,
                action: PayloadAction<IFactoryGetAllModel[]>
            ) => {
                state.factoryList = action.payload;

            }
        );

        builder.addCase(
            fetchLocationList.fulfilled,
            (
                state,
                action: PayloadAction<ILocationListAllModel[]>
            ) => {
                state.locationList = action.payload;

                const location = state.locationList[0];
                if (location) {
                    state.selectedPumpId = location.pumpId;
                }

            }
        );



        builder.addCase(fetchOrder.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(
            fetchOrder.fulfilled,
            (
                state,
                action: PayloadAction<{ data: IOrderModel }>
            ) => {
                state.order = action.payload.data;
                state.order.plannedDate = moment(action.payload.data.plannedDate).format("YYYY-MM-DD")
                const { details } = state.order;

                for (let i = 0; i < details.length; i++) {
                    details[i].scheduledShippingStartTime = moment(details[i].scheduledShippingStartTime).format("YYYY-MM-DDTHH:mm");
                    details[i].scheduledShippingEndTime = moment(details[i].scheduledShippingEndTime).format("YYYY-MM-DDTHH:mm");
                }
                state.isLoading = false;

                if (state.order.plannedDate && state.order?.details?.length > 0) {
                    state.selectedPlannedDate = moment(state.order.plannedDate).format("YYYY-MM-DD");
                }
            }
        );

        builder.addCase(
            fetchOrderByLocation.fulfilled,
            (
                state,
                action: PayloadAction<{ data: IOrderByLocation }>
            ) => {
                if (action.payload.data?.id) {
                    state.orderByLocation = action.payload.data;
                    state.weatherInfoUpdating = state.orderByLocation.weatherInfo
                }

            }
        );

        builder.addCase(
            updateLocationWeatherInfo.fulfilled,
            (
                state
            ) => {

                state.orderByLocation.weatherInfo = state.weatherInfoUpdating;
            }
        );
        builder.addCase(
            fetchProcessChartData.fulfilled,
            (
                state,
                action: PayloadAction<IProcessChartData>
            ) => {
                // const periodLabelList = []
                // if (action.payload.periodTimes?.length) {
                //     for (const time of action.payload.periodTimes) {
                //         periodLabelList.push(moment(time).format('HH:mm'))
                //     }
                // }
                state.processChartData = action.payload;
                // state.processChartData.periodTimes = periodLabelList;
            }
        );

        builder.addCase(fetchWarningTimeSetting.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(
            fetchWarningTimeSetting.fulfilled,
            (
                state,
                action: PayloadAction<{ data: IWarningTimeSetting }>
            ) => {
                state.warningTimeSetting = action.payload.data;

            }
        );
    },
});

export const {
    setLocationData,
    setOrderData,
    resetUpdatingOrderDetail,
    setUpdatingId,
    resetOrderDetailInsert,
    setDeletingId,
    setUpdatingOrderDetailData,
    setInsertingOrderDetailData,
    setSelectedPlannedDate,
    setWeatherInfoData,
    setWeatherInfoUpdating,
    setWarningTimeSetting,
    updateShipmentOrderDetail,
} = shipmentSlice.actions;

export default shipmentSlice.reducer;
